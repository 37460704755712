import { getCellValue } from "../../../../../../../utils/utils";
import {
  MtoJDateFormat,
} from "../../../../../../../utils/date";
import { general } from "../../../../../../../store/action";
import { connect } from "react-redux";
import React from "react";

type PatientAppointmentTableRowItemProps = {
  item: any;
};

const PatientListFamilyTableRowItem = ({
  item,
}: PatientAppointmentTableRowItemProps) => {
  return (
    <tr>
      {/* <td>{getCellValue(item?.healthUnit?.name)}</td> */}
      <td>
        <div className="d-flex">{getCellValue(item?.icd11?.name)}</div>
      </td>
      <td>
        <div>{getCellValue(item?.relation?.name)}</div>
      </td>
      <td>
        <div className="word-spacing-10">
          {MtoJDateFormat(item?.updateDate)}
        </div>
      </td>
      <td>
        <div>{item?.deceased ? 'بله' : 'خیر'}</div>
      </td>
      <td>
        <div>{getCellValue(item?.description)}</div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) =>
    dispatch(general.handleShowModal(data, body)),
});

export default connect(
  null,
  mapDispatchToProps
)(PatientListFamilyTableRowItem);
