import React, { ReactNode } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import Icon from "./icon";

type ModalProps = {
  setShow: (x: boolean) => void;
  showState: boolean;
  className?: string;
  bodyClassName: string;
  size?: "sm" | "lg" | "xl";
  fullscreen?: string | true;
  noCloseBtn?: boolean;
  backdrop?: boolean | "static";
  backdropClassName?: string;
  children: ReactNode;
  animation?: boolean;
  bsPrefix?: string;
}

const Modal = ({
  setShow,
  showState,
  className,
  bodyClassName,
  size,
  fullscreen,
  noCloseBtn,
  backdrop,
  backdropClassName,
  children,
  animation,
  bsPrefix
}: ModalProps) => {
  return(
    <BootstrapModal
      show={showState}
      onHide={() => setShow(false)}
      dialogClassName={className}
      size={size}
      fullscreen={fullscreen}
      backdrop={backdrop}
      backdropClassName={backdropClassName}
      bsPrefix={bsPrefix}
      centered
      animation={animation !== undefined ? animation : true}
    >
      <BootstrapModal.Body className={bodyClassName || 'p-0'}>
        {
          !noCloseBtn &&
            <div className="close-btn" onClick={() => setShow(false)}>
              <Icon name="close-circle" />
            </div>
        }
        {children}
      </BootstrapModal.Body>
    </BootstrapModal>
  )
}

export default Modal;
