import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {general} from "../../../../../../store/action";
import Icon from "../../../../general/icon";
import {getCellValue} from "../../../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../../../utils/date";


type DiagnosisCardsBoxItemProps = {
  item: any,
  handleShowModal: any
  readOnly: boolean
}

const DiagnosisCardsBoxItem = ({
 item,
 handleShowModal,
 readOnly = false
}: DiagnosisCardsBoxItemProps) => {

  return (
    <div className="card shadow-none border mt-4">
      <div className='bg-gray rounded-top justify-content-between'>
        <div className='d-flex just mx-4 my-3 align-items-center'>
          <div className="text-primary font-12 fw-semibold px-4">
            تشخیص:
            <span className='font-en me-3'>
              {getCellValue(item?.diagnosis?.name)}
            </span>
          </div>
          <div className="rmdp-border-right font-12 px-4">
            <span className="text-gray">
              شدت:
            </span>
            <span className='me-3'>
              {getCellValue(item?.severity?.name)}
            </span>
          </div>
          <div className="rmdp-border-right font-12 px-4">
            <span className="text-gray">
              وضعیت:
            </span>
            <span className='me-3'>
              {getCellValue(item?.status?.name)}
            </span>
          </div>
          <div className="rmdp-border-right font-12 px-4">
            <span className="text-gray">
              زمان تشخیص:
            </span>
            <span className='me-3'>
              <bdi>{getCellValue(MtoJFullDateFormat(item?.diagnosisDateTime))}</bdi>
            </span>
          </div>
          {
            !readOnly &&
            <div className='d-flex align-items-center me-auto'>
              <div className='btn btn-gray rounded rounded-3 p-1 mx-2'>
                <span
                  onClick={() => handleShowModal('addDiagnosisModal', {
                    id: item?.id,
                    visitId: item?.visit?.id,
                    patientData: item?.patient
                  })}
                  className='font-20 d-flex  align-items-center h-100 m-1'>
                  <Icon name='pen-square'/>
                </span>
              </div>
              <div className='btn btn-gray rounded rounded-3 p-1  mx-2'>
                <span
                  onClick={() => handleShowModal('deleteDiagnosisModal', item?.id)}
                  className='font-20 d-flex  align-items-center h-100 m-1'>
                  <Icon name='trash'/>
                </span>
              </div>
            </div>
          }
        </div>
      </div>
      <div className='m-4'>
        <div className='mt-2'>
          {item?.note ? getCellValue(item?.note) : 'توضیحی برای این تشخیص ثبت نشده است.'}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(DiagnosisCardsBoxItem);