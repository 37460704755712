import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect, useSelector} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {general} from "../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {useAddPatientVaccine, useGetPatientVaccine} from "../../../../../hook/request/ehr/patientVaccine";
import Modal from "../../../general/modal";
import BoxLoader from "../../../general/boxLoader";
import Icon from "../../../general/icon";
import TextField from "../../../general/form/textField";
import {AsyncSelectField} from "../../../general/form/selectField";
import DatePickerField from "../../../general/form/datePickerField";
import {TAddPatientVaccineFields} from "../../../../../types/patient";
import {addPatientVaccineSchema} from "../../../../../schema/patient-schema";
import TextAreaField from "../../../general/form/textAreaField";
import {useGetVaccines} from "../../../../../hook/request/vaccine";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import {asyncSelectLoadOptions} from "../../../../../utils/asyncSelectLoadOptions";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";


type AddPatientVaccineModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type ModalInfoProps = {
  id: string,
  patientId: string,
  patientData?: any,
}


const AddPatientVaccineModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: AddPatientVaccineModalProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    patientId: '',
    patientData: ''
  });

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [vaccinesList, setVaccinesList] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const fieldValueInitialState = {
    vaccine: "",
    manufacturer: "",
    lotNumber: "",
    vaccinationDateTime: null,
    expirationDate: null
  };
  const [fieldValue, setFieldValue] = useState<TAddPatientVaccineFields>(fieldValueInitialState);
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TAddPatientVaccineFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addPatientVaccineSchema),
  });

  const callbackRef = useRef<any>(null);


  // services
  const addPatientVaccineRequest = useAddPatientVaccine({
    onSuccess: () => {
      handleSetShowModal();
    }
  });
  const getPatientVaccineRequest = useGetPatientVaccine();
  const getVaccinesRequest = useGetVaccines(getQueryParams(filterValues), modalName === 'addPatientVaccineModal');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getVaccinesRequest?.data?.data?.result) {
      const {data} = getVaccinesRequest?.data?.data.result,
        _data = data.map((item: any) => (
          {label: item?.name, value: item?.id}
        ))
      setVaccinesList(_data);
    }
  }, [getVaccinesRequest.data])

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addPatientVaccineModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData?.id
      }
      handleGetPatientVaccine(data)
    }
  }, [modalData, modalName])

  const handleGetPatientVaccine = (data: any) => {
    data.id && getPatientVaccineRequest.mutate(data);
  }

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);


  useEffect(() => {
    if (getPatientVaccineRequest?.data?.data?.result) {
      const {
          imei, updateDate
        } = getPatientVaccineRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          imei,
          updateDate
        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getPatientVaccineRequest.data])

  const addPatientVaccine: SubmitHandler<TAddPatientVaccineFields> = (data) => {
    const { vaccine, manufacturer, lotNumber, vaccinationDateTime, expirationDate, doseNumber } = data,
      body = {
        id: modalInfo?.id ? modalInfo?.id : 0,
        patient: {
          id: modalInfo?.patientId
        },
        entrySource:{
          id:200
        },
        manufacturer, lotNumber, vaccinationDateTime, expirationDate, doseNumber,
        vaccine: {
          id: vaccine,
        }
      };
    addPatientVaccineRequest.mutate(body);
  }

  const changeRequestFilters = (inputValue: any, callback: any) => {
    callbackRef.current = callback;
    setFilterValues({
      ...filterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(callbackRef.current) {
      vaccinesLoadOptions(filterValues.search, callbackRef.current);
    }
  }, [filterValues?.search]);

  const vaccinesLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, filterValues, setFilterValues, getVaccinesRequest);
  };

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue(fieldValueInitialState);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          getPatientVaccineRequest?.isPending ||
          getVaccinesRequest?.isPending ||
          addPatientVaccineRequest?.isPending
        ) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {modalInfo?.id ? "ویرایش واکسیناسیون" : "ثبت واکسیناسیون"}
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.firstName + ' ' + modalInfo?.patientData?.lastName}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(addPatientVaccine)}>
          <div className="row">
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="vaccinationDateTime">تاریخ واکسیناسیون</label>
                <DatePickerField
                  name="vaccinationDateTime"
                  placeholder="تاریخ واکسیناسیون"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="vaccine">واکسن</label>
                <AsyncSelectField
                  options={vaccinesList}
                  name="vaccine"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  // returnObjectValue={true}
                  control={control}
                  error={errors}
                  asyncLoadOptions={changeRequestFilters}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="doseNumber">سری دوز</label>
                <TextField
                  name="doseNumber"
                  placeholder="تولید کننده"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="manufacturer">تولید کننده</label>
                <TextField
                  name="manufacturer"
                  placeholder="تولید کننده"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="lotNumber">سری ساخت (lot)</label>
                <TextField
                  name="lotNumber"
                  placeholder="سری ساخت"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="expirationDate">تاریخ انقضا</label>
                <DatePickerField
                  name="expirationDate"
                  placeholder="تاریخ انقضا"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  rows={2}
                  placeholder="توضیحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                {(isProvider || findAccessInAccessList('EHR_VACCINATIONS_CREATE')) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    {modalInfo?.id ? "ویرایش واکسیناسیون" : "ثبت واکسیناسیون"}
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientVaccineModal);