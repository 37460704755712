import TableLoader from "../../general/table/tableLoader";
import EmptyRow from "../../general/table/emptyRow";
import React from "react";
import VisitTableRowItem from "./visitTable_rowItem";

type VisitTableProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const VisitTable = ({
  dataList,
  dataListTitles,
  isRequesting
}: VisitTableProps) => {
  const _dataListTitles = dataListTitles.map((item, i) => <td key={i}>{item}</td>),
    _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
      return <VisitTableRowItem item={item} key={i}/>
    });

  return (
    <table>
      <thead>
      <tr>
        {_dataListTitles}
      </tr>
      </thead>
      <tbody>
      {isRequesting ?
        <TableLoader colSpan={dataListTitles.length}/> :
        _dataList ? _dataList : <EmptyRow colSpan={dataListTitles.length} content={''}/>
      }
      </tbody>
    </table>
  )
}

export default VisitTable;
