import {connect} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import {general} from "../../../../../store/action";
import {TaminReferralType} from "../../../../../types/visit";
import {getCellValue} from "../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../utils/date";
import {Collapse} from "react-bootstrap";
import Tooltip from "../../../../components/general/tooltip";
import Icon from "../../../../components/general/icon";
import TextAreaField from "../../../../components/general/form/textAreaField";
import {useForm} from "react-hook-form";
import {TTaminReferralPrescription} from "../../../../../types/prescription";

type taminReferralSummary = {
  taminReferralData?: TaminReferralType;
  updateData?: any;
  removeReferralData?: any;
}

// بعد از وارد کردن کد ارجاع تامین، اطلاعات ارجاع رو نمایش میدیم و توضیحات لازم رو از دکتر می‌گیریم.
const TaminReferralSummary = ({
  taminReferralData,
  updateData,
  removeReferralData
}: taminReferralSummary) => {

  const [fieldValue, setFieldValue] = useState<TaminReferralType | null>(null);
  const [showDoctorDescription, setShowDoctorDescription] = useState<boolean>(false);
  const [showChiefComplaints, setShowChiefComplaints] = useState<boolean>(false);
  const [showIcd10s, setShowIcd10s] = useState<boolean>(false);
  const [showReferralFeedback, setShowReferralFeedback] = useState<boolean>(false);
  const [taminReferralPrescriptionFieldValue, setTaminReferralPrescriptionFieldValue] = useState<TTaminReferralPrescription>({
    referralFeedbackId: 0,
    referralFeedbackDescription: ''
  });
  const {
    control,
    reset,
    formState: { errors },
  } = useForm<TTaminReferralPrescription>({
    defaultValues: useMemo(() => taminReferralPrescriptionFieldValue, [taminReferralPrescriptionFieldValue]),
  });

  useEffect(() => {
    if(taminReferralData) {
      setFieldValue(taminReferralData);
    }
  }, [taminReferralData]);

  const handleFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>)=> {
    const { name, value } = e?.target;
    updateData(value, name);
  };

  // حذف ارجاع از نسخه
  const removeReferral = () => {
    setShowReferralFeedback(false);
    updateData(0, 'referralFeedbackId')
    updateData('', 'referralFeedbackDescription')
    removeReferralData();
  }

  return (
    <div className="border rounded mb-3">
      <div className="px-2">
        <h4 className="mx-1 mb-3 mt-1">
          <span className="font-16">اطلاعات ارجاع&emsp;</span>
          <span className={'font-14 text-gray2'}>
            <Tooltip className="tooltip-pre-line" alt={'تاریخ ارجاع'}>
              <span>
                {getCellValue(MtoJDateFormat(fieldValue?.referralDate))}
              </span>
            </Tooltip>
          </span>
        </h4>
      </div>
      <div className="row row-cols-2 justify-content-center">
        <div className="col px-2 mb-4 text-center">
          <div className="font-14 mb-1">ارجاع شده از</div>
          <div className="font-11 d-flex justify-content-center">
            <span className="font-weight-bold">
              <bdi>
                <Tooltip className="tooltip-pre-line" alt={fieldValue?.docSpecReferral?.specCode}>
                  <span>
                    {getCellValue(fieldValue?.docSpecReferral?.specDesc)}
                  </span>
                </Tooltip>
              </bdi>
            </span>
          </div>
        </div>
        <div className="col px-2 mb-4 text-center">
          <div className="font-14 mb-1">ارجاع شده به</div>
          <div className="font-11 d-flex justify-content-center">
            <span className="font-weight-bold">
              <bdi>
                <Tooltip className="tooltip-pre-line" alt={fieldValue?.docSpecReferred?.specCode}>
                  <span>
                    {getCellValue(fieldValue?.docSpecReferred?.specDesc)}
                  </span>
                </Tooltip>
              </bdi>
            </span>
          </div>
        </div>
        <div className="col px-2 mb-4 text-center">
          <div className="d-flex align-items-center justify-content-center font-14 mb-1"
               onClick={() => setShowChiefComplaints(!showChiefComplaints)}>
            <span className="mx-1">
              شکایت بیمار
            </span>
            <span className='bg-gray3 text-gray rounded-3 fw-semibold font-12 mx-1 px-2 py-1'>
              {fieldValue?.complaints?.length} مورد
            </span>
            <span
              onClick={() => setShowChiefComplaints(!showChiefComplaints)}
              className={`btn btn-outline-primary text-nowrap rounded rounded-circle d-flex align-items-center px-2 mx-1 rotate-90 ${showChiefComplaints ? 'rotate' : ''}`}
            >
              <Icon name={'arrow-right'}/>
            </span>
          </div>
          <Collapse in={showChiefComplaints}>
            <div>
              <div className="font-11 d-flex justify-content-center">
                <span className="font-weight-bold">
                  {fieldValue?.complaints?.map((item: any) => {
                    return (
                      <div className={'text-end'}>
                        &#9679; {item?.displayName}
                      </div>
                    )
                  })}
                </span>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="col px-2 mb-4 text-center">
          <div className="d-flex align-items-center justify-content-center font-14 mb-1"
               onClick={() => setShowIcd10s(!showIcd10s)}>
            <span className={'mx-1'}>
              تشخیص اولیه
            </span>
            <span className='bg-gray3 text-gray rounded-3 fw-semibold font-12 mx-1 px-2 py-1'>
              {fieldValue?.icd10s?.length} مورد
            </span>
            <span
              onClick={() => setShowIcd10s(!showIcd10s)}
              className={`btn btn-outline-primary text-nowrap rounded rounded-circle d-flex align-items-center px-2 mx-1 rotate-90 ${showIcd10s ? 'rotate' : ''}`}
            >
              <Icon name={'arrow-right'}/>
            </span>
          </div>
          <Collapse in={showIcd10s}>
            <div>
              <div className="font-11 d-flex justify-content-center">
                <span className="font-weight-bold">
                  {fieldValue?.icd10s?.map((item: any) => {
                    return (
                      <div className={'text-end'}>
                        &#9679; {item?.icdName}
                      </div>
                    )
                  })}
                </span>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="col px-2 mb-4 text-center">
          <div className="d-flex align-items-center justify-content-center font-14 mb-1"
               onClick={() => setShowDoctorDescription(!showDoctorDescription)}>
            <span className="mx-1">
              توضیحات
            </span>
            <span className='bg-gray3 text-gray rounded-3 fw-semibold font-12 mx-1 px-2 py-1'>
              {fieldValue?.message ? 'دارد' : 'ندارد'}
            </span>
            <span
              onClick={() => setShowDoctorDescription(!showIcd10s)}
              className={`btn btn-outline-primary text-nowrap rounded rounded-circle d-flex align-items-center px-2 mx-1 rotate-90 ${showDoctorDescription ? 'rotate' : ''}`}
            >
              <Icon name={'arrow-right'}/>
            </span>
          </div>
          <Collapse in={showDoctorDescription}>
            <div>
              <div className="font-11 d-flex justify-content-center">
                <span className="font-weight-bold">
                  {fieldValue?.message}
                </span>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <div className="p-2">
        <div className="d-flex">
          <span
            className="btn btn-danger rounded rounded-3 text-nowrap font-12 d-flex align-items-center justify-content-center fw-bold px-3 ms-1"
            onClick={removeReferral}
          >
            حذف نسخه بازخورد
          </span>
            <span
              className="btn btn-dark rounded rounded-3 font-12 w-100 d-flex align-items-center justify-content-center fw-bold px-3 me-1"
              onClick={() => setShowReferralFeedback(!showReferralFeedback)}
            >
            بازخورد ارجاع
          </span>
        </div>
        <Collapse in={showReferralFeedback}>
          <div>
            <TextAreaField
              className={'my-2'}
              name="referralFeedbackDescription"
              placeholder="‌توضیحات"
              control={control}
              error={errors}
              onChange={(e) => handleFieldChange(e)}
            />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(TaminReferralSummary);