import React, {useState} from "react";
import {Accordion, Form} from "react-bootstrap";
import FavoriteMedicationPrescriptionRowItem from "./favoriteMedicationPrescription_rowItem";
import {useWindowSize} from "../../../../../../../../hook/useWindowSize";


type FavoriteMedicationPrescriptionRowProps = {
  item?: any
  pushFavoritePrescriptionMedication: any
}


const FavoriteMedicationPrescriptionRow = ({
 item,
 pushFavoritePrescriptionMedication
}: FavoriteMedicationPrescriptionRowProps) => {
  const [width] = useWindowSize();
  const [checkboxes, setCheckboxes] = useState({
    header: false,
    body: item?.medicationItems?.map(() => false),
  });

  // Function to handle changes in the header checkbox
  const handleHeaderCheckboxChange = (e: any) => {
    const isChecked = e.target.checked;
    setCheckboxes({
      header: isChecked,
      body: item?.medicationItems?.map(() => isChecked),
    });
  };

  // Function to handle changes in body checkboxes
  const handleBodyCheckboxChange = (index: number, checked: boolean, item: any) => {
    setCheckboxes((prev) => {
      const updatedBody = [...prev.body];
      updatedBody[index] = checked;
      const allChecked = updatedBody.every(Boolean);
      return {
        header: allChecked,
        body: updatedBody,
      };
    });
    pushFavoritePrescriptionMedication(item, checked)
  };

  return (
    <Accordion.Item eventKey={item?.id} className='mb-3 rounded border'>
      <Accordion.Header className='d-flex flex-wrap'>
        <div className="me-auto" onClick={(e) => e.stopPropagation()}>
          <Form.Check>
            <Form.Check.Label className='d-flex align-items-center w-100'>
              <div className='d-flex fw-bold font-12 ms-2'>{item?.draftTitle}</div>
              <Form.Check.Input
                className='rounded rounded-3 font-22 mb-1'
                name={`header-checkbox-${item?.id}`}
                type='checkbox'
                id={`inline-checkbox-${item?.id}`}
                checked={checkboxes.header}
                onChange={handleHeaderCheckboxChange}
              />
            </Form.Check.Label>
          </Form.Check>
        </div>
        {width > 900 &&
          <span className='font-14 fw-bold me-2 text-black'>
          <span
            className={`bg-primary bg-opacity-10 rounded rounded-3 border border-primary font-12 fw-bold text-primary text-nowrap px-4 py-2 mx-2`}>
            حاوی {item?.medicationItems?.length} قلم دارویی
          </span>
        </span>
        }
      </Accordion.Header>
      <Accordion.Body className='rounded-bottom'>
        <div className=" ">
          <div className="form-group">
            {
              item?.medicationItems?.map((item: any, index: number) => {
                return (
                  <FavoriteMedicationPrescriptionRowItem
                    key={item.id}
                    item={item}
                    index={index}
                    checked={checkboxes.body[index]}
                    onChange={handleBodyCheckboxChange}
                  />
                )
              })
            }
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};


export default FavoriteMedicationPrescriptionRow