import Icon from "../../../components/general/icon";
import {TVisitFields} from "../../../../types/visit";
import {useLocation} from "react-router-dom";
import {VISIT_MODE, VISIT_STATUS_ENUM, VISIT_TYPE_ENUM} from "../../../../constants/constant/enum";
import BrokenVideoAdd from "../../../../assets/images/svgComponents/icon/brokenVideoAdd";
import {useWindowSize} from "../../../../hook/useWindowSize";
import {useDispatch} from "react-redux";
import {videoCall} from "../../../../store/slice/videoCall";

type VisitDetailFooterProps = {
  visitData: TVisitFields
  submitEndVisit: any;
}

const VisitDetailFooter = ({
  visitData,
  submitEndVisit
}: VisitDetailFooterProps) => {

  const [width] = useWindowSize();
  const location = useLocation();
  const mode = location?.state?.mode;
  const dispatch = useDispatch();


  const determineTextColor = () => {
    const statusId = Number(visitData?.status?.id);

    switch (statusId) {
      case VISIT_STATUS_ENUM.INITIALIZED.value:
        return 'text-yellow'
      case VISIT_STATUS_ENUM.RESERVED.value:
        return 'text-yellow'
      case VISIT_STATUS_ENUM.IN_PROGRESS.value:
        return 'text-green2'
      case VISIT_STATUS_ENUM.RESOLVED.value:
        return 'text-primary'
      default:
        return 'text-red'
    }
  }

  const handleEnterVideoCall = () => {
    dispatch(videoCall(+visitData?.id))
  }

  return (
    <footer className='visit-footer'>
      <div className='footer-cont d-flex justify-content-center align-items-center h-100 px-3'>
        {(mode === VISIT_MODE.EDIT.value && +visitData?.visitType?.id === VISIT_TYPE_ENUM.ONLINE.value) &&
          <div className="d-flex align-items-center me-3 ms-auto">
            <span className='d-flex align-items-center text-gray2'>
              <div
                className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start m-md-0">
                <span
                  onClick={() => {
                    handleEnterVideoCall()
                  }}
                  className={`btn btn-light-blue rounded d-flex align-items-center ${width > 900 ? 'px-4' : 'px-2'}`}
                >
                  <span className={`d-inline-flex font-25 ${width > 900 ? 'ms-2 ps-2' : ''}`}><BrokenVideoAdd/></span>
                  {width > 900 && 'برو به ویزیت آنلاین'}
                </span>
              </div>
            </span>
          </div>
        }
        <div className='fw-bold py-1 px-2'>
          <span>وضعیت</span>
          <span className={`me-2 ${determineTextColor()}`}>{visitData?.status?.name}</span>
        </div>
        {mode === VISIT_MODE.EDIT.value &&
          <div className="d-flex align-items-center me-auto ms-3">
            <span className='d-flex align-items-center text-gray2'>
              <div
                className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start m-md-0">
                <span
                  onClick={() => {
                    submitEndVisit()
                  }}
                  className={`btn btn-dark rounded d-flex align-items-center ${width > 900 ? 'px-4' : 'px-2'}`}
                >
                  <span className={`d-inline-flex font-25 ${width > 900 ? 'ms-2 ps-2' : ''}`}><Icon name="clipboard-check"/></span>
                  {width > 900 && 'پایان دادن به ویزیت'}
                </span>
              </div>
            </span>
          </div>
        }
      </div>
    </footer>
  )
}

export default VisitDetailFooter;