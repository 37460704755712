import React, {useEffect} from "react";
import {Form} from "react-bootstrap";
import {useWindowSize} from "../../../../../../../../hook/useWindowSize";


type FavoriteMedicationPrescriptionRowItemProps = {
  item?: any;
  index?: number,
  checked?: any
  onChange?: any
}


const FavoriteMedicationPrescriptionRowItem = ({
 item,
 index,
 checked,
 onChange,
}: FavoriteMedicationPrescriptionRowItemProps) => {
  const [width] = useWindowSize();
  const handleCheckboxChange = (e: any) => {
    const { checked } = e.target;
    onChange(index, checked, item);
  };

  useEffect(() => {
    onChange(index, checked, item);
  }, [checked]);

  return (
    <div className="background-gray-hover border-bottom px-2">
      <Form.Check
        className='py-3'
      >
        <Form.Check.Label className='d-flex align-items-center w-100'>
          <div className={`d-flex align-items-center w-100 fw-bold ltr ${width > 900 && 'col-12'}`}>
            {width > 900 ?
              <>
                <div className="col-2 text-center ms-2">{item?.item?.generic}</div>
                <div className="col-2 text-center">{item?.item?.shape?.name}</div>
                <div className="col-4 text-center">{item?.item?.name}</div>
                <div className="col-1 text-center">{item?.itemCount}</div>
                <div className="col-1 text-center">{item?.frequency?.maxa?.value}</div>
                <div className="col-2 text-center">{item?.dosage?.faName}</div>
              </> :
              <div className="text-start ms-2">
                <span className='text-green mx-1'>{item?.item?.generic}</span>
                <span className='text-gray fw-semibold mx-1'>{item?.item?.shape?.name}</span>
                <span className='font-en mx-1'>{item?.item?.name}</span>
                <span className='text-green2 fw-semibold mx-1'>{item?.itemCount}</span>
                <span className='text-primary fw-semibold mx-1'>{item?.frequency?.maxa?.value}</span>
                <span className='text-orange fw-semibold mx-1'>{item?.dosage?.faName}</span>
              </div>
            }

          </div>
          <Form.Check.Input
            className='rounded rounded-3 font-22 mb-1'
            name={`checkbox-${item?.id}`}
            type='checkbox'
            checked={checked}
            onChange={handleCheckboxChange}
          />
        </Form.Check.Label>
      </Form.Check>
    </div>
  );
};


export default FavoriteMedicationPrescriptionRowItem