import React, { useEffect, useState } from "react";
import {generatePath, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { ROUTES } from "../../../constants";
import Icon from "../../components/general/icon";
import SearchBox from "../../components/general/searchBox";
import Pagination from "../../components/general/table/pagination";
import { useWindowSize } from "../../../hook/useWindowSize";
import { useMySearchParams } from "../../../hook/useMySearchParams";
import { useGetDevice, useGetDeviceAssignedList} from "../../../hook/request/devices";
import DeviceAssignedCardsBox from "../../components/devices/dataList/deviceAssignedCardsBox";
import DeviceAssignedTable from "../../components/devices/dataList/deviceAssignedTable";
import {TAddDeviceField} from "../../../types/device";
import momentJ from "moment-jalaali";
import BoxLoader from "../../components/general/boxLoader";
import DeleteAssignedDeviceModal from "../../components/devices/deleteAssignedDeviceModal";
import {TDataList} from "../../../types/general";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

const dataListTitles = [
  "نام مرکز",
  "نام اپراتور",
  "تاریخ تخصیص",
  "",
]

type TFilterValues = {
  device?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const DeviceAssignedList = () => {

  const [width] = useWindowSize();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const navigate = useNavigate();
  const [deviceAssignedData, setDeviceAssignedData] = useState<TDataList>({
    data: [],
    count: 0,
  });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    device: id,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [fieldValue, setFieldValue] = useState<TAddDeviceField>({
    deviceCode: "",
    deviceType: "",
    imei: "",
    serialCode: "",
    manufactureDate: null
  });

  // services
  const getDeviceAssignedRequest = useGetDeviceAssignedList(getQueryParams(filterValues));
  const getDeviceRequest = useGetDevice();

  useEffect(() => {
    id && getDeviceRequest.mutate({id});
  }, [id])

  useEffect(() => {
    if (getDeviceRequest?.data?.data?.result) {
      const { deviceCode, type, imei, serialCode, manufactureDate } = getDeviceRequest.data.data.result,
        _fieldValue = {
          deviceCode, imei, serialCode,
          deviceType: type?.name,
          manufactureDate: manufactureDate ? momentJ(manufactureDate) : null
        };
      setFieldValue(_fieldValue);
    }
  }, [getDeviceRequest.data])

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getDeviceAssignedRequest?.data?.data?.result &&
    setDeviceAssignedData(getDeviceAssignedRequest?.data?.data?.result)
  }, [getDeviceAssignedRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <div className="d-flex align-items-center mb-2">
          <button
            onClick={() => navigate(-1)}
            className="btn-arrow-right btn btn-dark px-1 d-flex align-items-center justify-content-center size-47 font-25 shadow"
          >
            <Icon name='arrow-right'/>
          </button>
          <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold me-3">
            لیست تخصیص دستگاه با کد
            {
              getDeviceRequest?.isPending ?
                <BoxLoader type="text-loader" className="text-loader mx-2"/> :
                <span className="d-inline-flex fw-semibold text-primary mx-1">
                  {fieldValue?.deviceCode} {fieldValue?.deviceType && '(' + fieldValue?.deviceType + ')'}
                </span>
            }
          </h4>
          <div className='flex-fill'></div>
          <h4 className="d-flex align-items-center font-14 font-weight-bold me-3">
             تعداد تخصیص های این دستگاه:
            {
              getDeviceAssignedRequest?.isPending ?
                <BoxLoader type="text-loader" className="text-loader mx-2"/> :
                <span
                  className="d-inline-flex fw-semibold text-primary mx-1">{deviceAssignedData?.count}
                </span>
            }
          </h4>
        </div>
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی دستگاه"
            />
          </div>
          {
            findAccessInAccessList('DEVICE_ASSIGNMENT_CREATE') &&
            <div className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <span onClick={() => navigate(generatePath(ROUTES.ASSIGN_DEVICE.PATH, {id: id}))}
                    className="btn btn-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                تخصیص دستگاه
              </span>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <DeviceAssignedCardsBox
                dataList={deviceAssignedData}
                dataListTitles={dataListTitles}
                isRequesting={getDeviceAssignedRequest.isPending}
              /> :
              <DeviceAssignedTable
                dataList={deviceAssignedData}
                dataListTitles={dataListTitles}
                isRequesting={getDeviceAssignedRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...deviceAssignedData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>

      <DeleteAssignedDeviceModal />
    </>
  );
};

export default DeviceAssignedList;