import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import Icon from "../../../../components/general/icon";
import SearchBox from "../../../../components/general/searchBox";
import Pagination from "../../../../components/general/table/pagination";
import {useGetPatientAttachments} from "../../../../../hook/request/ehr/patientAttachment";
import {general} from "../../../../../store/action";
import {connect, useSelector} from "react-redux";
import AddPatientAttachmentModal from "../../../../components/patients/ehr/attachment/addPatientAttachmentModal";
import DeletePatientAttachmentModal from "../../../../components/patients/ehr/attachment/deletePatientAttachmentModal";
import PatientAttachmentCardsBox
  from "../../../../components/patients/ehr/attachment/dataList/patientAttachmentCardsBox";
import ShowPatientAttachmentModal from "../../../../components/patients/ehr/attachment/showPatientAttachmentModal";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";


const dataListTitles = [
  "نام فایل",
  "تاریخ افزودن",
  "توضیحات",
  "مشاهده",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type attachmentsListProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const AttachmentsList = ({
  patientData,
  openTab,
  handleShowModal,
  visitEHRCheck = false
}: attachmentsListProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "9",
    search: "",
  });

  // services
  const getAttachmentsRequest = useGetPatientAttachments(getQueryParams(filterValues), openTab === 'attachments');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "9",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getAttachmentsRequest?.data?.data?.result &&
    setAttachmentsData(getAttachmentsRequest?.data?.data.result)
  }, [getAttachmentsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const _filterValues = {
      ...filterValues,
      [name]: value,
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    const _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی فایل ضمیمه"
            />
          </div>
          {((visitEHRCheck && isProvider) || findAccessInAccessList('EHR_ATTACHMENTS_CREATE')) &&
            <div
            className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <span
                onClick={() => handleShowModal('addPatientAttachmentModal', {patientId: id, patientData: patientData})}
                className="btn btn-outline-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                افزودن فایل ضمیمه
              </span>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          <PatientAttachmentCardsBox
                dataList={attachmentsData}
                dataListTitles={dataListTitles}
                isRequesting={getAttachmentsRequest.isPending}
          />
          <Pagination
            paginationData={{...filterValues, ...attachmentsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      {openTab === 'attachments' && <AddPatientAttachmentModal/>}
      <ShowPatientAttachmentModal/>
      <DeletePatientAttachmentModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AttachmentsList);
