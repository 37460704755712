import debounce from "lodash/debounce";

// Main function
export const asyncSelectLoadOptions = (
  inputValue: any,
  callback: any,
  filterValues: any,
  setFilterValues: any,
  selectRequest: any,
  mapItemToOption: (item: any) => { label: string, value: any } = (item) => ({ label: item?.name, value: item?.id })
) => {
  if (!inputValue) {
    console.log('entered', inputValue, callback)
    return callback([]);
  }

  // Call the debounced function, passing all arguments
  debouncedFetchOptions(inputValue, callback, filterValues, setFilterValues, selectRequest, mapItemToOption);
};


// Define the debounced function outside of asyncSelectLoadOptions
const debouncedFetchOptions = debounce(
  async (
    inputValue: string,
    callback: any,
    filterValues: any,
    setFilterValues: any,
    selectRequest: any,
    mapItemToOption: (item: any) => { label: string; value: any }
  ) => {
    try {
      const response = await selectRequest.refetch();
      let data = response?.data?.data?.result?.data;

      if (!data) {
        setTimeout(() => {
          asyncSelectLoadOptions(
            inputValue,
            callback,
            filterValues,
            setFilterValues,
            selectRequest,
            mapItemToOption
          );
        }, 500); // wait 100ms before retrying
        return;
      }

      if (Array.isArray(data)) {
        const _data = data.map(mapItemToOption);
        callback(_data);
      } else {
        callback([]);
      }
    } catch (error) {
      callback([]);
    }
  },
  700 // Adjust debounce delay as needed
);