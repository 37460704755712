import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {PrescriptionParaclinicalServiceService} from "../../services/requests/paraclinical";

const useGetPrescriptionParaclinicalServiceItems = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getPrescriptionParaclinicalServiceItems", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PrescriptionParaclinicalServiceService.getPrescriptionParaclinicalServiceItemsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetPrescriptionParaclinicalServiceItem = () => {
  return useMutation({
    mutationFn: PrescriptionParaclinicalServiceService.getPrescriptionParaclinicalServiceItem,
  })
}

const useAddPrescriptionParaclinicalServiceItem = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: PrescriptionParaclinicalServiceService.addPrescriptionParaclinicalServiceItem,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.MEDICATION.PATH);
        }
      })
    },
  })
}

const useDeletePrescriptionParaclinicalServiceItem = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PrescriptionParaclinicalServiceService.deletePrescriptionParaclinicalServiceItem,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPrescriptionParaclinicalServiceItems"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetPrescriptionParaclinicalServiceItems,
  useGetPrescriptionParaclinicalServiceItem,
  useAddPrescriptionParaclinicalServiceItem,
  useDeletePrescriptionParaclinicalServiceItem
}
