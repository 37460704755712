import { ToastPosition, toast } from "react-hot-toast";

const Noti = (
  type: "success" | "error",
  message: string,
  position?: ToastPosition,
  duration?: number
) => {
  if(!message) return false;
  return toast(message,
    {
      ...(type && { type }),
      position: position || 'top-left',
      duration: duration || 3000,
      // reverseOrder: false
    }
  );
}

export default Noti;