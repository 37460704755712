import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {connect, useSelector} from "react-redux";
import {useWindowSize} from "../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import SearchBox from "../../../components/general/searchBox";
import SelectField from "../../../components/general/form/selectField";
import Pagination from "../../../components/general/table/pagination";
import {general} from "../../../../store/action";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";
import Icon from "../../../components/general/icon";
import {useGetDraftPrescriptionList} from "../../../../hook/request/visit/draftPrescription";
import {Accordion} from "react-bootstrap";
import EmptyBox from "../../../components/general/emptyBox";
import FavoriteParaclinicalPrescriptionRowAccordion
  from "../../../components/prescription/favoritePrescriptions/dataList/favoriteParaclinicPrescription_rowAccordion";
import BoxLoader from "../../../components/general/boxLoader";
import DeleteFavoritePrescriptionModal
  from "../../../components/prescription/favoritePrescriptions/deleteFavoritePrescriptionModal";
import {ROUTES} from "../../../../constants";


const filterOptions = [
  {value: 100, label: "نسخ دارویی"},
  {value: 200, label: "نسخ پاراکلینیک"},
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  draftMedication?: boolean
}


type FavoritePrescriptionsListProps = {
  handleShowModal: any;
}

const FavoritePrescriptionsList = ({
 handleShowModal
}: FavoritePrescriptionsListProps) => {

  const [width] = useWindowSize();
  const navigate = useNavigate();
  const filterValuesInitialState = {
    pageNumber: "0",
    perPage: "10",
    search: '',
    draftMedication: true,
  }
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>(filterValuesInitialState);
  const [favoritePrescriptionsList, setFavoritePrescriptionsList] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TFilterValues>({
    defaultValues: useMemo(() => filterValues, [filterValues]),
  });

  // services
  const getDraftPrescriptionListRequest = useGetDraftPrescriptionList(
    getQueryParams(filterValues),true);


  useEffect(() => {
    if (getDraftPrescriptionListRequest?.data?.data?.result) {
      const { data } = getDraftPrescriptionListRequest.data.data.result
      setFavoritePrescriptionsList(data);
    }
  }, [getDraftPrescriptionListRequest.data])


  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    let _filterValues = {
      ...filterValues,
      pageNumber: "0",
      perPage: filterValues?.perPage,
      search: value
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  const openFilterDropDown = (param: any) => {
    if(param?.target?.value != null) {
      const {value} = param?.target?.value
      setFilterValues({
        ...filterValues,
        draftMedication: value !== 200
      })
    } else {
      setFilterValues({
        ...filterValues,
        draftMedication: true
      })
    }
  }


  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder={filterValues?.draftMedication === false ? "جستجوی نسخ پاراکلینیک" : 'جستجوی نسخ دارویی'}
            />
          </div>
          <div className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
            <SelectField
              options={filterOptions}
              name="status"
              className={'minw-120 mx-4'}
              placeholder='نوع راانتخاب کنید'
              control={control}
              error={errors}
              onChange={(e) => openFilterDropDown(e)}
              returnObjectValue={true}
            />
            {
              (isProvider || findAccessInAccessList('VISIT_VIEW')) &&
              <button
                onClick={() => filterValues?.draftMedication === false ? navigate(ROUTES.ADD_FAVORITE_PARACLINIC_PRESCRIPTION.PATH) : navigate(ROUTES.ADD_FAVORITE_MEDICATION_PRESCRIPTION.PATH)}
                className="btn btn-primary rounded d-flex align-items-center fw-bold px-4 ms-2"
              >
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                {filterValues?.draftMedication === false ? 'افزودن نسخه پاراکلینیک' : 'افزودن نسخه دارویی'}
              </button>
            }
          </div>
        </section>

        <section className="table-cont table-responsive">
          <Accordion className='visits-accordion rounded mb-4 mx-2' alwaysOpen style={{ maxHeight: '100%', overflowY: 'auto' }}>
            { getDraftPrescriptionListRequest?.isPending ? <BoxLoader type="cover" /> :
              favoritePrescriptionsList.length > 0 ?
                favoritePrescriptionsList.map((item: any, key: number) => {
                  return (
                    <FavoriteParaclinicalPrescriptionRowAccordion
                      key={key}
                      item={item}
                      draftMedication={filterValues?.draftMedication}
                    />
                  )
                }) : <EmptyBox/>
            }
          </Accordion>
          <Pagination
            paginationData={{...filterValues, ...favoritePrescriptionsList}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <DeleteFavoritePrescriptionModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(FavoritePrescriptionsList);