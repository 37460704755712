import {generatePath, useNavigate} from "react-router-dom";
import {getCellValue} from "../../../../../utils/utils";
import Icon from "../../../general/icon";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";
import React from "react";
import Tooltip from "../../../general/tooltip";
import {ROUTES} from "../../../../../constants";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

type VaccineTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const VaccineTableRowItem = ({
 item,
 handleShowModal
}: VaccineTableRowItemProps) => {
  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const toolTipTemplate = `کد مکسا: ${getCellValue(item?.maxa?.code)}\n
    مقدار مکسا: ${getCellValue(item?.maxa?.value)}\n
    ترمینولوژی: ${getCellValue(item?.maxa?.terminologyId)}
    `

  return (
    <tr>
      <td>{getCellValue(item?.name)}</td>
      <td>{getCellValue(item?.description)}</td>
      <td>
        <Tooltip alt={toolTipTemplate} className="tooltip-pre-line" placement='bottom'>
          <span className="item-lnk cur-pointer">
              <span className="item-txt hide-collapsed">{getCellValue(item?.maxa?.value)}</span>
          </span>
        </Tooltip>
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {
            findAccessInAccessList('BASIC_VACCINE') &&
            <>
              <Tooltip alt={'ویرایش'} className="tooltip-pre-line">
                <span
                  onClick={() => navigate(generatePath(ROUTES.VACCINE_EDIT.PATH, {id: item?.id}))}
                  className="d-flex text-hover mx-2 font-20 cur-pointer"
                >
                  <Icon name='pen-square'/>
                </span>
              </Tooltip>
              <Tooltip alt={'حذف'} className="tooltip-pre-line">
                <span
                  onClick={() => handleShowModal('deleteVaccineModal', item?.id)}
                  className="d-flex text-red-hover mx-2 font-20 cur-pointer"
                >
                  <Icon name='trash'/>
                </span>
              </Tooltip>
            </>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VaccineTableRowItem);
