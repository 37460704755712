
type BoxLoaderProps = {
  className?: string,
  type?: string
};

const BoxLoader = ({
  className,
  type
}: BoxLoaderProps) => {
  return (
    <div className={`box-loader d-flex justify-content-center align-items-center ${type === 'cover' ? 'cover-mode' : '' } ${className || ''}`}>
      {
        type !== "text-loader" &&
          <div className="spinner-grow text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
      }
    </div>
  )
}

export default BoxLoader;