import * as React from "react"
import { SVGProps } from "react"
const Thermometers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={58}
    viewBox="0 0 56 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_6854_10654)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.421 40.7525L15.6243 41.0737C14.3993 41.5677 13.0057 40.9751 12.5117 39.7501C12.0177 38.525 12.6103 37.1315 13.8354 36.6375L14.6321 36.3162C15.5305 35.9539 16.2155 35.2028 16.4939 34.2749L16.8597 33.0555C17.2772 31.6637 18.3047 30.5371 19.6524 29.9937L38.2228 22.505C41.4895 21.1876 45.2057 22.7679 46.5231 26.0347C47.8404 29.3015 46.2601 33.0176 42.9933 34.335L41.1374 35.0834L40.0483 32.3828C39.8031 31.7746 39.1111 31.4803 38.5029 31.7256C37.8947 31.9709 37.6004 32.6628 37.8457 33.271L38.9347 35.9717L34.4932 37.7627L33.4042 35.0621C33.1589 34.4539 32.467 34.1596 31.8587 34.4049C31.2505 34.6502 30.9562 35.3421 31.2015 35.9504L32.2906 38.651L27.8286 40.4503L26.7396 37.7497C26.4943 37.1414 25.8024 36.8472 25.1941 37.0925C24.5859 37.3378 24.2916 38.0297 24.5369 38.6379L25.626 41.3386L24.423 41.8237C23.0753 42.3671 21.5537 42.2685 20.2874 41.5557L19.1781 40.9311C18.3339 40.4559 17.3195 40.3902 16.421 40.7525ZM37.9955 30.7539C38.6038 30.5086 38.898 29.8167 38.6527 29.2085C38.4075 28.6002 37.7155 28.306 37.1073 28.5513L23.6089 33.9946C23.0007 34.2399 22.7064 34.9318 22.9517 35.5401C23.197 36.1483 23.8889 36.4426 24.4971 36.1973L37.9955 30.7539Z"
        fill="#EBEFFF"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.846 39.748L16.3993 40.5182C15.7125 41.7025 14.2364 42.0407 13.1024 41.2735C11.9684 40.5064 11.6059 38.9243 12.2928 37.74L12.7395 36.9697C13.2432 36.1011 13.3416 35.0239 13.0069 34.0419L12.567 32.7514C12.0649 31.2785 12.2125 29.6626 12.9681 28.3597L23.3803 10.4059C25.2119 7.24765 29.1481 6.34581 32.1721 8.39163C35.1961 10.4374 36.1626 14.6562 34.331 17.8145L33.2904 19.6088L30.7905 17.9175C30.2275 17.5366 29.4946 17.7045 29.1535 18.2926C28.8125 18.8806 28.9925 19.6661 29.5555 20.047L32.0554 21.7383L29.5651 26.0323L27.0652 24.3411C26.5022 23.9602 25.7693 24.1281 25.4282 24.7161C25.0872 25.3042 25.2672 26.0897 25.8302 26.4706L28.3301 28.1619L25.8283 32.4756L23.3284 30.7844C22.7654 30.4035 22.0325 30.5714 21.6915 31.1594C21.3504 31.7475 21.5304 32.533 22.0934 32.9139L24.5933 34.6051L23.9188 35.7682C23.1632 37.0711 21.8815 37.9197 20.4353 38.0746L19.1684 38.2103C18.2042 38.3136 17.3498 38.8793 16.846 39.748ZM28.1613 17.7551C28.5023 17.167 28.3223 16.3815 27.7593 16.0006C27.1963 15.6197 26.4634 15.7876 26.1223 16.3756L18.554 29.4258C18.2129 30.0139 18.3929 30.7994 18.9559 31.1803C19.519 31.5612 20.2519 31.3933 20.5929 30.8052L28.1613 17.7551Z"
      fill="#97ABFF"
    />
    <defs>
      <clipPath id="clip0_6854_10654">
        <rect
          width={38}
          height={38}
          fill="white"
          transform="translate(20.2344 7.66309) rotate(23.0378)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default Thermometers
