import {useFieldArray, useForm} from "react-hook-form";
import {connect} from "react-redux";
import React, {useMemo, useState} from "react";
import {Accordion} from "react-bootstrap";
import {general} from "../../../../store/action";
import AddPrescriptionParaclinicalItemModal
  from "../../../components/visits/visitDetail/prescription/paraclinical/addPrescriptionParaclinicalItemModal";
import Icon from "../../../components/general/icon";
import {TAddPrescriptionParaclinicalItemFields} from "../../../../types/paraclinicalItem";
import {useWindowSize} from "../../../../hook/useWindowSize";
import FavoriteParaclinicPrescriptionRowItem
  from "../../../components/prescription/favoritePrescriptions/favoriteParaclinicPrescriptionRowItem";
import FavoriteParaclinicPrescriptionRowItemResponsive
  from "../../../components/prescription/favoritePrescriptions/favoriteParaclinicPrescriptionRowItemResponsive";
import FavoritePrescriptionModal from "../../../components/visits/visitDetail/prescription/favoritePrescriptionModal";
import {useNavigate} from "react-router-dom";

/*
    کامپوننت مربوط به قسمت تجویز پاراکلینیک برای نسخه
    Parent: <Prescription/>
    Children: <ParaclinicalPrescriptionEmptyRow/>, <ParaclinicalPrescriptionRowItem/>
              <AddPrescriptionParaclinicalItemModal/>, <FavoriteParaclinicalItemModal/>,
              <FavoriteParaclinicalPrescriptionModal/>, <ParaclinicalPrescriptionHistoryModal/>
              <FavoritePrescriptionModal/>
    در این کامپوننت لیستی از پاراکلینیک های تجویز شده رندر میشود،
    و در صورتی که بیمه شخص بیمه سلامت باشد، ریکوئست های لازم
    زده میشود و چک کد از بیمه دریافت میشود.
*/

type paraclinicalPrescriptionProps = {
  handleShowModal: any;
}

type PrescriptionParaclinicalItemFieldArray = {
  root: TAddPrescriptionParaclinicalItemFields[]
}

const AddFavoriteParaclinicPrescription = ({
  handleShowModal,
}: paraclinicalPrescriptionProps) => {

  //<editor-fold desc="States">
  const navigate = useNavigate()
  const [width] = useWindowSize();
  const [paraclinicalFieldValue, setParaclinicalFieldValue] = useState<PrescriptionParaclinicalItemFieldArray>({root: []});
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useForm<PrescriptionParaclinicalItemFieldArray>({
    defaultValues: useMemo(() => paraclinicalFieldValue, [paraclinicalFieldValue]),
  });
  const {fields, append, update, remove} = useFieldArray({
    name: 'root',
    control
  })
  //</editor-fold>

  /*
    ساخت مدل پاراکلینیک برای نسخه
    @param: result
    @returns: TAddPrescriptionParaclinicalItemFields
  */
  const addResultToParaclinicalDataList = async (item: any) => {
    let prescriptionParaclinicalItem: TAddPrescriptionParaclinicalItemFields = {
      service: item?.service ? item.service : item,
      emergency: item?.emergency || false,
      itemCount: item?.itemCount || '1',
      activeDate: item?.activeDate || Date.now()
    }
    prescriptionParaclinicalItem = {...prescriptionParaclinicalItem}
    return prescriptionParaclinicalItem;
  }

  /*
    بعد از سابمیت کردن مودال اضافه کردن پاراکلینیک
    @param: fieldValue
    @param: index
  */
  const handleParaclinicalModalSubmit = async (fieldValue: any, index: number) => {
    let targetIndex = index;
    let checkCodes: string[] = [];
    for (const item of fieldValue) {
      try {
        console.log(checkCodes)
        const result = await addResultToParaclinicalDataList(item);
        if (targetIndex !== -1) {
          update(targetIndex, result);
          targetIndex = -1
        } else {
          append(result);
        }

        // Move on to the next item
      } catch (error) {
        console.error("Error processing prescription:", error);
      }
    }
  }

  /*
    پاک کردن پاراکلینیک از لیست تجویز.
    این فانکشن به فرزند فرستاده میشه و اونجا روش کلیک میشه
    @param: index
  */
  const deleteParaclinicalItemFromList = (index: any) => {
    if (index >= 0 && index < fields.length) {
      // پاراکلینیک براساس ایندکسش فیلتر میشه
      remove(index)
    }
  }

  /*
    بعد از تغییر مقدار هر اینپوت، استیت اون قسمت تغییر کنه
    و ریکوئست با داده جدید به بیمه سلامت زده بشه.
    @param: name
    @param: value
    @param: index
  */
  const onFieldsChange = (name: string, value: any, index: number, paraItem: any) => {
    // جدا کردن اسم اینپوت از آی دی
    const fieldName = name.split('_')[0];
    let fieldValue = {...paraItem, [fieldName]: value}

    update(index, { ...fieldValue})
  }

  const _renderRowItems = (responsive: boolean) => fields.map((item, index) => {
    return (
      responsive ?
        <FavoriteParaclinicPrescriptionRowItemResponsive
          item={item}
          index={index}
          control={control}
          errors={errors}
          onFieldsChange={onFieldsChange}
          handleParaclinicalModalSubmit={handleParaclinicalModalSubmit}
          deleteParaclinicalItemFromList={deleteParaclinicalItemFromList}
          register={register}
          watch={watch}
          update={update}
        /> :
        <FavoriteParaclinicPrescriptionRowItem
          item={item}
          index={index}
          control={control}
          errors={errors}
          onFieldsChange={onFieldsChange}
          handleParaclinicalModalSubmit={handleParaclinicalModalSubmit}
          deleteParaclinicalItemFromList={deleteParaclinicalItemFromList}
          register={register}
          watch={watch}
          update={update}
        />
    )
  })


  return (
    <div className={'card-box'}>
      {
        fields.length === 0 ?
          <div className="d-flex">
            <div className="card w-100 shadow-none border mb-4">
              <div className='bg-gray rounded-top'>
                <div className='d-flex just mx-4 my-3 align-items-center justify-content-center'>
                  <Icon name='clipboard-tick'/>
                  <span className="fw-bold text-center text-primary px-4">
                  هیچ خدمتی برای این نسخه ثبت نشده است.
                </span>
                </div>
              </div>
            </div>
          </div>
          :
          width < 900 ?
            <Accordion className='prescription-items-accordion rounded' alwaysOpen>
              {_renderRowItems(true)}
            </Accordion> :
            _renderRowItems(false)

      }
      {
        <div className="d-flex flex-row-reverse">
          <div
            className="flex-grow-1 align-items-center justify-content-center justify-content-md-start m-md-0">
            <span
              onClick={() => handleShowModal('addPrescriptionParaclinicalItemModal',
                {
                  // fieldValue: selectedMedicationItems,
                  handleParaclinicalModalSubmit: handleParaclinicalModalSubmit
                })}
              className="btn btn-primary justify-content-center rounded d-flex align-items-center font-14 fw-bolder px-4 mb-3">
              <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
              {width > 900 ? 'افزودن پاراکلینیک جدید' : 'پاراکلینیک جدید'}
            </span>
          </div>
          {
            fields.length !== 0 &&
            <div
              className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
              <span
                onClick={() => handleShowModal('favoritePrescriptionModal',
                  {
                    prescriptionData: fields,
                    draftMedication: false,
                    navigateToList: () => navigate(-1)
                  })}
                className="btn btn-dark rounded d-flex align-items-center px-4 mx-2 mb-3">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="document-add-fill"/></span>
                {width > 900 ? 'افزودن به عنوان نسخه پراستفاده' : 'نسخه پر‌استفاده'}
              </span>
            </div>
          }
        </div>
      }
      <AddPrescriptionParaclinicalItemModal/>
      <FavoritePrescriptionModal/>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AddFavoriteParaclinicPrescription);