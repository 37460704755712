import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Modal from "../general/modal";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import Icon from "../general/icon";
import {useGetTaminAuth, useVerifyTaminAuth} from "../../../hook/request/providerTamin";
import BoxLoader from "../general/boxLoader";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import {localStorageSet} from "../../../utils/localStorage";
import Noti from "../general/noti";


type TaminLoginModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  checkForTaminAuthorization?: () => void,
}

// ورود به سامانه تامین اجتماعی
// اول وارد لاگین تامین میشیم، با وارد کردن اطلاعات به سایت خودمون ریدایرکت می‌شیم و یک توکن به شکل url از تامین میگیریم.
// توکن رو برای ریکوئست وریفای میفرستیم و از طریق اون توکن اصلی رو از تامین میگیریم و سیو میکنیمش.
const TaminLoginModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: TaminLoginModalProps) => {

  const { getQueryParams } = useMySearchParams();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    checkForTaminAuthorization: undefined,
  });
  const [currentUrl, setCurrentUrl] = useState("");
  const [isRedirected, setIsRedirected] = useState(false);
  const initialRedirectData = {
    authorizationCode: '',
    verifierCode: '',
    expiresIn: '',
  }
  const [redirectData, setRedirectData] = useState(initialRedirectData);


  const getProviderTaminAuthRequest = useGetTaminAuth(null, modalName === 'taminLoginModal')
  const verifyTaminAuthRequest = useVerifyTaminAuth(getQueryParams(redirectData), false)

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'taminLoginModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      // setFieldValue({...fieldValue, nationalCode: modalData?.nationalCode})
      handleResetModal();
      var data = {
        id: modalData?.id
      }
    }
  }, [modalData, modalName])


  useEffect(() => {
    if(getProviderTaminAuthRequest?.data?.data?.result) {
      setCurrentUrl(getProviderTaminAuthRequest?.data?.data?.result)
    }
  }, [getProviderTaminAuthRequest?.data]);

  useEffect(() => {
    if(redirectData?.authorizationCode !== '' && redirectData?.verifierCode !== '') {
      verifyTaminAuthRequest?.refetch();
    }
  }, [redirectData]);

  // بعد از دریافت توکن داخل استورج سیوش میکنیم.
  useEffect(() => {
    if(verifyTaminAuthRequest?.data?.data?.result) {
      // console.log('verify', verifyTaminAuthRequest?.data?.data?.result)
      localStorageSet('TAMIN_ACCESS_TOKEN', {
        ...verifyTaminAuthRequest?.data?.data?.result,
        date_time: Date.now()
      });

      // باید به پنل صاایران ریدایرکت شده باشیم حتما
      const envUrl = process.env.REACT_APP_TAMIN_REDIRECT_ENV;
      if(envUrl && currentUrl.includes(envUrl)) {
        Noti('success', 'ورود شما به سامانه تأمین اجتماعی با موفقیت انجام شد!')
        handleSetShowModal();
        modalInfo?.checkForTaminAuthorization && modalInfo.checkForTaminAuthorization();
      }
    }
  }, [verifyTaminAuthRequest?.data]);

  const handleSetShowModal = () => {
    setShowState(false);
    setCurrentUrl('')
    setRedirectData(initialRedirectData)
    handleHideModal();
  }

  // صفحه داخل آیفریم که عوض بشه در صورتی که وارد پنل خودمون شده باشیم میتونیم url رو ببینیم و بفهمیم که موفقیت آمیز بوده.
  const handleIframeLoad = () => {
    const iframe = document.getElementById('tamin_login_iframe') as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      try {
        const iframeLocation = iframe.contentWindow.location.href;
        const envUrl = process.env.REACT_APP_TAMIN_REDIRECT_ENV;
        if (envUrl && iframeLocation.includes(envUrl)) {
          setIsRedirected(true);
          const parsedData = parseRedirectedURL(iframeLocation);
          setRedirectData(parsedData);
          setCurrentUrl(iframeLocation); // Update the URL if needed

          // Optionally, you can perform additional actions here
        }
      } catch (e) {
      }
    }
  };

  // دیتا داخل پاسخ تامین رو پارس'ا' میکنیم
  const parseRedirectedURL = (url: string) => {
    const parsedURL = new URL(url);
    const pathSegments = parsedURL.pathname.split('/').filter(segment => segment !== '');
    const authorizationCode = pathSegments.length > 1 ? pathSegments[1] : '';

    const params = new URLSearchParams(parsedURL.search);
    const verifierCode = params.get('code') || '';
    const expiresIn = params.get('expires_in') || '';

    return { authorizationCode, verifierCode, expiresIn };
  };

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {/*{patientAdmissionRequest?.isPending && <BoxLoader type="cover"/>}*/}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              ورود به سامانه تامین اجتماعی
            </h4>
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        {currentUrl ?
          <iframe
            id={'tamin_login_iframe'}
            src={currentUrl}
            title="Website"
            width="100%"
            height="400px"
            style={{border: "none"}}
            onLoad={handleIframeLoad} // Attach the onLoad handler
          /> : <BoxLoader type="cover"/>
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaminLoginModal);