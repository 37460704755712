import * as yup from "yup";

const changePasswordSchema = yup.object({
  oldPassword: yup
    .string()
    .required("این فیلد الزامیست."),
  newPassword: yup
    .string()
    .required("این فیلد الزامیست."),
  newPasswordConfirm: yup
    .string()
    .required("این فیلد الزامیست.")
    .oneOf([yup.ref('newPassword'),], 'تکرار کلمه عبور یکسان نمی باشد'),
});

export {
  changePasswordSchema
};
