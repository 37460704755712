import { axiosRequest } from '../api';
import { API_PATH } from '../../constants';

export const UserService = {
  getUsersList(params){ return axiosRequest('GET', API_PATH.USER.LIST(params)) },
  getUser(data){ return axiosRequest('POST', API_PATH.USER.INFO, data) },
  getMyOperatorInfo(data){ return axiosRequest('POST', API_PATH.USER.MY_INFO, data) },
  addUser(data){ return axiosRequest('POST', API_PATH.USER.ADD, data) },
  updateUserProfile(data){ return axiosRequest('POST', API_PATH.USER.UPDATE_PROFILE, data) },
  deleteUser(data){ return axiosRequest('POST', API_PATH.USER.DELETE, data) },
  getUserAccess(data){ return axiosRequest('POST', API_PATH.USER.GET_ACCESS, data) },
  AddUserAccess(data){ return axiosRequest('POST', API_PATH.USER.ADD_ACCESS, data) },
  getAccessProcess(data){ return axiosRequest('POST', API_PATH.USER.GET_ACCESS_PROCESS, data) },
  changeUserPassword(data){ return axiosRequest('POST', API_PATH.USER.CHANGE_PASSWORD, data) },
}