import React from "react";
import EmptyRow from "../../general/table/emptyRow";
import TableLoader from "../../general/table/tableLoader";
import ScheduleTableRowItem from "./scheduleTable_rowItem";

const ScheduleTable = ({
  dataList,
  dataListTitles,
  isRequesting
}) => {

  const _dataListTitles = dataListTitles.map((item, i) => <td key={i}>{item}</td>),
    _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item, i) => {
      return <ScheduleTableRowItem item={item} key={i} />
    });

  return(
    <table>
      <thead>
        <tr>
          {_dataListTitles}
        </tr>
      </thead>
      <tbody>
        { isRequesting ? 
            <TableLoader colSpan={dataListTitles.length} /> : 
            _dataList ? _dataList : <EmptyRow colSpan={dataListTitles.length} />
        }
      </tbody>
    </table>
  )
}

export default ScheduleTable;
