import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {Accordion} from "react-bootstrap";
import {general} from "../../../../../store/action";
import {TExaminationDetail, TVisitFields} from "../../../../../types/visit";
import BoxLoader from "../../../../components/general/boxLoader";
import ECG from "../../../../components/visits/visitDetail/examinations/ecg/ECG";
import {useExaminationDetail} from "../../../../../hook/request/visit/examination";
import SummaryCards from "../../../../components/visits/visitDetail/examinations/summaryCards";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {BloodPressureScatterChart} from "../../../../components/general/chartOptions";
import {getCellValue} from "../../../../../utils/utils";

type medicalHistoryProps = {
  visitData?: TVisitFields;
  isRequesting?: boolean;
  openTab?: string;
  handleShowModal: any;
}

const Examinations = ({
  openTab,
}: medicalHistoryProps) => {

  let { id } = useParams();
  const [examinationData, setExaminationData] = useState<TExaminationDetail>();

  // services
  const getExaminationDetail = useExaminationDetail();

  useEffect(() => {
    openTab === 'examinations' && getExaminationDetail.mutate({id})
  }, [openTab]);

  useEffect(() => {
    if(!getExaminationDetail.isPending && getExaminationDetail?.data?.data?.result){

      const {
        bpDiastolic, bpSystolic, bpPosition, ecgLeads, endDateDate,
        etco2, fico2, heartRate, id, pulseRate, respiratoryRate, spo2,
        startDate, temperature1, temperature2, visit
      } = getExaminationDetail?.data?.data?.result;
      setExaminationData({
        bpDiastolic, bpSystolic, bpPosition, ecgLeads, endDateDate,
        etco2, fico2, heartRate, id, pulseRate, respiratoryRate, spo2,
        startDate, temperature1, temperature2, visit
      })
    }
  }, [getExaminationDetail.data])



  return (
    <>
      <div className="card-box shadow-none rounded-0">
        {getExaminationDetail.isPending && <BoxLoader type="cover"/>}
        <h4 className='title-dot fw-bold font-18 mb-4'>
          معاینات
        </h4>
        <Accordion className='visits-accordion rounded mb-4' alwaysOpen>
          <Accordion.Item eventKey='ecg' className='mb-3 rounded border'>
            <ECG
              id={examinationData?.id}
              ecgLeads={examinationData?.ecgLeads}
              startDate={examinationData?.startDate}
              heartRate={examinationData?.heartRate}
              pulseRate={examinationData?.pulseRate}
              healthUnit={examinationData?.visit?.healthUnit?.name}
            />
          </Accordion.Item>
          <Accordion.Item eventKey='spo2&bp' className='mb-3 rounded border'>
            <Accordion.Header className='d-flex flex-wrap'>
               <span className='font-14 me-4 text-gray font-en'>
                NIBP: &ensp;
                 <span className="text-black">
                   {getCellValue(examinationData?.bpDiastolic?.magnitude.toFixed(0))}/{getCellValue(examinationData?.bpSystolic?.magnitude.toFixed(0))} mmhg
                 </span>
              </span>
              <span className='font-14 me-2 text-gray font-en'>
                SpO2: &ensp;
                <span className="text-black">
                 {getCellValue(examinationData?.spo2?.magnitude.toFixed(1))}
                </span>
              </span>
              <div className="flex-fill"></div>
              <span className='font-14 fw-bold me-2 text-black'>
                بررسی اطلاعات اکسیژن‌خون و فشارخون
              </span>
            </Accordion.Header>
            <Accordion.Body className='rounded-bottom bg-primary-10'>
              <div className='card-box'>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={BloodPressureScatterChart({
                    data: [examinationData?.bpDiastolic, examinationData?.bpSystolic]
                  })}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='co2' className='mb-3 rounded border'>
            <SummaryCards
              bpDiastolic={examinationData?.bpDiastolic}
              bpSystolic={examinationData?.bpSystolic}
              etco2={examinationData?.etco2}
              fico2={examinationData?.fico2}
              respiratoryRate={examinationData?.respiratoryRate}
              temperature1={examinationData?.temperature1}
              temperature2={examinationData?.temperature2}
            />
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(Examinations);