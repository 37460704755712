import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PatientBiometricsTable from "../../../biometric/dataList/patientBiometricsTable";
import PatientBiometricCardsBox from "../../../biometric/dataList/patientBiometricCardsBox";
import {useWindowSize} from "../../../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../../../hook/useMySearchParams";
import {useGetPatientBiometrics} from "../../../../../../../hook/request/ehr/patientBiometric";



const dataListTitles = [
  "تاریخ و ساعت معاینه",
  "وزن (Kg)",
  "قد (Cm)",
  "شاخص توده بدنی (Kg/m)",
  "دور بازو زیر بغل (Cm)",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type biometricsListProps = {
  patientData?: any;
  openTab: string;
}

const BiometricsListSummary = ({
  patientData,
  openTab,
}: biometricsListProps) => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [biometricsData, setBiometricsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: patientData,
    pageNumber: "0",
    perPage: "3",
    search: "",
  });

  // services
  const getBiometricsRequest = useGetPatientBiometrics(getQueryParams(filterValues), openTab === 'biometricsList' && filterValues?.patient !== '0');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      patient: patientData,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "3",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams, patientData]);

  useEffect(() => {
    getBiometricsRequest?.data?.data?.result &&
    setBiometricsData(getBiometricsRequest?.data?.data.result)
  }, [getBiometricsRequest.data])


  return (
    <section className="table-cont table-responsive">
      {
        width < 900 ?
          <PatientBiometricCardsBox
            dataList={biometricsData}
            dataListTitles={dataListTitles}
            isRequesting={getBiometricsRequest.isPending}
            readonly={true}
          /> :
          <PatientBiometricsTable
            dataList={biometricsData}
            dataListTitles={dataListTitles}
            isRequesting={getBiometricsRequest.isPending}
            readonly={true}
          />
      }
    </section>
  );
};

export default BiometricsListSummary;
