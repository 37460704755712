import React, { Component } from 'react';
import OpenViduVideoComponent from './OvVideo';
import Icon from "../../../general/icon";

export default class UserVideoComponent extends Component {

    getNicknameTag() {
        // Gets the nickName of the user
        return JSON.parse(this.props.streamManager.stream.connection.data).clientData;
    }

    render() {
        return (
            <div id={`video_call_container_${this.props.index}`} className='h-100-important w-100'>
                {this.props.streamManager !== undefined ? (
                  <div className="position-relative d-flex justify-content-center align-content-center  align-items-center streamcomponent rounded h-100-important width-full">
                      <OpenViduVideoComponent user={this.props.user} participant={this.props.participant} streamManager={this.props.streamManager} toggleFullscreen={this.props.toggleFullscreen}
                                              updateFloatingDiv={this.props.updateFloatingDiv} subscriberCount={this.props.subscriberCount}
                                              micStatusChange={this.props.micStatusChange} micStatus={this.props.micStatus} camStatusChange={this.props.camStatusChange} camStatus={this.props.camStatus}
                                              leaveSession={this.props.leaveSession} index={this.props.index}
                                              ref={this.props.videoComponentRef}
                      />
                      {/*<div><p>{this.getNicknameTag()}</p></div>*/}
                  </div>
                ) : null}
            </div>
        );
    }
}
