import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {ROUTES} from "../../../constants";
import {PatientConditionService} from "../../../services/requests/ehr/patientCondition";

const useGetPatientConditions = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getPatientConditions", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PatientConditionService.getPatientConditionsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetPatientCondition = () => {
  return useMutation({
    mutationFn: PatientConditionService.getPatientCondition,
  })
}

const useAddPatientCondition = ({
                                onSuccess
                              }: {
  onSuccess: () => void
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: PatientConditionService.addPatientCondition,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientConditions"] })
          onSuccess();
        }
      })
    },
  })
}

const useDeletePatientCondition = ({
                                   onSuccess
                                 }: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PatientConditionService.deletePatientCondition,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPatientConditions"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetPatientConditions,
  useGetPatientCondition,
  useAddPatientCondition,
  useDeletePatientCondition
}
