import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Modal from "../../../general/modal";
import Icon from "../../../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {general} from "../../../../../store/action";
import {TPrescriptionFields} from "../../../../../types/prescription";
import {Nav, Tab} from "react-bootstrap";
import DiagnosisList from "./diagnosis/diagnosisList";
import VisitMedicalHistory from "./medicalHistory/visitMedicalHistory";
import VisitPrescription from "./prescription/visitPrescription";

type ShowPatientVisitModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
  handleShowModal: any;
}

type ModalInfoProps = {
  id: string;
  visitId: string;
  patientData?: any;
  prescriptionFieldValue?: TPrescriptionFields;
  medicalHistoryFieldValue?: any;
  draftDescription?: string;
  handlePrescriptionModalSubmit: any;
}

const ShowPatientVisitModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: ShowPatientVisitModalProps) => {
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    visitId: '',
    patientData: '',
    handlePrescriptionModalSubmit: null
  });
  const [openTab, setOpenTab] = useState('')

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'showPatientVisitModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      setOpenTab('prescription');
      handleResetModal();
    }
  }, [modalData, modalName])

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-2 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              اطلاعات نسخه
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
            <Icon name='close-square'/>
          </span>
        </div>
        <Tab.Container
          id="vitals-charts-tabs"
          activeKey={openTab}
          onSelect={(eventKey) => setOpenTab(eventKey || '')}
        >
          <div className=''>
            <Nav variant={'pills'} justify>
              <div className='d-flex w-100 ehr-nav rounded bg-white shadow font-12 mb-4 mx-3'>
                <Nav.Item>
                  <Nav.Link eventKey="medicalHistory" className="py-3">
                    شرح حال
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="diagnosis" className="py-3">
                    تشخیص
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="prescription" className="py-3">
                    نسخه تجویز شده
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="medicalHistory">
              <VisitMedicalHistory openTab={openTab} visitData={modalInfo?.visitId}/>
            </Tab.Pane>
            <Tab.Pane eventKey="diagnosis">
              <DiagnosisList openTab={openTab} visitData={modalInfo?.visitId} patientId={modalInfo?.patientData?.id}/>
            </Tab.Pane>
            <Tab.Pane eventKey="prescription">
              <VisitPrescription openTab={openTab} visitData={modalInfo?.visitId}/>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPatientVisitModal);