import React, {useEffect, useState} from "react";
import {getCellValue} from "../../../../utils/utils";
import {MtoJHHMMFormat} from "../../../../utils/date";
import {VISIT_TYPE_ENUM} from "../../../../constants/constant/enum";
import EmptyVisits from "../../../../assets/images/svgComponents/EmptyVisits";
import {useGetWaitingRoomList} from "../../../../hook/request/videoCall";
import BoxLoader from "../boxLoader";
import dropdownHeaderBg from "../../../../assets/images/webp/dropdown-header-bg.webp";

type OnlineStatusDropdownWaitingRoomListProps = {
  openTab: string
  openVideoCallOverlay: any
}

const OnlineStatusDropdownWaitingRoomList = ({
  openTab,
  openVideoCallOverlay,
}: OnlineStatusDropdownWaitingRoomListProps) => {

  const [waitingRoomListData, setWaitingRoomListData] = useState<any[]>([]);

  // services
  const getWaitingRoomListRequest = useGetWaitingRoomList();

  useEffect(() => {
    openTab === 'waitingRoom' && getWaitingRoomListRequest.mutate({})
  }, [openTab])

  useEffect(() => {
    if (getWaitingRoomListRequest?.data?.data?.result) {
      setWaitingRoomListData(getWaitingRoomListRequest?.data?.data?.result)
    }
  }, [getWaitingRoomListRequest?.data?.data?.result]);

  return (
    <div className="h-100">
      {getWaitingRoomListRequest?.isPending && <BoxLoader type="cover"/>}
      <div className=" m-2">
        {waitingRoomListData && waitingRoomListData?.length > 0 ? waitingRoomListData?.slice(0, 4).map((item: any, key) => {
          const firstCharacter = item?.patient?.name ? item?.patient?.name.charAt(0) : '';
          return (
            <div key={key} className='d-flex align-items-center rounded-3 border-bottom visit-card-animation py-2 my-1'
                 style={{backgroundColor: '#F3F6FF'}}>
              <div className="rounded-circle avatar-placeholder">
                {firstCharacter}
              </div>
              <div className='w-100 mx-3'>
                <div className='font-15 text-end'>
                  {item?.patient?.name}
                </div>
                <div className='d-flex justify-content-between font-9 text-gray mt-2'>
                  <div>
                    <span>
                      ساعت ویزیت:
                    </span>
                    <span className='me-2'>
                      {item?.scheduledDateTime ? getCellValue(MtoJHHMMFormat(item?.scheduledDateTime)) : '-'}
                    </span>
                  </div>
                  <div>
                    <span>
                      نوع ویزیت:
                    </span>
                    <span className='me-2'>
                      {item?.visitType?.id === VISIT_TYPE_ENUM.ONLINE.value ? 'آنلاین' : 'حضوری'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        }) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <EmptyVisits/>
            <div className='font-12 mt-2'>
              در حال حاضر هیچ نوبتی برای ویزیت ثبت نشده است.
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-center rounded-bottom align-items-center text-white cur-pointer mt-2"
           style={{
             background: `url(${dropdownHeaderBg}) no-repeat center center`,
             backgroundSize: "cover",
           }}
           onClick={() => openVideoCallOverlay()}
      >
        <span className='d-flex justify-content-center w-100 h-100 dropdown-button-animation px-4 p-2'>رفتن به اتاق انتظار</span>
      </div>
    </div>
  )
}

export default OnlineStatusDropdownWaitingRoomList;