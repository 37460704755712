import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {AnnouncementsService} from "../../services/requests/announcements";
import {ProviderTaminService} from "../../services/requests/providerTamin";

const useGetTaminAuth = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getTaminAuth", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return ProviderTaminService.getTaminAuth(params);
    },
    enabled: modalName ?? true
  });
}

const useVerifyTaminAuth = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getVerifyTaminAuth", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return ProviderTaminService.verifyTaminAuth(params);
    },
    enabled: modalName ?? true
  });
}

const useGetTaminReferralCount = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getTaminReferralCount", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return ProviderTaminService.getTaminReferralCount(params);
    },
    enabled: modalName ?? true
  });
}

const useGetTaminReferralByTrackingCode = (patientId: number, trackingCode: number, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getTaminReferralByTrackingCode", trackingCode],
    queryFn: ({ queryKey }: any) => {
      const [_, trackingCode] = queryKey;
      return  ProviderTaminService.getTaminReferralByTrackingCode(patientId, trackingCode);
    },
    enabled: modalName ?? true
  });
}


export {
  useGetTaminAuth,
  useVerifyTaminAuth,
  useGetTaminReferralCount,
  useGetTaminReferralByTrackingCode
}
