const TaminRedirect = () => {

  return (
    <div>
      با موفقیت انجام شد
    </div>
  )
}


export default TaminRedirect;