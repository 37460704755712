import { ACTION_TYPES } from "../../../constants";

const initialState = {
  requesting: false,
  success: false,
  error: false,
  data: null,
};

const loginReducer = (state = initialState, action = {}) => {
  const { payload, type } = action;
  switch (type) {
    case ACTION_TYPES.AUTH.LOGIN.SUCCESS: {
      return {
        success: true,
        requesting: false,
        error: false,
        data: payload,
      }
    }
    case ACTION_TYPES.AUTH.LOGIN.REQUESTING:
      return { success: false, requesting: true, error: false };
    case ACTION_TYPES.AUTH.LOGIN.ERROR:
      return { data: payload, success: false, requesting: false, error: true };
    case ACTION_TYPES.AUTH.LOGIN.RESET:
      return initialState;
    default:
      return state
  }
}

export default loginReducer