import Icon from "../../general/icon";
import Image from "../../general/image";

type ImageUploaderBoxItemProps = {
  setFieldValue: any;
  fieldValue: any;
  name: any;
}

const ImageUploaderBoxItem = ({
  setFieldValue,
  fieldValue,
  name
}: ImageUploaderBoxItemProps) => {

  const removeProductImage = () => {
    setFieldValue({
      ...fieldValue,
      [name]: {}
    });
  }

  return (
    <div className="pckg-image-box w-100 h-100 d-flex justify-content-center align-items-center rounded overflow-hidden border bg-white pos-rel m-1">
      <Image
        src={fieldValue?.[name].imageUrl}
        className='h-100 object-fit-cover'
      />
      <div
        className="pckg-image-box-action d-flex justify-content-between align-items-end position-absolute text-white"
      >
        <span
          onClick={removeProductImage}
          className="d-flex font-20 text-red mx-1 mb-2 cur-pointer"
        >
          <Icon name='trash' />
        </span>
      </div>
    </div>
  );
};

export default ImageUploaderBoxItem;
