import React from "react";
import {connect, useSelector} from "react-redux";
import {generatePath, useNavigate} from "react-router-dom";
import {general} from "../../../../store/action";
import Icon from "../../general/icon";
import {getCellValue} from "../../../../utils/utils";
import {Dispatch} from "redux";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {VISIT_MODE, VISIT_STATUS_ENUM} from "../../../../constants/constant/enum";
import {ROUTES} from "../../../../constants";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

type VisitCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const VisitCardsBoxItem = ({
 item,
 dataListTitles,
 handleShowModal
}: VisitCardsBoxItemProps) => {
  const navigate = useNavigate();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-gray3'
      case 400:
        return 'bg-yellow1 text-yellow'
      default:
        return 'bg-light-red text-red'
    }
  }

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="patient">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.patient?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="provider">{dataListTitles[1]}</label>
        <span className="font-en mr-2">{getCellValue(item?.provider?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[2]}</label>
        <span className="mr-2">{getCellValue(item?.healthUnit?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="fromDate">{dataListTitles[3]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.fromDateTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="visitType">{dataListTitles[4]}</label>
        <span className="mr-2">{getCellValue(item?.visitType?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="status">{dataListTitles[5]}</label>
        <span className={`px-3 py-1 rounded text-nowrap ${determineBgColor()}`}>
          {getCellValue(item?.status?.name)}
          </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="trackingCode">{dataListTitles[6]}</label>
        <span className="mr-2">{getCellValue(item?.trackingCode)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="trackingCode">{dataListTitles[7]}</label>
        <span className="mr-2">{getCellValue(item?.issuerType)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="trackingCode">{dataListTitles[8]}</label>
        <span className="mr-2">{getCellValue(item?.prescriptionStatus)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[9]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            {
              (isProvider || findAccessInAccessList('VISIT_VIEW')) &&
              <>
                <Tooltip alt={"پذیرش"} className="tooltip-pre-line">
                <span
                  onClick={() =>
                    item?.status?.id === VISIT_STATUS_ENUM.RESERVED.value &&
                    handleShowModal('changeStatusAppointmentModal', {id: item?.id})
                  }
                  className={`d-flex mx-2 font-20 cur-pointer ${item?.status?.id === VISIT_STATUS_ENUM.RESERVED.value ? 'text-hover' : 'disabled text-gray3'}`}
                >
                  <Icon name='stethoscope'/>
                </span>
                </Tooltip>
                { isProvider &&
                  <Tooltip alt={"ویرایش"} className="tooltip-pre-line">
                    <span
                      onClick={() =>
                        item?.status?.id === VISIT_STATUS_ENUM.IN_PROGRESS.value &&
                        navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, {id: item?.id}), {state: {mode: VISIT_MODE.EDIT.value}})
                      }
                      className={`d-flex mx-2 font-20 cur-pointer ${item?.status?.id === VISIT_STATUS_ENUM.IN_PROGRESS.value ? 'text-hover' : 'disabled text-gray3'}`}
                    >
                      <Icon name='pen-square'/>
                    </span>
                  </Tooltip>
                }
                <Tooltip alt={"مشاهده ویزیت"} className="tooltip-pre-line">
                <span
                  onClick={() =>
                    item?.status?.id === VISIT_STATUS_ENUM.RESOLVED.value &&
                    navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, {id: item?.id}), {state: {mode: VISIT_MODE.SHOW.value}})
                  }
                  className={`d-flex mx-2 font-20 cur-pointer ${item?.status?.id === VISIT_STATUS_ENUM.RESOLVED.value ? 'text-hover' : 'disabled text-gray3'}`}
                >
                  <Icon name='document'/>
                </span>
                </Tooltip>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VisitCardsBoxItem);