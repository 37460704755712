import {axiosRequest} from "../api";
import {API_PATH} from "../../constants";

export const MAXAService = {
  getMAXAList(params){ return axiosRequest('GET', API_PATH.MAXA.LIST(params)) },
  getMAXA(data){ return axiosRequest('POST', API_PATH.MAXA.INFO, data) },
  addMAXA(data){ return axiosRequest('POST', API_PATH.MAXA.ADD, data) },
  deleteMAXA(data){ return axiosRequest('POST', API_PATH.MAXA.DELETE, data) },
  getTaminDiagnosisList(params){ return axiosRequest('GET', API_PATH.MAXA.TAMIN_DIAGNOSIS_LIST(params)) },
  getTaminComplaintList(params){ return axiosRequest('GET', API_PATH.MAXA.TAMIN_COMPLAINT_LIST(params)) },
}