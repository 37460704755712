import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {connect} from "react-redux";
import {Accordion} from "react-bootstrap";
import Icon from "../../../../components/general/icon";
import TextAreaField from "../../../../components/general/form/textAreaField";
import {general} from "../../../../../store/action";
import Tooltip from "../../../../components/general/tooltip";
import AddDiagnosisModal from "../../../../components/visits/visitDetail/medicalHistory/addDiagnosisModal";
import DeleteDiagnosisModal from "../../../../components/visits/visitDetail/medicalHistory/deleteDiagnosisModal";
import {TAddMedicalHistoryFields, TVisitFields} from "../../../../../types/visit";
import BoxLoader from "../../../../components/general/boxLoader";
import {useGetDiagnosisList} from "../../../../../hook/request/visit/diagnosis";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import DiagnosisCardsBox from "../../../../components/visits/visitDetail/medicalHistory/dataList/diagnosisCardsBox";
import {TDataList} from "../../../../../types/general";
import {VISIT_MODE} from "../../../../../constants/constant/enum";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import DiagnosisCardsBoxResponsive
  from "../../../../components/visits/visitDetail/medicalHistory/dataList/diagnosisCardsBoxResponsive";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  patient?: string;
  visit?: string;
}

type medicalHistoryProps = {
  visitData?: TVisitFields;
  isRequesting?: boolean;
  openTab?: string;
  updatePrescriptionFields: any
  handleShowModal: any;
}

const MedicalHistory = ({
  visitData,
  isRequesting,
  openTab,
  updatePrescriptionFields,
  handleShowModal
}: medicalHistoryProps) => {

  const [width] = useWindowSize();
  const location = useLocation();
  const  mode  = location?.state?.mode || VISIT_MODE.SHOW.value; // Access mode from the state
  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: visitData?.patient?.id,
    pageNumber: "0",
    perPage: "10",
    search: "",
    visit: visitData?.id
  });
  const [DiagnosisListData, setDiagnosisListData] = useState<TDataList>({
    data: [],
    count: 0
  });
  const [fieldValue, setFieldValue] = useState<TAddMedicalHistoryFields>({
    chiefComplaint: visitData?.chiefComplaint || '',
    clinicalFindings: visitData?.clinicalFindings || '',
    labResultNote: visitData?.labResultNote || '',
    visitGoalNote: visitData?.visitGoalNote || '',
    examinationNote: visitData?.examinationNote || ''
  });
  const {
    control,
    reset,
    formState: { errors },
  } = useForm<TAddMedicalHistoryFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });

  // services
  const getDiagnosisListRequest = useGetDiagnosisList(getQueryParams(filterValues), (openTab === 'medicalHistory'));

  useEffect(() => {
    setFieldValue({
      chiefComplaint: visitData?.chiefComplaint,
      clinicalFindings: visitData?.clinicalFindings,
      labResultNote: visitData?.labResultNote,
      visitGoalNote: visitData?.visitGoalNote,
      examinationNote: visitData?.examinationNote
    })
    setFilterValues({
      ...filterValues,
      patient: visitData?.patient?.id,
      visit: visitData?.id
    })
  }, [visitData]);

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    updatePrescriptionFields(fieldValue, 'medicalHistory');
  }, [fieldValue]);

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
      patient: visitData?.patient?.id,
      visit: visitData?.id
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    openTab === "medicalHistory" && getDiagnosisListRequest?.data?.data?.result &&
    setDiagnosisListData(getDiagnosisListRequest?.data?.data.result)
  }, [getDiagnosisListRequest.data])

  const onFieldsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {name, value} = e?.target;
    setFieldValue({...fieldValue, [name]: value});
  }

  return (
    <>
      <div className="card-box shadow-none rounded-0">
        {getDiagnosisListRequest.isLoading && <BoxLoader type="cover"/>}
        <h4 className='title-dot fw-bold font-18 mb-4'>
          شرح حال بیمار
        </h4>
        <form>
          <Accordion className='visits-accordion rounded mb-4' alwaysOpen>
            <Accordion.Item eventKey='chiefComplaint' className='mb-3 rounded border'>
              <Accordion.Header className='d-flex flex-wrap'>
                <div className="flex-fill"></div>
                <span className='font-14 fw-bold me-2 text-black'>  شکایت اصلی بیمار
                </span>
                <Tooltip alt={'توضیح مختصر از علائم و مشکلات اصلی بیمار'} className="tooltip-pre-line">
                  <div className='d-flex align-items-center'>
                    <Icon name='info'/>
                  </div>
                </Tooltip>
              </Accordion.Header>
              <Accordion.Body className={`rounded-bottom ${mode === VISIT_MODE.SHOW.value && 'bg-input-disabled'}`}>
                <div className=" ">
                  <div className="form-group">
                    <TextAreaField
                      className='border-0'
                      name="chiefComplaint"
                      placeholder="افزودن یادداشت"
                      rows={1}
                      control={control}
                      error={errors}
                      onChange={onFieldsChange}
                      disabled={mode === VISIT_MODE.SHOW.value}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='clinicalFindings' className='mb-3 rounded border'>
              <Accordion.Header className='d-flex flex-wrap'>
                <div className="flex-fill"></div>
                <span className='font-14 fw-bold me-2 text-black'>  یافته های بالینی
                </span>
                <Tooltip alt={'خلاصه نتایج معاینات فیزیکی بیمار'} className="tooltip-pre-line">
                  <div className='d-flex align-items-center'>
                    <Icon name='info'/>
                  </div>
                </Tooltip>
              </Accordion.Header>
              <Accordion.Body className={`rounded-bottom ${mode === VISIT_MODE.SHOW.value && 'bg-input-disabled'}`}>
                <div className=" ">
                  <div className="form-group">
                    <TextAreaField
                      className='border-0'
                      name="clinicalFindings"
                      placeholder="افزودن یادداشت"
                      rows={1}
                      control={control}
                      error={errors}
                      onChange={onFieldsChange}
                      disabled={mode === VISIT_MODE.SHOW.value}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='labResultNote' className='mb-3 rounded border'>
              <Accordion.Header className='d-flex flex-wrap'>
                <div className="flex-fill"></div>
                <span className='font-14 fw-bold me-2 text-black'>  نتایج تست های آزمایشگاهی
                </span>
                <Tooltip alt={'خلاصه نتایج آزمایشات و تست‌های بیمار'} className="tooltip-pre-line">
                  <div className='d-flex align-items-center'>
                    <Icon name='info'/>
                  </div>
                </Tooltip>
              </Accordion.Header>
              <Accordion.Body className={`rounded-bottom ${mode === VISIT_MODE.SHOW.value && 'bg-input-disabled'}`}>
                <div className=" ">
                  <div className="form-group">
                    <TextAreaField
                      className='border-0'
                      name="labResultNote"
                      placeholder="افزودن یادداشت"
                      rows={1}
                      control={control}
                      error={errors}
                      onChange={onFieldsChange}
                      disabled={mode === VISIT_MODE.SHOW.value}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='visitGoalNote' className='mb-3 rounded border'>
              <Accordion.Header className='d-flex flex-wrap'>
                <div className="flex-fill"></div>
                <span className='font-14 fw-bold me-2 text-black'>  هدف
                </span>
                <Tooltip alt={'کوتاه از اهداف و برنامه‌های این ویزیت'} className="tooltip-pre-line">
                  <div className='d-flex align-items-center'>
                    <Icon name='info'/>
                  </div>
                </Tooltip>
              </Accordion.Header>
              <Accordion.Body className={`rounded-bottom ${mode === VISIT_MODE.SHOW.value && 'bg-input-disabled'}`}>
                <div className=" ">
                  <div className="form-group">
                    <TextAreaField
                      className='border-0'
                      name="visitGoalNote"
                      placeholder="افزودن یادداشت"
                      rows={1}
                      control={control}
                      error={errors}
                      onChange={onFieldsChange}
                      disabled={mode === VISIT_MODE.SHOW.value}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='examinationNote' className='mb-3 rounded border'>
              <Accordion.Header className='d-flex flex-wrap'>
                <div className="flex-fill"></div>
                <span className='font-14 fw-bold me-2 text-black'>  یادداشت‌های پزشک
                </span>
                <Tooltip alt={'خلاصه‌ای از مشاهدات و یادداشت‌های پزشک'} className="tooltip-pre-line">
                  <div className='d-flex align-items-center'>
                    <Icon name='info'/>
                  </div>
                </Tooltip>
              </Accordion.Header>
              <Accordion.Body className={`rounded-bottom ${mode === VISIT_MODE.SHOW.value && 'bg-input-disabled'}`}>
                <div className=" ">
                  <div className="form-group">
                    <TextAreaField
                      className='border-0'
                      name="examinationNote"
                      placeholder="افزودن یادداشت"
                      rows={1}
                      control={control}
                      error={errors}
                      onChange={onFieldsChange}
                      disabled={mode === VISIT_MODE.SHOW.value}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </form>
        <div className="border border-black"></div>
        <div className="d-flex justify-content-between mt-4">
          <div className='title-dot fw-bold font-18 align-content-center'>
            تشخیص پزشک
          </div>
          {mode === VISIT_MODE.EDIT.value && (
            <div
              className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-end mb-3 m-md-0">
              <span
                onClick={() => handleShowModal('addDiagnosisModal', {
                  visitId: visitData?.id,
                  patientData: visitData?.patient
                })}
                className="btn btn-light-blue rounded d-flex align-items-center fw-bold px-4 py-3">
                ثبت تشخیص جدید
              </span>
            </div>
          )}
        </div>
        {
          DiagnosisListData.count === 0 ?
            <div className="card shadow-none border mt-4">
              <div className='bg-gray rounded-top justify-content-between'>
                <div className='d-flex just mx-4 my-3 align-items-center'>
                  <Icon name='clipboard-tick'/>
                  <span className="fw-bold text-primary px-4">
                    هیچ تشخیصی توسط شما برای این بیمار ثبت نشده است.
                  </span>
                </div>
              </div>
            </div>
            :
            width > 900 ?
              <DiagnosisCardsBox dataList={DiagnosisListData} isRequesting={getDiagnosisListRequest.isPending} readOnly={mode === VISIT_MODE.SHOW.value}/>
              :
              <DiagnosisCardsBoxResponsive dataList={DiagnosisListData} isRequesting={getDiagnosisListRequest.isPending} readOnly={mode === VISIT_MODE.SHOW.value}/>
        }
      </div>
      <AddDiagnosisModal/>
      <DeleteDiagnosisModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(MedicalHistory);