import admissionBackground from '../../../assets/images/webp/dashboard-blue-bg.webp';
import frequentlyUsedPrescriptionBackground from '../../../assets/images/webp/dashboard-darkblue-bg.webp';
import frequentlyUsedItems from '../../../assets/images/webp/dashboard-green-bg.webp';
import AdmissionAnimated from "../../../assets/images/svgComponents/animate/AdmissionAnimated";
import PrescriptionAnimated from "../../../assets/images/svgComponents/animate/prescriptionAnimated";
import Icon from "../../components/general/icon";
import React, {useEffect, useMemo, useState} from "react";
import TextField from "../../components/general/form/textField";
import MedicationItemsAnimated from "../../../assets/images/svgComponents/animate/medicationItemsAnimated";
import PatientChartsCarousel from "../../components/dashboards/doctor/patientChartsCarousel";
import VisitChartsCarousel from "../../components/dashboards/doctor/visitChartsCarousel";
import DailyVisitCountChart from "../../components/dashboards/doctor/dailyVisitCountChart";
import {SubmitHandler, useForm} from "react-hook-form";
import AddVisitModal from "../../components/visits/addVisitModal";
import {general} from "../../../store/action";
import {connect} from "react-redux";
import EmptyChartBg from "../../../assets/images/svg/emptyChart.svg";
import Clock from "../../../assets/images/svgComponents/icon/clock";
import Calendar from "../../../assets/images/svgComponents/icon/calendar";
import DailyVisitIssuerChart from "../../components/dashboards/doctor/dailyVisitIssuerChart";
import {useGetDashboardStatistics} from "../../../hook/request/systemReport";
import ReservedVisits from "../../components/dashboards/doctor/reservedVisits";
import ScheduleTimeline from "../../components/schedules/scheduleTimeline";
import {useGetCurrentSchedule, useGetScheduleItemsList} from "../../../hook/request/schedule";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import EmptySchedule from "../../../assets/images/svgComponents/EmptySchedule";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../constants";
import Tooltip from "../../components/general/tooltip";

type DoctorDashboardProps = {
  handleShowModal: any;
}

type TFastAdmission = {
  nationalCode: number;
}

type TDoctorDashboardStatistics = {
  draftPrescriptionMedication: number;
  draftPrescriptionParaclinical: number;
  draftPrescriptionItemMedication: number;
  draftPrescriptionItemParaclinic: number;
}

const DoctorDashboard = ({
  handleShowModal
}: DoctorDashboardProps) => {

  const navigate = useNavigate();
  const { getQueryParams } = useMySearchParams();
  const [dashboardData, setDashboardData] = useState<TDoctorDashboardStatistics>({
    draftPrescriptionMedication: 0,
    draftPrescriptionParaclinical: 0,
    draftPrescriptionItemMedication: 0,
    draftPrescriptionItemParaclinic: 0,
  });
  const [scheduleItemsList, setScheduleItemsList] = useState([]);
  const [fieldValue, setFieldValue] = useState<TFastAdmission>();
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TFastAdmission>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });

  //services
  const getDashboardStatistics = useGetDashboardStatistics()
  const getCurrentScheduleItemsList = useGetCurrentSchedule(getQueryParams({}));

  useEffect(() => {
    if(!getDashboardStatistics.isPending && getDashboardStatistics?.data?.data?.result){
      const { draftPrescriptionMedication, draftPrescriptionParaclinical, draftPrescriptionItemMedication, draftPrescriptionItemParaclinic } = getDashboardStatistics?.data?.data?.result;
      setDashboardData({
        draftPrescriptionMedication: draftPrescriptionMedication,
        draftPrescriptionParaclinical: draftPrescriptionParaclinical,
        draftPrescriptionItemMedication: draftPrescriptionItemMedication,
        draftPrescriptionItemParaclinic: draftPrescriptionItemParaclinic,
      })
    }
  }, [getDashboardStatistics.data])

  useEffect(() => {
    if (getCurrentScheduleItemsList?.data?.data?.result) {
      setScheduleItemsList(getCurrentScheduleItemsList?.data?.data?.result);
    } else {
      setScheduleItemsList([])
    }
  }, [getCurrentScheduleItemsList.data])

  useEffect(() => {
    getDashboardStatistics.mutate({provider: true})
  }, []);

  const fastAdmissionSubmit: SubmitHandler<TFastAdmission> = (data) => {
    const { nationalCode } = data;
    handleShowModal('addVisitModal', {nationalCode: nationalCode})
  }

  return (
    <div className='row flex-wrap justify-content-center'>
      <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
        <div className="dashboard-card-box d-flex col-12 h-100"
             style={{
               background: `url(${admissionBackground}) no-repeat center center`,
               backgroundSize: "cover",
             }}
        >
          <div className="col-6 d-flex flex-column px-2">
            <div className="d-flex flex-wrap align-items-center mt-4">
              <span className="d-flex align-items-center text-white font-25">
                <span className="d-flex align-items-center ms-2">
                 <Icon name="dashboard-admission"/>
                </span>
                <span className="font-20 fw-bold text-white">
                  پــذیـــرش
                </span>
              </span>
              <span className="font-15 text-black fw-medium me-2">
                  بیمار
                </span>
            </div>
            <div className='font-10 fw-semibold'>
              برای پذیرش سریع شماره ملی را وارد نمایید.
            </div>
            <div className="d-flex align-items-end mt-auto">
              <div className="ps-1">
                <form>
                  <div
                    className='d-flex border-primary-subtle form-control rounded-3 align-items-center align-content-center justify-content-around ps-1'>
                    <span className='text-gray mx-1 text-center font-14'>
                      <TextField
                        name='nationalCode'
                        placeholder='کد ملی را وارد نمایید...'
                        className='border-0 placeholder-fs-sm font-12'
                        control={control}
                        error={errors}
                      />
                    </span>
                    <span onClick={handleSubmit(fastAdmissionSubmit)}>
                      <a
                        className='d-flex align-items-center justify-content-center btn btn-green-dashboard rounded rounded-3  text-black p-2'>
                          <Icon name='stethoscope'/>
                      </a>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-6">
            <AdmissionAnimated/>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
        <div className="dashboard-card-box d-flex col-12 h-100"
             style={{
               background: `url(${frequentlyUsedPrescriptionBackground}) no-repeat center center`,
               backgroundSize: "cover",
             }}
        >
          <div className="col-6 d-flex flex-column px-2">
            <div className="d-flex flex-wrap align-items-center mt-4">
              <span className="d-flex align-items-center text-white font-25">
                <span className="d-flex align-items-center ms-2">
                 <Icon name="dashboard-capsules"/>
                </span>
                <span className="font-20 fw-bold text-white">
                  نسخه‌های
                </span>
              </span>
              <span className="font-15 fw-medium me-2" style={{color: '#97ABFF'}}>
                  پر‌استفاده
                </span>
            </div>
            <div className="d-flex flex-wrap align-items-center mt-2">
              <span className="text-white">
                <span className="font-20 fw-bold triangle-bg overflow-visible px-2">
                  {dashboardData?.draftPrescriptionMedication}
                </span>
                <span className="font-10 fw-medium mx-1">
                  دارویی
                </span>
              </span>
              <span className="text-white">
                <span className="font-20 fw-bold triangle-bg overflow-visible px-2">
                  {dashboardData?.draftPrescriptionParaclinical}
                </span>
                <span className="font-10 fw-medium mx-1">
                  پاراکلینیکی
                </span>
              </span>
            </div>
            <div className="d-flex align-items-end mt-auto">
              <span
                className="btn btn-navy-blue rounded-3 d-flex align-items-center font-9 fw-bold text-nowrap px-2 mb-1"
                onClick={() => navigate(ROUTES.FAVORITE_PRESCRIPTIONS.PATH)}
              >
                نسخه‌های پر‌استفاده
              </span>
            </div>
          </div>
          <div className="col-6">
            <PrescriptionAnimated/>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
        <div className="dashboard-card-box d-flex col-12 h-100"
             style={{
               background: `url(${frequentlyUsedItems}) no-repeat center center`,
               backgroundSize: "cover",
             }}
        >
          <div className="col-6 d-flex flex-column px-2">
            <div className="d-flex flex-wrap align-items-center mt-4">
              <span className="d-flex align-items-center text-white font-25">
                <span className="d-flex align-items-center text-black ms-2">
                 <Icon name="microscope"/>
                </span>
                <span className="font-20 fw-bold text-white">
                  اقـــلــــام
                </span>
              </span>
              <span className="font-15 fw-medium me-2">
                  پر‌استفاده
                </span>
            </div>
            <div className="d-flex flex-wrap align-items-center mt-2">
              <span className="text-white">
                <span className="font-20 fw-bold triangle-bg-white overflow-visible px-2" style={{color: '#3699FF'}}>
                  {dashboardData?.draftPrescriptionItemMedication}
                </span>
                <span className="font-10 fw-medium mx-1">
                  دارویی
                </span>
              </span>
              <span className="text-white">
                <span className="font-20 fw-bold triangle-bg-white overflow-visible px-2" style={{color: '#3699FF'}}>
                  {dashboardData?.draftPrescriptionItemParaclinic}
                </span>
                <span className="font-10 fw-medium mx-1">
                  پاراکلینیکی
                </span>
              </span>
            </div>
            <div className="d-flex align-items-end mt-auto">
              <span className="btn btn-white rounded-3 d-flex align-items-center font-9 fw-bold text-nowrap px-2 mb-1"
                    style={{color: '#3F8DFD'}}
                    onClick={() => navigate(ROUTES.FAVORITE_ITEMS.PATH)}
              >
                اقلام پر‌استفاده
              </span>
            </div>
          </div>
          <div className="col-6">
            <MedicationItemsAnimated/>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 mb-4" style={{maxHeight: '476px'}}>
        <DailyVisitCountChart/>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 mb-4" style={{maxHeight: '476px'}}>
        <PatientChartsCarousel/>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12 mb-4" style={{maxHeight: '476px'}}>
        <VisitChartsCarousel/>
      </div>
      <div className="col-lg-4 col-12 mb-4">
        <ReservedVisits/>
      </div>
      <div className="col-lg-8 col-12 mb-4">
        <DailyVisitIssuerChart/>
      </div>
      <div className="col-12 mb-4">
        <div className="dashboard-card-box h-100">
          <div className="flex-wrap align-items-center m-2">
            <div className="d-flex">
              <span className="d-flex align-items-center text-white font-25">
                <span className="d-flex align-items-center ms-2">
                 <Clock/>
                </span>
                <span className="font-15 fw-bold text-black">
                  برنامه کاری روزانه
                </span>
              </span>
              <span className='me-auto'>
                <Calendar/>
              </span>
            </div>
            {scheduleItemsList && scheduleItemsList?.length > 0 ?
              <div className='mt-4'>
                <ScheduleTimeline scheduleItemsList={scheduleItemsList} draftStatus={false}/>
              </div> :
              <div className='d-flex flex-column justify-content-center'>
                <div className="d-flex justify-content-center">
                  <EmptySchedule/>
                </div>
                <div className="d-flex justify-content-center">
                  <span
                    onClick={() => navigate(ROUTES.SCHEDULE.PATH)}
                    className={`btn btn-light-blue rounded d-flex align-items-center px-4 mx-2`}>
                      <span className={`d-inline-flex font-20 ms-2`}><Icon name="time"/></span>
                      {'برو به زمان‌بندی'}
                  </span>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <AddVisitModal/>
    </div>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(DoctorDashboard);