import { combineReducers } from 'redux';
import getClinicsListReducer from './getClinicsList';
import getClinicsAllListReducer from './getClinicsAllList';
import getClinicReducer from './getClinic';
import addClinicReducer from './addClinic';
import editClinicReducer from './editClinic';
import deleteClinicReducer from './deleteClinic';
import getDoctorExpertiseReducer from './getDoctorExpertise';

const clinic = combineReducers({
  getClinicsListReducer,
  getClinicsAllListReducer,
  getClinicReducer,
  addClinicReducer,
  editClinicReducer,
  deleteClinicReducer,
  getDoctorExpertiseReducer
});

export default clinic;
