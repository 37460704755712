import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BootstrapTooltip from 'react-bootstrap/Tooltip';

type TooltipProps = {
  alt?: any
  placement?: any
  show?: number;
  hide?: number
  className?: string
  children?: any;
}

const Tooltip = ({
  alt,
  placement,
  show,
  hide,
  className,
  children
}: TooltipProps) => {
  return(
    <OverlayTrigger
      placement={placement || 'top'}
      delay={{show: show || 50 , hide: hide || 50}}
      overlay={
        <BootstrapTooltip id="tooltip-top" className={`custom-tooltip ${className}`}>
          <span dangerouslySetInnerHTML={{ __html: alt }}></span>
        </BootstrapTooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default Tooltip;