import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
  isHideModalDataSelector,
  isShowModalDataSelector, modalDataSelector,
  modalNameSelector
} from "../../../../../../../store/selector/general";
import {general} from "../../../../../../../store/action";
import Modal from "../../../../../general/modal";
import BoxLoader from "../../../../../general/boxLoader";
import SearchBox from "../../../../../general/searchBox";
import Icon from "../../../../../general/icon";
import {useMySearchParams} from "../../../../../../../hook/useMySearchParams";
import {Accordion} from "react-bootstrap";
import {useGetDraftPrescriptionList} from "../../../../../../../hook/request/visit/draftPrescription";
import FavoriteMedicationPrescriptionRow from "./dataList/favoriteMedicationPrescriptionRow";
import EmptyBox from "../../../../../general/emptyBox";
import {TAddPrescriptionMedicationItemFields} from "../../../../../../../types/medicationItem";
import {useWindowSize} from "../../../../../../../hook/useWindowSize";

type FavoriteMedicationPrescriptionModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  draftMedication?: boolean;
}

type ModalInfoProps = {
  id: string;
  visitId: string;
  patientData?: any;
  index?: number;
  item?: any;
  handleFavoriteModalSubmit: any;
  fieldValue: any;
}

const FavoriteMedicationPrescriptionModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: FavoriteMedicationPrescriptionModalProps) => {

  const [width] = useWindowSize();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    visitId: '',
    patientData: '',
    handleFavoriteModalSubmit: null,
    fieldValue: null
  });

  const filterValuesInitialState = {
    pageNumber: "0",
    perPage: "10",
    search: '',
    draftMedication: true
  }
  const { getQueryParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>(filterValuesInitialState);
  const [fieldValue, setFieldValue] = useState<TAddPrescriptionMedicationItemFields[]>([]);
  const [draftPrescriptionList, setDraftPrescriptionList] = useState([]);

  // services
  const getDraftPrescriptionMedicationListRequest = useGetDraftPrescriptionList(
    getQueryParams(filterValues),modalName === 'favoritePrescriptionMedicationModal');

  useEffect(() => {
    showState && getDraftPrescriptionMedicationListRequest.refetch();
  }, [filterValues])

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'favoritePrescriptionMedicationModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  // useEffect(() => {
  //   let _filterValues = {};
  //   _filterValues = {
  //     ...filterValues,
  //     pageNumber: searchParams.get("pageNumber") || "0",
  //     perPage: searchParams.get("perPage") || "10",
  //     search: searchParams.get("search") || modalInfo?.item?.name
  //   }
  //   setFilterValues(_filterValues)
  // }, [searchParams]);

  useEffect(() => {
    if (getDraftPrescriptionMedicationListRequest?.data?.data?.result) {
      const { data } = getDraftPrescriptionMedicationListRequest.data.data.result
      setDraftPrescriptionList(data);
    }
  }, [getDraftPrescriptionMedicationListRequest.data])


  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue([])
    setFilterValues(filterValuesInitialState);
    handleHideModal();
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    // updateSearchParams({
    //   pageNumber: "0",
    //   perPage: filterValues?.perPage,
    //   [name]: value,
    // })
    let _filterValues = {
      ...filterValues,
      pageNumber: "0",
      perPage: filterValues?.perPage,
      search: value
    }
    setFilterValues(_filterValues)
  }

  const addDraftPrescriptions = () => {
    handleSetShowModal();
    const updatedFieldValue = fieldValue.map(({ id, ...rest }) => ({
      medId: id,
      ...rest,
    }));
    modalInfo.handleFavoriteModalSubmit(updatedFieldValue, -1)
  }

  const pushFavoritePrescriptionMedication = (item: any, checked: boolean) => {
    setFieldValue((prev: any) => {
      if (checked) {
        // Add the item if it is not already in the list
        if (!prev.find((i: any) => i.id === item.id)) {
          const body = {
            id: item?.id,
            dosage: item?.dosage?.id,
            frequency: item?.frequency?.id,
            item: item?.item,
            itemCount: item?.itemCount,
          }
          return [...prev, body];
        }
      } else {
        // Remove the item if it exists in the list
        return prev.filter((i: any) => i.id !== item.id);
      }
      return prev;
    })
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel overflow-hidden"
      fullscreen={width <= 900 ? true : 'false'}
    >
      {getDraftPrescriptionMedicationListRequest?.isLoading && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              نسخ پراستفاده دارویی
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
            <Icon name='close-square'/>
          </span>
        </div>
        <div className="col-12 px-2">
          <div className="form-group mb-4 ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="نام دارو"
            />
          </div>
        </div>
        {/*{isRequesting && <BoxLoader/>}*/}
        <div className="overflow-auto custom-scrollbar" style={{ maxHeight: '70vh' }}>
          <Accordion className='visits-accordion rounded mb-4 mx-2 overflow-auto' alwaysOpen style={{ maxHeight: '100%', overflowY: 'auto' }}>
            {
              draftPrescriptionList.length > 0 ?
                draftPrescriptionList.map((item: any, key: number) => {
                  return (
                    <FavoriteMedicationPrescriptionRow
                      key={key}
                      item={item}
                      pushFavoritePrescriptionMedication={pushFavoritePrescriptionMedication}
                    />
                  )
                }) : <EmptyBox/>
            }
          </Accordion>
        </div>
        <div className="col-12 d-flex justify-content-end ">
          <div className="d-flex w-100 align-items-stretch form-group px-2">
            <button
              type='submit'
              className="btn btn-primary rounded d-flex w-100 justify-content-center align-items-center"
              onClick={addDraftPrescriptions}
              disabled={draftPrescriptionList?.length === 0}
            >
              {"افزودن به نسخه"}
            </button>
          </div>
        </div>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteMedicationPrescriptionModal);