import React from "react";
import { Form } from "react-bootstrap";
import Icon from "../../../../general/icon";
import Tooltip from "../../../../general/tooltip";
import {useWindowSize} from "../../../../../../hook/useWindowSize";
import {getValueById, MEDICATION_SHAPE_IHIO} from "../../../../../../constants/constant/enum";

/*
    کامپوننت رندر کردن هر دارو و چک باکس آن.
    Parent: <addMedicationPrescriptionItemModal/>
    Children: -
    در این کامپوننت هر چک باکس هر دارو و اطلاعات آن نمایش داده میشود،
    همچنین اسم دارو بر اساس کلمه سرچ شده هایلایت میشود.
*/


type MedicationItemBoxProps = {
  searchStr: string | undefined;
  item: any;
  fieldValue: any;
  setFieldValue: React.Dispatch<React.SetStateAction<any>>;
}

const MedicationItemBox = ({
  searchStr,
  item,
  fieldValue,
  setFieldValue
}: MedicationItemBoxProps) => {
  const [width] = useWindowSize();

  /*
    بررسی و اضافه و پاک کردن اقلام دارویی از لیست انتخاب شده ها.
    @param: e
  */
  const handleMedicationItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFieldValue((prevState: any) => {
      if (checked) {
        return [...prevState, item];
      } else {
        return prevState.filter((find: string) => find !== item);
      }
    });
  }

  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };


  /*
    هایلایت کلمه های سرچ شده.
    @param: string
  */
  const highlightText = (text: string, searchQuery: string | undefined) => {
    if (!searchQuery) return text;
    const searchWords = searchQuery.split(' ').filter(word => word.trim() !== '').map(escapeRegExp);
    if (searchWords.length === 0) return text;
    const regex = new RegExp(`(${searchWords.join('|')})`, 'gi');
    const segments = text.split(regex);
    return segments.map((segment, index) =>
      regex.test(segment) ? <span key={index} className="highlight rounded rounded-1 fw-bold py-2">{segment}</span> : segment
    );
  };

  const checked = fieldValue.some((find: any) => find?.id === item?.id)

  return (
    <div key={`inline-checkbox-${item?.id}`}
         className={`border-bottom border-2 px-2 ${!item?.active ? 'bg-gray-disabled' : !item?.support ? 'bg-red-hover' : 'background-gray-hover'}`}
    >
      <Form.Check
        className='py-2'
      >
        <Form.Check.Label className='d-flex align-items-center w-100'>
          <div className="d-flex align-items-center w-100 ltr">
            <div className="d-flex align-content-center ms-3">
              <Form.Check.Input
                className='rounded rounded-3 font-22 my-1'
                name={`checkbox-${item?.id}`}
                type='checkbox'
                id={`inline-checkbox-${item?.id}`}
                checked={checked ? checked : null}
                onChange={handleMedicationItemChange}
                disabled={!item?.active}
              />
              <div
                className={`d-flex align-items-center justify-content-center bg-primary-10 rounded rounded-3 border border-primary font-12 text-primary text-nowrap mx-2 ${width > 900 ? 'w-80' : 'w-20 font-en fw-bold'}`}>
                {width > 900 ? item?.category?.name : getValueById(item?.shape?.id, MEDICATION_SHAPE_IHIO, 'enName')[0]}
              </div>
            </div>
            <span className={`font-en text-start ${!item?.active ? 'text-gray' : ''}`}>
              {highlightText(item?.molecule, searchStr)} {width > 900 && <>&ensp;</>} {highlightText(item?.dose, searchStr)} {width > 900 && <>&ensp;</>}  {highlightText(item?.doseForm, searchStr)}
            </span>
            {
              (!item.active || !item.support) &&
              <div className="d-flex ms-auto">
                {
                  !item?.active &&
                  <div className={`d-flex align-items-center`}>
                    <Tooltip alt={'با توجه به بیمه بیمار، این خدمت غیر فعال می‌باشد'} className="tooltip-pre-line">
                      <div className='d-flex align-items-center text-gray font-18 ms-2'>
                        <Icon name='info-circle'/>
                      </div>
                    </Tooltip>
                  </div>
                }
                {
                  !item?.support &&
                  <div className={`d-flex align-items-center`}>
                    <Tooltip alt={'این قلم تحت پوشش بیمه بیمار نمی‌باشد'} className="tooltip-pre-line">
                      <div className='d-flex align-items-center text-red font-18 ms-2'>
                        <Icon name='info-circle'/>
                      </div>
                    </Tooltip>
                  </div>
                }
              </div>
            }
          </div>
        </Form.Check.Label>
      </Form.Check>
    </div>
  )
};

export default MedicationItemBox;
