import {TGIdNameData} from "./general";

export type TEcgSegmentData = [{
  p?: {
    start: number
    end: number,
  };
  qrs?: {
    start: number
    end: number,
  };
  t?: {
    start: number
    end: number,
  };
  r?: number;
  classify?: TGIdNameData
}]

export type TEcgSegmentation = {
  p: any[];
  qrs: any[];
  t: any[];
  st: any[];
  pr: any[];
  rr: any[];
  tp: any[];
  qt: any[];
  r: any[];
}

export const ecgSegmentationInitialValue = {
  p: [],
  qrs: [],
  t: [],
  st: [],
  pr: [],
  rr: [],
  tp: [],
  qt: [],
  r: [],
}

export type TEcgQtc = {
  bazett?: number;
  fridericia?: number;
  hodges?: number;
  framingham?: number;
  rautaharju?: number;
}

export const ecgQtcInitialValue = {
  bazett: 0,
  fridericia: 0,
  hodges: 0,
  framingham: 0,
  rautaharju: 0
}

export type TEcgArrhythmia = {
  from?: number;
  to?: number;
  color?: string;
  label?: string;
}