import { getCellValue } from "../../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../../utils/date";

const LoginHistoryCardsBoxItem = ({
  item,
  dataListTitles,
}) => {

  return(
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.account?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="specialty">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.mobile)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="nationalCode">{dataListTitles[2]}</label>
        <span className="mr-2">{getCellValue(item?.requesterIp)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="updateDate">{dataListTitles[3]}</label>
        <span className="mr-2">
          <bdi>{getCellValue(MtoJFullDateFormat(item?.dateTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="medId">{dataListTitles[4]}</label>
        <span className="mr-2">{getCellValue(item?.type?.name)}</span>
      </div>
    </div>
  )
}

export default LoginHistoryCardsBoxItem;