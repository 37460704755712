import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchBox from "../../components/general/searchBox";
import { useWindowSize } from "../../../hook/useWindowSize";
import PatientTable from "../../components/patients/dataList/patientTable";
import PatientCardsBox from "../../components/patients/dataList/patientCardsBox";
import { useGetPatients } from "../../../hook/request/patients";
import { useMySearchParams } from "../../../hook/useMySearchParams";
import Pagination from "../../components/general/table/pagination";

const dataListTitles = [
  "نام بیمار",
  "کد ملی",
  "شماره پاسپورت",
  "شماره همراه",
  "تاریخ تولد",
  "جنسیت",
  "تاریخ ایجاد",
  "عملیات",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const PatientsList = () => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [patientsData, setPatientsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getPatientsRequest = useGetPatients(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getPatientsRequest?.data?.data?.result &&
      setPatientsData(getPatientsRequest?.data?.data.result)
  }, [getPatientsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          {/* <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-3 m-md-0">
            <Link to={ROUTES.PATIENT_ADD.PATH} className="btn btn-primary rounded d-flex align-items-center px-3">
              <span className="d-inline-flex font-20 ms-2"><Icon name="add-square" /></span>
              افزودن پزشک
            </Link>
          </div> */}
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی بیمار"
            />
          </div>
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <PatientCardsBox
                dataList={patientsData}
                dataListTitles={dataListTitles}
                isRequesting={getPatientsRequest.isPending}
              /> :
              <PatientTable
                dataList={patientsData}
                dataListTitles={dataListTitles}
                isRequesting={getPatientsRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...patientsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      
      {/* <DeletePatientModal /> */}
    </>
  );
};

export default PatientsList;