import React from 'react';
import Icon from './icon';

type AlertBoxProps = {
  type: "info" | "success" | "warning" | "danger" | "primary";
  icon: string;
  iconClass?: string;
  text?: string | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  px?: string;
  py?: string;
}

const AlertBox = ({
  type,
  icon,
  iconClass = '',
  text,
  className,
  children,
  px = 'px-3',
  py = 'py-3'
}: AlertBoxProps) => {
  return(
    <div className={`alert alert-${type} d-flex align-items-center ${className} ${px} ${py}`}>
      <span className="d-inline-flex ms-2 font-24">
        <Icon name={`${icon || 'info-circle'} ${iconClass}`} />
      </span>
      {text ? <p className="m-0">{text}</p> : children}
    </div>
  )
}

export default AlertBox;