import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Collapse} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {TAddPrescriptionParaclinicalItemFields} from "../../../../types/paraclinicalItem";
import Icon from "../../general/icon";
import SelectField from "../../general/form/selectField";
import TextAreaField from "../../general/form/textAreaField";
import {general} from "../../../../store/action";
import DebounceField from "../../general/form/debounceField";
import {VISIT_MODE} from "../../../../constants/constant/enum";
import DatePickerField from "../../general/form/datePickerField";

/*
    کامپوننت مربوط به انتخاب اطلاعات تجویز هر خدمت پاراکلینیک برای نسخه
    Parent: <PrescriptionParaclinicalItem/>
    Children: -
    در این کامپوننت هر یک از خدمت های پاراکلینیک تجویز شده رندر میشود،
    سپس اطلاعات نسخه مانند تعداد خدمت ثبت میشود و اطلاعات آن برای کامپوننت پرنت فرستاده میشود.
*/

type FavoriteParaclinicPrescriptionRowItemProps = {
  item: any;
  index: number;
  control: any;
  errors: any;
  handleShowModal:any;
  onFieldsChange: any;
  handleParaclinicalModalSubmit: any;
  deleteParaclinicalItemFromList: any;
  register: any;
  watch: any
  update?: any;
}


const FavoriteParaclinicPrescriptionRowItem = ({
 item,
 index,
 control,
 errors,
 onFieldsChange,
 handleShowModal,
 handleParaclinicalModalSubmit,
 deleteParaclinicalItemFromList,
 register,
 watch,
 update,
}: FavoriteParaclinicPrescriptionRowItemProps) => {

  const [showDescription, setShowDescription] = useState(false)
  const [fieldValue, setFieldValue] = useState<TAddPrescriptionParaclinicalItemFields>()


  useEffect(() => {
    setFieldValue(item)
  }, [item]);


  const handleFieldChange = (e?: any) => {
    const { name, value } = e?.target;
    const fieldName = name.split('_')[0];
    let fieldValue = {...item, [fieldName]: value};
    update(index, { ...fieldValue})
  };

  return (
    <div className='bg-primary bg-opacity-10 rounded my-4 px-4 pb-3'>
      <div className="d-flex">
        <div className="w-100">
          <div className='d-flex fw-bold font-14 mt-4'>
            <div className='p-1 btn btn-gray rounded rounded-3'>
              <span
                onClick={() => deleteParaclinicalItemFromList(index)}
                className='mx-2 font-20 d-flex  align-items-center h-100'>
                <Icon name='trash'/>
              </span>
            </div>
            <div
              onClick={() => setShowDescription(!showDescription)}
              className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
                <span
                  className="btn btn-gray rounded d-flex align-items-center px-4 mx-2">
                  <span className="d-inline-flex font-25 ms-2 ps-2">
                    <Icon name={`${showDescription ? 'chat-close' : 'chat'}`}/>
                  </span>
                  توضیحات خدمت
                </span>
            </div>
            <div className="d-flex me-auto">
              <div className="col-6 me-auto">
                <div className="form-group mb-2">
                  <DebounceField
                    onChange={handleFieldChange}
                    name={`itemCount_${index}`}
                    placeholder="تعداد"
                    control={control}
                    error={errors}
                    register={register(`root.${index}.itemCount`)}
                    watch={watch(`root.${index}.itemCount`)}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className="d-flex mb-2">
            {/*<div className="col-12 col-md-6 ps-2 me-4">*/}
            {/*  <div className="form-group">*/}
            {/*    <div className='d-flex border form-control mb-3 pl-5'>*/}
            {/*      <span>پاراکلینیک اورژانسی </span>*/}
            {/*      <span className='font-20 me-auto ms-1'>*/}
            {/*      <SwitchField*/}
            {/*        label={''}*/}
            {/*        onChange={handleFieldChange}*/}
            {/*        name={`emergency_${index}`}*/}
            {/*        control={control}*/}
            {/*        error={errors}*/}
            {/*      />*/}
            {/*    </span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      <div className={`border border-dark-subtle rounded mb-2 cur-pointer`}
           onClick={() => handleShowModal('addPrescriptionParaclinicalItemModal', {
             index: index,
             item: item,
             handleParaclinicalModalSubmit: handleParaclinicalModalSubmit,
             insurance: 40
           })}
      >
        <div className="d-flex">
          <div className="d-flex col-9 pe-3 my-3">
            <div className="col-6 pe-3">
              <span className="font-9 fw-semibold">
                {item?.service.name}
              </span>
            </div>
            <div className="col-6 text-start pe-3">
              <span className="font-en font-9">
                {item?.service.enName ? item?.service?.enName : item?.service.name}
              </span>
            </div>
          </div>
          <div className="col-3 ps-2 my-3">
            <div className='d-flex justify-content-end align-items-center pl-5'>
              <div className='ltr'>
                <span className='fw-bold text-primary font-en ms-1 '> {(index + 1) + '. '} </span>
                <span
                  className={`rounded rounded-3 bg-primary text-white border border-2 border-primary font-12 text-primary text-nowrap px-2 py-1`}>
                      {item?.service?.type?.name}
                     </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Collapse in={showDescription}>
        <div className={`col-12`}>
          <div className="form-group">
            <TextAreaField
              onChange={handleFieldChange}
              rows={1}
              name={`note_${index}`}
              placeholder="توضیحات خدمت"
              control={control}
              error={errors}
              register={register(`root.${index}.note`)}
              watch={watch(`root.${index}.note`)}
            />
          </div>
        </div>
      </Collapse>
    </div>
  )
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(FavoriteParaclinicPrescriptionRowItem);