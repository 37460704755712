import {useQuery,useMutation} from '@tanstack/react-query'
import { PatientSurgeryList } from '../../../services/requests/ehr/surgery';

import Noti from "../../../view/components/general/noti";
import { handleResponse } from '../setup';


const useGetListSurgery = (params:any, modalName?: boolean)=>{
  return useQuery({
      queryKey:["getListSurgery",params],
      queryFn:({queryKey})=>{
       const [_,params] = queryKey;
       return PatientSurgeryList.getPatientSurgeryList(params)
      },
    enabled: modalName ?? true
  })
}



const useGetListAllSurgery = (params:any, modalName: boolean)=>{
  return useQuery({
      queryKey:["getListAllSurgery",params],
      queryFn:({queryKey})=>{
       const [_,params] = queryKey;
       return PatientSurgeryList.getPatientSurgeryListAll(params)
      },
    enabled: modalName ?? true
  })
};

const useAddSurgery = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation({
      mutationFn:PatientSurgeryList.AddPatientSurgery,
      onSuccess: (data: any) => {
        handleResponse({
          data,
          onSuccess: () => {
            Noti("success", data?.data?.msg);
            onSuccess();
          },
        });
    },
  });
}


const useGetPatientSurgeryListAll = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation({
      mutationFn:PatientSurgeryList.getPatientSurgeryListAll,
      onSuccess: (data: any) => {
        handleResponse({
          data,
          onSuccess: () => {
            Noti("success", data?.data?.msg);
            onSuccess();
          },
        });
    },
  });
};



export {
    useGetListSurgery,
    useGetListAllSurgery,
    useGetPatientSurgeryListAll,
    useAddSurgery
}