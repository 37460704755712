import { useEffect, useState } from "react";
import { ROUTES } from "../../../constants";
import BoxLoader from "../../components/general/boxLoader";
import PasswordField from "../../components/general/form/passwordField";
import {useNavigate, useSearchParams} from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { TChangePasswordFields } from "../../../types/account";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordSchema } from "../../../schema/account-schema";
import { useAccountChangePassword } from "../../../hook/request/account";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const ChangePassword = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [specialtiesFilterValues, setSpecialtiesFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TChangePasswordFields>({
    resolver: yupResolver(changePasswordSchema),
  });

  // services
  const accountChangePasswordRequest = useAccountChangePassword();

  useEffect(() => {
    let _SpecialtiesFilterValues = {};
    _SpecialtiesFilterValues = {
      ...specialtiesFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setSpecialtiesFilterValues(_SpecialtiesFilterValues)
  }, [searchParams]);

  const changePasswordSubmit: SubmitHandler<TChangePasswordFields> = (data) => {
    const { oldPassword, newPassword } = data,
      body = { oldPassword, newPassword };
    accountChangePasswordRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          accountChangePasswordRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(changePasswordSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">تغییر کلمه عبور</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="oldPassword">کلمه عبور قبلی خود را وارد کنید</label>
                <PasswordField
                  name="oldPassword"
                  placeholder="کلمه عبور قبلی خود را وارد کنید"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="newPassword">کلمه عبور جدید</label>
                <PasswordField
                  name="newPassword"
                  placeholder="کلمه عبور جدید"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="newPasswordConfirm">‌تکرار کلمه عبور جدید</label>
                <PasswordField
                  name="newPasswordConfirm"
                  placeholder="تکرار کلمه عبور جدید"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-4 pb-1">
                <button
                  type='button'
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  تغییر کلمه عبور
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;