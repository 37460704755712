import {Link, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import SearchBox from "../../../components/general/searchBox";
import Icon from "../../../components/general/icon";
import Pagination from "../../../components/general/table/pagination";
import {useGetVaccines} from "../../../../hook/request/vaccine";
import VaccinesTable from "../../../components/basicInformation/vaccine/dataList/vaccinesTable";
import VaccineCardsBox from "../../../components/basicInformation/vaccine/dataList/vaccineCardsBox";
import {ROUTES} from "../../../../constants";
import DeleteVaccineModal from "../../../components/basicInformation/vaccine/deleteVaccineModal";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const dataListTitles = [
  "نام واکسن",
  "توضیحات",
  "مکسا",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const VaccinesList = () => {

  const [width] = useWindowSize();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [vaccinesData, setVaccinesData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getVaccinesRequest = useGetVaccines(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getVaccinesRequest?.data?.data?.result &&
    setVaccinesData(getVaccinesRequest?.data?.data.result)
  }, [getVaccinesRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی واکسن"
            />
          </div>
          {
            findAccessInAccessList('BASIC_VACCINE') &&
            <div className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <Link to={ROUTES.VACCINE_ADD.PATH} className="btn btn-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                افزودن واکسن
              </Link>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <VaccineCardsBox
                dataList={vaccinesData}
                dataListTitles={dataListTitles}
                isRequesting={getVaccinesRequest.isPending}
              /> :
              <VaccinesTable
                dataList={vaccinesData}
                dataListTitles={dataListTitles}
                isRequesting={getVaccinesRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...vaccinesData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <DeleteVaccineModal/>
    </>
  );
};

export default VaccinesList;