import React, {useEffect, useMemo, useRef, useState} from "react";
import { ROUTES } from "../../../constants";
import BoxLoader from "../../components/general/boxLoader";
import SelectField, {AsyncSelectField} from "../../components/general/form/selectField";
import TextField from "../../components/general/form/textField";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { TAddItemToScheduleFields, TScheduleFields } from "../../../types/schedule";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addItemToScheduleSchema } from "../../../schema/scheduleSchema";
import { useAddItemToSchedule, useGetSchedule, useGetScheduleItemsList } from "../../../hook/request/schedule";
import Icon from "../../components/general/icon";
import { DAY_OF_WEEK, VISIT_TYPE } from "../../../constants/constant/enum";
import { useGetOrganizations } from "../../../hook/request/organizations";
import ScheduleTimeline from "../../components/schedules/scheduleTimeline";
import DeleteScheduleItemModal from "../../components/schedules/deleteScheduleItemModal";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import AlertBox from "../../components/general/alert";
import {general} from "../../../store/action";
import {connect, useSelector} from "react-redux";
import ActiveScheduleModal from "../../components/schedules/activeScheduleModal";
import DatePickerField from "../../components/general/form/datePickerField";
import { MtoJHHMMFormat } from "../../../utils/date";
import {asyncSelectLoadOptions} from "../../../utils/asyncSelectLoadOptions";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type addItemToScheduleProps = {
  handleShowModal: any;
}

const AddItemToSchedule = ({
 handleShowModal
}:addItemToScheduleProps ) => {
  const navigate = useNavigate();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const { id } = useParams();
  const { getQueryParams } = useMySearchParams();
  const [searchParams] = useSearchParams();
  const [organizationFilterValues, setOrganizationFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [scheduleFieldValue, setScheduleFieldValue] = useState<TScheduleFields>({
    provider: {
      id: 0,
      name: ""
    },
    name: '',
    fromDate: '',
    toDate: '',
    description: '',
  });
  const [fieldValue, setFieldValue] = useState<TAddItemToScheduleFields>({
    healthUnit: '',
    visitType: '',
    dayOfWeek: '',
    fromTime: '',
    toTime: '',
    capacity: '',
  })
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<TAddItemToScheduleFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addItemToScheduleSchema),
  });
  const [organizationsList, setOrganizationsList] = useState([]);
  const [scheduleItemsList, setScheduleItemsList] = useState([]);
  const [fetch, setFetch] = useState<boolean>(true)

  const callbackRef = useRef<any>(null);

  // services
  const getOrganizationsRequest = useGetOrganizations(getQueryParams(organizationFilterValues), fetch);
  const getScheduleRequest = useGetSchedule();
  const getScheduleItemsListRequest = useGetScheduleItemsList();
  const addItemToScheduleRequest = useAddItemToSchedule({
    onSuccess: () => {
      fetchScheduleItems();
    }
  });

  useEffect(() => {
    let _OrganizationFilterValues = {};
    _OrganizationFilterValues = {
      ...organizationFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setOrganizationFilterValues(_OrganizationFilterValues)
    setFetch(false)
  }, [searchParams]);

  useEffect(() => {
    if(id) {
      getScheduleRequest.mutate({id});
      fetchScheduleItems();
    }
  }, [id])

  const fetchScheduleItems = () => {
    getScheduleItemsListRequest.mutate({id})
  }

  useEffect(() => {
    if(getOrganizationsRequest?.data?.data?.result) {
      const { data } = getOrganizationsRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id }
        ))
      setOrganizationsList(_data);
    }
  }, [getOrganizationsRequest.data])

  useEffect(() => {
    if (getScheduleRequest?.data?.data?.result) {
      const { provider, name, fromDate, toDate, description , draft} = getScheduleRequest.data.data.result,
        _fieldValue = { provider, name, fromDate, toDate, description, draft };
      setScheduleFieldValue(_fieldValue);
    }
  }, [getScheduleRequest.data])

  useEffect(() => {
    if (getScheduleItemsListRequest?.data?.data?.result) {
      setScheduleItemsList(getScheduleItemsListRequest?.data?.data?.result);
    }
  }, [getScheduleItemsListRequest.data])

  const changeRequestFilters = (inputValue: any, callback: any) => {
    callbackRef.current = callback;
    setOrganizationFilterValues({
      ...organizationFilterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(callbackRef.current) {
      organizationsLoadOptions(organizationFilterValues.search, callbackRef.current);
    }
  }, [organizationFilterValues?.search]);

  const organizationsLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, organizationFilterValues, setOrganizationFilterValues, getOrganizationsRequest);
  };

  const addItemToScheduleSubmit: SubmitHandler<TAddItemToScheduleFields> = (data) => {
    const { healthUnit, visitType, dayOfWeek, fromTime, toTime, capacity } = data,
      body = {
        providerSchedule: {
          id,
          name: ""
        },
        provider: {
          id: scheduleFieldValue?.provider?.id,
          name: ""
        },
        healthUnit: {
          id: healthUnit,
          name: ""
        },
        visitType: {
          id: visitType,
          name: ""
        },
        dayOfWeek: {
          id: dayOfWeek,
          name: ""
        },
        fromTime: MtoJHHMMFormat(fromTime),
        toTime: MtoJHHMMFormat(toTime),
        capacity
        // description,
      };
    addItemToScheduleRequest.mutate(body);
  }

  return (
    <>
      {
        getScheduleRequest?.isPending ?
          <BoxLoader type="text-loader" className="text-loader w-100 mx-2 mb-4"/> :
          (!scheduleFieldValue?.draft && (isProvider || findAccessInAccessList('PROVIDER_SCHEDULE_CREATE'))) &&
          <AlertBox 
            type='success'
            icon='info'
            iconClass='fs-2'
            text={'پزشک گرامی، لطفاً توجه داشته باشید که برنامه زمان‌بندی شما در حال حاضر در وضعیت تایید نهایی قرار دارد. قابلیت ویرایش بازه های زمانی برنامه امکان پذیر نمی‌باشد.'}
            className='mb-4'
          />
      }
      <div className="card-box mb-3">
        {
          (
            getOrganizationsRequest?.isLoading ||
            getScheduleRequest?.isPending ||
            getScheduleItemsListRequest?.isPending ||
            addItemToScheduleRequest?.isPending
          ) && <BoxLoader type="cover"/>
        }
        {
          (isProvider || findAccessInAccessList('PROVIDER_SCHEDULE_CREATE')) &&
          <div className="form-box mx-auto py-3">
            <form onSubmit={handleSubmit(addItemToScheduleSubmit)}>
              <div className="row">
                <div className="d-flex align-items-center px-4 mb-4">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="btn-arrow-right btn btn-dark px-1 d-flex align-items-center justify-content-center size-47 font-25 shadow"
                  >
                    <Icon name='arrow-right' />
                  </button>
                  <div className="d-flex w-100 justify-content-between">
                    <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold me-3">
                      تنظیم ساعت کاری
                      {
                        getScheduleRequest?.isPending ?
                          <BoxLoader type="text-loader" className="text-loader mx-2"/> :
                          <span className="d-inline-flex fw-semibold text-primary mx-1">{scheduleFieldValue?.provider?.name}</span>
                      }
                    </h4>
                    <div className='flex-fill'></div>
                    <h4 className="d-flex align-items-center font-16 font-weight-bold mx-3">
                      عنوان برنامه:
                      {
                        getScheduleRequest?.isPending ?
                          <BoxLoader type="text-loader" className="text-loader mx-2"/> :
                          <>
                            <span className="d-inline-flex fw-semibold text-primary mx-1">{scheduleFieldValue?.name}</span>
                            <span className={`px-3 py-1 rounded me-2 font-14 ${scheduleFieldValue?.draft ? 'alert-warning' : 'alert-success'}`}>
                              {scheduleFieldValue?.draft ? 'پیش نویس' : 'تایید نهایی'}
                            </span>

                          </>
                      }
                    </h4>
                  </div>
                </div>
                {
                  getScheduleRequest?.isPending ?
                    <BoxLoader type="text-loader" className="text-loader w-100 my-4 mx-auto"/> :
                    scheduleFieldValue?.draft &&
                    <>
                      <AlertBox
                        type='warning'
                        icon='info'
                        iconClass='fs-2'
                        className='mb-5 w-100'
                      >
                        <div className="d-flex align-items-center w-100 justify-content-between">
                          <span>پزشک گرامی، لطفاً توجه داشته باشید که برنامه زمان‌بندی شما در حال حاضر در وضعیت پیش‌نویس قرار دارد. بعد از تایید نهایی شما، قابلیت دریافت نوبت برای بیماران فراهم خواهد شد.</span>
                          <div className="d-flex justify-content-md-end mb-3 m-md-0 ">
                            <span
                              onClick={() => handleShowModal('activeScheduleModal', id)}
                              className="btn btn-primary text-nowrap rounded d-flex align-items-center px-3"
                            >
                              <span className="d-inline-flex font-20 ms-2"><Icon name="check-square"/></span>
                              نهایی کردن برنامه
                            </span>
                          </div>
                        </div>
                      </AlertBox>
                      <div className="col-12 col-md-6 px-1">
                        <div className="form-group mb-4">
                          <label className="inp-lbl me-2 mb-2" htmlFor="healthUnit">انتخاب مرکز درمانی</label>
                          <AsyncSelectField
                            options={organizationsList}
                            name="healthUnit"
                            placeholder='انتخاب کنید'
                            control={control}
                            error={errors}
                            asyncLoadOptions={changeRequestFilters}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 px-1">
                        <div className="form-group mb-4">
                          <label className="inp-lbl me-2 mb-2" htmlFor="visitType">نوع نوبت</label>
                          <SelectField
                            options={VISIT_TYPE}
                            name="visitType"
                            placeholder='انتخاب کنید'
                            control={control}
                            error={errors}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-3 px-1">
                        <div className="form-group mb-5">
                          <label className="inp-lbl me-2 mb-2" htmlFor="dayOfWeek">انتخاب روز</label>
                          <SelectField
                            options={DAY_OF_WEEK}
                            name="dayOfWeek"
                            placeholder='انتخاب کنید'
                            control={control}
                            error={errors}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-3 px-1">
                        <div className="form-group mb-5">
                          <label className="inp-lbl me-2 mb-2" htmlFor="fromTime">ساعت شروع کار</label>
                          {/* <SelectField
                            options={TIME_OF_DAY}
                            name="fromTime"
                            placeholder='انتخاب کنید'
                            control={control}
                            error={errors}
                          /> */}
                          <DatePickerField
                            name="fromTime"
                            placeholder="ساعت شروع کار"
                            showTimePicker="only"
                            format="HH:mm"
                            control={control}
                            error={errors}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-3 px-1">
                        <div className="form-group mb-5">
                          <label className="inp-lbl me-2 mb-2" htmlFor="toTime">ساعت پایان کار</label>
                          <DatePickerField
                            name="toTime"
                            placeholder="ساعت پایان کار"
                            showTimePicker="only"
                            format="HH:mm"
                            control={control}
                            error={errors}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-3 px-1">
                        <div className="form-group mb-5">
                          <label className="inp-lbl me-2 mb-2" htmlFor="capacity">ظرفیت (تعداد ویزیت در بازه)</label>
                          <TextField
                            name="capacity"
                            placeholder="ظرفیت (تعداد ویزیت در بازه)"
                            control={control}
                            error={errors}
                          />
                        </div>
                      </div>
                      <div className="col-12 px-4 d-flex justify-content-end">
                        <div className="d-flex form-group mb-4 mr-auto mt-auto pb-1">
                          <button
                            type='button'
                            onClick={() => navigate(ROUTES?.SCHEDULE?.PATH)}
                            className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                          >
                            بازگشت
                          </button>
                          <button
                            type='submit'
                            className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                          >
                            { id ? "افزودن بازه زمان‌بندی" : "افزودن بازه زمان‌بندی" }
                          </button>
                        </div>
                      </div>
                    </>
                }
              </div>
            </form>
          </div>
        }
        <ScheduleTimeline scheduleItemsList={scheduleItemsList} draftStatus={scheduleFieldValue?.draft}/>
      </div>
      {
        (isProvider || findAccessInAccessList('PROVIDER_SCHEDULE_DELETE') || findAccessInAccessList('PROVIDER_SCHEDULE_EDIT')) &&
        <DeleteScheduleItemModal onSuccess={fetchScheduleItems} />
      }
      <ActiveScheduleModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AddItemToSchedule);
