import {useQuery} from "@tanstack/react-query";
import {MAXAService} from "../../../services/requests/maxa";

// get tamin diagnosis list
const useGetTaminDiagnosisList = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getTaminDiagnosisList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return MAXAService.getTaminDiagnosisList(params);
    },
    enabled: modalName ?? true
  });
}

// get tamin complaint list
const useGetTaminComplaintList = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getTaminComplaintList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return MAXAService.getTaminComplaintList(params);
    },
    enabled: modalName ?? true
  });
}

export {
  useGetTaminDiagnosisList,
  useGetTaminComplaintList
}
