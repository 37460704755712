import { API_PATH } from "../../../constants"
import { axiosRequest } from "../../api"



 const PatientCigaretteHistory = {
    addCigarettePatient(data:any){
        return axiosRequest('POST',API_PATH.CIGARETTE.ADD,data)
    },
    getCigaretteList(params:any){
        return axiosRequest("GET",API_PATH.CIGARETTE.GET_LIST(params))
    },
    getCigaretteListAll(params:any){
        return axiosRequest('GET',API_PATH.CIGARETTE.CIGARETTE_LIST_All(params))
    }
}



export {
    PatientCigaretteHistory,
    
}