type EmptyRowProps = {
  colSpan: number;
  content?: string;
}

const EmptyRow = ({
  colSpan,
  content
}: EmptyRowProps) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="text-center p-2">{content || "داده ای یافت نشد!"}</div>
      </td>
    </tr>
  )
}

export default EmptyRow;