import React, {useEffect, useRef, useState} from "react";
import {connect, useSelector} from "react-redux";
import {Accordion, Collapse, Nav, Tab} from "react-bootstrap";
import {general} from "../../../../../../store/action";
import Highcharts from "highcharts";
import HighchartsReact, {HighchartsReactRefObject} from "highcharts-react-official";
import Observer from "../../../../utility/observerAPI/observer";
import {useECGLead, useECGSegment} from "../../../../../../hook/request/visit/examination";
import {TGIdNameData, TGQuantityData} from "../../../../../../types/general";
import {MtoJDateFormat, MtoJHHMMFormat} from "../../../../../../utils/date";
import {getCellValue} from "../../../../../../utils/utils";
import {EcgOptions} from "../../../../general/ecgChartOptions";
import Icon from "../../../../general/icon";
import EcgAiReportModal from "./ecgAiReportModal";
import ExaminationAnalysis from "../analysis/examinationAnalysis";
import EcgSummary from "./ecgSummary";
import {useWindowSize} from "../../../../../../hook/useWindowSize";
import {
  ecgQtcInitialValue,
  ecgSegmentationInitialValue, TEcgArrhythmia,
  TEcgQtc,
  TEcgSegmentation,
  TEcgSegmentData
} from "../../../../../../types/ecg";
import {ARRHYTHMIA_TYPE_ENUM} from "../../../../../../constants/constant/enum";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";
import {generatePath} from "react-router-dom";
import {ROUTES} from "../../../../../../constants";

type ECGProps = {
  id?: number,
  ecgLeads?: any[],
  startDate?: any,
  heartRate?: TGQuantityData,
  pulseRate?: TGQuantityData,
  healthUnit?: string,
  handleShowModal?: any
}

const segmentTitles = [
  'P',
  'QRS',
  'T',
  'ST',
  'PR',
  'RR',
  'TP',
  'QT'
]

const ECG = ({
  id,
  ecgLeads,
  startDate,
  heartRate,
  pulseRate,
  healthUnit,
  handleShowModal
}: ECGProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [width] = useWindowSize();
  const [mainEcgData, setMainEcgData] = useState<any>([]);
  const [ecgLeadData, setEcgLeadData] = useState<any[]>([]);
  const [leadDuration, setLeadDuration] = useState<any>();
  const [labels, setLabels] = useState([]);
  const [leadTitles, setLeadTitles] = useState<string[]>([]);
  const [activeLead, setActiveLead] = useState('II');
  const [aiActive, setAiActive] = useState(false);
  const [activeSegment, setActiveSegment] = useState('');
  const [gain, setGain] = useState(1.25);
  const [ecgSegmentData, setEcgSegmentData] = useState<TEcgSegmentData | null>(null);
  const [ecgSegmentation, setEcgSegmentation] = useState<TEcgSegmentation>(ecgSegmentationInitialValue);
  const [ecgQtc, setEcgQtc] = useState<TEcgQtc>(ecgQtcInitialValue);
  const [ecgArrhythmia, setEcgArrhythmia] = useState<TEcgArrhythmia[]>([]);
  const [minimumYValue, setMinimumYValue] = useState<number>(0);
  const [activeSegmentData, setActiveSegmentData] = useState<any[]>();
  const [heartBeatPerMinute, setHeartBeatPerMinute] = useState<number>(-1)

  const [isVisible, setIsVisible] = useState(false);

  const chartComponentRef = useRef<HighchartsReactRefObject>(null);

  // services
  const getEcgLead = useECGLead();
  const getEcgSegment = useECGSegment();

  useEffect(() => {
    isVisible && getEcgLead.mutate({examination: id, lead: `LEAD_${activeLead}`})
  }, [isVisible, activeLead]);

  useEffect(() => {
    if(!getEcgLead.isPending && getEcgLead?.data?.data?.result){

      const { signals } = getEcgLead?.data?.data?.result;
      let mainEcgData = [],
        minimumPoint = 0
      if (signals){
        mainEcgData = signals.map((item: any) => {
          if (item?.data && item.data < minimumPoint) {
            minimumPoint = item.data;
          }
          return item?.data;
        });
      }
      const _leadDuration = signals[signals?.length - 1]?.timestamp - signals[0]?.timestamp;
      setLeadDuration(Math.round(_leadDuration / 60000));
      setMinimumYValue(minimumPoint)
      setMainEcgData(mainEcgData);
    }
  }, [getEcgLead.data])

  useEffect(() => {
    const _HBPM = (mainEcgData?.length * 3000) / leadDuration
    setHeartBeatPerMinute(_HBPM)
  }, [mainEcgData]);

  useEffect(() => {
    if (chartComponentRef.current && (getEcgLead.isPending || getEcgSegment.isPending)) {
      chartComponentRef.current.chart.showLoading('در حال دریافت اطلاعات...');
    } else {
      if (chartComponentRef.current) {
        chartComponentRef.current.chart.hideLoading();
      }
    }
  }, [getEcgLead.isPending, getEcgSegment.isPending]);

  useEffect(() => {
    if (ecgLeads) {
      const transformedData = ecgLeads.map((item: any) => {
        return {
          lead: item.lead,
          data: item.signals.map((signal: any) => signal?.data),
        };
      });
      const _leadTitles = transformedData.map((item: any) => item.lead.replace('LEAD_', ''));
      setLeadTitles(_leadTitles.slice().reverse())
      setEcgLeadData(transformedData);
    }
  }, [ecgLeads]);

  useEffect(() => {
    if(!getEcgSegment.isPending && getEcgSegment?.data?.data?.result){
      const { beats, qtc } = getEcgSegment?.data?.data?.result;
        setEcgSegmentData(beats);
        setEcgQtc(qtc);
      setActiveSegment('QRS')
    }
  }, [getEcgSegment.data])

  useEffect(() => {
    const beats: TEcgSegmentation = { ...ecgSegmentationInitialValue };
    const arrhythmia: TEcgArrhythmia[] = [];

    ecgSegmentData?.forEach((item: any, index: number) => {
      const { p, qrs, t, r, classify } = item;
      const nextSegment = ecgSegmentData[index + 1];

      if (p) {
        beats.p.push([p.start, p.end]);
        qrs && beats.pr.push([p.end, qrs.start]);  // PR interval
      }

      qrs && beats.qrs.push([qrs.start, qrs.end]);
      qrs && t && beats.qt.push([qrs.start, t.end]) && beats.st.push([qrs.end, t.start]); // QT & ST

      t && beats.t.push([t.start, t.end]);
      t && nextSegment?.p && beats.tp.push([t.end, nextSegment.p.start]); // TP segment

      r && beats.r.push(r);
      r && nextSegment?.r && beats.rr.push([r, nextSegment.r]); // RR interval

      const arrhythmiaType = Object.values(ARRHYTHMIA_TYPE_ENUM).find(item => item.value === classify?.id) || ARRHYTHMIA_TYPE_ENUM?.TYPE_U

      if (p) {
        arrhythmia.push({
          from: p.start,
          to: nextSegment?.p?.start || nextSegment?.qrs?.start || t?.end || qrs?.end,
          color: arrhythmiaType?.backgroundColor,
          label: arrhythmiaType?.label
        });
      } else if (qrs) {
        arrhythmia.push({
          from: qrs.start,
          to: nextSegment?.p?.start || nextSegment?.qrs?.start,
          color: arrhythmiaType?.backgroundColor,
          label: arrhythmiaType?.label
        });
      }

    });

    setEcgSegmentation(beats);
    setEcgArrhythmia(arrhythmia)
  }, [ecgSegmentData?.length]);


  useEffect(() => {
    const segmentMap: Record<string, any> = {
      P: ecgSegmentation?.p,
      QRS: ecgSegmentation?.qrs,
      T: ecgSegmentation?.t,
      RR: ecgSegmentation?.rr,
      ST: ecgSegmentation?.st,
      PR: ecgSegmentation?.pr,
      TP: ecgSegmentation?.tp,
      QT: ecgSegmentation?.qt,
    };

    setActiveSegmentData(segmentMap[activeSegment] || []);
  }, [activeSegment]);


  const modifyGain = (status: boolean) => {
    if(status && gain <= 10) {
      setGain(gain*2);
      modifyDataBasedOnGain(status)
    } else if (!status && gain >= 2.5) {
      setGain(gain/2);
      modifyDataBasedOnGain(status)
    }
  }

  const modifyDataBasedOnGain = (status: boolean) => {
    setMainEcgData((prevNumbers: any) => {
      const updatedNumbers = [];
      for (let i = 0; i < prevNumbers.length; i++) {
        updatedNumbers.push(status? prevNumbers[i] * 2 : prevNumbers[i] / 2);
      }
      return updatedNumbers;
    });
  }

  const handleIntersect = (inView: boolean, entry: IntersectionObserverEntry) => {
    if (inView) {
      setIsVisible(true);
    }
  };

  const handleAi = () => {
    setAiActive(!aiActive)
    if(ecgSegmentData === null) {
      getEcgSegment.mutate({examination: id, lead: `LEAD_${activeLead}`})
    }
  }


  const handleOpenReportModal = (index: number) => {
    handleShowModal('ecgAiReportModal', {data: index, examination: id, lead: `LEAD_${activeLead}`});
  }

  const handleChangeLead = (eventKey: string | null) => {
    setActiveLead(eventKey || '');
    setEcgSegmentData(null)
    setEcgArrhythmia([])
    setEcgSegmentation(ecgSegmentationInitialValue)
    setAiActive(false)
    setActiveSegment('')
    setGain(1.25)
  }

  return (
    <>
      <Accordion.Header className='d-flex flex-wrap'>
         <span className='font-14 me-4 text-gray'>
          زمان گرفتن نمونه: &ensp;
           <span className="text-black">
             {MtoJDateFormat(startDate)} - {MtoJHHMMFormat(startDate)}
           </span>
        </span>
        <span className='font-14 me-2 text-gray'>
          نام مرکز: &ensp;
          <span className="text-black">
           {getCellValue(healthUnit)}
          </span>
        </span>
        <div className="flex-fill"></div>
        <span className='font-14 fw-bold me-2 text-black'>
          بررسی اطلاعات نوار قلب
        </span>
      </Accordion.Header>
        <Accordion.Body className='rounded-bottom bg-primary-10'>
          <div>
          <Observer onIntersect={handleIntersect} threshold={0.01}>
            <EcgSummary
              heartRate={heartRate}
              pulseRate={pulseRate}
              leadDuration={leadDuration}
              HBPM={heartBeatPerMinute}
            />
            <div className="d-flex align-content-center align-items-center flex-row-reverse">
              <div className="ps-1">
                <div
                  className='d-flex border-primary-subtle form-control rounded-3 align-items-center justify-content-around'>
                  <span onClick={() => modifyGain(false)}>
                    <a className='d-flex align-items-center justify-content-center btn btn-dark rounded rounded-3 p-0'
                       style={{width: '20px', height: '20px'}}>
                      <span className="">-</span>
                    </a>
                  </span>
                  <span className='text-gray mx-1 text-center font-14' style={{width: '40px'}}>
                    {gain}
                  </span>
                  <span onClick={() => modifyGain(true)}>
                    <a className='d-flex align-items-center justify-content-center btn btn-primary rounded rounded-3 p-0'
                       style={{width: '20px', height: '20px'}}>
                      <span className="">+</span>
                    </a>
                  </span>
                </div>
              </div>
              {(isProvider || findAccessInAccessList('EXAMINATION_AI')) &&
                <a className={`p-1 d-flex align-items-center btn rounded rounded-3 mx-1 my-2 ${aiActive ? 'btn-primary' : 'btn-outline-primary'}`}
                   onClick={() => handleAi()}>
                  <span className={`d-inline-flex font-25 ${ width > 900 ? 'ps-1' : ''}`}><Icon name="ai"/></span>
                  {width > 900 &&<span className="text-nowrap m-1">دستیار هوشمند</span>}
                </a>
              }
              <a className='p-1 d-flex align-items-center btn btn-outline-primary rounded rounded-3 mx-1 my-2'>
                <span className={`d-inline-flex font-25 ${ width > 900 ? 'ps-1' : ''}`}><Icon name="share"/></span>
                {width > 900 && <span className="text-nowrap m-1">اشتراک گذاری</span>}
              </a>
              {!aiActive ?
                <div className="d-flex justify-content-end overflow-x-auto px-2 my-2 me-2">
                  <Tab.Container
                    id="ecg-lead-nav"
                    activeKey={activeLead}
                    onSelect={(eventKey) => handleChangeLead(eventKey)}
                  >
                    <Nav variant={"pills"} className='d-flex overflow-x-auto custom-scrollbar ecg-lead-nav w-100'>
                      <div className='d-flex rounded'>
                        {
                          leadTitles?.slice().reverse().map((item: any) => {
                            return (
                              <Nav.Item>
                                <Nav.Link eventKey={item} className='text-nowrap font-en font-12 me-1'>{item}</Nav.Link>
                              </Nav.Item>
                            )
                          })
                        }
                      </div>
                    </Nav>
                  </Tab.Container>
                </div> :
                <div className="d-flex justify-content-end overflow-x-auto px-2 my-2 me-2">
                  <Tab.Container
                    id="ecg-lead-nav"
                    activeKey={activeSegment}
                    onSelect={(eventKey) => setActiveSegment(eventKey || '')}
                  >
                    <Nav variant={"pills"} className='d-flex overflow-x-auto custom-scrollbar ecg-lead-nav ltr'>
                      <div className='d-flex rounded'>
                        {
                          segmentTitles?.map((item: any) => {
                            return (
                              <Nav.Item className='d-flex align-items-center'>
                                {
                                  item === 'P' ? <span className="text-gray mx-3">Wave: </span> :
                                  item === 'ST' ? <span className="text-gray mx-3">Segment: </span> :
                                  item === 'RR' && <span className="text-gray mx-3">Interval: </span>
                                }
                                <Nav.Link eventKey={item} className='text-nowrap text-black fw-semibold font-en font-12 me-1'>{item}</Nav.Link>
                              </Nav.Item>
                            )
                          })
                        }
                      </div>
                    </Nav>
                  </Tab.Container>
                </div>
              }
              <div className='d-flex font-en fw-bold text-nowrap mx-3 ms-auto'>
                {'Lead ' + activeLead}
              </div>
            </div>
            <div>
              <HighchartsReact
                highcharts={Highcharts}
                options={EcgOptions({
                  data: [mainEcgData],
                  dataNames: ['ولتاژ'],
                  labels: labels,
                  chartTitle: 'نمودار نوار قلب بیمار',
                  showLegend: false,
                  showToolTip: false,
                  showExportButton: true,
                  plotBands: aiActive && ecgArrhythmia,
                  dataRanges: aiActive && activeSegmentData,
                  // yValue: chartComponentRef?.current?.chart?.yAxis[0].getExtremes()?.max,
                  yValue: minimumYValue * gain,
                  activeSegment: activeSegment,
                  handleOpenReportModal: handleOpenReportModal
                })}
                ref={chartComponentRef}
              />
            </div>
            <div className='d-flex align-items-center mt-2'>
              <span className='d-flex align-items-center rounded bg-primary-subtle px-2 py-1'>
                <span
                  className="d-flex mx-2 font-18 text-primary"
                >
                  <Icon name='info-circle'/>
                </span>
                <span className='text-primary fw-semibold'>
                  جهت پیمایش نوار قلب، دکمه Shift را نگه دارید.
                </span>
              </span>
            </div>
            <Collapse in={aiActive}>
              <Accordion>
                <ExaminationAnalysis ecgQtc={ecgQtc}/>
              </Accordion>
            </Collapse>
            <div className="row">
              {
                ecgLeadData?.map((item: any, index) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-sm-12 my-2"
                         onClick={() => handleChangeLead(leadTitles[index])}
                         key={index}
                    >
                      <div
                        className={`p-2 ${activeLead === leadTitles[index] ? 'border border-2 border-primary rounded shadow' : ''}`}>
                      <span className="font-en fw-bold me-3">Lead {leadTitles[index]}</span>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={EcgOptions({
                          data: [item?.data],
                          dataNames: ['ولتاژ'],
                          labels: labels,
                          chartTitle: 'نمودار نوار قلب بیمار',
                          displayXLabels: false,
                          displayYLabels: false,
                          showLegend: false,
                          showToolTip: false,
                          showExportButton: false,
                          maxPointToShow: 3000,
                          height: '167px',
                        })}
                      />
                    </div>
                  </div>
                )
              })
              }
            </div>
          </Observer>
        </div>
      </Accordion.Body>
      {(isProvider || findAccessInAccessList('EXAMINATION_AI_BUG_REPORT')) &&
        <EcgAiReportModal/>
      }
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ECG);