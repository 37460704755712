import { axiosRequest } from '../api';
import { API_PATH } from '../../constants';

export const DeviceService = {
  getDevicesList(params){ return axiosRequest('GET', API_PATH.DEVICE.LIST(params)) },
  getDevice(data){ return axiosRequest('POST', API_PATH.DEVICE.INFO, data) },
  addDevice(data){ return axiosRequest('POST', API_PATH.DEVICE.ADD, data) },
  deleteDevice(data){ return axiosRequest('POST', API_PATH.DEVICE.DELETE, data) },
  getAssignableAccountsList(params){ return axiosRequest('GET', API_PATH.DEVICE.ASSIGNABLE_ACCOUNTS_LIST(params)) },
  getDeviceAssignedList(params){ return axiosRequest('GET', API_PATH.DEVICE.DEVICE_ASSIGNED_LIST(params)) },
  assignDevice(data){ return axiosRequest('POST', API_PATH.DEVICE.ADD_ASSIGN, data) },
  getAssignDevice(data){ return axiosRequest('POST', API_PATH.DEVICE.GET_ASSIGN, data) },
  deleteAssignDevice(data){ return axiosRequest('POST', API_PATH.DEVICE.DELETE_ASSIGN, data) },
}