import * as React from "react"
import { SVGProps } from "react"

type HDLogoProps = SVGProps<SVGSVGElement> & {
  heightAndWidth?: number;
};

const HDLogo = ({ heightAndWidth = 48, ...props }: HDLogoProps) => (
  <svg
    width={heightAndWidth}
    height={heightAndWidth}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={48} height={48} rx={10} fill="#3290FF" />
    <path
      opacity={0.3}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0273 33.2812C15.5704 36.0484 21.9034 35.6355 25.5651 29.2884C28.4727 24.2405 28.5242 15.9782 22.1982 11.1817C21.9075 10.9647 21.6581 11.0676 21.437 11.208C20.165 12.0065 18.7606 13.6239 18.2559 15.853C17.5169 19.1018 19.0161 20.812 20.174 23.026C22.4809 28.3414 17.6219 34.4089 12.0975 33.0652C11.62 32.8774 11.1606 33.0026 11.0314 33.2822L11.0273 33.2812Z"
      fill="white"
    />
    <path
      opacity={0.6}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0028 33.5774C17.4974 38.6252 26.1827 36.1861 30.2331 28.4498C31.5048 25.9948 32.2769 23.312 32.5046 20.5566C32.7323 17.8012 32.4111 15.028 31.5597 12.3976C31.4123 11.9443 31.0488 12.0513 30.7177 12.2573C27.3619 14.3219 25.7375 17.4535 25.5215 20.982C25.4205 22.6145 25.9404 23.7008 25.6678 25.4877C24.5654 32.6859 17.3692 36.2224 11.2159 33.0807C11.131 33.1287 11.0645 33.2035 11.0267 33.2934C10.9889 33.3833 10.9819 33.4831 11.0069 33.5774H11.0028Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5687 34.1105C14.1935 35.9156 17.4575 37.0382 20.5589 36.9373C26.8485 36.7495 32.1043 32.899 34.1951 27.4948C34.7725 25.9496 35.1159 24.3268 35.2138 22.6801C35.2653 21.9209 34.8826 21.8654 34.3203 21.9654C32.8682 22.2218 31.5171 22.8804 30.4205 23.8662C29.3239 24.852 28.5256 26.1255 28.1165 27.5422C27.0686 31.1635 24.371 33.5431 22.0662 34.5789C18.9718 35.9711 14.8104 36.166 10.9922 33.4845C10.9922 33.7349 11.2496 33.8884 11.5687 34.1105Z"
      fill="white"
    />
  </svg>
)
export default HDLogo
