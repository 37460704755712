import React from "react";
import BoxLoader from "../../../general/boxLoader";
import EmptyBox from "../../../general/emptyBox";
import MedicationItemCardsBoxItem from "./medicationItemCardsBox_Item";

type MedicationItemsCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const MedicationItemCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting
}: MedicationItemsCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <MedicationItemCardsBoxItem item={item} dataListTitles={dataListTitles} key={i}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default MedicationItemCardsBox;