import React, {useEffect, useState} from "react";

import TwoPerson from "../../../../assets/images/svgComponents/icon/twoPerson";
import {useGetVisits} from "../../../../hook/request/visit/visits";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import {TDataList} from "../../../../types/general";
import {getCellValue} from "../../../../utils/utils";
import {MtoJDateFormat, MtoJHHMMFormat} from "../../../../utils/date";
import {VISIT_TYPE_ENUM} from "../../../../constants/constant/enum";
import {general} from "../../../../store/action";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import ChangeStatusAppointmentModal from "../../appointments/changeStatusAppointmentModal";
import EmptyVisits from "../../../../assets/images/svgComponents/EmptyVisits";
import Icon from "../../general/icon";
import LeftArrow from "../../../../assets/images/svgComponents/icon/leftArrow";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  status?: string
}

type ReservedVisitsProps = {
  handleShowModal: any;
}

const ReservedVisits = ({
  handleShowModal
}: ReservedVisitsProps) => {

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [visitsData, setVisitsData] = useState<TDataList>({
    data: [],
    count: 0
  });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "5",
    search: "",
    status: "200"
  });

  // services
  const getVisitsRequest = useGetVisits(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "5",
      search: searchParams.get("search") || "",
      status: searchParams.get("status") || "200"
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getVisitsRequest?.data?.data?.result &&
    setVisitsData(getVisitsRequest?.data?.data.result)
  }, [getVisitsRequest.data])

  return (
    <div className="dashboard-card-box h-100">
      <div className="flex-wrap align-items-center m-2">
        <span className="d-flex align-items-center text-white font-25">
          <span className="d-flex align-items-center ms-2">
           <TwoPerson/>
          </span>
          <span className="font-15 fw-bold text-black">
            ویزیت‌های پیش‌رو
          </span>
        </span>
      </div>
      <div className="h-90 m-2">
        {visitsData?.data && visitsData?.data.length > 0 ? visitsData?.data.map((item: any, key) => {
          const firstCharacter = item?.patient?.name ? item?.patient?.name.charAt(0) : '';
          return (
            <div key={key} className='d-flex align-items-center border-bottom visit-card-animation py-3'>
              <div className="rounded-circle avatar-placeholder">
                {firstCharacter}
              </div>
              <div className='w-100 me-3'>
                <div className='font-15'>
                  {item?.patient?.name}
                </div>
                <div className='d-flex justify-content-between font-9 text-gray mt-2'>
                  <div>
                    <span>
                      تاریخ شروع:
                    </span>
                    <span className='me-2'>
                      {item?.startDateTime ? getCellValue(MtoJDateFormat(item?.startDateTime)) : '-'}
                    </span>
                  </div>
                  <div>
                    <span>
                      ساعت:
                    </span>
                    <span className='me-2'>
                      {item?.startDateTime ? getCellValue(MtoJHHMMFormat(item?.startDateTime)) : '-'}
                    </span>
                  </div>
                  <div>
                    <span>
                      نوع:
                    </span>
                    <span className='me-2'>
                      {item?.visitType?.id === VISIT_TYPE_ENUM.ONLINE.value ? 'آنلاین' : 'حضوری'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="me-auto ms-1">
                <button
                  type='submit'
                  className="btn btn-green rounded-3 d-flex text-nowrap align-items-center me-2 px-2 py-1"
                  onClick={() => handleShowModal('changeStatusAppointmentModal', {id: item?.id})}
                >
                  شروع ویزیت
                  <span className='d-flex align-items-center me-1'>
                    <LeftArrow/>
                  </span>
                </button>
              </div>
            </div>
          )
        }) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <EmptyVisits/>
            <div className='font-12 mt-2'>
              در حال حاضر هیچ نوبتی برای ویزیت ثبت نشده است.
            </div>
          </div>
        )}
      </div>
      <ChangeStatusAppointmentModal/>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ReservedVisits);