import {useParams} from "react-router-dom";
import React, {useState} from "react";
import Icon from "../../../../components/general/icon";
import {general} from "../../../../../store/action";
import {connect, useSelector} from "react-redux";
import AddPatientBiometricModal from "../../../../components/patients/ehr/biometric/addPatientBiometricModal";
import DeletePatientBiometricModal from "../../../../components/patients/ehr/biometric/deletePatientBiometricModal";
import {Nav, Tab} from "react-bootstrap";
import PatientWeightChart from "../../../../components/patients/ehr/biometric/dataCharts/patientWeightChart";
import PatientHeightChart from "../../../../components/patients/ehr/biometric/dataCharts/patientHeightChart";
import PatientBMIChart from "../../../../components/patients/ehr/biometric/dataCharts/patientBMIChart";
import PatientMUACChart from "../../../../components/patients/ehr/biometric/dataCharts/patientMUACChart";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

type biometricsChartsProps = {
  patientData?: any;
  handleShowModal: any;
  initialOpenTab?: string;
  visitEHRCheck?: boolean;
}

const BiometricsCharts = ({
  patientData,
  handleShowModal,
  initialOpenTab,
  visitEHRCheck = false,
}: biometricsChartsProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const { id } = useParams();
  const [openTab, setOpenTab] = useState('weight')

  return (
    <>
      <section className="filter-box d-flex flex-column flex-md-row mb-4">
        {((visitEHRCheck && isProvider) || findAccessInAccessList('EHR_BIOMETRIC_CREATE')) &&
          <div
            className="d-flex align-items-center justify-content-center justify-content-md-end mb-3 m-md-0 me-md-auto">
            <span
              onClick={() => handleShowModal('addPatientBiometricModal', {patientId: id, patientData: patientData})}
              className="btn btn-outline-primary rounded d-flex align-items-center fw-bold px-4"
            >
              <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
              ثبت بیومتریک
            </span>
          </div>
        }
      </section>

      <section className="table-cont table-responsive d-flex">
        <Tab.Container
          id="biometrics-charts-tabs"
          defaultActiveKey="weight"
          onSelect={(eventKey) => setOpenTab(eventKey || '')}
        >
          <div className='col-2'>
            <Nav variant={'underline'}>
              <div className='flex-column w-100 font-14 vital-chart-nav'>
                <Nav.Item>
                  <Nav.Link eventKey="weight" className="py-3">
                    وزن
                    <span className="me-1 font-10">(Kg)</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="height" className="py-3">
                    قد
                    <span className="me-1 font-10">(Cm)</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bmi" className="py-3">
                    شاخص توده بدنی
                    <span className="me-1 font-10">(Kg/m)</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="muac" className="py-3">
                    دور بازو زیر بغل
                    <span className="me-1 font-10">(Cm)</span>
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </div>
          <div className='col-10'>
            <Tab.Content>
              <Tab.Pane eventKey="weight">
                {openTab == 'weight' && <PatientWeightChart openTab={openTab} patientId={patientData?.id}/>}
              </Tab.Pane>
              <Tab.Pane eventKey="height">
                {openTab == 'height' && <PatientHeightChart openTab={openTab} patientId={patientData?.id}/>}
              </Tab.Pane>
              <Tab.Pane eventKey="bmi">
                {openTab == 'bmi' && <PatientBMIChart openTab={openTab} patientId={patientData?.id}/>}
              </Tab.Pane>
              <Tab.Pane eventKey="muac">
                {openTab == 'muac' && <PatientMUACChart openTab={openTab} patientId={patientData?.id}/>}
              </Tab.Pane>
            </Tab.Content>

          </div>
        </Tab.Container>

      </section>
      {initialOpenTab === 'biometricsCharts' && <AddPatientBiometricModal/>}
      <DeletePatientBiometricModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(BiometricsCharts);
