import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect, useSelector} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {general} from "../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {useAddPatientCondition, useGetPatientCondition} from "../../../../../hook/request/ehr/patientCondition";
import Modal from "../../../general/modal";
import BoxLoader from "../../../general/boxLoader";
import Icon from "../../../general/icon";
import {AsyncSelectField} from "../../../general/form/selectField";
import DatePickerField from "../../../general/form/datePickerField";
import {TAddPatientConditionFields} from "../../../../../types/patient";
import {addPatientConditionSchema} from "../../../../../schema/patient-schema";
import TextAreaField from "../../../general/form/textAreaField";
import {useGetConditions} from "../../../../../hook/request/condition";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import Form from 'react-bootstrap/Form';
import {asyncSelectLoadOptions} from "../../../../../utils/asyncSelectLoadOptions";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";


type AddPatientConditionModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type ModalInfoProps = {
  id: string,
  patientId: string,
  patientData?: any,
}


const AddPatientConditionModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPatientConditionModalProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    patientId: '',
    patientData: ''
  });

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [conditionsList, setConditionsList] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "100",
    search: "",
  });
  const fieldValueInitialState = {
    condition: "",
    startDateTime: null,
    endDateTime: null,
    description: '',
  }
  const [fieldValue, setFieldValue] = useState<TAddPatientConditionFields>(fieldValueInitialState);
  const [conditionState, setConditionState] = useState('inActive')
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TAddPatientConditionFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addPatientConditionSchema),
  });

  const callbackRef = useRef<any>(null);


  // services
  const addPatientConditionRequest = useAddPatientCondition({
    onSuccess: () => {
      handleSetShowModal();
    }
  });
  const getPatientConditionRequest = useGetPatientCondition();
  const getConditionsRequest = useGetConditions(getQueryParams(filterValues), modalName === 'addPatientConditionModal');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "100",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getConditionsRequest?.data?.data?.result) {
      const {data} = getConditionsRequest?.data?.data.result,
        _data = data.map((item: any) => (
          {label: item?.name, value: item?.id}
        ))
      setConditionsList(_data);
    }
  }, [getConditionsRequest.data])

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addPatientConditionModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData?.id
      }
      handleGetPatientCondition(data)
    }
  }, [modalData, modalName])

  const handleGetPatientCondition = (data: any) => {
    data.id && getPatientConditionRequest.mutate(data);
  }

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);


  useEffect(() => {
    if (getPatientConditionRequest?.data?.data?.result) {
      const {
          imei, updateDate
        } = getPatientConditionRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          imei,
          updateDate
        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getPatientConditionRequest.data])

  const addPatientCondition: SubmitHandler<TAddPatientConditionFields> = (data) => {
    const { condition, startDateTime, endDateTime, description } = data,
      body = {
        id: modalInfo?.id ? modalInfo?.id : 0,
        patient: {
          id: modalInfo?.patientId
        },
        entrySource:{
          id:200
        },
        startDateTime, description,
        endDateTime: (conditionState == 'inActive' ? endDateTime : null),
        condition: {
          id: condition,
        }
      };
    addPatientConditionRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue(fieldValueInitialState)
    setConditionState('inActive')
    handleHideModal();
  }

  const conditionStateChange = (e:any) => {
    setConditionState(e.target.id)
  }

  const changeRequestFilters = (inputValue: any, callback: any) => {
    callbackRef.current = callback;
    setFilterValues({
      ...filterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(callbackRef.current) {
      conditionsLoadOptions(filterValues.search, callbackRef.current);
    }
  }, [filterValues?.search]);

  const conditionsLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, filterValues, setFilterValues, getConditionsRequest);
  };


  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          getPatientConditionRequest?.isPending ||
          getConditionsRequest?.isPending ||
          addPatientConditionRequest?.isPending
        ) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {modalInfo?.id ? "ویرایش شرایط پزشکی" : "ثبت شرایط پزشکی"}
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.firstName + ' ' + modalInfo?.patientData?.lastName}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(addPatientCondition)}>
          <div className="row">
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="condition">شرایط پزشکی</label>
                <AsyncSelectField
                  options={conditionsList}
                  name="condition"
                  placeholder='انتخاب کنید'
                  className="font-en"
                  control={control}
                  error={errors}
                  asyncLoadOptions={changeRequestFilters}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group ">
                <label className="inp-lbl me-2 mb-2" htmlFor="state">وضعیت</label>
                <div className='border form-control mb-3 pl-5'>
                  <span className='d-inline-block'> </span>
                  <span className='float-start'>
                    <Form.Check
                      inline
                      id='active'
                      label="جاری"
                      type={"radio"}
                      name="conditionState"
                      checked={conditionState == 'active'}
                      onChange={conditionStateChange}
                    />
                    <Form.Check
                      inline
                      id='inActive'
                      label="پایان یافته"
                      type={"radio"}
                      checked={conditionState == 'inActive'}
                      name="conditionState"
                      onChange={conditionStateChange}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="startDateTime">تاریخ شروع</label>
                <DatePickerField
                  name="startDateTime"
                  placeholder="تاریخ شروع"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            { conditionState == 'inActive' &&
              <div className="col-12 col-md-6 px-4">
                <div className="form-group mb-4">
                  <label className="inp-lbl me-2 mb-2" htmlFor="endDateTime">تاریخ پایان</label>
                  <DatePickerField
                    name="endDateTime"
                    placeholder="تاریخ پایان"
                    control={control}
                    error={errors}
                  />
                </div>
              </div>
            }
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  rows={2}
                  placeholder="توضیحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                {(isProvider || findAccessInAccessList('EHR_MEDICAL_CONDITIONS_CREATE')) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    {modalInfo?.id ? "ویرایش شرایط پزشکی" : "ثبت شرایط پزشکی"}
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientConditionModal);