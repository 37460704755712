import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../../utils/date";
import {general} from "../../../../../../../store/action";
import {connect} from "react-redux";
import React from "react";


type VisitParaclinicalPrescriptionTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const VisitParaclinicalPrescriptionTableRowItem = ({
  item,
  handleShowModal
}: VisitParaclinicalPrescriptionTableRowItemProps) => {

  return (
    <tr>
      <td>{getCellValue(item?.itemCount)}</td>
      <td>{getCellValue(MtoJDateFormat(item?.activeDate))}</td>
      <td>{getCellValue(item?.service?.type?.name)}</td>
      <td className='d-flex justify-content-end'>
        <bdi>
          <span className='text-primary font-en mx-2'>{getCellValue(item?.service?.code)}</span>
          <span>{item?.service?.enName ? getCellValue(item?.service?.enName) : getCellValue(item?.service?.name)}</span>
        </bdi>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VisitParaclinicalPrescriptionTableRowItem);
