import React, {useEffect, useMemo, useState} from "react";
import {connect, useSelector} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {general} from "../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {useAddPatientAttachment, useGetPatientAttachment} from "../../../../../hook/request/ehr/patientAttachment";
import Modal from "../../../general/modal";
import BoxLoader from "../../../general/boxLoader";
import Icon from "../../../general/icon";
import TextField from "../../../general/form/textField";
import {TAddPatientAttachmentFields} from "../../../../../types/patient";
import {addPatientAttachmentSchema} from "../../../../../schema/patient-schema";
import TextAreaField from "../../../general/form/textAreaField";
import DropzoneUploaderBox from "../../../utility/dropzoneUploader/dropzoneUploaderBox";
import Image from "../../../general/image";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

type AddPatientAttachmentModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  id: string,
  patientId: string,
  patientData?: any,
}

const AddPatientAttachmentModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPatientAttachmentModalProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    patientId: '',
    patientData: ''
  });
  const fieldValueInitialState = {
    name: "",
    description: "",
  }
  const [fieldValue, setFieldValue] = useState<TAddPatientAttachmentFields>(fieldValueInitialState);
  const attachmentDataInitialState = {
    attachment: {
      id: 0,
      imageUrl: "",
      imageData: {
        name: ""
      }
    }
  }
  const [attachmentData, setAttachmentData] = useState(attachmentDataInitialState);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<TAddPatientAttachmentFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addPatientAttachmentSchema),
  });

  // services
  const addPatientAttachmentRequest = useAddPatientAttachment({
    onSuccess: () => {
      handleSetShowModal();
    }
  });
  const getPatientAttachmentRequest = useGetPatientAttachment();

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addPatientAttachmentModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData?.id
      }
      handleGetPatientAttachment(data)
    }
  }, [modalData, modalName])

  const handleGetPatientAttachment = (data: any) => {
    data.id && getPatientAttachmentRequest.mutate(data);
  }

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getPatientAttachmentRequest?.data?.data?.result) {
      const {
          name, description, attachment
        } = getPatientAttachmentRequest.data.data.result,
        _fieldValue = {
          ...fieldValue,
          name,
          description
        };
      setFieldValue(_fieldValue);
      setAttachmentData({
        attachment: {
          id: attachment?.id,
          imageUrl: attachment?.url,
          imageData: {
            name: ""
          }
        }
      })
    }
  }, [getPatientAttachmentRequest.data])

  useEffect(() => {
    // const _fieldValue = {
    //   ...fieldValue,
    //   name: fieldValue.name || 'فایل ضمیمه'
    // }
    // setFieldValue(_fieldValue)
    const {name} = getValues()
    if(!name && attachmentData?.attachment?.id) {
      setValue("name", 'فایل ضمیمه')
    }
  }, [attachmentData]);

  const addPatientAttachment: SubmitHandler<TAddPatientAttachmentFields> = (data) => {
    const { name, description } = data,
      { attachment } = attachmentData,
      body = {
        id: modalInfo?.id ? modalInfo?.id : 0,
        patient: {
          id: modalInfo?.patientId
        },
        entrySource:{
          id:200
        },
        name, description,
        attachment: attachment?.id ? {
          id: attachment?.id,
          name: attachment?.imageData?.name
        } : null
      };
    addPatientAttachmentRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
    setAttachmentData(attachmentDataInitialState);
    setFieldValue(fieldValueInitialState)
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          getPatientAttachmentRequest?.isPending ||
          addPatientAttachmentRequest?.isPending ||
          isFileUploading
        ) && <BoxLoader type="cover"/>
      }
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              {modalInfo?.id ? "ویرایش فایل ضمیمه" : "ثبت فایل ضمیمه"}
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.firstName + ' ' + modalInfo?.patientData?.lastName}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(addPatientAttachment)}>
          <div className="row">
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">نام فایل</label>
                <TextField
                  name="name"
                  placeholder="نام فایل"
                  control={control}
                  error={errors}
                  disabled={!!modalInfo?.id}
                />
              </div>
            </div>
            <div className="col-12 px-2 px-lg-4">
              <div className="form-group mb-5 d-flex justify-content-center">
                {
                  modalInfo?.id ? (
                    <a href={attachmentData?.attachment?.imageUrl} className='p-1 rounded bg-gray4'>
                      <Image src={attachmentData?.attachment?.imageUrl}
                             className={`${attachmentData?.attachment?.imageUrl ? 'rounded border bg-white m-1' : 'd-none'}`}
                      />
                    </a>)
                  :
                  <DropzoneUploaderBox
                    setFieldValue={setAttachmentData}
                    fieldValue={attachmentData}
                    setIsFileUploading={setIsFileUploading}
                    name='attachment'
                    inputContent="عکس ضمیمه خود را اینجا رها کنید."
                  />
              }
            </div>
          </div>

          <div className="col-12 px-4">
            <div className="form-group mb-5">
              <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
              <TextAreaField
                name="description"
                rows={2}
                placeholder="توضیحات"
                control={control}
                error={errors}
                disabled={!!modalInfo?.id}
              />
            </div>
          </div>
          {
            !modalInfo?.id &&
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-2 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                {(isProvider || findAccessInAccessList('EHR_ATTACHMENTS_CREATE')) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    {modalInfo?.id ? "ویرایش فایل ضمیمه" : "ثبت فایل ضمیمه"}
                  </button>
                }
              </div>
            </div>
          }
      </div>
    </form>
</div>

</Modal>
)
  ;
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPatientAttachmentModal);