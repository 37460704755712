import {connect, useSelector} from "react-redux";
import React from "react";
import {generatePath, useNavigate} from "react-router-dom";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";
import {usePrescriptionInsurerSave} from "../../../../../hook/request/visit/prescription";
import {PRESCRIPTION_STATUS_ENUM, VISIT_MODE, VISIT_STATUS_ENUM} from "../../../../../constants/constant/enum";
import {getCellValue} from "../../../../../utils/utils";
import Tooltip from "../../../general/tooltip";
import Icon from "../../../general/icon";
import {ROUTES} from "../../../../../constants";
import {general} from "../../../../../store/action";

type FavoriteMedicationTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const FavoriteMedicationTableRowItem = ({
 item,
 handleShowModal
}: FavoriteMedicationTableRowItemProps) => {

  const navigate = useNavigate();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const prescriptionInsurerSaveRequest = usePrescriptionInsurerSave()

  const handleResendPrescriptionToInsurer = () => {
    prescriptionInsurerSaveRequest.mutate({id: item?.insurerStatus?.prescriptionId})
  }

  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-yellow1 text-yellow'
      case 300:
        return 'bg-light-green text-green2'
      case 400:
        return 'bg-primary-subtle text-primary'
      default:
        return 'bg-light-red text-red'
    }
  }

  const determinePrescriptionBgColor = () => {
    switch (item?.insurerStatus?.status?.id) {
      case PRESCRIPTION_STATUS_ENUM.ADD_OK.value:
        return 'bg-light-green text-green2'
      case PRESCRIPTION_STATUS_ENUM.ADD_ERROR.value:
        return 'bg-light-red text-red'
      case PRESCRIPTION_STATUS_ENUM.EDIT_OK.value:
        return 'bg-light-green text-green2'
      case PRESCRIPTION_STATUS_ENUM.EDIT_ERROR.value:
        return 'bg-light-red text-red'
    }
  }

  return (
    <tr>
      <td>
        <span className={'text-primary mx-1'}>
          {getCellValue(item?.item?.generic)}
        </span>
        <span className={'mx-1'}>
          -
        </span>
        <span className={'mx-1'}>
          {getCellValue(item?.item?.name)}
        </span>
      </td>
      <td>{getCellValue(item?.item?.shape?.name)}</td>
      <td>{getCellValue(item?.itemCount)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(item?.frequency?.maxa?.value)}</bdi>
      </td>
      <td>{getCellValue(item?.dosage?.faName)}</td>
      <td>
        {getCellValue(item?.item?.brandName)}
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {
            (isProvider) &&
            <>
              <Tooltip alt={"حذف"} className="tooltip-pre-line">
                <span
                  onClick={() =>
                    isProvider &&
                    handleShowModal('deleteFavoriteMedicationModal', item?.id)
                  }
                  className={`d-flex mx-2 font-20 cur-pointer ${(isProvider) ? 'text-red-hover' : 'disabled text-gray3'}`}
                >
                  <Icon name='trash'/>
                </span>
              </Tooltip>
            </>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(FavoriteMedicationTableRowItem);
