import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../../utils/date";

type patientConditionSummaryProps = {
  item: any;
}

const PatientConditionSummary_rowItem = ({
 item
}: patientConditionSummaryProps) => {

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div>
          <span className="text-gray">نام شرایط</span>
          <span className="me-3">{getCellValue(item?.condition?.name)}</span>
        </div>
        <div>
          <span className="text-gray">زمان شروع</span>
          <span className="me-3">{getCellValue(MtoJDateFormat(item?.startDateTime))}</span>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <div>
          <span className="text-gray">وضعیت</span>
          <span className="me-3">{item?.endDateTime ? 'پایان یافته' : 'جاری'}</span>
        </div>
      </div>
    </>
  );
};

export default PatientConditionSummary_rowItem;
