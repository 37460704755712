import React from "react";
import BoxLoader from "../../../../general/boxLoader";
import EmptyBox from "../../../../general/emptyBox";
import PatientVisitCardsBoxItem from "./patientVisitCardsBox_Item";

type PatientVisitsCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const PatientVisitCardsBox = ({
 dataList,
 dataListTitles,
 isRequesting
}: PatientVisitsCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <PatientVisitCardsBoxItem item={item} dataListTitles={dataListTitles} key={i}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default PatientVisitCardsBox;