import React from "react";
import {generatePath, useNavigate} from "react-router-dom";
import Icon from "../../general/icon";
import {getCellValue} from "../../../../utils/utils";
import {MtoJDateFormat, MtoJHHMMFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {VISIT_MODE, VISIT_STATUS_ENUM} from "../../../../constants/constant/enum";
import {ROUTES} from "../../../../constants";

type ExaminationCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
}

const ExaminationCardsBoxItem = ({
 item,
 dataListTitles,
}: ExaminationCardsBoxItemProps) => {
  const navigate = useNavigate();
  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-gray3'
      case 400:
        return 'bg-yellow1 text-yellow'
      default:
        return 'bg-light-red text-red'
    }
  }

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="patient">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.patient?.firstName)} {getCellValue(item?.patient?.lastName)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="provider">{dataListTitles[1]}</label>
        <span className="font-en mr-2">{getCellValue(item?.device?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[2]}</label>
        <span className="mr-2">{getCellValue(MtoJDateFormat(item?.startTime))}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="fromDate">{dataListTitles[3]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJHHMMFormat(item?.startTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="fromDate">{dataListTitles[5]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(item?.type?.name)}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[6]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            <Tooltip alt={"مشاهده ویزیت"} className="tooltip-pre-line">
            <span
              onClick={() =>
                navigate(generatePath(ROUTES.EXAMINATION_DETAIL.PATH, {id: item?.id}))
              }
              className={`d-flex mx-2 font-20 cur-pointer text-hover`}
            >
              <Icon name='eye'/>
            </span>
            </Tooltip>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ExaminationCardsBoxItem;