import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {MenuService} from "../../services/requests/menu";

const useGetMenus = () => {
  return useMutation({
    mutationFn: MenuService.getMenusList,
  })
}

const useAddMenu = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: MenuService.addMenu,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.MENU.PATH);
        }
      })
    },
  })
}

const useDeleteMenu = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: MenuService.deleteMenu,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

const useGetAccessGroups = () => {
  return useMutation({
    mutationFn: MenuService.getAccessGroupsList,
  })
}

export {
  useGetMenus,
  useAddMenu,
  useDeleteMenu,
  useGetAccessGroups
}
