import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {connect, useSelector} from "react-redux";
import {useWindowSize} from "../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import {TAddPrescriptionMedicationItemFields} from "../../../../types/medicationItem";
import {
  useGetDraftPrescriptionMedicationItemList
} from "../../../../hook/request/visit/draftPrescriptionMedicationItem";
import SearchBox from "../../../components/general/searchBox";
import SelectField from "../../../components/general/form/selectField";
import Pagination from "../../../components/general/table/pagination";
import {general} from "../../../../store/action";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";
import {
  useGetDraftPrescriptionParaclinicalItemList
} from "../../../../hook/request/visit/draftPrescriptionParaclinicalItem";
import Icon from "../../../components/general/icon";
import FavoriteMedicationCardsBox
  from "../../../components/prescription/favoriteItems/medicationDataList/favoriteMedicationCardsBox";
import FavoriteMedicationsTable
  from "../../../components/prescription/favoriteItems/medicationDataList/favoriteMedicationsTable";
import FavoriteParaclinicCardsBox
  from "../../../components/prescription/favoriteItems/paraclinicDataList/favoriteParaclinicCardsBox";
import FavoriteParaclinicsTable
  from "../../../components/prescription/favoriteItems/paraclinicDataList/favoriteParaclinicsTable";
import DeleteFavoriteParaclinicModal
  from "../../../components/prescription/favoriteItems/deleteFavoriteParaclinicModal";
import DeleteFavoriteMedicationModal
  from "../../../components/prescription/favoriteItems/deleteFavoriteMedicationModal";
import {ROUTES} from "../../../../constants";


const favoriteMedicationDataListTitles = [
  "نام دارو",
  "نوع دارو",
  "تعداد",
  "تواتر مصرف",
  "دستور مصرف",
  "برند",
  "عملیات",
]

const favoriteParaclinicDataListTitles = [
  "نام خدمت",
  "نوع خدمت",
  "تعداد",
  "عملیات",
]

const filterOptions = [
  {value: 100, label: "اقلام دارویی"},
  {value: 200, label: "خدمات پاراکلینیک"},
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  status?: number
}


type FavoriteItemsListProps = {
  handleShowModal: any;
}

const FavoriteItemsList = ({
  handleShowModal
}: FavoriteItemsListProps) => {

  const navigate = useNavigate();
  const [width] = useWindowSize();

  const filterValuesInitialState = {
    pageNumber: "0",
    perPage: "10",
    search: '',
    status: 100,
  }
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>(filterValuesInitialState);
  const [favoriteMedicationItemsList, setFavoriteMedicationItemsList] = useState([]);
  const [favoriteParaclinicItemsList, setFavoriteParaclinicItemsList] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TFilterValues>({
    defaultValues: useMemo(() => filterValues, [filterValues]),
  });

  // services
  const getDraftPrescriptionMedicationItemListRequest = useGetDraftPrescriptionMedicationItemList(
    getQueryParams(filterValues),filterValues?.status === 100);
  const getDraftPrescriptionParaclinicalItemListRequest = useGetDraftPrescriptionParaclinicalItemList(
    getQueryParams(filterValues),filterValues?.status === 200);


  useEffect(() => {
    if (getDraftPrescriptionMedicationItemListRequest?.data?.data?.result) {
      const { data } = getDraftPrescriptionMedicationItemListRequest.data.data.result
      setFavoriteMedicationItemsList(data);
    }
  }, [getDraftPrescriptionMedicationItemListRequest.data])

  useEffect(() => {
    if (getDraftPrescriptionParaclinicalItemListRequest?.data?.data?.result) {
      const { data } = getDraftPrescriptionParaclinicalItemListRequest.data.data.result
      setFavoriteParaclinicItemsList(data);
    }
  }, [getDraftPrescriptionParaclinicalItemListRequest.data])

  useEffect(() => {
    console.log(filterValues?.status)
    if (filterValues?.status === 100) {
      getDraftPrescriptionMedicationItemListRequest.refetch();
    } else if (filterValues?.status === 200) {
      getDraftPrescriptionParaclinicalItemListRequest?.refetch();
    }
  }, [filterValues?.status])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    let _filterValues = {
      ...filterValues,
      pageNumber: "0",
      perPage: filterValues?.perPage,
      search: value
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  const openFilterDropDown = (param: any) => {
    if(param?.target?.value != null) {
      const {value} = param?.target?.value
      setFilterValues({
        ...filterValues,
        status: value
      })
    } else {
      setFilterValues({
        ...filterValues,
        status: 0
      })
    }
  }


  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder={filterValues?.status === 200 ? "جستجوی خدمات پاراکلینیک" : 'جستجوی اقلام دارویی'}
            />
          </div>
          <div className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
            <SelectField
              options={filterOptions}
              name="status"
              className={'mx-4'}
              placeholder='نوع راانتخاب کنید'
              control={control}
              error={errors}
              onChange={(e) => openFilterDropDown(e)}
              returnObjectValue={true}
            />
            {
              (isProvider || findAccessInAccessList('VISIT_VIEW')) &&
              <button
                onClick={() => filterValues?.status === 200 ? navigate(ROUTES.ADD_FAVORITE_PARACLINIC_ITEM.PATH) : navigate(ROUTES.ADD_FAVORITE_MEDICATION_ITEM.PATH)}
                className="btn btn-primary rounded d-flex align-items-center fw-bold px-4 ms-2"
              >
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                {filterValues?.status === 200 ? 'افزودن خدمت' : 'افزودن قلم'}
              </button>
            }
          </div>
        </section>

        <section className="table-cont table-responsive">
          {
            filterValues?.status === 100 ? (
            width < 900 ?
              <FavoriteMedicationCardsBox
                dataList={favoriteMedicationItemsList}
                dataListTitles={favoriteMedicationDataListTitles}
                isRequesting={getDraftPrescriptionMedicationItemListRequest.isPending}
              /> :
              <FavoriteMedicationsTable
                dataList={favoriteMedicationItemsList}
                dataListTitles={favoriteMedicationDataListTitles}
                isRequesting={getDraftPrescriptionMedicationItemListRequest.isPending}
              />
            ) : (
              width < 900 ?
                <FavoriteParaclinicCardsBox
                  dataList={favoriteParaclinicItemsList}
                  dataListTitles={favoriteParaclinicDataListTitles}
                  isRequesting={getDraftPrescriptionParaclinicalItemListRequest.isPending}
                /> :
                <FavoriteParaclinicsTable
                  dataList={favoriteParaclinicItemsList}
                  dataListTitles={favoriteParaclinicDataListTitles}
                  isRequesting={getDraftPrescriptionParaclinicalItemListRequest.isPending}
                />
            )
          }
          <Pagination
            paginationData={{...filterValues, ...favoriteMedicationItemsList}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <DeleteFavoriteMedicationModal/>
      <DeleteFavoriteParaclinicModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(FavoriteItemsList);