import React from "react";
import BoxLoader from "../../../general/boxLoader";
import EmptyBox from "../../../general/emptyBox";
import FavoriteParaclinicCardsBoxItem from "./favoriteParaclinicCardsBox_Item";

type FavoriteParaclinicCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const FavoriteParaclinicCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting
}: FavoriteParaclinicCardsBoxProps) => {
  const _dataList = dataList && dataList?.length > 0 && dataList?.map((item: any, i: number) => {
    return <FavoriteParaclinicCardsBoxItem item={item} dataListTitles={dataListTitles} key={i}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default FavoriteParaclinicCardsBox;