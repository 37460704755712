import {SET_FULL_NAME} from "./userData";

export const SET_ID = 'SET_ID';
export const SET_VIDEO_CALL_OPEN_PIP = 'SET_VIDEO_CALL_OPEN_PIP';
export const SET_IS_IN_VIDEO_CALL = 'SET_IS_IN_VIDEO_CALL';
export const SET_LOCAL_MIC_STATUS = 'SET_LOCAL_MIC_STATUS';
export const SET_LOCAL_CAM_STATUS = 'SET_LOCAL_CAM_STATUS';

export const videoCall = (id: number) => {
  return {
    type: SET_ID,
    payload: id,
  };
};

export const setVideoCallOpenPiP = (value: boolean) => ({
  type: SET_VIDEO_CALL_OPEN_PIP,
  payload: value,
});

export const setIsInVideoCall = (value: boolean) => ({
  type: SET_IS_IN_VIDEO_CALL,
  payload: value,
});

export const setLocalMicStatus = (value: boolean) => ({
  type: SET_LOCAL_MIC_STATUS,
  payload: value,
});

export const setLocalCamStatus = (value: boolean) => ({
  type: SET_LOCAL_CAM_STATUS,
  payload: value,
});