import React from 'react';

type EmptyBoxProps = {
  content?: string | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const EmptyBox = ({
  content,
  className,
  children
}: EmptyBoxProps) => {  
  return (
    <div
      className={`text-center p-4 ${className || ''}`}
    >
      {content || children || 'موردی یافت نشد!'}
    </div>
  )
}

export default EmptyBox;