import {TGIdNameData} from "../../../../../../../types/general";

type patientAllergySummaryProps = {
  item: any;
}

const PatientAllergySummaryRowItem = ({
 item
}: patientAllergySummaryProps) => {


  return (
    <>
      <div className='d-flex justify-content-between'>
        <div>
          <span className="text-gray">حساسیت</span>
          <span className="me-3">{item?.allergy?.name}</span>
        </div>
        <div>
          <span className="text-gray">شدت حساسیت</span>
          <span className="me-3">{item?.severity?.name}</span>
        </div>
      </div>
      <div className='d-flex text-overflow'>
        <span className="text-gray">واکنش ها</span>
        <span className="me-3">
              {
                item?.reactions?.map((reaction: TGIdNameData, index: number) => {
                  return (
                    <span>
                      {reaction.name}{(index + 1) !== item?.reactions?.length && ', '}
                    </span>
                  )
                })
              }
            </span>
      </div>
    </>
  );
};

export default PatientAllergySummaryRowItem;
