import React from "react";
import {connect, useSelector} from "react-redux";
import {generatePath, useNavigate} from "react-router-dom";

import {Dispatch} from "redux";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";
import {getCellValue} from "../../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../../utils/date";
import {VISIT_MODE, VISIT_STATUS_ENUM} from "../../../../../constants/constant/enum";
import Icon from "../../../general/icon";
import {ROUTES} from "../../../../../constants";
import {general} from "../../../../../store/action";
import Tooltip from "../../../general/tooltip";

type FavoriteMedicationCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const FavoriteMedicationCardsBoxItem = ({
 item,
 dataListTitles,
 handleShowModal
}: FavoriteMedicationCardsBoxItemProps) => {
  const navigate = useNavigate();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="generic">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.item?.generic)} - {getCellValue(item?.item?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="provider">{dataListTitles[1]}</label>
        <span className="font-en mr-2">{getCellValue(item?.item?.shape?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[2]}</label>
        <span className="mr-2">{getCellValue(item?.itemCount)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="fromDate">{dataListTitles[3]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(item?.frequency?.maxa?.value)}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="visitType">{dataListTitles[4]}</label>
        <span className="mr-2">{getCellValue(item?.dosage?.faName)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="status">{dataListTitles[5]}</label>
        <span className={`px-3 py-1 rounded text-nowrap`}>
          {getCellValue(item?.item?.brandName)}
          </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[9]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            {
              (isProvider) &&
              <>
                <Tooltip alt={"حذف"} className="tooltip-pre-line">
                  <span
                    onClick={() =>
                      isProvider &&
                      handleShowModal('deleteFavoriteMedicationModal', item?.id)
                    }
                    className={`d-flex mx-2 font-20 cur-pointer ${isProvider ? 'text-red-hover' : 'disabled text-gray3'}`}
                  >
                    <Icon name='trash'/>
                  </span>
                </Tooltip>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(FavoriteMedicationCardsBoxItem);