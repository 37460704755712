import React from "react";
import BoxLoader from "../../../../../../components/general/boxLoader";
import EmptyBox from "../../../../../../components/general/emptyBox";
import PatientSurgeryCardsBoxItem from "./patientSurgeryCardsBox_Item";

type PatientSurgeryCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const PatientSurgeryCardsBox = ({
 dataList,
 dataListTitles,
 isRequesting
}: PatientSurgeryCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <PatientSurgeryCardsBoxItem item={item} dataListTitles={dataListTitles} key={i}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default PatientSurgeryCardsBox;