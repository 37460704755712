import React, {useEffect, useState} from "react";
import Icon from "../../../../general/icon";
import {TPrescriptionFields} from "../../../../../../types/prescription";
import {useGetPrescription} from "../../../../../../hook/request/visit/prescription";
import VisitMedicationPrescriptionTable
  from "../../../../visits/visitDetail/endVisit/dataList/medication/visitMedicationPrescriptionTable";
import VisitParaclinicalPrescriptionTable
  from "../../../../visits/visitDetail/endVisit/dataList/paraclinical/visitParaclinicalPrescriptionTable";

const medicationDataListTitles = [
  "برند",
  "واحد",
  "مقدار مصرف",
  "عدد",
  "دوز",
  "نام دارو",
  "شکل دارو",
]

const paraclinicalDataListTitles = [
  "تعداد",
  "تاریخ",
  "نوع خدمت",
  "عنوان",
]

type VisitPrescriptionProps = {
  visitData?: string;
  openTab?: string;
}

const VisitPrescription = ({
 visitData,
 openTab,
}: VisitPrescriptionProps) => {

  const [prescriptionData, setPrescriptionData] = useState<TPrescriptionFields>()

  // services
  const getVisitPrescriptionRequest = useGetPrescription();

  useEffect(() => {
    visitData && getVisitPrescriptionRequest.mutate({visit: {id: visitData}})
  }, [visitData]);

  useEffect(() => {
    if (getVisitPrescriptionRequest?.data?.data?.result) {
      const {
          medicationItems, paraclinicalItems
        } = getVisitPrescriptionRequest.data.data.result,
        _fieldValue = {
          ...prescriptionData,
          medicationItems, paraclinicalItems
        };
      setPrescriptionData(_fieldValue)
    }
  }, [getVisitPrescriptionRequest.data])


  return (
    <div className='mx-3'>
      <h4 className='d-flex font-14 mb-2 pb-2'>
        <div className="d-flex align-items-center align-content-center border-bottom ms-auto ps-5">
          <span className="d-flex font-22"><Icon name='pills'/></span>
          <span className='me-2'>تجویز دارویی</span>
        </div>
      </h4>
      <section className="table-cont table-responsive border rounded mb-3">
        <VisitMedicationPrescriptionTable
          dataList={prescriptionData?.medicationItems}
          dataListTitles={medicationDataListTitles}
          isRequesting={false}
        />
      </section>
      <h4 className='d-flex font-14 mb-2 pb-2'>
        <div className="d-flex align-items-center align-content-center border-bottom ms-auto ps-5">
          <span className="d-flex font-22"><Icon name='test'/></span>
          <span className='me-2'>تجویز پاراکلینیک</span>
        </div>
      </h4>
      <section className="table-cont table-responsive border rounded mb-4">
        <VisitParaclinicalPrescriptionTable
          dataList={prescriptionData?.paraclinicalItems}
          dataListTitles={paraclinicalDataListTitles}
          isRequesting={false}
        />
      </section>
    </div>
  );
};

export default VisitPrescription;