import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import Icon from "../general/icon";
import BoxLoader from "../general/boxLoader";
import Modal from "../general/modal";
import {useBlockingAccount} from "../../../hook/request/account";
import {queryClient} from "../../../hook/request/setup";


type BlockingAccountModalProps = {
  modalData: any;
  modalName: any;
  isShowModalData: any;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  accountId: string,
  banned: boolean
  queryKey: string
}

const BlockingAccountModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: BlockingAccountModalProps) => {

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    accountId: '',
    banned: false,
    queryKey: '',
  });

  // services
  const blockingAccountRequest = useBlockingAccount({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [modalInfo?.queryKey] })
      handleSetShowModal();
    }
  });

  useEffect(() => {
    if(
      isShowModalData &&
      modalName === 'blockingUserModal'
    ){
      console.log(modalData)
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])


  const blockUserSubmit = () => {
    const body = {
      account: modalInfo?.accountId,
      banned: !modalInfo?.banned
    };
    blockingAccountRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-400 mw-100"
      bodyClassName="py-5 px-3 px-lg-5 pos-rel"
    >
      { blockingAccountRequest?.isPending && <BoxLoader type="cover" /> }
      <div className="text-center">
        <div className="d-flex justify-content-center mb-4">
          <span className={`d-flex justify-content-center align-items-center fs-1 pt-4 ${modalInfo?.banned ? 'text-primary info-item-bg' : 'text-red delete-item-bg' }`}>
            <Icon name={`${modalInfo?.banned ? 'check-square' : 'close-square' }`} />
          </span>
        </div>
        <h4 className="font-14 mb-2">کاربر گرامی</h4>
        <p className="text-black">
          آیا از {modalInfo?.banned ? 'فعال سازی ' : 'مسدود کردن '} این حساب کاربر اطمینان دارید؟
        </p>
      </div>

      <div className="d-flex justify-content-center mt-4 pt-2">
        <button
          onClick={() => handleSetShowModal()}
          className="btn btn-outline-gray flex-grow-1 py-2 mx-1"
        >
          انصراف
        </button>
        <button
          onClick={blockUserSubmit}
          className={`btn d-flex align-items-center py-2 mx-1 ${modalInfo?.banned ? 'btn-primary' : 'btn-danger'}`}
        >
          بله اطمینان دارم
        </button>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockingAccountModal);
