import React from 'react';
import { NumericFormat } from "react-number-format";
import Icon from '../icon';
import { Controller } from 'react-hook-form';

type NumberFieldProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  name: string;
  value?: string;
  prefix?: string;
  format?: any;
  thousandSeparator?: string;
  placeholder: string;
  className?: string;
  decimalScale?: number;
  allowLeadingZeroes?: boolean;
  isNumericString?: boolean;
  maxLength?: number;
  icon?: string;
  iconContent?: string;
  readOnly?: boolean;
  disabled?: boolean;
  error: any;
  control?: any;
}

const NumberField = ({
  onChange,
  onValueChange,
  onKeyDown,
  name,
  value,
  prefix,
  format,
  thousandSeparator,
  placeholder,
  className,
  decimalScale,
  allowLeadingZeroes = false,
  isNumericString = false,
  maxLength,
  icon,
  iconContent,
  readOnly,
  disabled,
  error,
  control
}: NumberFieldProps) => {

  const handleChange = (val: any, onFieldChange: any) => {
    if(onValueChange) return false;
    onChange && onChange(val);
    onFieldChange(val);
  }

  const handleValueChange = (val: any, onFieldChange: any) => {
    if(!onValueChange) return false;
    let returnValue = {
      target: {
        name: name,
        value: val.floatValue,
        type: 'number'
      }
    };
    onValueChange(returnValue);
    onFieldChange(returnValue);
  }

  let showError = error?.[name] && error?.[name]?.message;
  return(
    <>
      <div className={`inp-box ${showError ? 'invalid-field' : ''}`}>
        { iconContent ? iconContent : <span className="inp-icon"><Icon name={icon} /></span> }
        {
          control && 
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value } }) => (
                <NumericFormat
                  className={`form-control ${icon ? 'pl-5' : ''} ${className}`}
                  inputMode="numeric"
                  value={value}
                  name={name}
                  onChange={(e) => handleChange(e, onChange)}
                  onValueChange={(e) => handleValueChange(e, onChange)}
                  prefix={prefix}
                  // format={format}
                  thousandSeparator={thousandSeparator}
                  onKeyDown={onKeyDown}
                  placeholder={placeholder}
                  readOnly={readOnly}
                  disabled={disabled}
                  decimalScale={decimalScale}
                  allowLeadingZeros={allowLeadingZeroes}
                  valueIsNumericString={isNumericString}
                  maxLength={maxLength}
                />
              )}
            />
        }
      </div>
      {showError && <p className="text-danger font-10 mt-1 me-2">{error?.[name]?.message}</p>}
    </>
  )
}

export default NumberField;