import React from "react";
import {Form} from "react-bootstrap";


type FavoriteMedicationItemRowItemProps = {
  item?: any;
  onChange?: any
}


const FavoriteMedicationItemRowItemResponsive = ({
 item,
 onChange,
}: FavoriteMedicationItemRowItemProps) => {

  const handleCheckboxChange = (e: any) => {
    console.log(e.target)
    const { checked } = e.target;
    onChange(checked, item);
  };

  return (
    <div className="background-gray-hover border-bottom px-2">
      <Form.Check
        className='py-3'
      >
        <Form.Check.Label className='d-flex align-items-center w-100'>
          <div className="d-flex align-items-center w-100 ltr">
            <div className="ms-3">
              <Form.Check.Input
                className='rounded rounded-3 font-22 mb-1'
                name={`checkbox-${item?.id}`}
                type='checkbox'
                // checked={checked}
                onChange={handleCheckboxChange}
              />
            </div>
            <div className="text-center">
              <span
                className={`bg-primary bg-opacity-10 rounded rounded-3 border border-primary font-12 text-primary text-nowrap px-1 py-2 mx-2`}>
                {item?.item?.category?.name[0]}
              </span>
            </div>
            <div className="text-start">
              {item?.item?.name}
              <span className="text-green2 fw-semibold mx-1"> {item?.itemCount} </span>
              <span className="text-primary fw-semibold mx-1"> {item?.frequency?.maxa?.value} </span>
              <span className="text-orange fw-semibold mx-1"> {item?.dosage?.faName} </span>
            </div>
          </div>
        </Form.Check.Label>
      </Form.Check>
    </div>
  );
};


export default FavoriteMedicationItemRowItemResponsive