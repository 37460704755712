import React, {useEffect} from "react";
import {getCellValue} from "../../../../../utils/utils";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import {Form} from "react-bootstrap";


type FavoriteParaclinicalPrescriptionAccordionItemProps = {
  item?: any;
  index?: number,
  draftMedication?: boolean;
}


const FavoriteParaclinicalPrescriptionAccordionItem = ({
 item,
 draftMedication,
}: FavoriteParaclinicalPrescriptionAccordionItemProps) => {
  const [width] = useWindowSize();

  return (
    <div className="background-gray-hover px-2 border-bottom py-3">
      { draftMedication? (
        <div className="px-2">
              <div className={`d-flex align-items-center w-100 fw-bold ltr ${width > 900 && 'col-12'}`}>
                {width > 900 ?
                  <>
                    <div className="col-2 text-center ms-2">{item?.item?.generic}</div>
                    <div className="col-2 text-center">{item?.item?.shape?.name}</div>
                    <div className="col-4 text-center">{item?.item?.name}</div>
                    <div className="col-1 text-center">{item?.itemCount}</div>
                    <div className="col-1 text-center">{item?.frequency?.maxa?.value}</div>
                    <div className="col-2 text-center">{item?.dosage?.faName}</div>
                  </> :
                  <div className="text-start ms-2">
                    <span className='text-green mx-1'>{item?.item?.generic}</span>
                    <span className='text-gray fw-semibold mx-1'>{item?.item?.shape?.name}</span>
                    <span className='font-en mx-1'>{item?.item?.name}</span>
                    <span className='text-green2 fw-semibold mx-1'>{item?.itemCount}</span>
                    <span className='text-primary fw-semibold mx-1'>{item?.frequency?.maxa?.value}</span>
                    <span className='text-orange fw-semibold mx-1'>{item?.dosage?.faName}</span>
                  </div>
                }
              </div>
        </div>
      ) : (
        <div className={`d-flex align-items-center w-100 fw-bold ltr ${width > 900 && 'col-12'}`}>
          {width > 900 ?
            <>
              <div className="col-2 text-center ms-2">{getCellValue(item?.service?.loinc)}</div>
              <div className="col-2 text-center">{getCellValue(item?.service?.type?.name)}</div>
              <div className={`col-5 text-center ${item?.service?.enName && 'font-en'}`}>{item?.service?.enName ? getCellValue(item?.service?.enName) : getCellValue(item?.service?.name)}</div>
              <div className="col-1 text-center">{getCellValue(item?.itemCount)}</div>
              <div className="col-2 text-center">{item?.emergency == true ? 'اورژانسی' : 'غیر اورژانسی'}</div>
            </> :
            <div className="text-start ms-2">
              <span className='text-green mx-1'>{getCellValue(item?.service?.loinc)}</span>
              <span className='text-gray fw-semibold mx-1'>{getCellValue(item?.service?.type?.name)}</span>
              <span className={`${item?.service?.enName && 'font-en'} mx-1`}>{item?.service?.enName ? getCellValue(item?.service?.enName) : getCellValue(item?.service?.name)}</span>
              <span className='text-green2 fw-semibold mx-1'>{getCellValue(item?.itemCount)}</span>
            </div>
          }
        </div>
      )}
    </div>
  );
};


export default FavoriteParaclinicalPrescriptionAccordionItem