import React from "react";
import {connect, useSelector} from "react-redux";
import {generatePath, useNavigate} from "react-router-dom";
import {Dispatch} from "redux";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";
import {VISIT_MODE, VISIT_STATUS_ENUM} from "../../../../../constants/constant/enum";
import {ROUTES} from "../../../../../constants";
import Icon from "../../../general/icon";
import Tooltip from "../../../general/tooltip";
import {getCellValue} from "../../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../../utils/date";
import {general} from "../../../../../store/action";

type FavoriteParaclinicCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const FavoriteParaclinicCardsBoxItem = ({
 item,
 dataListTitles,
 handleShowModal
}: FavoriteParaclinicCardsBoxItemProps) => {
  const navigate = useNavigate();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-gray3'
      case 400:
        return 'bg-yellow1 text-yellow'
      default:
        return 'bg-light-red text-red'
    }
  }

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="serviceName">{dataListTitles[0]}</label>
        <span className="mr-2">{item?.service?.name ? getCellValue(item?.service?.name) : getCellValue(item?.service?.enName)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="serviceType">{dataListTitles[1]}</label>
        <span className="font-en mr-2">{getCellValue(item?.service?.type?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[2]}</label>
        <span className="mr-2">{getCellValue(item?.itemCount)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[9]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            {
              (isProvider) &&
              <>
                <Tooltip alt={"حذف"} className="tooltip-pre-line">
                <span
                  onClick={() =>
                    isProvider &&
                    handleShowModal('deleteFavoriteParaclinicModal', item?.id)
                  }
                  className={`d-flex mx-2 font-20 cur-pointer ${isProvider ? 'text-red-hover' : 'disabled text-gray3'}`}
                >
                  <Icon name='stethoscope'/>
                </span>
                </Tooltip>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(FavoriteParaclinicCardsBoxItem);