import { ACTION_TYPES } from "../../../constants";

const initialState = {
  show: false,
  hide: false,
  data: null,
  modalName: null
};

const modalReducer = (state = initialState, action = {}) => {
  const { payload, modalName, type } = action;
  switch (type) {
    case ACTION_TYPES.GENERAL.MODAL.SHOW: {
      return {
        show: true,
        hide: false,
        data: payload,
        modalName
      }
    }
    case ACTION_TYPES.GENERAL.MODAL.RESET: {
      return initialState;
    }
    case ACTION_TYPES.GENERAL.MODAL.HIDE:
      return { show: false, hide: true, data: null, modalName: null };
    default:
      return state
  }
}

export default modalReducer