import {Accordion} from "react-bootstrap";
import React from "react";
import Tooltip from "../../../../general/tooltip";
import Icon from "../../../../general/icon";
import AnalysisGuide from "./analysisGuide";
import ExaminationFormulaTable from "./examinationFomulaTable";
import {TEcgQtc} from "../../../../../../types/ecg";

type EcgQtcProps = {
  ecgQtc: TEcgQtc
}

const ExaminationAnalysis = ({
  ecgQtc
}: EcgQtcProps) => {

  return (
    <Accordion.Item eventKey='analysis' className='my-4 rounded bg-white'>
      <Accordion.Header className='d-flex flex-wrap bg-white rounded'>
        <Tooltip alt={'از این شماره موبایل برای فرستادن لینک نسخه به بیمار استفاده می شود'} className="tooltip-pre-line">
          <span className='d-flex align-items-center text-black font-22 ms-2'>
            <Icon name='info-circle'/>
          </span>
        </Tooltip>
        <div className='flex-fill'></div>
        <div className='d-flex'>تفسیر نتایج</div>
      </Accordion.Header>
      <Accordion.Body className='rounded-bottom bg-white'>
        <h5 className='title-dot'>تفسیر نتایج</h5>
        <AnalysisGuide/>
        <h5 className='title-dot mt-4'>فرمول‌های QTc</h5>
        <ExaminationFormulaTable ecgQtc={ecgQtc}/>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ExaminationAnalysis;