import * as yup from "yup";

const addMenuSchema = yup.object({
  url: yup
    .string()
    .required("این فیلد الزامیست."),
  icon: yup
    .string()
    .required("این فیلد الزامیست."),
  accessGroup: yup
    .string()
    .required("این فیلد الزامیست."),
  // accessProcess: yup
  //   .string()
  //   .required("این فیلد الزامیست."),
});


export {
  addMenuSchema,
};
