import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosRequest } from "../../../services/api";
import { PatientMisuse } from "../../../services/requests/ehr/patientMisuse";
import Noti from "../../../view/components/general/noti";
import { handleResponse } from "../setup";




const useAddMisusePatient = ({ onSuccess }: { onSuccess: any }) => {
  return useMutation({
    mutationFn: PatientMisuse.addMisusePatient,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data);
          Noti("success", data?.data?.msg);
          onSuccess();
        },
      });
    },
  });
};

const useGetListMaxaMisuse = (params:any, modalName?: boolean)=>{
  return useQuery({
      queryKey:["getListMaxaMisuse",params],
      queryFn:({queryKey})=>{
       const [_,params] = queryKey;
       return PatientMisuse.getMisuseMaxaList(params);
      },
    enabled: modalName ?? true
  })
}



const useGetListAllMisuse = (params:any, modalName: boolean)=>{
  return useQuery({
      queryKey:["getListAllMisuse",params],
      queryFn:({queryKey})=>{
       const [_,params] = queryKey;
       return PatientMisuse.getPatientMisuseListAll(params)
      },
    enabled: modalName ?? true
  })

};
export {
  useAddMisusePatient,
  useGetListMaxaMisuse,
  useGetListAllMisuse
  // useGetListMisuse
};
