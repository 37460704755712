import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Accordion} from "react-bootstrap";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import Icon from "../../general/icon";
import SelectField from "../../general/form/selectField";
import DebounceField from "../../general/form/debounceField";
import TextAreaField from "../../general/form/textAreaField";
import Tooltip from "../../general/tooltip";
import {MEDICATION_SHAPE_IHIO} from "../../../../constants/constant/enum";
import {useGetMedicationBrand} from "../../../../hook/request/medication";
import AccordionButtonIcon from "../../../../assets/images/svgComponents/accordionButtonIcon";
import {general} from "../../../../store/action";
import {TAddPrescriptionMedicationItemFields} from "../../../../types/medicationItem";

/*
    کامپوننت مربوط به انتخاب اطلاعات تجویز هر خدمت دارو برای نسخه
    Parent: <PrescriptionMedicationItem/>
    Children: -
    در این کامپوننت هر یک از خدمت های دارو تجویز شده رندر میشود،
    سپس اطلاعات نسخه مانند تعداد خدمت ثبت میشود و اطلاعات آن برای کامپوننت پرنت فرستاده میشود.
*/

type FavoriteMedicationPrescriptionRowItemResponsiveProps = {
  item: any;
  index: number;
  insurance?: number;
  frequency: any;
  dosage: any;
  control: any;
  errors: any;
  handleShowModal:any;
  onFieldsChange: any;
  handleMedicationModalSubmit: any;
  deleteMedicationItemFromList: any;
  register: any;
  watch?: any;
  update?: any;
}

type TBrandFilterValues = {
  molecule?: string;
  dose?: string;
  doseForm?: string;
}

const FavoriteMedicationPrescriptionRowItemResponsive = ({
 item,
 index,
 insurance,
 frequency,
 dosage,
 handleShowModal,
 control,
 errors,
 onFieldsChange,
 deleteMedicationItemFromList,
 handleMedicationModalSubmit,
 register,
 watch,
 update,
}: FavoriteMedicationPrescriptionRowItemResponsiveProps) => {

  //<editor-fold desc="States">
  const { getQueryParams } = useMySearchParams();
  const [arrowColor, setArrowColor] = useState('black')
  const [fieldValue, setFieldValue] = useState<TAddPrescriptionMedicationItemFields>()
  const brandFilterValuesInitialState = {
    molecule: "",
    dose: "",
    doseForm: "",
  }
  const [brandFilterValues, setBrandFilterValues] = useState<TBrandFilterValues>(brandFilterValuesInitialState);
  const [brandList, setBrandList] = useState<any>([])
  const [showBrand, setShowBrand] = useState(false)

  //</editor-fold>

  //<editor-fold desc="Services">
  const getMedicationBrandRequest = useGetMedicationBrand(getQueryParams(brandFilterValues), showBrand);
  //</editor-fold>

  //<editor-fold desc="UseEffects">
  useEffect(() => {
    setFieldValue(item)
  }, [item]);

  /*
    دریافت برند های یک دارو برای سلکت و حذف برند هایی که توسط بیمه بیمار ارائه نمی‌شوند.
    @Dependency: getMedicationBrandRequest
  */
  useEffect(() => {
    if (getMedicationBrandRequest?.data?.data?.result) {
      const data  = getMedicationBrandRequest.data.data.result.filter((item: any) => {
        return true; // If no specific insurance type, include all items
      })
      const _data = data.map((item: any) => (
        { label: item?.brandName ?
            <Tooltip alt={`${item?.brandName}`} placement='right-start' show={300} hide={200} className="tooltip-pre-line"><span className={``}>{item?.brand ? item?.brand : "No Brand"}</span></Tooltip>
            : <span className={``}>{item?.brand ? item?.brand : "No Brand"}</span>,
          value: item?.id,
          erx: item?.erx,
          generic: item?.generic,
          titleText: item?.brand ? item?.brand : "No Brand",
          ihioSupport: item?.ihioSupport,
          taminSupport: item?.taminSupport,
        }
      ))
      setBrandList(_data);
    }
  }, [getMedicationBrandRequest.data])

  /*
    فاکتور های پیدا کردن برند دارو بر اساس اطلاعات دارو تغییر میکنند.
    @Dependency: item
  */
  useEffect(() => {
    setBrandFilterValues({
      molecule: item?.item?.molecule,
      dose: item?.item?.dose,
      doseForm: item?.item?.doseForm,
    })
  }, [item])

  //</editor-fold desc="UseEffects">

  /*
    تغییر اطلاعات استیت در کامپوننت پرنت بر اساس اطلاعات تغییر یافته در اینپوت ها.
    @param: e
  */
  const handleFieldChange = (e?: any) => {
    const { name, value } = e?.target;
    const fieldName = name.split('_')[0];
    const optionsMap: { [key: string]: any[] | undefined } = {
      dosage,
      frequency,
      brand: brandList,
    };
    const labelKey = fieldName === 'brand' ? 'name' : 'label';
    const label = optionsMap[fieldName]?.find((item: any) => item?.value === value)?.[labelKey];
    // onFieldsChange(name, value, label, index, item);
    let fieldValue = {}
    if(fieldName !== 'brand') {
      if(fieldName === 'dosage' || fieldName === 'frequency'){
        fieldValue = {...item, [fieldName]: value, [fieldName + 'Name']: label}
      } else {
        fieldValue = {...item, [fieldName]: value}
      }
    } else {
      console.log(value, label)
      fieldValue = {...item,
        item: {
          ...item.item,
          id: value,
          // erx: brand?.erx,
          // support: insurance === ISSUER_TYPE.TAMIN.value ? brand?.taminSupport : brand?.ihioSupport,
        },
        brand: value,
        brandName: label
      }
    }
    update(index, { ...fieldValue})
  };

  return (
    <Accordion.Item eventKey={`medicationItem_${index}`} className={`bg-primary bg-opacity-10 rounded my-4`}>
      <Accordion.Header className='rounded font-14'
                        onClick={() => setArrowColor(arrowColor === 'blue' ? 'black' : 'blue')}
      >
        <div className="accordion-title d-flex justify-content-between align-items-center w-100">
          <div className='w-100 d-flex justify-content-start'>
            <div>
              <div className='p-1 btn btn-gray rounded rounded-3 ms-2'
                   onClick={() => deleteMedicationItemFromList(index)}
              >
                <span
                  className='mx-2 font-20 d-flex  align-items-center h-100'>
                  <Icon name='trash'/>
                </span>
              </div>
            </div>
          </div>
          <span className="accordion-icon">
            <AccordionButtonIcon fill={arrowColor}/>
          </span>
        </div>
      </Accordion.Header>
      <Accordion.Body className="d-flex row">
        {!showBrand ?
          <div className="col-lg-2 col-md-3 col-sm-3 col-6 mb-2"
               onClick={() => setShowBrand(true)}
          >
            <div className='btn btn-primary d-flex align-items-center rounded px-2'>
              <span
                className='d-flex justify-content-center align-items-center w-100 font-27'>
                <Icon name='color-swatch'/>
              </span>
            </div>
          </div> :
          <div className=" col-lg-2 col-md-3 col-sm-3 col-6 mb-2">
            <div className="form-group">
              <SelectField
                width={'50px'}
                options={brandList}
                name={`brand_${index}`}
                placeholder='برند'
                control={control}
                error={errors}
                onChange={handleFieldChange}
              />
            </div>
          </div>
        }
        <div className=" col-lg-2 col-md-4 col-sm-4 col-6 mb-2">
          <div className="form-group">
            <SelectField
              width={'10px'}
              options={dosage}
              name={`dosage_${index}`}
              placeholder='دستور مصرف'
              control={control}
              error={errors}
              onChange={handleFieldChange}
              register={register(`root.${index}.dosage`)}
              watch={watch(`root.${index}.dosage`)}
            />
          </div>
        </div>
        <div className=" col-lg-2 col-md-3 col-sm-3 col-6 mb-2">
          <div className="form-group">
            <SelectField
              width={'10px'}
              options={frequency}
              name={`frequency_${index}`}
              placeholder='تواتر مصرف'
              control={control}
              error={errors}
              onChange={handleFieldChange}
              register={register(`root.${index}.frequency`)}
              watch={watch(`root.${index}.frequency`)}
            />
          </div>
        </div>
        <div className=" col-6 col-lg-2 col-md-2 col-sm-2 mb-2">
          <div className="form-group">
            <DebounceField
              onChange={handleFieldChange}
              name={`itemCount_${index}`}
              placeholder="تعداد"
              control={control}
              error={errors}
              register={register(`root.${index}.itemCount`)}
              watch={watch(`root.${index}.itemCount`)}
            />
          </div>
        </div>
        <div className={`col-12`}>
          <div className="form-group">
            <TextAreaField
              rows={1}
              onChange={handleFieldChange}
              name={`drugDescription_${index}`}
              placeholder="توضیحات دارو"
              control={control}
              error={errors}
              register={register(`root.${index}.drugDescription`)}
              watch={watch(`root.${index}.drugDescription`)}
            />
          </div>
        </div>
      </Accordion.Body>
      <div className='d-flex justify-content-end align-items-center prescription-accordion-footer'>
        <div className="rounded border bg-white w-100 px-1 py-1 cur-pointer"
             onClick={() => handleShowModal('addPrescriptionMedicationItemModal', {
               index: index,
               item: item,
               handleMedicationModalSubmit: handleMedicationModalSubmit,
               insurance: insurance
             })}
        >
          <bdi className='d-flex justify-content-end me-2'>
            <div className='d-flex align-items-center w-100 font-en ltr'>
              <div className='d-flex align-items-center ms-1'>
                <span
                  className={`rounded-3 bg-primary text-white border border-2 border-primary font-12 fw-bold text-nowrap p-2 me-2`}>
                  {item?.item?.category?.name}
                 </span>
              </div>
              <div className='d-flex justify-content-start w-100'>
                <span className='d-flex align-items-center font-20 text-hover font-en'>
                </span>
                <div className="d-flex flex-column">
                  <span
                    className={`d-flex justify-content-start text-start fw-bold font-en`}>
                    {(index + 1) + '. ' + MEDICATION_SHAPE_IHIO.find((item: any) => item?.value === fieldValue?.item?.shape?.id)?.enName + ' - ' + fieldValue?.item?.molecule} - &nbsp;
                    {fieldValue?.item?.dose} - &nbsp;
                    {fieldValue?.itemCount} - &nbsp;
                    {frequency && frequency?.find((item: any) => item?.value === fieldValue?.frequency)?.maxaValue} - &nbsp;
                    {dosage && dosage?.find((item: any) => item?.value === fieldValue?.dosage)?.label} &nbsp;
                    {brandList && brandList?.find((item: any) => item?.value === fieldValue?.item?.id)?.titleText}
                  </span>
                  {arrowColor === 'blue' && (
                    <span className='d-flex justify-content-start font-en'>
                      {item?.item?.generic &&
                        <span className='text-dark fw-bolder me-1'>
                          {'(' + item?.item?.generic + ')'}
                        </span>
                      }
                      {item?.item?.molecule}
                    </span>
                  )}
                </div>
                <div className="flex-fill"></div>
                <span className='d-flex align-items-center font-20 text-hover font-en'>
                  <Icon name='pen-square'/>
                </span>
              </div>
            </div>
          </bdi>
        </div>
      </div>
    </Accordion.Item>
  )
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(FavoriteMedicationPrescriptionRowItemResponsive);