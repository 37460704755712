import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "../setup";
import Noti from "../../../view/components/general/noti";
import {
  DraftPrescriptionParaclinicalItemService
} from "../../../services/requests/visit/draftPrescriptionParaclinicalItem";

const useGetDraftPrescriptionParaclinicalItemList = (params: any, modalName: boolean) => {
  return useQuery({
    queryKey: ["getDraftPrescriptionParaclinicalItemList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return DraftPrescriptionParaclinicalItemService.getDraftPrescriptionParaclinicalItemList(params);
    },
    enabled: modalName ?? true,
  });
}

const useGetDraftPrescriptionParaclinicalItem = () => {
  return useMutation({
    mutationFn: DraftPrescriptionParaclinicalItemService.getDraftPrescriptionParaclinicalItem,
  })
}

const useAddDraftPrescriptionParaclinicalItem = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: DraftPrescriptionParaclinicalItemService.addDraftPrescriptionParaclinicalItem,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getDraftPrescriptionParaclinicalItemList"] })
          onSuccess();
        }
      })
    },
  })
}

const useDeleteDraftPrescriptionParaclinicalItem = ({
 onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: DraftPrescriptionParaclinicalItemService.deleteDraftPrescriptionParaclinicalItem,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getDraftPrescriptionParaclinicalItemList"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetDraftPrescriptionParaclinicalItemList,
  useGetDraftPrescriptionParaclinicalItem,
  useAddDraftPrescriptionParaclinicalItem,
  useDeleteDraftPrescriptionParaclinicalItem
}
