import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useDeleteDraftPrescriptionParaclinicalItem
} from "../../../../hook/request/visit/draftPrescriptionParaclinicalItem";
import Modal from "../../general/modal";
import BoxLoader from "../../general/boxLoader";
import Icon from "../../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../store/selector/general";
import {general} from "../../../../store/action";

type DeleteFavoriteParaclinicModalProps = {
  modalData: any;
  modalName: any;
  isShowModalData: any;
  handleHideModal: any;
  handleResetModal: any;
}

const DeleteFavoriteParaclinicModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: DeleteFavoriteParaclinicModalProps) => {

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  useEffect(() => {
    if(
      isShowModalData &&
      modalName === 'deleteFavoriteParaclinicModal'
    ){
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  // services
  const deleteFavoriteParaclinicRequest = useDeleteDraftPrescriptionParaclinicalItem({
    onSuccess: () => {
      handleSetShowModal();
    }
  });

  const deleteFavoriteParaclinicSubmit = () => {
    const body = {id: modalInfo};
    deleteFavoriteParaclinicRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-400 mw-100"
      bodyClassName="py-5 px-3 px-lg-5 pos-rel"
    >
      { deleteFavoriteParaclinicRequest?.isPending && <BoxLoader type="cover" /> }
      <div className="text-center">
        <div className="d-flex justify-content-center mb-4">
          <span className="delete-item-bg d-flex justify-content-center align-items-center text-red font-25 pt-4">
            <Icon name='trash' />
          </span>
        </div>
        <h4 className="font-14 mb-2">کاربر گرامی</h4>
        <p className="text-black">
          آیا از حذف این خدمت پاراکلینیک اطمینان دارید؟
        </p>
      </div>

      <div className="d-flex justify-content-center mt-4 pt-2">
        <button
          onClick={() => handleSetShowModal()}
          className="btn btn-outline-gray flex-grow-1 py-2 mx-1"
        >
          انصراف
        </button>
        <button
          onClick={deleteFavoriteParaclinicSubmit}
          className="btn btn-danger d-flex align-items-center py-2 mx-1"
        >
          بله حذف شود
        </button>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFavoriteParaclinicModal);
