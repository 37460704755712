export const fileToBase64 = (data, fileType) => {
  return new Promise((resolve, reject) => {
    if (!data) return;
    const reader = new FileReader();

    // Handle different file types based on their MIME type
    if (data.type.match('text')) {
      reader.readAsText(data);
    } else if (data.type.match('image')) {
      reader.readAsDataURL(data);
    } else if (data.type.match('pdf')) {
      reader.readAsDataURL(data);
    } else if (data.type.match('sheet')) {
      // Handle Excel or other spreadsheet types
      // You can implement this logic based on your requirements
      reject(`Unsupported file type: ${data.type}`);
    } else {
      // For unknown file types, reject with an appropriate message
      reject(`Unsupported file type: ${data.type}`);
    }

    reader.onload = () => {
      const base64Data = reader.result;
      resolve(base64Data);
    };

    reader.onerror = (error) => {
      reject(`Error converting file to ${fileType}: ${error}`);
    };
  });
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    if (!blob) return;
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};
