import {useEffect, useMemo, useRef, useState} from "react";
import { ROUTES } from "../../../constants";
import BoxLoader from "../../components/general/boxLoader";
import {AsyncSelectField} from "../../components/general/form/selectField";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { useAddVacation, useGetVacation } from "../../../hook/request/vacations";
import { TAddVacationFields } from "../../../types/vacation";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addVacationSchema } from "../../../schema/vacationSchema";
import DatePickerField from "../../components/general/form/datePickerField";
import {
  convertToLocalTimeInUTCFormat,
  getStartOfTodayAsString,
  getStartOfTomorrowAsDate,
  ToFullDateTimeFormat
} from "../../../utils/date";
import momentJ from "moment-jalaali";
import { useGetDoctors } from "../../../hook/request/doctors";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import AlertBox from "../../components/general/alert";
import {asyncSelectLoadOptions} from "../../../utils/asyncSelectLoadOptions";
import {localStorageGet} from "../../../utils/localStorage";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";
import {useSelector} from "react-redux";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const AddVacation = () => {

  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const { id } = useParams();
  const { getQueryParams } = useMySearchParams();
  const [searchParams] = useSearchParams();
  const [doctorFilterValues, setDoctorFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [fieldValue, setFieldValue] = useState<TAddVacationFields>({
    provider: '',
    fromDateTime: null,
    toDateTime: null
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddVacationFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addVacationSchema),
  });
  const [doctorsList, setDoctorsList] = useState([]);
  const [isOperator, setIsOperator] = useState<boolean>(false);
  const [fetch, setFetch] = useState<boolean>(true)


  const callbackRef = useRef<any>(null);

  // services
  const getDoctorsRequest = useGetDoctors(getQueryParams(doctorFilterValues), fetch);
  const getVacationRequest = useGetVacation();
  const addVacationRequest = useAddVacation();

  useEffect(() => {
    const _userData = localStorageGet('USER_DATA')
    if (_userData?.operatorId !== 0) {
      setIsOperator(true);
    } else {
      setIsOperator(false);
      setFieldValue({ ...fieldValue, provider: '0' });
    }
  }, []);

  useEffect(() => {
    let _DoctorFilterValues = {};
    _DoctorFilterValues = {
      ...doctorFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setDoctorFilterValues(_DoctorFilterValues)
    setFetch(false)
  }, [searchParams]);

  useEffect(() => {
    id && getVacationRequest.mutate({id});
  }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if(getDoctorsRequest?.data?.data?.result) {
      const { data } = getDoctorsRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: `${item?.firstName} ${item?.lastName}`, value: item?.id }
        ))
      setDoctorsList(_data);
    }
  }, [getDoctorsRequest.data])

  useEffect(() => {
    if (getVacationRequest?.data?.data?.result) {
      const { provider, fromDateTime, toDateTime } = getVacationRequest.data.data.result,
        _fieldValue = {
          provider,
          fromDateTime: fromDateTime ? momentJ(fromDateTime) : null,
          toDateTime: toDateTime ? momentJ(toDateTime) : null,
        };
      // MtoJFullDateFormat
      setFieldValue(_fieldValue);
    }
  }, [getVacationRequest.data])

  const changeRequestFilters = (inputValue: any, callback: any) => {
    callbackRef.current = callback;
    setDoctorFilterValues({
      ...doctorFilterValues,
      search: inputValue,
    })
  }

  useEffect(() => {
    if(callbackRef.current) {
      doctorsLoadOptions(doctorFilterValues.search, callbackRef.current);
    }
  }, [doctorFilterValues?.search]);

  const doctorsLoadOptions = (inputValue: any, callback: any) => {
    asyncSelectLoadOptions(inputValue, callback, doctorFilterValues, setDoctorFilterValues, getDoctorsRequest,
      (item) => ({ label: `${item?.firstName} ${item?.lastName}`, value: item?.id })
    );
  };

  const addVacationSubmit: SubmitHandler<TAddVacationFields> = (data) => {
    const { provider, fromDateTime, toDateTime } = data,
      body = {
        ...(id ? {id} : {}),
        provider: {
          id: isOperator ? +provider : '0',
          name: ""
        },
        fromDateTime: convertToLocalTimeInUTCFormat(fromDateTime),
        toDateTime: convertToLocalTimeInUTCFormat(toDateTime)
      };
    console.log(body, fromDateTime);
    addVacationRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getDoctorsRequest?.isLoading ||
          getVacationRequest?.isPending ||
          addVacationRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(addVacationSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <AlertBox type='warning' icon='info' iconClass='fs-2' text='در نظر داشته باشید که در صورت اقدام به ثبت مرخصی، نوبت‌هایی که پیشتر برایتان در نظر گرفته شده است، در طول مدت مرخصی لغو خواهد شد.' className='mb-5' children=''/>
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات مرخصی</h4>
            </div>
            { isOperator &&
              <div className="col-12 px-4">
                <div className="form-group mb-4">
                  <label className="inp-lbl me-2 mb-2" htmlFor="provider">نام پزشک</label>
                  <AsyncSelectField
                    options={doctorsList}
                    name="provider"
                    placeholder='انتخاب کنید'
                    control={control}
                    error={errors}
                    asyncLoadOptions={changeRequestFilters}
                  />
                </div>
              </div>
            }
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="fromDateTime">ساعت و تاریخ شروع مرخصی</label>
                <DatePickerField
                  name="fromDateTime"
                  placeholder="ساعت و تاریخ شروع مرخصی"
                  showTimePicker={true}
                  format="YYYY/MM/DD - HH:mm"
                  minDate={getStartOfTodayAsString()}
                  control={control}
                  error={errors}

                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="toDateTime">ساعت و تاریخ پایان مرخصی</label>
                <DatePickerField
                  name="toDateTime"
                  placeholder="ساعت و تاریخ پایان مرخصی"
                  showTimePicker={true}
                  format="YYYY/MM/DD - HH:mm"
                  minDate={getStartOfTodayAsString()}
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.VACATION?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                {
                  ((isProvider || findAccessInAccessList('PROVIDER_VACATION_EDIT') && id) || (findAccessInAccessList('PROVIDER_VACATION_CREATE') && !id)) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    { id ? "ویرایش مرخصی" : "افزودن مرخصی" }
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVacation;