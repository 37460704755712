import {useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../hook/useMySearchParams";
import Icon from "../../../../components/general/icon";
import SearchBox from "../../../../components/general/searchBox";
import Pagination from "../../../../components/general/table/pagination";
import {general} from "../../../../../store/action";
import {connect, useSelector} from "react-redux";
import PatientVitalCardsBox from "../../../../components/patients/ehr/vital/dataList/patientVitalCardsBox";
import PatientVitalsTable from "../../../../components/patients/ehr/vital/dataList/patientVitalsTable";
import AddPatientVitalModal from "../../../../components/patients/ehr/vital/addPatientVitalModal";
import DeletePatientVitalModal from "../../../../components/patients/ehr/vital/deletePatientVitalModal";
import {useGetPatientVitals} from "../../../../../hook/request/ehr/patientVital";
import {useFindAccessInAccessList} from "../../../../../constants/constant/accessProcess";

const dataListTitles = [
  "تاریخ و ساعت معاینه",
  "درجه حرارت (DEG C)",
  "فشار خون (mmHg)",
  "ضربان قلب (beats/min)",
  "نرخ تنفس (breaths/min)",
  "اکسیژن اشباع خون (%)",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type vitalsListProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const VitalsList = ({
  patientData,
  openTab,
  handleShowModal,
  visitEHRCheck = false,
}: vitalsListProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { getQueryParams } = useMySearchParams();
  const [vitalsData, setVitalsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: id,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getVitalsRequest = useGetPatientVitals(getQueryParams(filterValues), openTab === 'vitalsList');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getVitalsRequest?.data?.data?.result &&
    setVitalsData(getVitalsRequest?.data?.data.result)
  }, [getVitalsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const _filterValues = {
      ...filterValues,
      [name]: value,
    }
    setFilterValues(_filterValues)
  }

  const doChangePage = (param: any) => {
    const _filterValues = {
      ...filterValues,
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    }
    setFilterValues(_filterValues)
  }

  return (
    <>
      <section className="filter-box d-flex flex-column flex-md-row mb-4">
        <div className="form-group ms-md-auto">
          <SearchBox
            name="search"
            value={filterValues?.search}
            onChange={handleFilterChange}
            placeholder="جستجوی شاخص حیاتی"
          />
        </div>
        {((visitEHRCheck && isProvider) || findAccessInAccessList('EHR_VITAL_CREATE')) &&
          <div
            className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
            <button
              onClick={() => handleShowModal('addPatientVitalModal', {patientId: id, patientData: patientData})}
              className="btn btn-outline-primary rounded d-flex align-items-center fw-bold px-4"
            >
              <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
              ثبت شاخص حیاتی
            </button>
          </div>
        }
      </section>

      <section className="table-cont table-responsive">
        {
          width < 900 ?
            <PatientVitalCardsBox
              dataList={vitalsData}
              dataListTitles={dataListTitles}
              isRequesting={getVitalsRequest.isPending}
              readonly={!visitEHRCheck}
            /> :
            <PatientVitalsTable
              dataList={vitalsData}
              dataListTitles={dataListTitles}
              isRequesting={getVitalsRequest.isPending}
              readonly={!visitEHRCheck}
            />
        }
        <Pagination
            paginationData={{...filterValues, ...vitalsData}}
            doChangePage={doChangePage}
          />
        </section>
      {openTab === 'vitalsList' && <AddPatientVitalModal/>}
      <DeletePatientVitalModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VitalsList);
