import React from "react";
import { connect } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const OrganizationCardsBoxItem = ({
  item,
  dataListTitles,
  handleShowModal
}) => {
  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return(
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="telNo">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.telNo)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="address">{dataListTitles[2]}</label>
        <span className="font-en mr-2">{getCellValue(item?.address)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="updateDate">{dataListTitles[3]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="relation">{dataListTitles[4]}</label>
        <span className="mr-2">
          {
            (findAccessInAccessList('HEALTH_ORGANIZATION_CREATE') || findAccessInAccessList('HEALTH_ORGANIZATION_EDIT')) &&
            <Tooltip alt={'مراکز متصل'} placement='bottom' className="tooltip-pre-line">
              <span
               onClick={() => navigate(generatePath(ROUTES.ORGANIZATION_RELATION.PATH, {id: item?.id}))}
               className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='maximize-square'/>
              </span>
            </Tooltip>
          }
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-end my-3">
        <div className="d-flex align-items-center">
          {
            findAccessInAccessList('HEALTH_ORGANIZATION_VIEW') &&
            <Tooltip alt={'ویرایش'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.ORGANIZATION_EDIT.PATH, { id: item?.id }))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='pen-square' />
              </span>
            </Tooltip>
          }
          {
            findAccessInAccessList('HEALTH_ORGANIZATION_DELETE') &&
            <Tooltip alt={'حذف'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deleteOrganizationModal', item?.id)}
                className="d-flex text-red-hover mr-2 font-20 cur-pointer"
              >
                <Icon name='trash' />
              </span>
            </Tooltip>
          }
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(OrganizationCardsBoxItem);