import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {general} from "../../../store/action";
import {TGIdNameData} from "../../../types/general";
import {TPatientFields} from "../../../types/patient";
import Image from "../general/image";
import {getAge, getCellValue} from "../../../utils/utils";
import EmptyAvatar from "../../../assets/images/svg/profile-empty-avatar.svg";
import {MtoJFullDateFormat} from "../../../utils/date";
import {TAddDeviceField} from "../../../types/device";
import {useGetDevice} from "../../../hook/request/devices";

type ExaminationInfoProps = {
  device?: TGIdNameData;
  patient?: TPatientFields;
  examiner?: TGIdNameData;
  provider?: {
    id: string,
    name: string
  },
  healthUnit?: {
    id: string,
    name: string
  },
  chiefComplaint?: string;
  leadCount?: number;
  startDate?: any;
}

const ExaminationInfo = ({
  device,
  patient,
  examiner,
  provider,
  healthUnit,
  chiefComplaint,
  leadCount,
  startDate
}: ExaminationInfoProps) => {

  const [deviceInfo, setDeviceInfo] = useState<TAddDeviceField>()

  const getDeviceRequest = useGetDevice()

  useEffect(() => {
    device?.id && getDeviceRequest.mutate({id: device.id})
  }, [device]);

  useEffect(() => {
    if(getDeviceRequest?.data?.data?.result) {
      const {imei, deviceCode, serialCode, deviceType, manufactureDate, updateDate} = getDeviceRequest?.data?.data?.result
      setDeviceInfo({imei, deviceCode, serialCode, deviceType, manufactureDate, updateDate})
    }
  }, [getDeviceRequest?.data?.data?.result]);

  return (
      <div className="bg-primary-10 rounded-2 align-items-center px-4 mb-4">
        <div className='d-lg-flex rounded mx-3'>
          <div className="d-flex col-lg-2 col-sm-12 col-md-12 justify-content-center">
            <div className='align-content-center align-items-center text-center mx-2 w-50'>
              <Image
                className='rounded my-2'
                src={EmptyAvatar}
              />
              <div
                className='fw-medium font-12 mb-2'>
                {getCellValue(patient?.firstName)} {getCellValue(patient?.lastName)}
              </div>
              <div className='font-10 mb-2'>{patient?.birthDate && getAge(getCellValue(patient.birthDate))} سال</div>
            </div>
          </div>
          <div className='justify-content-center col-lg-10 col-sm-12 col-md-12 row row-cols-5 my-4'>
            <div className="col px-2 pb-2 text-center border-bottom border-2">
              <div className='font-10 text-gray fw-medium mb-3'>زمان گرفتن نمونه</div>
              <div className='font-13 d-flex justify-content-center'>
                <span className=''>{getCellValue(MtoJFullDateFormat(startDate))}</span>
              </div>
            </div>
            <div className="col px-2 pb-2 text-center border-bottom border-2">
              <div className='font-10 text-gray fw-medium mb-3'>نوع نمونه گیری</div>
              <div className='font-13 d-flex justify-content-center'>
                <span className=''>{leadCount ? leadCount + ' لید' : '-'}</span>
              </div>
            </div>
            <div className="col px-2 pb-2 text-center border-bottom border-2">
              <div className='font-10 text-gray fw-medium mb-3'>علت مراجعه</div>
              <div className='font-13 d-flex justify-content-center'>
                <span className=''>{getCellValue(chiefComplaint)}</span>
              </div>
            </div>
            <div className="col px-2 pb-2 text-center border-bottom border-2">
              <div className='font-10 text-gray fw-medium mb-3'>کد دستگاه</div>
              <div className='font-13 d-flex justify-content-center'>
                <span className=''>{getCellValue(deviceInfo?.deviceCode)}</span>
              </div>
            </div>
            <div className="col px-2 pb-2 text-center border-bottom border-2">
              <div className='font-10 text-gray fw-medium mb-3'>دستگاه IMEI</div>
              <div className='font-13 d-flex justify-content-center'>
                <span className=''>{getCellValue(deviceInfo?.imei)}</span>
              </div>
            </div>
            <div
              className="col px-2 pt-2 mb-2 text-center align-content-end">
              <div className='font-10 text-gray fw-medium mb-3'>کد ملی</div>
              <div className='font-13 d-flex justify-content-center'>
                <span className=''>{getCellValue(patient?.nationalCode)}</span>
              </div>
            </div>
            <div
              className="col px-2 pt-2 mb-2 text-center align-content-end">
              <div className='font-10 text-gray fw-medium mb-3'>نام مرکز</div>
              <div className='font-13 d-flex justify-content-center'>
                <span className=''>{getCellValue(healthUnit?.name)}</span>
              </div>
            </div>
            <div
              className="col px-2 pt-2 mb-2 text-center align-content-end">
              <div className='font-10 text-gray fw-medium mb-3'>پزشک معالج</div>
              <div className='font-13 d-flex justify-content-center'>
                <span className=''>{getCellValue(provider?.name)}</span>
              </div>
            </div>
            <div
              className="col px-2 pt-2 mb-2 text-center align-content-end">
              <div className='font-10 text-gray fw-medium mb-3 '>اپراتور مربوطه</div>
              <div className='font-13 d-flex justify-content-center'>
                <span className=''>{getCellValue(examiner?.name)}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ExaminationInfo);