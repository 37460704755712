import React, {useEffect, useState} from "react";
import { Accordion } from "react-bootstrap";
import Icon from "../../general/icon";
import AccordionButtonIcon from "../../../../assets/images/svgComponents/accordionButtonIcon";
import TextAreaField from "../../general/form/textAreaField";
import {general} from "../../../../store/action";
import {TAddPrescriptionParaclinicalItemFields} from "../../../../types/paraclinicalItem";
import {connect} from "react-redux";

/*
    کامپوننت مربوط به انتخاب اطلاعات تجویز هر خدمت پاراکلینیک برای نسخه
    Parent: <PrescriptionParaclinicalItem/>
    Children: -
    در این کامپوننت هر یک از خدمت های پاراکلینیک تجویز شده رندر میشود،
    سپس اطلاعات نسخه مانند تعداد خدمت ثبت میشود و اطلاعات آن برای کامپوننت پرنت فرستاده میشود.
*/

type FavoriteParaclinicPrescriptionRowItemResponsiveProps = {
  item: any;
  index: number;
  control: any;
  errors: any;
  handleShowModal:any;
  onFieldsChange: any;
  handleParaclinicalModalSubmit: any;
  deleteParaclinicalItemFromList: any;
  register: any;
  watch: any
  update?: any;
}


const FavoriteParaclinicPrescriptionRowItemResponsive = ({
 item,
 index,
 control,
 errors,
 onFieldsChange,
 handleShowModal,
 handleParaclinicalModalSubmit,
 deleteParaclinicalItemFromList,
 register,
 watch,
 update,
}: FavoriteParaclinicPrescriptionRowItemResponsiveProps) => {

  const [arrowColor, setArrowColor] = useState('black')
  const [fieldValue, setFieldValue] = useState<TAddPrescriptionParaclinicalItemFields>()


  useEffect(() => {
    setFieldValue(item)
  }, [item]);

  /*
    تغییر اطلاعات استیت در کامپوننت پرنت بر اساس اطلاعات تغییر یافته در اینپوت ها.
    @param: e
  */
  const handleFieldChange = (e?: any) => {
    const { name, value } = e?.target;
    const fieldName = name.split('_')[0];
    let fieldValue = {...item, [fieldName]: value};
    // onFieldsChange(name, value, label, index, item);

    update(index, { ...fieldValue})
  };


  return (
    <Accordion.Item eventKey={`medicationItem_${index}`} className={`bg-primary bg-opacity-10 rounded my-4`}>
      <Accordion.Header className='rounded font-14'
                        onClick={() => setArrowColor(arrowColor === 'blue' ? 'black' : 'blue')}
      >
        <div className="accordion-title d-flex justify-content-between align-items-center w-100">
          <div className='w-100 d-flex justify-content-start'>
            <div>
              <div className='p-1 btn btn-gray rounded rounded-3 ms-2'
                   onClick={() => deleteParaclinicalItemFromList(index)}
              >
                <span
                  className='mx-2 font-20 d-flex  align-items-center h-100'>
                  <Icon name='trash'/>
                </span>
              </div>
            </div>
          </div>
          <span className="accordion-icon">
            <AccordionButtonIcon fill={arrowColor}/>
          </span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className={`col-12`}>
          <div className="form-group">
            <TextAreaField
              onChange={handleFieldChange}
              rows={1}
              name={`note_${index}`}
              placeholder="توضیحات خدمت"
              control={control}
              error={errors}
              register={register(`root.${index}.note`)}
              watch={watch(`root.${index}.note`)}
            />
          </div>
        </div>
      </Accordion.Body>
      <div
        className={`border border-dark-subtle rounded mb-2 mx-3 cur-pointer`}
        onClick={() => handleShowModal('addPrescriptionParaclinicalItemModal', {
          index: index,
          item: item,
          handleParaclinicalModalSubmit: handleParaclinicalModalSubmit,
          insurance: 40
        })}
      >
        <div className="d-flex w-100">
          <div className="d-flex w-100 pe-3 my-3">
            <div className="d-flex flex-row-reverse w-100">
              <div className="d-flex justify-content-start ps-1">
                <div className='d-flex justify-content-start align-items-center pl-5'>
                  <div className='ltr text-nowrap'>
                    <span className='fw-bold text-primary font-en ms-1 '> {(index + 1) + '. '} </span>
                    <span
                      className={`rounded rounded-3 bg-primary text-white border border-2 border-primary font-12 text-primary text-nowrap px-2 py-1`}>
                      {item?.service?.type?.name}
                     </span>
                  </div>
                </div>
              </div>
              <span className="d-flex flex-fill font-9 fw-semibold">
                {item?.service.name}
              </span>
            </div>
          </div>
        </div>
        <div className="text-start px-3">
          <span className="font-en font-9">
            {item?.service.enName ? item?.service?.enName : item?.service.name}
          </span>
        </div>
      </div>
    </Accordion.Item>
  )
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(FavoriteParaclinicPrescriptionRowItemResponsive);