import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import {useGetDiagnosisList} from "../../../../../../hook/request/visit/diagnosis";
import BoxLoader from "../../../../general/boxLoader";
import Icon from "../../../../general/icon";
import DiagnosisCardsBox from "../../../../visits/visitDetail/medicalHistory/dataList/diagnosisCardsBox";
import {TDataList} from "../../../../../../types/general";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  visit?: string;
  patient?: string;
}

type DiagnosisListProps = {
  visitData?: string;
  patientId?: string;
  openTab?: string;
}

const DiagnosisList = ({
  visitData,
  patientId,
  openTab,
}: DiagnosisListProps) => {

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    visit: visitData,
    patient: patientId,
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [DiagnosisListData, setDiagnosisListData] = useState<TDataList>({
    data: [],
    count: 0
  });

  // services
  const getDiagnosisListRequest = useGetDiagnosisList(getQueryParams(filterValues), (openTab === 'diagnosis'));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      visit: visitData,
      patient: patientId,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    openTab === "diagnosis" && getDiagnosisListRequest?.data?.data?.result &&
    setDiagnosisListData(getDiagnosisListRequest?.data?.data.result)
  }, [getDiagnosisListRequest.data])


  return (
    <>
      <div className=" shadow-none rounded-0 mx-3">
        {getDiagnosisListRequest.isLoading && <BoxLoader type="cover"/>}
        {
          DiagnosisListData.count === 0 ?
            <div className="card shadow-none border mt-4">
              <div className='bg-gray rounded-top justify-content-between'>
                <div className='d-flex just mx-4 my-3 align-items-center'>
                  <Icon name='clipboard-tick'/>
                  <span className="fw-bold text-primary px-4">
                    هیچ تشخیصی توسط شما برای این بیمار ثبت نشده است.
                  </span>
                </div>
              </div>
            </div>
            :
            <DiagnosisCardsBox dataList={DiagnosisListData} isRequesting={getDiagnosisListRequest.isPending} readOnly={true} />
        }
      </div>
    </>
  );
};

export default DiagnosisList;