import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants";
import Icon from "../../components/general/icon";
import SearchBox from "../../components/general/searchBox";
import DeleteVacationsModal from "../../components/vacations/deleteVacationModal";
import { useWindowSize } from "../../../hook/useWindowSize";
import VacationsTable from "../../components/vacations/dataList/vacationTable";
import VacationsCardsBox from "../../components/vacations/dataList/vacationCardsBox";
import { useGetVacations } from "../../../hook/request/vacations";
import { useMySearchParams } from "../../../hook/useMySearchParams";
import Pagination from "../../components/general/table/pagination";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";
import {useSelector} from "react-redux";

const dataListTitles = [
  "نام پزشک",
  "شروع مرخصی از",
  "پایان مرخصی تا",
  "",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const VacationsList = () => {

  const [width] = useWindowSize();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [vacationsData, setVacationsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getVacationsRequest = useGetVacations(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getVacationsRequest?.data?.data?.result &&
      setVacationsData(getVacationsRequest?.data?.data.result)
  }, [getVacationsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی مرخصی"
            />
          </div>
          {
            (isProvider || findAccessInAccessList('PROVIDER_VACATION_CREATE')) &&
            <div className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <Link to={ROUTES.VACATION_ADD.PATH} className="btn btn-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                افزودن مرخصی
              </Link>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <VacationsCardsBox
                dataList={vacationsData}
                dataListTitles={dataListTitles}
                isRequesting={getVacationsRequest.isPending}
              /> :
              <VacationsTable
                dataList={vacationsData}
                dataListTitles={dataListTitles}
                isRequesting={getVacationsRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...vacationsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      
      <DeleteVacationsModal />
    </>
  );
};

export default VacationsList;