import {useFieldArray, useForm} from "react-hook-form";
import {connect} from "react-redux";
import React, {useEffect, useMemo, useState} from "react";
import {Accordion} from "react-bootstrap";
import {useWindowSize} from "../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import {useGetMedicationDosage, useGetMedicationFrequency} from "../../../../hook/request/medication";
import {useAddDraftPrescriptionMedicationItem} from "../../../../hook/request/visit/draftPrescriptionMedicationItem";
import Icon from "../../../components/general/icon";
import {general} from "../../../../store/action";
import FavoriteMedicationPrescriptionRowItemResponsive
  from "../../../components/prescription/favoritePrescriptions/favoriteMedicationPrescriptionRowItemResponsive";
import AddPrescriptionMedicationItemModal
  from "../../../components/visits/visitDetail/prescription/medication/addMedicationPrescriptionItemModal";
import {TAddPrescriptionMedicationItemFields} from "../../../../types/medicationItem";
import FavoritePrescriptionModal from "../../../components/visits/visitDetail/prescription/favoritePrescriptionModal";
import {useNavigate} from "react-router-dom";
import FavoriteMedicationPrescriptionRowItem
  from "../../../components/prescription/favoritePrescriptions/favoriteMedicationPrescriptionRowItem";

/*
    کامپوننت مربوط به قسمت ثبت دارو برای نسخه
    Parent: <Prescription/>
    Children: <MedicationPrescriptionEmptyRow/>, <MedicationPrescriptionRowItem/>
              <AddPrescriptionMedicationItemModal/>, <FavoriteMedicationItemModal/>,
              <FavoriteMedicationPrescriptionModal/>, <MedicationPrescriptionHistoryModal/>
              <FavoritePrescriptionModal/>
    در این کامپوننت لیستی از دارو های تجویز شده رندر میشود،
    و در صورتی که بیمه شخص بیمه سلامت باشد، ریکوئست های لازم
    زده میشود و چک کد از بیمه دریافت میشود.
*/

type medicalHistoryProps = {
  handleShowModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
}

type TDosageListByShape = {
  defaultId?: number,
  list?: {
    name?: string,
    label?: string,
    value?: number
  }[]
}[]

type PrescriptionMedicationItemFieldArray = {
  root: TAddPrescriptionMedicationItemFields[]
}

const AddFavoriteMedicationItem = ({
 handleShowModal,
}: medicalHistoryProps) => {

  //<editor-fold desc="States">
  const filterValuesInitialState = {
    pageNumber: "0",
    perPage: "999",
  }
  const navigate = useNavigate()
  const [width] = useWindowSize();
  const { getQueryParams } = useMySearchParams();
  const [filterValues] = useState<TFilterValues>(filterValuesInitialState);
  const [medicationFieldValue, setMedicationFieldValue] = useState<PrescriptionMedicationItemFieldArray>({root: []});
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useForm<PrescriptionMedicationItemFieldArray>({
    defaultValues: useMemo(() => medicationFieldValue, [medicationFieldValue]),
  });
  const {fields, append, update, remove} = useFieldArray({
    name: 'root',
    control
  })
  const [medicationItemsValueChangeQueue, setMedicationItemsValueChangeQueue] = useState<any>([]);

  const [medicationFrequencyList, setMedicationFrequencyList] = useState([])
  const [medicationDosageList, setMedicationDosageList] = useState([])
  const [dosageListByShape, setDosageListByShape] = useState<any>([])

  //</editor-fold>

  //<editor-fold desc="Services">
  const getMedicationFrequencyRequest = useGetMedicationFrequency(getQueryParams(filterValues));
  const getMedicationDosageRequest = useGetMedicationDosage(getQueryParams(filterValues));
  const addDraftPrescriptionMedicationItemRequest = useAddDraftPrescriptionMedicationItem({
    onSuccess: () => {
      navigate(-1)
    }
  })
  //</editor-fold>

  //<editor-fold desc="UseEffects">

  /*
    گرفتن لیست تواتر مصرف (فرکانس)
     برای ارسال به کامپوننت فرزند برای استفاده در سلکت
    @Dependency: getMedicationFrequencyRequest
   */
  useEffect(() => {
    if (getMedicationFrequencyRequest?.data?.data?.result) {
      const { data } = getMedicationFrequencyRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id, maxaValue: item?.maxa?.value}
        ))
      setMedicationFrequencyList(_data);
    }
  }, [getMedicationFrequencyRequest.data])

  /*
    گرفتن لیست دستور مصرف‌ (دوز)
     برای ارسال به کامپوننت فرزند برای استفاده در سلکت
    @Dependency: getMedicationDosageRequest
  */
  useEffect(() => {
    if (getMedicationDosageRequest?.data?.data?.result) {
      const { data } = getMedicationDosageRequest.data.data.result,
        _data = data.map((item: any) => (
          {
            id: item?.id,
            name: item?.name,
            faName: item?.faName,
            shape: item?.shape,
            defaultDosage: item?.defaultDosage,
            amount: item?.amount
          }
        ))
      setMedicationDosageList(_data);
    }
  }, [getMedicationDosageRequest.data])

  useEffect(() => {
    let temp: TDosageListByShape = []
    const _data = medicationDosageList.reduce((acc: any[], dosageItem: any) => {
      const shapeId = dosageItem?.shape?.id;
      if (shapeId !== undefined) {
        if (!temp[shapeId]) {
          temp[shapeId] = {list: []};
        }
        temp[shapeId].list?.push({
          name: dosageItem?.name,
          label: dosageItem?.faName,
          value: dosageItem?.id,
        });
        if (dosageItem?.defaultDosage) {
          temp[shapeId].defaultId = dosageItem?.id;
        }
      }
      return acc
    },[]);
    setDosageListByShape(temp)
  }, [medicationDosageList]);

  //</editor-fold>

  /*
    ساخت مدل دارو برای نسخه
    @param: result
    @returns: TAddPrescriptionMedicationItemFields
  */
  const addResultToMedicationDataList = async (item: any) => {
    let prescriptionMedicationItem: TAddPrescriptionMedicationItemFields = {
      item: item?.item ? item.item : item,
      frequency: item?.frequency || 28,
      dosage: item?.dosage || dosageListByShape[item?.shape?.id]?.defaultId || 1,
      itemCount: item?.itemCount || 1,
      brand: item?.brand || 0,
      activeDate: item?.activeDate
    }

    prescriptionMedicationItem = {...prescriptionMedicationItem}
    return prescriptionMedicationItem;
  }

  /*
    بعد از سابمیت کردن مودال اضافه کردن دارو
    @param: fieldValue
    @param: index
  */
  const handleMedicationModalSubmit = async (fieldValue: any, index: number) => {
    let targetIndex = index;
    for (const item of fieldValue) {
      try {
        const result = await addResultToMedicationDataList(item);
        if (targetIndex !== -1) {
          update(targetIndex, result);
          targetIndex = -1
        } else {
          append(result);
        }

        // Move on to the next item
      } catch (error) {
        console.error("Error processing prescription:", error);
      }
    }
  }

  /*
    پاک کردن دارو از لیست تجویز.
    این فانکشن به فرزند فرستاده میشه و اونجا روش کلیک میشه
    @param: index
  */
  const deleteMedicationItemFromList = (index: number) => {
    if (index >= 0 && index < fields.length) {
      // دارو براساس ایندکسش فیلتر میشه
      remove(index)
    }
  }

  /*
    بعد از تغییر مقدار هر اینپوت، استیت اون قسمت تغییر کنه
    و ریکوئست با داده جدید به بیمه سلامت زده بشه.
    @param: name
    @param: value
    @param: index
  */
  const onFieldsChange = (name: string, value: any, label: any, index: number, medItem: any, brand?: any) => {
    // جدا کردن اسم اینپوت از آی دی
    const fieldName = name.split('_')[0];
    let fieldValue = {}
    if(fieldName !== 'brand') {
      if(fieldName === 'dosage' || fieldName === 'frequency'){
        fieldValue = {...medItem, [fieldName]: value, [fieldName + 'Name']: label}
      } else {
        fieldValue = {...medItem, [fieldName]: value}
      }
    } else {
      console.log(value, label)
      fieldValue = {...medItem,
        item: {
          ...medItem.item,
          id: value,
          erx: brand?.erx,
          // support: insurance === ISSUER_TYPE.TAMIN.value ? brand?.taminSupport : brand?.ihioSupport,
        },
        brand: value,
        brandName: label
      }
    }
    update(index, { ...fieldValue})
  }

  /*
    اضافه کردن دارو به لیست علاقه مندی ها.
    این فانکشن به فرزند فرستاده میشه و اونجا روش کلیک میشه
    @param: index
  */
  const addMedicationItemToFavorites = () => {
    const body = {
      item: {id: fields[0]?.item?.id},
      frequency: {id: fields[0]?.frequency},
      dosage: {id: fields[0]?.dosage},
      itemCount: fields[0]?.itemCount,
      drugDescription: fields[0]?.drugDescription
    }
    addDraftPrescriptionMedicationItemRequest.mutate(body)
  }

  const _renderRowItems = (responsive: boolean) => fields.map((item, index) => {
    return (
      responsive ?
        <FavoriteMedicationPrescriptionRowItemResponsive
          item={item}
          index={index}
          insurance={40}
          frequency={medicationFrequencyList}
          dosage={dosageListByShape[item?.item?.shape?.id || 10]?.list}
          control={control}
          errors={errors}
          onFieldsChange={onFieldsChange}
          handleMedicationModalSubmit={handleMedicationModalSubmit}
          deleteMedicationItemFromList={deleteMedicationItemFromList}
          register={register}
          watch={watch}
          update={update}
        /> :
        <FavoriteMedicationPrescriptionRowItem
          item={item}
          index={index}
          insurance={40}
          frequency={medicationFrequencyList}
          dosage={dosageListByShape[item?.item?.shape?.id || 10]?.list}
          control={control}
          errors={errors}
          onFieldsChange={onFieldsChange}
          handleMedicationModalSubmit={handleMedicationModalSubmit}
          deleteMedicationItemFromList={deleteMedicationItemFromList}
          register={register}
          watch={watch}
          update={update}
        />
    )
  })

  return (
    <div className={'card-box'}>
      <div className="">
        {
          fields.length === 0 ?
            <div className="d-flex">
              <div className="card w-100 shadow-none border mb-4">
                <div className='bg-gray rounded-top'>
                  <div className='d-flex just mx-4 my-3 align-items-center justify-content-center'>
                    <Icon name='clipboard-tick'/>
                    <span className="fw-bold text-center text-primary px-4">
                      لطفا یک دارو انتخاب کنید.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            :
            width < 900 ?
              <Accordion className='prescription-items-accordion rounded' alwaysOpen>
                {_renderRowItems(true)}
              </Accordion> :
              _renderRowItems(false)
        }
      </div>
      <div className="d-flex flex-row-reverse">
        { fields.length === 0 &&
          <div
            className="flex-grow-1 align-items-center justify-content-center justify-content-md-start m-md-0">
              <span
                onClick={() => handleShowModal('addPrescriptionMedicationItemModal',
                  {
                    handleMedicationModalSubmit: handleMedicationModalSubmit,
                    insurance: 40,
                    selectOnlyOneItem: true,
                  })}
                className="btn btn-primary justify-content-center rounded d-flex align-items-center font-14 fw-bolder px-4 mb-3">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                {width > 900 ? 'افزودن داروی جدید' : 'داروی جدید'}
              </span>
          </div>
        }
        {
          fields.length !== 0 &&
          <div
            className="d-flex align-items-center justify-content-center justify-content-md-start m-md-0">
              <span
                onClick={addMedicationItemToFavorites}
                className="btn btn-dark rounded d-flex align-items-center px-4 mx-2 mb-3">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="document-add-fill"/></span>
                {width > 900 ? 'افزودن به عنوان قلم پراستفاده' : 'قلم پراستفاده'}

              </span>
          </div>
        }
      </div>
      <AddPrescriptionMedicationItemModal/>
      <FavoritePrescriptionModal/>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(AddFavoriteMedicationItem);