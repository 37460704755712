import React from "react";
import {connect, useSelector} from "react-redux";
import {general} from "../../../../../../store/action";
import Icon from "../../../../general/icon";
import {getCellValue} from "../../../../../../utils/utils";
import {Dispatch} from "redux";
import {isBetween, isPastDay, MtoJFullDateFormat} from "../../../../../../utils/date";
import Tooltip from "../../../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type PatientProgramCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  readonly: boolean;
handleShowModal: any
}

const PatientProgramCardsBoxItem = ({
  item,
  dataListTitles,
  readonly,
  handleShowModal
}: PatientProgramCardsBoxItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const getProgramType = () => {
    let _type = "";
    const isEndedProgram = isPastDay(item?.endDateTime),
      isCurrentProgram = isBetween(new Date() ,item.startDateTime, item.endDateTime),
      isCurrentWithNullToDateProgram = item?.endDateTime == null && isPastDay(item?.startDateTime),
      isFutureProgram = !isPastDay(item.startDateTime);
    if(isEndedProgram) _type = "برنامه پایان یافته";
    else if(isCurrentProgram || isCurrentWithNullToDateProgram) _type = "برنامه جاری";
    else if(isFutureProgram) _type = "برنامه آینده";
    return _type;
  }

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="program">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.program?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="startDateTime">{dataListTitles[1]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.startDateTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="endDateTime">{dataListTitles[2]}</label>
        <span
          className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.endDateTime))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="location">{dataListTitles[3]}</label>
        <span
          className="mr-2">{getCellValue(item?.location)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="programType">{dataListTitles[4]}</label>
        <span className="mr-2">{getProgramType()}</span>
      </div>
      {((!readonly && isProvider) || findAccessInAccessList('EHR_PROGRAM_DELETE')) &&
        <div className="d-flex align-items-center justify-content-between my-3">
          <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[6]}</label>
          <div className="d-flex align-items-center justify-content-end my-3">
            <div className="d-flex align-items-center">
              <Tooltip alt={'حذف'} placement='bottom' className="tooltip-pre-line">
                <span
                  onClick={() => handleShowModal('deletePatientProgramModal', item?.id)}
                  className="d-flex text-red-hover mx-2 font-20 cur-pointer"
                >
                  <Icon name='trash'/>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientProgramCardsBoxItem);