import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {general} from "../../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../../store/selector/general";
import Modal from "../../../../general/modal";
import BoxLoader from "../../../../general/boxLoader";
import Icon from "../../../../general/icon";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import SearchBox from "../../../../general/searchBox";
import {useGetMedicationItems} from "../../../../../../hook/request/medication";
import MedicationItemBox from "./medicationItemBox";
import EmptyBox from "../../../../general/emptyBox";
import {useGetMedicationCategories} from "../../../../../../hook/request/medicationCategory";
import {Nav, Tab} from "react-bootstrap";
import {useWindowSize} from "../../../../../../hook/useWindowSize";

/*
    مودال نمایش و انتخاب اقلام دارویی جهت تجویز کردن.
    Parent: <PrescriptionMedicationItem/>
    Children: <MedicationItemBox/>
    در این کامپوننت لیستی از دارو های قابل تجویز نمایش داده میشوند،
    و با انتخاب هر دارو، اطلاعات آن برای کامپوننت پرنت فرستاده میشود.
*/


type AddPrescriptionMedicationItemModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  category?: string;
  issuerType?: number;
}

type ModalInfoProps = {
  id: string;
  visitId: string;
  insurance: number;
  patientData?: any;
  index?: number;
  item?: any;
  handleMedicationModalSubmit: any;
  fieldValue: any;
  selectOnlyOneItem?: boolean
}

const AddMedicationPrescriptionItemModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: AddPrescriptionMedicationItemModalProps) => {

  //<editor-fold desc="States">
  const [width] = useWindowSize();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    visitId: '',
    insurance: 0,
    patientData: '',
    handleMedicationModalSubmit: null,
    fieldValue: null,
    selectOnlyOneItem: false
  });
  const filterValuesInitialState = {
    pageNumber: "0",
    perPage: "30",
    search: '',
    category: '0',
    issuerType: 0
  }
  const { getQueryParams } = useMySearchParams();
  const [filterValues, setFilterValues] = useState<TFilterValues>(filterValuesInitialState);
  const [fieldValue, setFieldValue] = useState([]);
  const [prescriptionMedicationItemList, setPrescriptionMedicationItemList] = useState([]);
  const [medicationCategoriesData, setMedicationCategoriesData] = useState([]);
  //</editor-fold>

  //<editor-fold desc="Services">
  const getPrescriptionMedicationItemListRequest = useGetMedicationItems(
    getQueryParams(filterValues),modalName === 'addPrescriptionMedicationItemModal');
  const getMedicationCategoriesRequest = useGetMedicationCategories(getQueryParams({page: 0, perPage: 999}), (modalName === 'addPrescriptionMedicationItemModal' && modalInfo.insurance !== 0));
  //</editor-fold>

  //<editor-fold desc="UseEffects">
  useEffect(() => {
    showState && getPrescriptionMedicationItemListRequest.refetch();
  }, [filterValues])

  useEffect(() => {
    modalInfo.insurance && getMedicationCategoriesRequest.refetch();
  }, [modalInfo.insurance])

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'addPrescriptionMedicationItemModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  useEffect(() => {
    setFilterValues({...filterValuesInitialState, search: modalInfo?.item?.item?.molecule, issuerType: modalInfo?.insurance})
  }, [modalInfo]);

  useEffect(() => {
    if (getPrescriptionMedicationItemListRequest?.data?.data?.result) {
      const { data } = getPrescriptionMedicationItemListRequest.data.data.result
      setPrescriptionMedicationItemList(data);
    }
  }, [getPrescriptionMedicationItemListRequest.data])

  useEffect(() => {
    getMedicationCategoriesRequest?.data?.data?.result &&
    setMedicationCategoriesData(getMedicationCategoriesRequest?.data?.data.result.data)
  }, [getMedicationCategoriesRequest.data])

  useEffect(() => {
    if(fieldValue.length > 0) {
      modalInfo?.selectOnlyOneItem && addPrescriptionMedicationItems();
    }
  }, [fieldValue.length])
  //</editor-fold>

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue([])
    setFilterValues(filterValuesInitialState);
    handleHideModal();
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    let _filterValues = {
      ...filterValues,
      pageNumber: "0",
      perPage: filterValues?.perPage,
      search: value
    }
    setFilterValues(_filterValues)
  }

  const addPrescriptionMedicationItems = () => {
    handleSetShowModal();
    modalInfo.handleMedicationModalSubmit(fieldValue, modalInfo?.index ?? -1)
  }

  const _allMedicationItems = prescriptionMedicationItemList.map((item: any, key: number) => {
    return (
      <MedicationItemBox
        key={key}
        searchStr={filterValues?.search}
        item={item}
        setFieldValue={setFieldValue}
        fieldValue={fieldValue}
      />
    )
  })

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
      fullscreen={width <= 900 ? true : 'false'}
    >
      {getPrescriptionMedicationItemListRequest?.isLoading && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              جستجوی دارو
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
            <Icon name='close-square'/>
          </span>
        </div>
        <div className="col-12 px-2">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="نام دارو"
              autoFocus={true}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end px-2 my-2 me-2">
          <Tab.Container
            id="vitals-list-chart-tabs"
            defaultActiveKey="0"
            onSelect={(eventKey) => setFilterValues({...filterValues, category: eventKey || '0'})}
          >
            <Nav variant={"pills"} className='d-flex overflow-x-auto custom-scrollbar prescription-category-nav'>
              <div className='d-flex rounded'>
                {
                  medicationCategoriesData?.map((item: any, key: number) => {
                    return (
                      <Nav.Item key={key}>
                        <Nav.Link eventKey={item?.id} className='text-nowrap font-12 me-1'>{item?.name}</Nav.Link>
                      </Nav.Item>
                    )
                  })
                }
              </div>
            </Nav>
            <Nav variant={"pills"} className='prescription-category-nav'>
              <Nav.Item>
                <Nav.Link eventKey={'0'} className='text-nowrap font-12 me-1'>همه</Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
        <div className='h-500 custom-scrollbar ltr px-2'>
          {_allMedicationItems?.length > 0 ? _allMedicationItems : <EmptyBox/>}
        </div>
        <div className="col-12 d-flex justify-content-end mt-4">
          <div className="d-flex w-100 align-items-stretch form-group px-2">
            <button
              type='submit'
              className="btn btn-primary rounded d-flex w-100 justify-content-center align-items-center"
              onClick={addPrescriptionMedicationItems}
              disabled={fieldValue?.length === 0}
            >
              {fieldValue?.length !== 0 ?
                `افزودن ${fieldValue.length} دارو به نسخه ` :
                'لطفا دارو انتخاب کنید'
              }
            </button>
          </div>
        </div>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMedicationPrescriptionItemModal);