import TableLoader from "../../../../general/table/tableLoader";
import EmptyRow from "../../../../general/table/emptyRow";
import React from "react";
import PatientVitalTableRowItem from "./patientVitalTable_rowItem";

type PatientVitalsTableProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean;
  readonly?: boolean;
}

const PatientVitalsTable = ({
  dataList,
  dataListTitles,
  isRequesting,
  readonly = false
}: PatientVitalsTableProps) => {
  const _dataListTitles = dataListTitles.map((item, i) => <td key={i}>{item}</td>),
    _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
      return <PatientVitalTableRowItem item={item} key={i} readonly={readonly}/>
    });

  return (
    <table>
      <thead>
      <tr className={`${readonly && 'text-black fw-semibold'}`}>
        {_dataListTitles}
      </tr>
      </thead>
      <tbody>
      {isRequesting ?
        <TableLoader colSpan={dataListTitles.length}/> :
        _dataList ? _dataList : <EmptyRow colSpan={dataListTitles.length} content={''}/>
      }
      </tbody>
    </table>
  )
}

export default PatientVitalsTable;
