import { useMutation, useQuery } from "@tanstack/react-query";
import { VacationService } from "../../services"
import { useNavigate } from "react-router-dom";
import { handleResponse, queryClient } from "./setup";
import Noti from "../../view/components/general/noti";
import { ROUTES } from "../../constants";

const useGetVacations = (params: any) => {
  return useQuery({
    queryKey: ["GetVacations", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return VacationService.getVacationsList(params);
    },
  });
}

const useGetVacation = () => {
  return useMutation({
    mutationFn: VacationService.getVacation,
  })
}

const useAddVacation = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: VacationService.addVacation,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.VACATION.PATH);
        }
      })
    },
  })
}

const useDeleteVacation = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: VacationService.deleteVacation,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["GetVacations"] })
          onSuccess();
        }
      })
    },
  })
}

export {
  useGetVacations,
  useGetVacation,
  useAddVacation,
  useDeleteVacation
}
