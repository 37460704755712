import React from "react";
import {connect} from "react-redux";
import {general} from "../../../../store/action";
import Icon from "../../general/icon";
import {getCellValue} from "../../../../utils/utils";
import {Dispatch} from "redux";
import {MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

type DeviceAssignedCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
  handleShowModal: any
}

const DeviceAssignedCardsBoxItem = ({
 item,
 dataListTitles,
 handleShowModal
}: DeviceAssignedCardsBoxItemProps) => {
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.healthUnit?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="account">{dataListTitles[1]}</label>
        <span className="font-en mr-2">{getCellValue(item?.account?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="updateDate">{dataListTitles[2]}</label>
        <span className="mr-2">
          <bdi>{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="actions">{dataListTitles[3]}</label>
        <div className="d-flex align-items-center justify-content-end my-3">
          <div className="d-flex align-items-center">
            {
              findAccessInAccessList('DEVICE_ASSIGNMENT_DELETE') &&
              <Tooltip alt={'حذف'} placement='bottom' className="tooltip-pre-line">
                <span
                  onClick={() => handleShowModal('deleteAssignedDeviceModal', item?.id)}
                  className="d-flex text-red-hover mr-2 font-20 cur-pointer"
                >
                  <Icon name='trash'/>
                </span>
              </Tooltip>
            }
          </div>
        </div>
      </div>

    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(DeviceAssignedCardsBoxItem);