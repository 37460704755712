import * as React from "react"
import { SVGProps } from "react"
const ECG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={34}
    height={33}
    viewBox="0 0 34 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4645 12.4645C12 13.9289 12 16.286 12 21C12 25.714 12 28.0711 13.4645 29.5355C14.9289 31 17.286 31 22 31C26.714 31 29.0711 31 30.5355 29.5355C32 28.0711 32 25.714 32 21C32 16.286 32 13.9289 30.5355 12.4645C29.0711 11 26.714 11 22 11C17.286 11 14.9289 11 13.4645 12.4645ZM25.0994 21.0935C24.9145 20.7916 24.7343 20.4972 24.558 20.2865C24.3708 20.0628 24.0427 19.7552 23.541 19.7753C23.0392 19.7954 22.7369 20.1284 22.5682 20.3663C22.4094 20.5905 22.2533 20.8984 22.0933 21.2141L20.1054 25.1339C20.0303 25.2819 19.9678 25.4051 19.9116 25.511C19.8432 25.4125 19.7665 25.2976 19.6744 25.1595L19.4976 24.8942C19.2942 24.5891 19.1141 24.3189 18.9414 24.1058C18.7541 23.8747 18.5432 23.6683 18.2589 23.5161C17.9746 23.364 17.6859 23.303 17.3897 23.2753C17.1166 23.2499 16.7919 23.2499 16.4253 23.2499L15 23.2499C14.5858 23.2499 14.25 23.5857 14.25 23.9999C14.25 24.4141 14.5858 24.7499 15 24.7499H16.3944C16.8011 24.7499 17.0553 24.7507 17.2505 24.7689C17.4302 24.7856 17.5038 24.8133 17.5511 24.8387C17.5984 24.864 17.6623 24.9099 17.776 25.0501C17.8994 25.2024 18.041 25.4135 18.2666 25.7518L18.4526 26.0309C18.6409 26.3135 18.8261 26.5915 19.0055 26.7898C19.1984 27.003 19.5282 27.2871 20.0153 27.2582C20.5025 27.2292 20.7963 26.9081 20.9626 26.6736C21.1173 26.4554 21.2683 26.1574 21.4218 25.8546L23.4092 21.9356C23.4876 21.7811 23.553 21.6522 23.6119 21.5414C23.6794 21.6472 23.7549 21.7704 23.8454 21.9181L24.4994 22.9859C24.6995 23.3127 24.8766 23.6018 25.0485 23.8299C25.2348 24.0772 25.4477 24.2988 25.7406 24.4629C26.0336 24.627 26.3337 24.6928 26.642 24.7225C26.9263 24.75 27.2653 24.7499 27.6485 24.7499L29 24.7499C29.4142 24.7499 29.75 24.4141 29.75 23.9999C29.75 23.5857 29.4142 23.2499 29 23.2499H27.6805C27.2557 23.2499 26.9897 23.2491 26.786 23.2295C26.5983 23.2114 26.5223 23.1815 26.4737 23.1542C26.4251 23.127 26.36 23.0779 26.2465 22.9272C26.1233 22.7638 25.9837 22.5374 25.7618 22.1751L25.0994 21.0935Z"
      fill="#EBEFFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.46447 3.46447C2 4.92893 2 7.28595 2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12C22 7.28595 22 4.92893 20.5355 3.46447C19.0711 2 16.714 2 12 2C7.28595 2 4.92893 2 3.46447 3.46447ZM15.0994 12.0935C14.9145 11.7916 14.7343 11.4972 14.558 11.2865C14.3708 11.0628 14.0427 10.7552 13.541 10.7753C13.0392 10.7954 12.7369 11.1284 12.5682 11.3663C12.4094 11.5905 12.2533 11.8984 12.0933 12.2141L10.1054 16.1339C10.0303 16.2819 9.96781 16.4051 9.91157 16.511C9.84318 16.4125 9.76649 16.2976 9.67443 16.1595L9.49755 15.8942C9.2942 15.5891 9.11407 15.3189 8.9414 15.1058C8.75414 14.8747 8.54321 14.6683 8.2589 14.5161C7.9746 14.364 7.6859 14.303 7.38972 14.2753C7.11663 14.2499 6.79189 14.2499 6.42526 14.2499L5 14.2499C4.58579 14.2499 4.25 14.5857 4.25 14.9999C4.25 15.4141 4.58579 15.7499 5 15.7499H6.39445C6.80113 15.7499 7.0553 15.7507 7.25047 15.7689C7.43017 15.7856 7.50379 15.8133 7.55112 15.8387C7.59845 15.864 7.66234 15.9099 7.77596 16.0501C7.89936 16.2024 8.04097 16.4135 8.26656 16.7518L8.4526 17.0309C8.64087 17.3135 8.8261 17.5915 9.00554 17.7898C9.1984 18.003 9.52819 18.2871 10.0153 18.2582C10.5025 18.2292 10.7963 17.9081 10.9626 17.6736C11.1173 17.4554 11.2683 17.1574 11.4218 16.8546L13.4092 12.9356C13.4876 12.7811 13.553 12.6522 13.6119 12.5414C13.6794 12.6472 13.7549 12.7704 13.8454 12.9181L14.4994 13.9859C14.6995 14.3127 14.8766 14.6018 15.0485 14.8299C15.2348 15.0772 15.4477 15.2988 15.7406 15.4629C16.0336 15.627 16.3337 15.6928 16.642 15.7225C16.9263 15.75 17.2653 15.7499 17.6485 15.7499L19 15.7499C19.4142 15.7499 19.75 15.4141 19.75 14.9999C19.75 14.5857 19.4142 14.2499 19 14.2499H17.6805C17.2557 14.2499 16.9897 14.2491 16.786 14.2295C16.5983 14.2114 16.5223 14.1815 16.4737 14.1542C16.4251 14.127 16.36 14.0779 16.2465 13.9272C16.1233 13.7638 15.9837 13.5374 15.7618 13.1751L15.0994 12.0935Z"
      fill="#97ABFF"
    />
  </svg>
)
export default ECG
