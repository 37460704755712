import React from "react";
import {connect, useSelector} from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { general } from "../../../../store/action";
import Icon from "../../general/icon";
import { getCellValue } from "../../../../utils/utils";
import {MtoJDateFormat, MtoJFullDateFormat} from "../../../../utils/date";
import Tooltip from "../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

const PatientCardsBoxItem = ({
  item,
  dataListTitles,
}) => {

  const isProvider = useSelector((state) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const navigate = useNavigate();

  return(
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="name">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.firstName)} {getCellValue(item?.lastName)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="admin">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(item?.nationalCode)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="userName">{dataListTitles[2]}</label>
        <span className="font-en mr-2">{getCellValue(item?.passportNo)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="birthDate">{dataListTitles[3]}</label>
        <span className="mr-2">{getCellValue(item?.mobile)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="gender">{dataListTitles[4]}</label>
        <span className="mr-2">{getCellValue(MtoJDateFormat(item?.birthDate))}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="nationalId">{dataListTitles[5]}</label>
        <span className="mr-2">{getCellValue(item?.gender?.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="phoneNumber">{dataListTitles[6]}</label>
        <bdi className="mr-2 word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.updateDate))}</bdi>
      </div>
      <div className="d-flex align-items-center justify-content-end my-3">
        <div className="d-flex align-items-center">
          {
            (isProvider || findAccessInAccessList('PATIENT_EHR_VIEW')) &&
            <Tooltip alt={'پرونده سلامت'} placement='bottom' className="tooltip-pre-line">
              <span
                onClick={() => navigate(generatePath(ROUTES.PATIENT_EHR.PATH, { id: item?.id }))}
                className="d-flex text-hover mx-2 font-18 cur-pointer"
              >
                <Icon name='eye' />
              </span>
            </Tooltip>
          }
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleShowModal: (data, body) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientCardsBoxItem);