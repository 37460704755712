type TableLoaderProps = {
  colSpan: number;
}

const TableLoader = ({
  colSpan
}: TableLoaderProps) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="list-loader"></div>
      </td>
    </tr>
  )
}

export default TableLoader;