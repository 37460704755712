import React, { useEffect, useMemo, useState } from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BoxLoader from "../../../components/general/boxLoader";
import TextField from "../../../components/general/form/textField";
import SelectField from "../../../components/general/form/selectField";
import {ROUTES} from "../../../../constants";
import TextAreaField from "../../../components/general/form/textAreaField";
import {TAddMedicationCategoryFields} from "../../../../types/medicationCategory";
import {
  useAddMedicationCategory,
  useGetMedicationCategories,
  useGetMedicationCategory
} from "../../../../hook/request/medicationCategory";
import {addMedicationCategorySchema} from "../../../../schema/medicationCategory-schema";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const AddMedicationCategory = () => {

  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [categoryFilterValues, setCategoryFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [fieldValue, setFieldValue] = useState<TAddMedicationCategoryFields>({
    name: '',
    description: '',
    parent: '',
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddMedicationCategoryFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addMedicationCategorySchema),
  });
  const [categoriesList, setCategoriesList] = useState([]);

  // services
  const getMedicationCategoriesRequest = useGetMedicationCategories(getQueryParams(categoryFilterValues));
  const getMedicationCategoryRequest = useGetMedicationCategory();
  const addMedicationCategoryRequest = useAddMedicationCategory();

  useEffect(() => {
    id && getMedicationCategoryRequest.mutate({id});
  }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getMedicationCategoryRequest?.data?.data?.result) {
      const { name, description, parent } = getMedicationCategoryRequest.data.data.result,
        _fieldValue = {
          name, description, parent
        };
      setFieldValue(_fieldValue);
    }
  }, [getMedicationCategoryRequest.data])

  useEffect(() => {
    let _CategoryFilterValues = {};
    _CategoryFilterValues = {
      ...categoryFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setCategoryFilterValues(_CategoryFilterValues)
  }, [searchParams]);

  useEffect(() => {
    if (getMedicationCategoriesRequest?.data?.data?.result) {
      const { data } = getMedicationCategoriesRequest.data.data.result,
        _data = data.map((item: any) => (
          { label: item?.name, value: item?.id }
        ))
      setCategoriesList(_data);
    }
  }, [getMedicationCategoriesRequest.data])


  const addMedicationCategorySubmit: SubmitHandler<TAddMedicationCategoryFields> = (data) => {
    const { name, description, parent } = data,
      body = {
        ...(id ? {id} : {}),
        name, description,
        parent: {
          id: parent,
          name: ''
        }
      };
    addMedicationCategoryRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getMedicationCategoryRequest?.isPending ||
          addMedicationCategoryRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(addMedicationCategorySubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات دسته بندی</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">نام</label>
                <TextField
                  name="name"
                  placeholder="نام"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="parent">مجموعه</label>
                <SelectField
                  options={categoriesList}
                  name="parent"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="description">توضیحات</label>
                <TextAreaField
                  name="description"
                  placeholder="توضیاحات"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.MEDICATION_CATEGORY?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                {
                  findAccessInAccessList('BASIC_MEDICATION_CATEGORY') &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    { id ? "ویرایش دسته بندی" : "افزودن دسته بندی" }
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMedicationCategory;