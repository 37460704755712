import * as React from "react"
import { SVGProps } from "react"
const DoctorsAnimated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="animated"
    id="freepik_stories-doctors"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 750 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
    width={250}
    height={250}
  >
    <style>
      {
        "svg#freepik_stories-doctors:not(.animated) .animable {opacity: 0;}svg#freepik_stories-doctors.animated #freepik--background-complete--inject-19 {animation: 1.5s Infinite  linear wind;animation-delay: 0s;}svg#freepik_stories-doctors.animated #freepik--Icons--inject-19 {animation: 1.5s Infinite  linear heartbeat;animation-delay: 0s;}            @keyframes wind {                0% {                    transform: rotate( 0deg );                }                25% {                    transform: rotate( 1deg );                }                75% {                    transform: rotate( -1deg );                }            }                    @keyframes heartbeat {                0% {                    transform: scale(1);                }                10% {                    transform: scale(1.1);                }                30% {                    transform: scale(1);                }                40% {                    transform: scale(1);                }                50% {                    transform: scale(1.1);                }                60% {                    transform: scale(1);                }                100% {                    transform: scale(1);                }            }"
      }
    </style>
    <g
      id="freepik--background-complete--inject-19"
      style={{
        transformOrigin: "378.009px 247.656px 0px",
      }}
      className="animable"
    >
      <path
        d="M620.56,448.33c-24.12-14.77-31.18-44.47-31.89-71.51-.12-4.51-.9-9.23,1-13.31s6.74-7.08,10.89-5.43c3.44,1.37,5.13,5.18,7.19,8.28a26.37,26.37,0,0,0,11.18,9.45c3,1.32,6.56,2,9.29.28,3.76-2.38,3.89-7.77,3.59-12.25q-.82-12.51-1.66-25c-.3-4.48-.59-9.05.55-13.39s4-8.47,8.16-9.92,9.61.69,10.77,5c.49,1.8.23,3.72.47,5.57s1.22,3.85,3,4.27,3.54-1,4.8-2.36c4.35-4.8,7.13-10.81,9.71-16.79s5.07-12.07,9-17.26,9.47-9.44,15.86-10.19,13.47,2.79,15.47,9-1.29,12.85-5,18.2a100.1,100.1,0,0,1-19.62,21.1c-1.36,1.09-2.82,2.27-3.26,4-.79,3,2.21,5.84,5.22,6.64,3.41.91,7,.35,10.53.55s7.39,1.51,8.91,4.73c2.11,4.47-1.43,9.5-5,12.87A85.83,85.83,0,0,1,675.25,377c-3.22,1.41-6.63,2.68-9.14,5.15s-3.88,6.6-2.09,9.66,6,3.82,9.39,3,6.39-2.91,9.61-4.35c6-2.69,14-2.75,18.25,2.33,2.8,3.34,3.33,8.2,2.27,12.45s-3.5,8-6.13,11.48c-9.16,12.17-21.23,22.25-35.21,28.06s-27.13,8-41.64,3.62"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "648.478px 367.192px 0px",
        }}
        id="elmrr5ujyk24c"
        className="animable"
      />
      <path
        d="M626.51,431a402.8,402.8,0,0,1,13.32-44.18q2.07-5.77,4.09-11.4c1.35-3.74,2.69-7.44,4.22-11a203.37,203.37,0,0,1,10-19.87c3.48-6.16,7-11.89,10.34-17.21s6.57-10.23,9.69-14.57a158.34,158.34,0,0,1,16.24-19.38c2.09-2.13,3.76-3.73,4.91-4.8l1.32-1.21a4.59,4.59,0,0,1,.46-.39s-.12.16-.4.45l-1.26,1.27c-1.11,1.11-2.75,2.74-4.8,4.9a168.7,168.7,0,0,0-16,19.5c-3.08,4.35-6.26,9.26-9.58,14.6s-6.81,11.07-10.26,17.23a203.18,203.18,0,0,0-9.9,19.81c-1.52,3.52-2.84,7.2-4.19,10.95s-2.7,7.55-4.08,11.39a412,412,0,0,0-13.36,44.07"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "663.805px 359.075px 0px",
        }}
        id="ela0z6kzoqzdh"
        className="animable"
      />
      <path
        d="M648.37,364.38a9.64,9.64,0,0,1-.55-1.88c-.31-1.22-.7-3-1.15-5.22-.9-4.42-1.94-10.57-3.06-17.35s-2.24-12.91-3.2-17.3c-.48-2.2-.89-4-1.18-5.2a9.39,9.39,0,0,1-.37-1.93,8.73,8.73,0,0,1,.68,1.84c.39,1.2.88,3,1.43,5.15,1.12,4.38,2.34,10.5,3.46,17.3s2.07,12.75,2.82,17.38c.35,2.09.65,3.85.89,5.26A8.77,8.77,0,0,1,648.37,364.38Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "643.615px 339.94px 0px",
        }}
        id="elmz42hn22qw"
        className="animable"
      />
      <path
        d="M705.32,350.15a13.49,13.49,0,0,1-2.29.41c-1.46.22-3.59.53-6.21,1-5.24.89-12.44,2.37-20.3,4.35s-14.91,4.1-20,5.78c-2.53.81-4.56,1.53-6,2a11.42,11.42,0,0,1-2.22.68,13.18,13.18,0,0,1,2.11-1c1.37-.58,3.38-1.38,5.89-2.27,5-1.82,12.06-4,20-6A201.93,201.93,0,0,1,696.73,351c2.64-.39,4.78-.61,6.27-.73A11.33,11.33,0,0,1,705.32,350.15Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "676.81px 357.253px 0px",
        }}
        id="elgck1vx2tklq"
        className="animable"
      />
      <path
        d="M627.15,429.89a3.31,3.31,0,0,1-.44-.67c-.3-.55-.66-1.22-1.11-2-1-1.76-2.27-4.35-3.86-7.56-3.17-6.43-7.28-15.45-11.68-25.46s-8.42-19.07-11.46-25.56L595,360.94l-1-2.09a2.47,2.47,0,0,1-.29-.76,3.41,3.41,0,0,1,.43.68l1.11,2c1,1.77,2.28,4.35,3.87,7.57,3.17,6.43,7.28,15.45,11.68,25.46s8.42,19.06,11.46,25.55l3.62,7.67,1,2.1A2.58,2.58,0,0,1,627.15,429.89Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "610.43px 393.99px 0px",
        }}
        id="elfi02kpndln6"
        className="animable"
      />
      <path
        d="M702.19,400.61a3.07,3.07,0,0,1-.74.36l-2.18.88-8,3.15c-6.79,2.66-16.11,6.45-26.41,10.64s-19.68,7.9-26.52,10.42c-3.42,1.26-6.2,2.25-8.13,2.9l-2.23.73a2.77,2.77,0,0,1-.8.2,3.62,3.62,0,0,1,.74-.36l2.18-.87,8-3.15c6.79-2.66,16.12-6.45,26.42-10.64S684.19,407,691,404.45c3.42-1.27,6.2-2.26,8.13-2.91l2.23-.73A2.85,2.85,0,0,1,702.19,400.61Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "664.685px 415.25px 0px",
        }}
        id="el3o08qlbpem7"
        className="animable"
      />
      <path
        d="M632.54,141.27c-4.12,10.3-10.7,26.95-13.73,34.6a4.4,4.4,0,0,0,2.47,5.7l28.14,11.21a4.39,4.39,0,0,0,5.71-2.46L668.78,156a9.76,9.76,0,0,0-5.46-12.69h0l2-5L646.9,131,645,135.74l-.07,0A9.76,9.76,0,0,0,632.54,141.27Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "643.99px 162.047px 0px",
        }}
        id="elt8uu7n0w3m"
        className="animable"
      />
      <polygon
        points="646.65 131.61 645.74 133.9 663.9 141.9 665.08 138.95 646.65 131.61"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "655.41px 136.755px 0px",
        }}
        id="elok6psv1zark"
        className="animable"
      />
      <g id="elz1xd2fpvbp">
        <path
          d="M647.89,127.27h18.49a3,3,0,0,1,3,3v4.92a1.93,1.93,0,0,1-1.93,1.93H646.83a1.93,1.93,0,0,1-1.93-1.93v-4.92a3,3,0,0,1,3-3Z"
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "657.14px 132.195px 0px",
            transform: "rotate(21.72deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M646.84,133.43a23.62,23.62,0,0,1,1.57-4.64,24,24,0,0,1,2.06-4.46A23.82,23.82,0,0,1,648.9,129,22.32,22.32,0,0,1,646.84,133.43Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "648.655px 128.88px 0px",
        }}
        id="elll51w4pwxu"
        className="animable"
      />
      <path
        d="M649.27,134.4a23.82,23.82,0,0,1,1.57-4.65,22.32,22.32,0,0,1,2.06-4.45,23.82,23.82,0,0,1-1.57,4.65A22.32,22.32,0,0,1,649.27,134.4Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "651.085px 129.85px 0px",
        }}
        id="el0ik49k0ha6zq"
        className="animable"
      />
      <path
        d="M651.7,135.37a23.59,23.59,0,0,1,1.57-4.65,22.59,22.59,0,0,1,2.06-4.46,23.36,23.36,0,0,1-1.57,4.65A24,24,0,0,1,651.7,135.37Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "653.515px 130.815px 0px",
        }}
        id="els3z8dzkkgnk"
        className="animable"
      />
      <path
        d="M654.13,136.34a22.61,22.61,0,0,1,1.57-4.65,22.59,22.59,0,0,1,2.06-4.46,23.49,23.49,0,0,1-1.58,4.65A24,24,0,0,1,654.13,136.34Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "655.945px 131.785px 0px",
        }}
        id="elp56k4jjsan9"
        className="animable"
      />
      <path
        d="M656.56,137.3a23,23,0,0,1,1.57-4.65,23.35,23.35,0,0,1,2-4.45,23,23,0,0,1-1.57,4.65A23.35,23.35,0,0,1,656.56,137.3Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "658.345px 132.75px 0px",
        }}
        id="el6907ddko9nw"
        className="animable"
      />
      <path
        d="M659,138.27a23.49,23.49,0,0,1,1.58-4.65,24,24,0,0,1,2.05-4.46,22.61,22.61,0,0,1-1.57,4.65A22.59,22.59,0,0,1,659,138.27Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "660.815px 133.715px 0px",
        }}
        id="elg04dmyc4pvf"
        className="animable"
      />
      <path
        d="M661.41,139.24a23.36,23.36,0,0,1,1.57-4.65,24,24,0,0,1,2.06-4.46,23.59,23.59,0,0,1-1.57,4.65A22.59,22.59,0,0,1,661.41,139.24Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "663.225px 134.685px 0px",
        }}
        id="elx1orv29cgv"
        className="animable"
      />
      <path
        d="M663.84,140.2a23.62,23.62,0,0,1,1.57-4.64,22.59,22.59,0,0,1,2.06-4.46,23.82,23.82,0,0,1-1.57,4.65A23,23,0,0,1,663.84,140.2Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "665.655px 135.65px 0px",
        }}
        id="el0xcjg3zwdq6a"
        className="animable"
      />
      <g id="elsx6gwl2rqf">
        <rect
          x={631.22}
          y={152.87}
          width={28.42}
          height={18.8}
          rx={1.48}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "645.43px 162.27px 0px",
            transform: "rotate(21.72deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M653.86,174.36a2.78,2.78,0,0,1,.1-.3l.3-.82,1.17-3.09c1-2.67,2.5-6.44,4.28-11l.11.27-23.16-9.17c-.07,0,.64-.27.34-.14h0v0l0,0,0,.06-.05.13-.1.25-.2.5-.4,1c-.27.66-.53,1.32-.79,2-.52,1.31-1,2.6-1.55,3.87l-2.92,7.29-.13-.31,16.56,6.72,4.76,2,1.28.54a4.59,4.59,0,0,1,.45.21l-.42-.14-1.25-.47-4.76-1.82-16.8-6.57-.22-.08.09-.23c.92-2.32,1.88-4.76,2.89-7.3.5-1.27,1-2.56,1.54-3.87.26-.66.52-1.32.79-2l.39-1,.21-.5.1-.25.05-.13,0-.06v0h0l.35-.15L660,159l.19.08-.08.19c-1.88,4.6-3.43,8.38-4.52,11.07l-1.26,3-.33.77Q653.88,174.38,653.86,174.36Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "645.325px 162.085px 0px",
        }}
        id="elah3gclhhlko"
        className="animable"
      />
      <path
        d="M654.36,162.68a70.07,70.07,0,0,1-8-2.89,69.09,69.09,0,0,1-7.77-3.37,68.28,68.28,0,0,1,8,2.89A69.4,69.4,0,0,1,654.36,162.68Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "646.475px 159.55px 0px",
        }}
        id="el64s7p57kdne"
        className="animable"
      />
      <path
        d="M653.3,165.35a67.54,67.54,0,0,1-8-2.89,71.17,71.17,0,0,1-7.77-3.37,69,69,0,0,1,8,2.89A70.12,70.12,0,0,1,653.3,165.35Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "645.415px 162.22px 0px",
        }}
        id="elg3otkef1r2f"
        className="animable"
      />
      <path
        d="M652.28,167.92a66.83,66.83,0,0,1-8-2.89,70.12,70.12,0,0,1-7.77-3.37,67.54,67.54,0,0,1,8,2.89A71.17,71.17,0,0,1,652.28,167.92Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "644.395px 164.79px 0px",
        }}
        id="eleye9gwcbos7"
        className="animable"
      />
      <path
        d="M662.33,142.77a81.26,81.26,0,0,1-8.67-3.17,83.2,83.2,0,0,1-8.49-3.66,79.24,79.24,0,0,1,8.68,3.17A82.37,82.37,0,0,1,662.33,142.77Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "653.75px 139.355px 0px",
        }}
        id="elt5qrs56y0j9"
        className="animable"
      />
      <path
        d="M621.12,174.49a11.54,11.54,0,0,1,.56-1.66c.43-1.13,1-2.63,1.7-4.45,1.45-3.75,3.49-8.91,5.78-14.6,1.15-2.84,2.24-5.55,3.25-8a17.2,17.2,0,0,1,3.65-6.08,7.67,7.67,0,0,1,4.25-2.17,5.72,5.72,0,0,1,1.31,0,1.68,1.68,0,0,1,.44.11,11.51,11.51,0,0,0-1.72.1,7.79,7.79,0,0,0-4,2.23,17.23,17.23,0,0,0-3.47,6c-1,2.48-2.07,5.19-3.21,8-2.28,5.69-4.38,10.84-5.92,14.55l-1.85,4.38A10.31,10.31,0,0,1,621.12,174.49Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "631.59px 155.991px 0px",
        }}
        id="el8o5e3pave6y"
        className="animable"
      />
      <path
        d="M652.74,192.58a6.89,6.89,0,0,1-1.1-.89c-.34-.31-.77-.67-1.28-1.07s-1.06-.89-1.73-1.36a48.72,48.72,0,0,0-4.77-3.16,51.22,51.22,0,0,0-6.29-3,51.78,51.78,0,0,0-6.67-2.11,48.6,48.6,0,0,0-5.63-1c-.81-.12-1.55-.15-2.19-.21s-1.21-.08-1.66-.09a8.5,8.5,0,0,1-1.42-.11,7,7,0,0,1,1.42-.1c.46,0,1,0,1.67,0s1.4,0,2.21.13a44.07,44.07,0,0,1,5.71.88,49.63,49.63,0,0,1,6.76,2.09,48.63,48.63,0,0,1,6.34,3.13,44.75,44.75,0,0,1,4.75,3.28c.65.49,1.2,1,1.69,1.43a16.15,16.15,0,0,1,1.22,1.14A7.63,7.63,0,0,1,652.74,192.58Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "636.37px 186.028px 0px",
        }}
        id="elqdsple05r2a"
        className="animable"
      />
      <g id="eld7fwvauv29d">
        <rect
          x={69.21}
          y={197.43}
          width={90.75}
          height={124.67}
          rx={2.41}
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "114.585px 259.765px 0px",
            transform: "rotate(-25.7deg)",
          }}
          className="animable"
        />
      </g>
      <g id="eldyewy1kt0rm">
        <rect
          x={73.56}
          y={200.25}
          width={80.62}
          height={116.04}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "113.87px 258.27px 0px",
            transform: "rotate(-25.7deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M104.23,200.47a9.86,9.86,0,0,0-10.33-1.08L91.67,195a6.77,6.77,0,0,0-9-3.17h0a6.78,6.78,0,0,0-3.17,9.06l2.23,4.63-1.55.78a9.89,9.89,0,0,0-5.45,8.7l0,2.3,31.63-15.22Zm-16.88.72a3.65,3.65,0,1,1,1.71-4.88A3.65,3.65,0,0,1,87.35,201.19Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "90.545px 204.237px 0px",
        }}
        id="eli0g0aspv0w"
        className="animable"
      />
      <path
        d="M86.43,225.33c.07.13-4.29,2.36-9.73,5s-9.9,4.63-10,4.5,4.29-2.36,9.73-5S86.37,225.2,86.43,225.33Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "76.5646px 230.08px 0px",
        }}
        id="eld08nhh28egt"
        className="animable"
      />
      <path
        d="M77.83,233.56a26.72,26.72,0,0,1-4.63,2.52,26.47,26.47,0,0,1-4.86,2A27,27,0,0,1,73,235.61,27.92,27.92,0,0,1,77.83,233.56Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "73.085px 235.82px 0px",
        }}
        id="el3z9r64sr5jj"
        className="animable"
      />
      <path
        d="M128.56,220.55c.06.12-12.16,6.14-27.3,13.42s-27.46,13.09-27.52,13S85.9,240.79,101,233.5,128.5,220.42,128.56,220.55Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "101.15px 233.759px 0px",
        }}
        id="elgv56g0krwfg"
        className="animable"
      />
      <path
        d="M131.08,225.77c.06.13-12.16,6.14-27.3,13.43s-27.46,13.09-27.52,13,12.15-6.14,27.3-13.43S131,225.64,131.08,225.77Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "103.67px 238.984px 0px",
        }}
        id="elmw0fxakxbqa"
        className="animable"
      />
      <path
        d="M133.59,231c.07.13-12.16,6.14-27.29,13.43s-27.46,13.09-27.53,13,12.16-6.14,27.3-13.43S133.53,230.87,133.59,231Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "106.18px 244.214px 0px",
        }}
        id="el36dxxubtjw"
        className="animable"
      />
      <path
        d="M136.11,236.23c.06.13-12.16,6.14-27.3,13.43s-27.46,13.08-27.52,13,12.16-6.14,27.3-13.42S136.05,236.1,136.11,236.23Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "108.7px 249.444px 0px",
        }}
        id="el44u6jxalmst"
        className="animable"
      />
      <path
        d="M138.63,241.45c.06.13-12.16,6.15-27.3,13.43s-27.46,13.09-27.52,13S96,261.7,111.1,254.41,138.56,241.33,138.63,241.45Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "111.22px 254.665px 0px",
        }}
        id="elc4cfgczfj2d"
        className="animable"
      />
      <path
        d="M141.14,246.68c.06.13-12.16,6.14-27.29,13.43s-27.47,13.09-27.53,13,12.16-6.14,27.3-13.43S141.08,246.55,141.14,246.68Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "113.73px 259.894px 0px",
        }}
        id="el7kn43fjsfm7"
        className="animable"
      />
      <path
        d="M116,264.59a5.06,5.06,0,0,1-1,.61l-2.9,1.49c-2.46,1.23-5.88,2.91-9.66,4.73s-7.23,3.45-9.73,4.6l-3,1.34a4.85,4.85,0,0,1-1.12.42,6.24,6.24,0,0,1,1-.61l2.9-1.48c2.46-1.24,5.88-2.92,9.66-4.74s7.23-3.44,9.73-4.59l3-1.34A5.3,5.3,0,0,1,116,264.59Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "102.295px 271.185px 0px",
        }}
        id="el7f5ifbwo9rc"
        className="animable"
      />
      <path
        d="M124.65,206.35a23.08,23.08,0,0,1-4.28,2.35,22.79,22.79,0,0,1-4.5,1.88,23.08,23.08,0,0,1,4.28-2.35A22.79,22.79,0,0,1,124.65,206.35Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "120.26px 208.465px 0px",
        }}
        id="elr5g6i1vutsa"
        className="animable"
      />
      <path
        d="M109,282a58.76,58.76,0,0,1-7,3.66,60.44,60.44,0,0,1-7.23,3.19,58.76,58.76,0,0,1,7-3.66A60.44,60.44,0,0,1,109,282Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "101.885px 285.425px 0px",
        }}
        id="elxtqz5l4w6kq"
        className="animable"
      />
      <path
        d="M111.78,286.5a60.75,60.75,0,0,1-7.12,3.71,61.44,61.44,0,0,1-7.35,3.25,62.69,62.69,0,0,1,7.12-3.72A62.27,62.27,0,0,1,111.78,286.5Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "104.545px 289.98px 0px",
        }}
        id="eldffmvyuac1g"
        className="animable"
      />
      <path
        d="M150,261.39a13.69,13.69,0,0,1-3.32,1.89,14,14,0,0,1-3.56,1.42,14.57,14.57,0,0,1,3.33-1.89A14.42,14.42,0,0,1,150,261.39Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "146.56px 263.045px 0px",
        }}
        id="elqm8ohc087uc"
        className="animable"
      />
      <path
        d="M152.27,265.55a14.57,14.57,0,0,1-3.33,1.89,14.42,14.42,0,0,1-3.55,1.42,13.69,13.69,0,0,1,3.32-1.89A14,14,0,0,1,152.27,265.55Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "148.83px 267.205px 0px",
        }}
        id="eloq5je2iqr2"
        className="animable"
      />
      <path
        d="M107.54,294.68a13.4,13.4,0,0,1-3.21,1.83,13.5,13.5,0,0,1-3.44,1.37A13.62,13.62,0,0,1,104.1,296,13.38,13.38,0,0,1,107.54,294.68Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "104.215px 296.28px 0px",
        }}
        id="elv8pt88eul6b"
        className="animable"
      />
      <path
        d="M154.9,270.42a7.41,7.41,0,0,1-2.37,1.43,8,8,0,0,1-2.61,1,7.45,7.45,0,0,1,2.38-1.44A7.58,7.58,0,0,1,154.9,270.42Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "152.41px 271.635px 0px",
        }}
        id="elazta6n48xbm"
        className="animable"
      />
      <path
        d="M105.87,309.34a1.75,1.75,0,0,1,.28-.21l.84-.56,3.23-2.07,11.61-7.29.23-.15.1.25a6.59,6.59,0,0,1,.13,4.41,5.38,5.38,0,0,1-3.2,3.46,5.32,5.32,0,0,1-5-.55,4.65,4.65,0,0,1-1.65-4.87,4.31,4.31,0,0,1,1-1.82,3.24,3.24,0,0,1,.38-.36,2,2,0,0,1,.45-.31.76.76,0,0,1,.7,0,1.21,1.21,0,0,1,.4.46,8.72,8.72,0,0,1,1,3.7,8.62,8.62,0,0,1-.57,3.65,5.53,5.53,0,0,1-2.15,2.85,3.19,3.19,0,0,1-3.38-.07,4.86,4.86,0,0,1-2-2.51,11.69,11.69,0,0,1,0-5.65l.06-.38.26.29a7.89,7.89,0,0,1,1.91,4.31,8.48,8.48,0,0,1-.34,3.55,7.56,7.56,0,0,1-1,2c-.16.21-.29.35-.38.45s-.13.15-.14.14a9.31,9.31,0,0,0,1.33-2.66,8.55,8.55,0,0,0,.24-3.45,7.55,7.55,0,0,0-1.89-4.1l.32-.09a11.25,11.25,0,0,0,0,5.44,4.39,4.39,0,0,0,1.83,2.26,2.72,2.72,0,0,0,2.89.05,5.13,5.13,0,0,0,1.94-2.61,8.38,8.38,0,0,0,.52-3.46,8.24,8.24,0,0,0-1-3.47.62.62,0,0,0-.21-.27.22.22,0,0,0-.22,0,2.46,2.46,0,0,0-.65.54,3.76,3.76,0,0,0-.84,1.59,4.14,4.14,0,0,0,1.46,4.33,4.83,4.83,0,0,0,4.46.5,4.9,4.9,0,0,0,2.92-3.14,6.09,6.09,0,0,0-.09-4.09l.33.11-11.71,7.13-3.3,2-.87.5A1.33,1.33,0,0,1,105.87,309.34Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "114.239px 305.561px 0px",
        }}
        id="elfl5oyyyz0ig"
        className="animable"
      />
      <path
        d="M157.79,284.13a3,3,0,0,1-.16.94,4,4,0,0,1-.46,1.06,2.24,2.24,0,0,1-1.25,1,1.55,1.55,0,0,1-1,0,2,2,0,0,1-.84-.65,19,19,0,0,1-1.12-1.9L150.51,280l.45-.1c-.08,2.29-.16,4.79-.26,7.41,0,.63,0,1.24-.07,1.85l0,1-.45-.93c-1.37-2.81-2.64-5.44-3.76-7.72l.42,0a26.63,26.63,0,0,0-1,7c0,1.71.11,2.68.06,2.69a2.31,2.31,0,0,1-.15-.7,19.78,19.78,0,0,1-.17-2,24.39,24.39,0,0,1,.78-7.09l.15-.58.26.54,3.83,7.69-.5.1c0-.6,0-1.22.07-1.85.11-2.62.21-5.12.3-7.41l0-.9.41.79c.88,1.65,1.69,3.19,2.42,4.57a20,20,0,0,0,1.05,1.89,1.34,1.34,0,0,0,1.45.61,2.56,2.56,0,0,0,1.63-1.78A7.54,7.54,0,0,1,157.79,284.13Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "151.677px 285.1px 0px",
        }}
        id="ely2x8txgmwm"
        className="animable"
      />
      <path
        d="M157.69,280.39c.08.11-4.05,3.06-9.22,6.58s-9.43,6.27-9.51,6.15,4-3.06,9.22-6.58S157.61,280.27,157.69,280.39Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "148.325px 286.755px 0px",
        }}
        id="elyy7rydojmzf"
        className="animable"
      />
      <path
        d="M162.76,281.26a78.85,78.85,0,0,1-6.76,6.15,79.62,79.62,0,0,1-7.1,5.76,80.85,80.85,0,0,1,6.76-6.15A79.62,79.62,0,0,1,162.76,281.26Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "155.83px 287.215px 0px",
        }}
        id="el0883d7iltatl"
        className="animable"
      />
      <path
        d="M199.34,65.83h-4.21L186,53v-8.4s-6.51-.4-5.74,10.6-.06,8.19-.06,8.19-13.81.06-15,1.26c-.84.87-1.21,14.59-1.36,22a8.06,8.06,0,0,0,5.71,8.11A5.81,5.81,0,0,0,171,95c3.44,0,21-2.8,21-2.8h7.27Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "181.585px 69.7991px 0px",
        }}
        id="elz17t6ihy6md"
        className="animable"
      />
      <polygon
        points="211.39 63.52 208.8 92.3 201.59 92.3 201.59 64.1 211.39 63.52"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "206.49px 77.91px 0px",
        }}
        id="el6xndofx796u"
        className="animable"
      />
    </g>
    <g
      id="freepik--Icons--inject-19"
      style={{
        transformOrigin: "401.362px 105.385px 0px",
      }}
      className="animable animator-active"
    >
      <path
        d="M524,68.38c-1.08-8.76-8.49-16.93-17.32-16.93-5.21,0-10.16,2.72-13.72,6.52a36.6,36.6,0,0,0-3.49,4.13,35.65,35.65,0,0,0-2.6-4.73c-2.74-4.43-7.06-8.08-12.17-9.1-8.65-1.73-17.52,4.81-20.31,13.19s-.47,17.77,4.12,25.31,12.48,17.39,27,22.28c10-1.29,23.46-10.18,29.44-16.67S525.05,77.13,524,68.38Z"
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "488.673px 78.5204px 0px",
        }}
        id="elkyd6obeth4"
        className="animable"
      />
      <path
        d="M520.78,84.1a1.29,1.29,0,0,1-.29,0l-.85-.06-3.27-.27-12-1.1.22-.13c-1.45,3.77-3.14,8.19-5,13l-.3.78-.2-.82c-1.53-6.32-3.23-13.41-5-20.88-.55-2.31-1.1-4.59-1.63-6.81l.51.1-9.16,12.6-.09.12-.15,0-21.08-2.2-6-.66-1.58-.2a2.13,2.13,0,0,1-.54-.1,2.85,2.85,0,0,1,.55,0l1.58.11,6,.52,21.1,2-.23.1,9.12-12.62.36-.51.14.61c.54,2.22,1.08,4.49,1.64,6.81l5,20.89-.5,0c1.89-4.83,3.62-9.24,5.1-13l.05-.15.16,0,12,1.28,3.25.38.85.11A1,1,0,0,1,520.78,84.1Z"
        style={{
          fill: "rgb(250, 250, 250)",
          transformOrigin: "487.575px 81.695px 0px",
        }}
        id="eln6cwrdgopf"
        className="animable"
      />
      <rect
        x={300.24}
        y={123.05}
        width={6.6}
        height={29.59}
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "303.54px 137.845px 0px",
        }}
        id="eldq5jbu7q8ct"
        className="animable"
      />
      <g id="eli3d59r68naq">
        <rect
          x={300.24}
          y={123.05}
          width={6.6}
          height={29.59}
          style={{
            fill: "rgb(43, 178, 161)",
            transformOrigin: "303.54px 137.845px 0px",
            transform: "rotate(-90deg)",
          }}
          className="animable"
        />
      </g>
      <path
        d="M328.11,137.92a3.35,3.35,0,0,1-.05-.4c0-.29-.05-.68-.08-1.17,0-.26,0-.55-.06-.86s-.1-.66-.16-1a17.67,17.67,0,0,0-.52-2.54,23.5,23.5,0,0,0-2.74-6.52A24.43,24.43,0,0,0,295.13,115a24.91,24.91,0,0,0-3.19,1.4,25.77,25.77,0,0,0-3,1.93,27.78,27.78,0,0,0-2.77,2.35,28.36,28.36,0,0,0-2.39,2.83,23.86,23.86,0,0,0-3.49,6.72,24.19,24.19,0,0,0,0,15.41,23.72,23.72,0,0,0,3.49,6.72,26.26,26.26,0,0,0,2.39,2.83,26.61,26.61,0,0,0,2.77,2.36,25.77,25.77,0,0,0,3,1.93,26.39,26.39,0,0,0,3.19,1.4,24.51,24.51,0,0,0,29.37-10.42,23.42,23.42,0,0,0,2.74-6.53,17.29,17.29,0,0,0,.52-2.53c.06-.38.13-.72.16-1s0-.6.06-.86c0-.5.06-.88.08-1.17a3,3,0,0,1,.05-.4,2.38,2.38,0,0,1,0,.4c0,.29,0,.68,0,1.17l0,.87c0,.32-.09.66-.13,1a18.45,18.45,0,0,1-.48,2.56,23.54,23.54,0,0,1-2.69,6.63A24.85,24.85,0,0,1,308,162.36,25,25,0,0,1,295,161.31a27.35,27.35,0,0,1-3.25-1.42,27.49,27.49,0,0,1-5.89-4.36,27.08,27.08,0,0,1-2.44-2.88,23.88,23.88,0,0,1-3.57-6.87,26.53,26.53,0,0,1-1.29-7.87,26.84,26.84,0,0,1,1.29-7.86,24.08,24.08,0,0,1,3.57-6.86,28.23,28.23,0,0,1,2.44-2.89,28.87,28.87,0,0,1,2.83-2.39A24.85,24.85,0,0,1,308,113.48a24.85,24.85,0,0,1,16.71,11.77,23.61,23.61,0,0,1,2.69,6.62,18.84,18.84,0,0,1,.48,2.57c0,.37.11.72.13,1l0,.86c0,.5,0,.89,0,1.18A2.47,2.47,0,0,1,328.11,137.92Z"
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "303.339px 137.918px 0px",
        }}
        id="el2bwmmv01d62"
        className="animable"
      />
    </g>
    <g
      id="freepik--character-3--inject-19"
      style={{
        transformOrigin: "562.1px 281.328px 0px",
      }}
      className="animable"
    >
      <path
        d="M632.65,341.71s29.26-7,30.23-8.21l-24.07-62a45.35,45.35,0,0,0-28.07-27.36,79.53,79.53,0,0,0-28.42-4.39l-40.63-2.15a80.47,80.47,0,0,0-32.06,8.87,64.69,64.69,0,0,0-30.09,33.17l-18.22,45.51,46,9.61,1.75,48.9-6.93,67.47H629l-.88-106.94h0Z"
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "562.1px 344.365px 0px",
        }}
        id="elshlz1ne6ffj"
        className="animable"
      />
      <g id="elezikvh8a1en">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "640.815px 330.666px 0px",
          }}
          className="animable"
        >
          <path
            d="M657.87,325.4c0,.14-7.55,2.62-17,5.53s-17.07,5.17-17.11,5,7.54-2.62,17-5.54S657.83,325.26,657.87,325.4Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "640.815px 330.666px 0px",
            }}
            id="ellh2wsdnq8rc"
            className="animable"
          />
        </g>
      </g>
      <path
        d="M525.1,281.82a6.76,6.76,0,0,1-.78-1.07c-.46-.7-1.17-1.7-2.09-2.9a48.33,48.33,0,0,0-3.45-4,53,53,0,0,0-4.76-4.4,55.54,55.54,0,0,0-5.31-3.74,51.12,51.12,0,0,0-4.67-2.51c-1.36-.65-2.49-1.13-3.28-1.44a5.72,5.72,0,0,1-1.21-.54,7.68,7.68,0,0,1,1.28.35,35.65,35.65,0,0,1,3.36,1.29,46.35,46.35,0,0,1,4.77,2.44,51.17,51.17,0,0,1,5.38,3.74,48.81,48.81,0,0,1,4.79,4.48,44.63,44.63,0,0,1,3.39,4.14,31.66,31.66,0,0,1,2,3A6,6,0,0,1,525.1,281.82Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "512.325px 271.52px 0px",
        }}
        id="elvx62ot9ohkn"
        className="animable"
      />
      <path
        d="M519.11,296.62c.14,0-1.28,14.94-3.17,33.34s-3.54,33.31-3.68,33.29,1.27-14.94,3.17-33.34S519,296.6,519.11,296.62Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "515.685px 329.935px 0px",
        }}
        id="el0n0hkr3figw"
        className="animable"
      />
      <path
        d="M619,269a28.4,28.4,0,0,1-.65-3,56.32,56.32,0,0,0-2-7.16,55.36,55.36,0,0,0-3-6.82,28.9,28.9,0,0,1-1.42-2.75s.22.21.53.65.73,1.1,1.22,1.92a42.5,42.5,0,0,1,3.16,6.82,41.55,41.55,0,0,1,1.89,7.27c.15.95.24,1.72.28,2.26A2.91,2.91,0,0,1,619,269Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "615.482px 259.135px 0px",
        }}
        id="elpxh2pbvr1ck"
        className="animable"
      />
      <path
        d="M621.56,321.15a8.88,8.88,0,0,1-.24-1.47c-.13-.94-.28-2.32-.45-4-.33-3.4-.68-8.12-.86-13.33s-.17-9.95-.08-13.37c.05-1.71.1-3.09.16-4a7.38,7.38,0,0,1,.15-1.48,9.64,9.64,0,0,1,.06,1.48V289c0,3.41.05,8.13.23,13.34s.47,9.91.71,13.32c.11,1.63.2,3,.28,4A7.66,7.66,0,0,1,621.56,321.15Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "620.723px 302.325px 0px",
        }}
        id="elijnsgwbdcde"
        className="animable"
      />
      <path
        d="M560.12,312.42a208.48,208.48,0,0,1-32-.6V316h32Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "544.12px 313.91px 0px",
        }}
        id="elp0wcjzfhrmf"
        className="animable"
      />
      <g id="elixxvnx3j3ho">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "557.75px 259.65px 0px",
          }}
          className="animable"
        >
          <path
            d="M592,243.28a2.73,2.73,0,0,1,.13.63l.28,1.84c.2,1.62.48,4,.71,7,.48,6,.9,14.55,1,24.64v.38l-.35-.16c-6.38-3.11-13.53-6.49-21-10.21l-36.37-18.2-11-5.57-3-1.53-.76-.42a1.11,1.11,0,0,1-.26-.15,1.09,1.09,0,0,1,.28.11l.79.36,3,1.45,11.06,5.42L573,266.93c7.44,3.72,14.58,7.11,21,10.24l-.34.21c-.11-10.07-.46-18.61-.86-24.62-.18-3-.42-5.37-.57-7-.09-.78-.16-1.4-.21-1.85A6.07,6.07,0,0,1,592,243.28Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "557.75px 259.65px 0px",
            }}
            id="el9l8wfx4qcca"
            className="animable"
          />
        </g>
      </g>
      <g id="eli7vo4zlayna">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "489.96px 323.466px 0px",
          }}
          className="animable"
        >
          <path
            d="M515.55,329.66a1.81,1.81,0,0,1-.53-.09l-1.49-.35-5.47-1.39c-4.62-1.19-11-2.8-18.06-4.51s-13.48-3.19-18.13-4.25l-5.5-1.26-1.49-.36a2.51,2.51,0,0,1-.51-.16,1.94,1.94,0,0,1,.53,0l1.51.27c1.31.24,3.2.62,5.54,1.1,4.67,1,11.1,2.39,18.17,4.1s13.44,3.39,18,4.66c2.3.64,4.16,1.17,5.43,1.55l1.47.45A2.64,2.64,0,0,1,515.55,329.66Z"
            style={{
              fill: "rgb(255, 255, 255)",
              transformOrigin: "489.96px 323.466px 0px",
            }}
            id="elbha2ufd4f5i"
            className="animable"
          />
        </g>
      </g>
      <path
        d="M586.37,235.71c0-.15,2.75,1.29,5.4,5.22s5.06,10,6.43,17a69.93,69.93,0,0,1,1.17,10c0,1.54.07,3,0,4.37s-.09,2.64-.21,3.8-.24,2.19-.34,3.12-.28,1.69-.39,2.33a7.1,7.1,0,0,1-.48,2,7.62,7.62,0,0,1,.08-2l.21-2.34c.06-.93.12-2,.18-3.11s0-2.41.09-3.76-.07-2.8-.12-4.31a78.05,78.05,0,0,0-1.23-9.8,53.34,53.34,0,0,0-6-16.77,23,23,0,0,0-3.33-4.24A19.34,19.34,0,0,1,586.37,235.71Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "592.886px 259.625px 0px",
        }}
        id="ell9aues8ued"
        className="animable"
      />
      <path
        d="M540.9,236a21.59,21.59,0,0,1-2.11,3,42.85,42.85,0,0,0-4.71,8.8,59.9,59.9,0,0,0-3.49,14.37c-.73,5.54-1,11.74-1.45,18.23s-.35,12.67-.36,18.3,0,10.71,0,15-.11,7.73-.23,10.12a17.11,17.11,0,0,1-.37,3.7,17.42,17.42,0,0,1-.24-3.71c-.07-2.43-.15-5.87-.26-10.1s-.25-9.34-.33-15-.1-11.86.24-18.4.76-12.71,1.58-18.34a57.36,57.36,0,0,1,3.9-14.6,36.65,36.65,0,0,1,5.25-8.73A13.57,13.57,0,0,1,540.9,236Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "534.1px 281.76px 0px",
        }}
        id="el4pvxt7ou9wv"
        className="animable"
      />
      <path
        d="M608.14,319.59a19,19,0,0,1-.2-3.3c-.06-2.13-.11-5.21-.15-9,0-1.91,0-4-.06-6.22s0-4.64-.13-7.1a11.75,11.75,0,0,0-2.55-7,9.64,9.64,0,0,0-7-3.34,11,11,0,0,0-7.37,2.86,12.7,12.7,0,0,0-3.72,6.49c-.62,2.38-.88,4.77-1.25,7l-1,6.14c-.6,3.75-1.11,6.79-1.48,8.88a20.11,20.11,0,0,1-.68,3.24,18.69,18.69,0,0,1,.28-3.3c.25-2.11.64-5.16,1.15-8.93.26-1.89.55-3.95.85-6.16s.56-4.59,1.18-7.09a13.5,13.5,0,0,1,4-7A12,12,0,0,1,598,282.6a10.6,10.6,0,0,1,7.8,3.71,12.6,12.6,0,0,1,2.74,7.58c.15,2.56,0,4.93,0,7.17s-.05,4.32-.08,6.22c0,3.8-.11,6.88-.18,9A20.33,20.33,0,0,1,608.14,319.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "595.578px 301.095px 0px",
        }}
        id="ela0ysiv6vyo"
        className="animable"
      />
      <path
        d="M598.49,273.87c-.33,3.5-1.45,7.43-3.88,10a14.06,14.06,0,0,1,6.45.12c-1.52-3-1.76-6.7-1.72-10.09"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "597.835px 278.93px 0px",
        }}
        id="el6dtzogqyfoe"
        className="animable"
      />
      <path
        d="M608.17,296.62c-.19,0-.3-1.68-1.58-4a12.44,12.44,0,0,0-2.91-3.48,10.8,10.8,0,0,0-4.92-2.34,10.5,10.5,0,0,0-5.42.54,11.83,11.83,0,0,0-3.87,2.34c-1.94,1.76-2.58,3.36-2.75,3.27s.3-1.77,2.24-3.8a11.64,11.64,0,0,1,4-2.7,11,11,0,0,1,5.94-.68,11.2,11.2,0,0,1,5.36,2.64,11.94,11.94,0,0,1,2.94,3.87C608.43,294.84,608.26,296.63,608.17,296.62Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "597.46px 291.124px 0px",
        }}
        id="el2rkfdcobrpa"
        className="animable"
      />
      <path
        d="M634.93,409.9c6,0,11.28-4.29,14.08-9.54s3.52-11.36,3.79-17.31a140.51,140.51,0,0,0-5.72-46.7L621,340.9l7.11,70.29Z"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "636.975px 373.77px 0px",
        }}
        id="elisnmn9nox1"
        className="animable"
      />
      <polygon
        points="529.37 418.65 614.35 415.56 634.96 315.98 545.45 320.95 529.37 418.65"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "582.165px 367.315px 0px",
        }}
        id="el3puhfakuxc9"
        className="animable"
      />
      <path
        d="M573.11,319.24l1.28-2.54,6.78-.49s1.72-4.45,11.42-4.91c8.68-.4,9,4,9,4h4.68v2.13Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "589.69px 315.257px 0px",
        }}
        id="eli47avuqsm7s"
        className="animable"
      />
      <path
        d="M620.49,386.56l6.44-1.87a7.86,7.86,0,0,1,3.73-.17h0a7.86,7.86,0,0,1,4,2.11c2.13,2.1,5.57,5.57,6.06,6.61.72,1.51.72,4.13,0,5.09s1.58,5.93,1.29,7.58a10.32,10.32,0,0,1-1.29,4c-.58.69,0,3.05-1.44,4.83a23.85,23.85,0,0,1-2.73,2.88s-17.7.83-19.42,0-4.75-4-4.75-4-1.58-2.89.86-3.17l-10.07-3.85a2.4,2.4,0,0,1-1.46-3,1.68,1.68,0,0,1,.89-1s-4.52-1.43-5.57-1.89c-2.09-.92-2.88-5.78,1.45-5.16,2.28.33,5.37,1,5.37,1s-1.65-2.68.66-4.28c1.4-1,7.7.21,7.7.21l8.28-5.91Z"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "618.688px 401.18px 0px",
        }}
        id="eli9gmhcvaov"
        className="animable"
      />
      <path
        d="M622.73,413.12a14.89,14.89,0,0,1-5.23-.78,13,13,0,0,1-4.82-2.18c.07-.12,2.18.91,5,1.68S622.75,413,622.73,413.12Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "617.705px 411.639px 0px",
        }}
        id="ela8ckk7x5kve"
        className="animable"
      />
      <path
        d="M618.88,406.83a68.26,68.26,0,0,1-8.21-1.83,65.84,65.84,0,0,1-8.08-2.33,136.92,136.92,0,0,1,16.29,4.16Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "610.735px 404.75px 0px",
        }}
        id="el0wy7b65qkmd"
        className="animable"
      />
      <path
        d="M621,400.57a155.33,155.33,0,0,1-17.56-4.11A155.33,155.33,0,0,1,621,400.57Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "612.22px 398.515px 0px",
        }}
        id="elx35s23ca2um"
        className="animable"
      />
      <path
        d="M627.53,392.51a11.58,11.58,0,0,1-2.27.73l-2.5.64-1.48.37a5.65,5.65,0,0,1-1.7.16,9.43,9.43,0,0,1-1.66-.27l-1.49-.34-2.5-.6a11,11,0,0,1-2.29-.69,10.73,10.73,0,0,1,2.36.33l2.54.49a30,30,0,0,0,3.07.56,11.22,11.22,0,0,0,3-.47l2.53-.54A11.12,11.12,0,0,1,627.53,392.51Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "619.585px 393.465px 0px",
        }}
        id="el4dcv56kz2h9"
        className="animable"
      />
      <path
        d="M628.66,395.9c.06,0,.3.18.49.66a2.93,2.93,0,0,1,.07,2,3,3,0,0,1-1.18,1.59c-.43.28-.75.32-.78.26s1-.68,1.46-2A5.56,5.56,0,0,0,628.66,395.9Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "628.311px 398.167px 0px",
        }}
        id="elliq2gj0306"
        className="animable"
      />
      <path
        d="M628.3,402.74c.12-.06.52.65.46,1.62s-.52,1.64-.64,1.56.08-.74.12-1.59S628.15,402.79,628.3,402.74Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "628.424px 404.331px 0px",
        }}
        id="el1707k10zu5v"
        className="animable"
      />
      <path
        d="M628.4,410.16c.13-.05.4.43.27,1.06s-.55,1-.66.87.06-.48.15-1S628.26,410.2,628.4,410.16Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "628.339px 411.136px 0px",
        }}
        id="ell34q31v7won"
        className="animable"
      />
      <path
        d="M630.33,392.1a5.46,5.46,0,0,1-1.7-.08l-1.82-.24a7.1,7.1,0,0,0-2.17-.11,5.41,5.41,0,0,0-1.92.87c-.56.37-1,.79-1.51,1.06a2.87,2.87,0,0,1-1.63.56c0-.09.61-.26,1.43-.87a8.71,8.71,0,0,1,3.56-2.13,6.26,6.26,0,0,1,1.22,0c.41,0,.74.12,1.09.18l1.81.34A6,6,0,0,1,630.33,392.1Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "624.955px 392.645px 0px",
        }}
        id="elyjnvkhats5p"
        className="animable"
      />
      <path
        d="M640.55,410.23A8.72,8.72,0,0,1,641,409a8.68,8.68,0,0,0,.33-6c-.2-.88-.61-1.76-.83-2.81a8.81,8.81,0,0,1,.22-3.27,5.8,5.8,0,0,0-.58-3.21,15,15,0,0,0-1.84-2.85c-.69-.87-1.36-1.72-2-2.48a11.86,11.86,0,0,0-4.19-3.27,6.74,6.74,0,0,0-3.52-.54c-.84.12-1.28.34-1.3.3s.1-.08.31-.19a4.91,4.91,0,0,1,1-.31,6.75,6.75,0,0,1,3.69.39,11.89,11.89,0,0,1,4.4,3.3c.7.77,1.38,1.61,2.08,2.48a15.74,15.74,0,0,1,1.91,3,6.21,6.21,0,0,1,.61,3.5,9,9,0,0,0-.25,3.11c.19,1,.59,1.89.78,2.79a10,10,0,0,1,.33,2.57,9.16,9.16,0,0,1-.89,3.6A5,5,0,0,1,640.55,410.23Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "634.719px 397.25px 0px",
        }}
        id="el34d1baim9ht"
        className="animable"
      />
      <path
        d="M581.06,361l-69.77,11.08,3.1-36.41-38.8-7.5-4.72,53.91-.82,12.36a24.15,24.15,0,0,0,10.13,21.28h0A24.17,24.17,0,0,0,501.6,419l15-4.89,73.12-26"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "529.859px 374.172px 0px",
        }}
        id="eljs1ri88rikr"
        className="animable"
      />
      <path
        d="M623.28,353.47a2,2,0,0,0-2-2.33c-3.93,0-14.55,0-18.3,1.11-4.64,1.31-21.93,8.73-21.93,8.73l7.33,27,26.53.28s9.69-4.45,9.81-6.38a31.07,31.07,0,0,0-.24-3.61l2.93-1.57c4.36-2.76,1.56-8.07,1.56-8.07s3-1.68,3.16-4.72a4.72,4.72,0,0,0-2.34-4.47s1.2-4.28-1-5.43-6,.32-6,.32A1.55,1.55,0,0,0,623.28,353.47Z"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "606.598px 369.7px 0px",
        }}
        id="el2y89fgqrr0g"
        className="animable"
      />
      <path
        d="M624.81,353.91a2.74,2.74,0,0,1-.82.29c-.53.15-1.32.32-2.29.49-2,.35-4.66.64-7.65,1a62,62,0,0,0-7.58,1.16c-1.91.44-3,1-3,1a2.84,2.84,0,0,1,.73-.47,12.87,12.87,0,0,1,2.2-.85,46.84,46.84,0,0,1,7.61-1.32c3-.32,5.71-.55,7.65-.8S624.78,353.84,624.81,353.91Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "614.14px 355.875px 0px",
        }}
        id="el0swe4nphs3s"
        className="animable"
      />
      <path
        d="M629.8,359.33a6.38,6.38,0,0,1-1.87.95,29.74,29.74,0,0,1-4.83,1.41c-1.93.4-3.68.66-5,.83a8.6,8.6,0,0,1-2.07.16c0-.17,3.1-.73,6.92-1.5a49.63,49.63,0,0,0,4.82-1.24A11.89,11.89,0,0,1,629.8,359.33Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "622.915px 361.009px 0px",
        }}
        id="el6izlnkx8r04"
        className="animable"
      />
      <path
        d="M629,368.73a28.77,28.77,0,0,1-11.64,2.64c0-.16,2.64-.49,5.84-1.22S629,368.58,629,368.73Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "623.18px 370.041px 0px",
        }}
        id="elvcx61jpgy1"
        className="animable"
      />
      <path
        d="M627.42,376.75a20,20,0,0,1-9,2.17,731.72,731.72,0,0,0,9-2.17Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "622.92px 377.835px 0px",
        }}
        id="elm9oreqnc0o8"
        className="animable"
      />
      <path
        d="M613.58,372c0,.06-.27.23-.81.28a3,3,0,0,1-2-.55,3.07,3.07,0,0,1-1.19-1.71c-.13-.53-.06-.86,0-.86s.28,1.29,1.48,2.14A5.5,5.5,0,0,0,613.58,372Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "611.543px 370.725px 0px",
        }}
        id="elcamrt0qjusj"
        className="animable"
      />
      <path
        d="M613,380.34c0,.14-1,.2-1.95-.61s-1-1.87-.83-1.86.41.8,1.18,1.47S613,380.19,613,380.34Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "611.572px 379.147px 0px",
        }}
        id="elj56fc4gp21"
        className="animable"
      />
      <path
        d="M610,363.31c.06.14-.67.37-1.35-.15s-.66-1.28-.51-1.26.32.49.82.85S609.93,363.17,610,363.31Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "609.029px 362.692px 0px",
        }}
        id="el77u5b0mfhix"
        className="animable"
      />
      <path
        d="M581.06,361l.21-.12.63-.29,2.46-1.09c2.16-.93,5.29-2.28,9.26-3.91,2-.8,4.16-1.69,6.56-2.58a24.83,24.83,0,0,1,3.8-1.18,40.21,40.21,0,0,1,4.22-.52c2.9-.23,6-.33,9.18-.37l2.43,0h1.24a2.63,2.63,0,0,1,1.32.28,2.12,2.12,0,0,1,1.13,2.41.74.74,0,0,1-.3.34l-.3.19-.6.28a9.84,9.84,0,0,1-1.26.44.26.26,0,0,1-.31-.18.25.25,0,0,1,.18-.31,27.26,27.26,0,0,1,3.3-.74,12,12,0,0,1,3.5-.12,3.22,3.22,0,0,1,1.72.74,3.14,3.14,0,0,1,.85,1.69,8.82,8.82,0,0,1-.23,3.65l-.13-.31a4.91,4.91,0,0,1,1.47,1.3,5.21,5.21,0,0,1,.76,4.73,7,7,0,0,1-3,3.63l.1-.35a8.27,8.27,0,0,1,.84,4.62,5.31,5.31,0,0,1-2.5,3.78h0l-2.92,1.57.13-.25c.07.7.14,1.4.19,2.09,0,.34,0,.69.05,1,0,.17,0,.35,0,.53a1.38,1.38,0,0,1-.21.57,6.4,6.4,0,0,1-1.51,1.44c-.54.4-1.1.76-1.65,1.1a72.38,72.38,0,0,1-6.59,3.45l-.09,0-19.31-.3-5.34-.11-1.4-.05a2.2,2.2,0,0,1-.48,0,2.49,2.49,0,0,1,.48,0l1.4,0h5.34l19.31.11-.09,0a70.49,70.49,0,0,0,6.53-3.46c.54-.33,1.09-.69,1.61-1.08a5.76,5.76,0,0,0,1.38-1.31.86.86,0,0,0,.15-.35v-.49c0-.34,0-.68,0-1-.05-.68-.12-1.37-.2-2.06a.23.23,0,0,1,.13-.24l2.92-1.58h0a4.81,4.81,0,0,0,2.27-3.43,7.85,7.85,0,0,0-.8-4.31.26.26,0,0,1,.11-.34,6.52,6.52,0,0,0,2.8-3.34,4.71,4.71,0,0,0-.69-4.26,4.31,4.31,0,0,0-1.29-1.14.27.27,0,0,1-.13-.3,8.42,8.42,0,0,0,.22-3.41,2.26,2.26,0,0,0-2.14-2,11.36,11.36,0,0,0-3.34.12,24.9,24.9,0,0,0-3.24.71l-.13-.49a8.63,8.63,0,0,0,1.18-.4c.19-.08.39-.17.56-.26s.35-.27.41-.25a2.13,2.13,0,0,0-.16-1.05,1.84,1.84,0,0,0-.77-.77,2.08,2.08,0,0,0-1.09-.22h-3.66c-3.2,0-6.27.12-9.16.33a41.41,41.41,0,0,0-4.17.49,26,26,0,0,0-3.75,1.14c-2.39.87-4.58,1.74-6.57,2.52l-9.31,3.77-2.49,1-.65.26Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "606.736px 369.723px 0px",
        }}
        id="elem3z20c7oum"
        className="animable"
      />
      <path
        d="M511.57,372a27.55,27.55,0,0,0-5.15,4.45,27.13,27.13,0,0,0-3.44,5.88,5.64,5.64,0,0,1,.52-2,15.72,15.72,0,0,1,6.23-7.49A5.79,5.79,0,0,1,511.57,372Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "507.275px 377.165px 0px",
        }}
        id="elnwtp8hr45ip"
        className="animable"
      />
      <path
        d="M602.68,156.25a35.24,35.24,0,0,0-35.06-32.48h-1.83c-19.68,1.11-29.74,18.44-28.08,38.09l3.39,78.75a3.57,3.57,0,0,0,2,3.06l44.18,20.73L586,223.07s18.93-2.9,19.2-21.82C605.28,192.12,604,173.28,602.68,156.25Z"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "571.368px 194.085px 0px",
        }}
        id="elmz3fn6w1gno"
        className="animable"
      />
      <path
        d="M586.11,223a45.82,45.82,0,0,1-24.77-7.3s5.8,13.62,24.68,12Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "573.725px 221.767px 0px",
        }}
        id="el0509cm2om7w"
        className="animable"
      />
      <path
        d="M596,168a2.71,2.71,0,0,1-2.61,2.72,2.57,2.57,0,0,1-2.78-2.44,2.69,2.69,0,0,1,2.6-2.72A2.59,2.59,0,0,1,596,168Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "593.305px 168.14px 0px",
        }}
        id="el3edv3xvpr4h"
        className="animable"
      />
      <path
        d="M598.6,165.5c-.34.35-2.38-1.17-5.29-1.17s-5,1.46-5.35,1.1c-.16-.16.18-.79,1.11-1.47a7.45,7.45,0,0,1,4.29-1.31,7.08,7.08,0,0,1,4.21,1.37C598.46,164.7,598.76,165.34,598.6,165.5Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "593.282px 164.101px 0px",
        }}
        id="el8vnobygkx43"
        className="animable"
      />
      <path
        d="M567.4,168.88a2.69,2.69,0,0,1-2.61,2.72,2.58,2.58,0,0,1-2.78-2.44,2.7,2.7,0,0,1,2.61-2.72A2.58,2.58,0,0,1,567.4,168.88Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "564.705px 169.02px 0px",
        }}
        id="el2nm1o75zkr1"
        className="animable"
      />
      <path
        d="M570,166c-.33.35-2.38-1.16-5.28-1.16s-5,1.45-5.36,1.09c-.15-.16.19-.79,1.12-1.46a7.38,7.38,0,0,1,4.29-1.32,7.16,7.16,0,0,1,4.2,1.37C569.88,165.19,570.19,165.83,570,166Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "564.69px 164.601px 0px",
        }}
        id="el7smaah91u9m"
        className="animable"
      />
      <path
        d="M580.53,187.56c0-.16,1.79-.47,4.72-.84.74-.08,1.45-.22,1.57-.73a3.74,3.74,0,0,0-.49-2.2l-2.18-5.66c-3-8-5.23-14.67-4.9-14.79s3,6.31,6.08,14.37l2.09,5.69a4.28,4.28,0,0,1,.38,2.91,1.85,1.85,0,0,1-1.23,1.08,5.16,5.16,0,0,1-1.26.18C582.37,187.72,580.54,187.73,580.53,187.56Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "583.56px 175.512px 0px",
        }}
        id="ely21r3mqy53m"
        className="animable"
      />
      <path
        d="M572,189.56c.47,0,.47,3.12,3.17,5.37s6.05,1.9,6.08,2.34c0,.2-.75.62-2.17.65a7.85,7.85,0,0,1-5.08-1.78,6.84,6.84,0,0,1-2.45-4.5C571.4,190.32,571.75,189.54,572,189.56Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "576.382px 193.74px 0px",
        }}
        id="el1rguikg1205"
        className="animable"
      />
      <path
        d="M570.8,154.59c-.29.79-3.19.41-6.6.82s-6.17,1.33-6.63.62c-.2-.34.28-1.09,1.4-1.85a11.12,11.12,0,0,1,4.91-1.71,11,11,0,0,1,5.16.62C570.29,153.6,570.92,154.22,570.8,154.59Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "564.17px 154.342px 0px",
        }}
        id="elyd1n7gj6b5c"
        className="animable"
      />
      <path
        d="M597.55,157.09c-.52.65-2.52,0-4.93-.07s-4.44.43-4.91-.26c-.21-.33.12-1,1-1.62a7.14,7.14,0,0,1,7.92.26C597.49,156.08,597.77,156.76,597.55,157.09Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "592.635px 155.721px 0px",
        }}
        id="el48k0bo7fcoy"
        className="animable"
      />
      <path
        d="M540.54,175.86c-.32-.14-13-3.87-12.6,9.07s13.29,9.87,13.3,9.5S540.54,175.86,540.54,175.86Z"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "534.585px 185.133px 0px",
        }}
        id="elzud1hirjuth"
        className="animable"
      />
      <path
        d="M538.28,189.65c-.06,0-.22.16-.6.35a2.26,2.26,0,0,1-1.68.09c-1.38-.47-2.6-2.6-2.73-4.93a7.55,7.55,0,0,1,.53-3.24,2.6,2.6,0,0,1,1.65-1.78,1.16,1.16,0,0,1,1.35.57c.18.36.11.62.17.64s.28-.22.16-.75a1.51,1.51,0,0,0-.54-.8,1.69,1.69,0,0,0-1.25-.29,3.17,3.17,0,0,0-2.3,2.07,7.65,7.65,0,0,0-.67,3.63c.16,2.61,1.57,5,3.45,5.49a2.4,2.4,0,0,0,2-.41C538.26,190,538.32,189.67,538.28,189.65Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "535.323px 185.128px 0px",
        }}
        id="el896bgi1tmqc"
        className="animable"
      />
      <path
        d="M549,137.42l53.92,1.19a24.58,24.58,0,0,0-7.92-9.16,42.07,42.07,0,0,0-24.42-8.79c-8.79-.16-26.7,10.56-33.07,16.62-5.1,4.85-5.06,9.05-5.06,9.05-.81,7,3.1,14.94,4.66,21.81.8,3.54,2.35,14.25,2.35,14.25h0c3,0,4.07-2.37,3.62-5.31l-.55-11.53s10.1-7.18,5.31-20.32C547.1,144.18,545.46,139.38,549,137.42Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "567.63px 151.524px 0px",
        }}
        id="elmfqyif2do6"
        className="animable"
      />
      <path
        d="M560.34,178.32A.33.33,0,0,1,560,178a.34.34,0,0,1,.24-.41.35.35,0,0,1,.39.29A.34.34,0,0,1,560.34,178.32Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "560.318px 177.954px 0px",
        }}
        id="elswu2b1oop4c"
        className="animable"
      />
      <path
        d="M565.9,177.07c-.18,0-.35-.11-.4-.33a.38.38,0,0,1,.24-.46.37.37,0,0,1,.39.34A.36.36,0,0,1,565.9,177.07Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "565.816px 176.675px 0px",
        }}
        id="el70v1jeb4p5i"
        className="animable"
      />
      <path
        d="M569.64,177c-.18,0-.35-.19-.39-.48s.08-.54.26-.56.34.19.38.48S569.81,177,569.64,177Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "569.57px 176.479px 0px",
        }}
        id="elt62jd1xqr2"
        className="animable"
      />
      <path
        d="M563.06,181.81c-.17,0-.32-.09-.32-.2s.15-.21.32-.21.32.1.32.21S563.24,181.81,563.06,181.81Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "563.06px 181.605px 0px",
        }}
        id="eli2kyjlk4f7e"
        className="animable"
      />
      <path
        d="M568.16,180.64a.36.36,0,0,1-.06-.72.34.34,0,0,1,.35.33A.35.35,0,0,1,568.16,180.64Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "568.111px 180.281px 0px",
        }}
        id="elgeuwtvvncto"
        className="animable"
      />
      <path
        d="M594.67,177.53a.33.33,0,0,1-.38-.29.35.35,0,0,1,.24-.42.35.35,0,0,1,.39.29A.34.34,0,0,1,594.67,177.53Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "594.606px 177.176px 0px",
        }}
        id="el2dmq4e6qflk"
        className="animable"
      />
      <path
        d="M598.46,177.19a.41.41,0,1,1,.28-.43A.37.37,0,0,1,598.46,177.19Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "598.334px 176.8px 0px",
        }}
        id="elbyke4frne3"
        className="animable"
      />
      <path
        d="M591.33,177.62a.31.31,0,0,1-.37-.21.32.32,0,0,1,.63-.13A.31.31,0,0,1,591.33,177.62Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "591.273px 177.326px 0px",
        }}
        id="eljd2dk7lkbbk"
        className="animable"
      />
      <path
        d="M594.13,181.41a.38.38,0,1,1,.14-.47A.35.35,0,0,1,594.13,181.41Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "593.926px 181.092px 0px",
        }}
        id="elg11pz4nio7k"
        className="animable"
      />
      <path
        d="M597.36,180.77a.3.3,0,0,1-.24-.34.33.33,0,0,1,.62.17A.3.3,0,0,1,597.36,180.77Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "597.428px 180.522px 0px",
        }}
        id="el31wnrc9qbwy"
        className="animable"
      />
      <path
        d="M537.18,138a15.94,15.94,0,0,1,15.93-19.82c2.52.1,5,.81,7.52.54,4.67-.51,8.27-4.25,12.6-6.07a16.44,16.44,0,0,1,19.39,5.82c1.64,2.45,2.71,5.48,5.23,7,2.17,1.33,4.91,1.22,7.35,2,4.94,1.48,8.32,6.73,8.06,11.88a14.12,14.12,0,0,1-8.61,11.88,17.28,17.28,0,0,1-14.9-1c-4.07-2.27-7.71-6.13-12.37-6-4.82.14-9,4.68-13.76,3.74-2.48-.49-4.39-2.4-6.21-4.16s-3.9-3.55-6.43-3.7-3.84,3.32-2.93,5.68L537.18,138"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "574.986px 131.994px 0px",
        }}
        id="elc7b12idln0p"
        className="animable"
      />
      <path
        d="M601.9,131.76a1.66,1.66,0,0,1-.09.19,5.29,5.29,0,0,1-.31.52,9,9,0,0,1-1.55,1.76,9.67,9.67,0,0,1-3.21,1.86,9.18,9.18,0,0,1-4.89.29,9.73,9.73,0,0,1-5.16-2.94c-1.47-1.61-2.35-3.78-3.72-5.68a7.5,7.5,0,0,0-2.61-2.36,7.64,7.64,0,0,0-3.58-.79,8.13,8.13,0,0,0-3.67,1,6.89,6.89,0,0,0-2.7,2.78c-.65,1.19-1.12,2.49-1.9,3.64a4.46,4.46,0,0,1-3.46,2.1,6.73,6.73,0,0,1-3.76-1.08,14.21,14.21,0,0,1-2.86-2.37c-1.67-1.68-3.31-3.36-5.35-3.81a6.8,6.8,0,0,0-5.42,1.27,9.3,9.3,0,0,0-3,3.68,15.47,15.47,0,0,0-1.12,3.47c-.2,1-.29,1.76-.35,2.29a6.05,6.05,0,0,1-.12.81.72.72,0,0,1,0-.21c0-.14,0-.34,0-.61a18.76,18.76,0,0,1,.27-2.32,14.77,14.77,0,0,1,1-3.55,9.55,9.55,0,0,1,3-3.86,7.12,7.12,0,0,1,5.73-1.39c2.21.47,3.92,2.23,5.58,3.89a14.26,14.26,0,0,0,2.78,2.28,6.24,6.24,0,0,0,3.48,1,4,4,0,0,0,3.06-1.88c.73-1.08,1.2-2.37,1.87-3.6a7.39,7.39,0,0,1,2.91-3,8.66,8.66,0,0,1,3.91-1.05,8.17,8.17,0,0,1,3.82.86,8,8,0,0,1,2.77,2.52c1.41,2,2.25,4.12,3.65,5.68a9.38,9.38,0,0,0,4.92,2.86,8.82,8.82,0,0,0,4.72-.21,9.6,9.6,0,0,0,3.17-1.73A12.1,12.1,0,0,0,601.9,131.76Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "572.481px 131.239px 0px",
        }}
        id="elrpesdzou47m"
        className="animable"
      />
      <path
        d="M611.67,132a3,3,0,0,1,.22,1,8.4,8.4,0,0,1-.27,2.89,10,10,0,0,1-2,3.8,11.74,11.74,0,0,1-4.26,3.11,17.47,17.47,0,0,1-9.41,1.06,25.72,25.72,0,0,1-2.82-.56,3.46,3.46,0,0,1-1-.34c0-.08,1.47.28,3.87.53a18.19,18.19,0,0,0,9.15-1.17,11.65,11.65,0,0,0,4.11-2.94,10.09,10.09,0,0,0,2-3.6A11.47,11.47,0,0,0,611.67,132Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "602.024px 138.045px 0px",
        }}
        id="elsmq2kf4mlie"
        className="animable"
      />
      <path
        d="M519.64,340.31a10.81,10.81,0,0,0,2.26,5.6c1.16,1.51,3.51,3,5.4,2.87a8.24,8.24,0,0,0,7.27-3.65c2.31-3.66,2.87-11.29,1-15.8-1.27-3.1-4.13-6-7.48-6.07-4.4-.1-7.16,3.08-8.1,6.3"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "528.166px 336.024px 0px",
        }}
        id="eltgtlty7x6fi"
        className="animable"
      />
      <path
        d="M520,329.56a6.72,6.72,0,0,1,.82-2.16,8.65,8.65,0,0,1,1.65-2.17,7.61,7.61,0,0,1,3.11-1.82,7.88,7.88,0,0,1,2.09-.34,6.29,6.29,0,0,1,2.29.3,9,9,0,0,1,4.09,2.93,11.89,11.89,0,0,1,2.4,5,21.54,21.54,0,0,1,.45,5.93c-.21,3.13-.82,6.25-2.56,8.63a7.68,7.68,0,0,1-3.32,2.5,8.9,8.9,0,0,1-3.75.63h0a5.68,5.68,0,0,1-2.7-.66,9,9,0,0,1-2-1.42,10,10,0,0,1-2.21-3.28,10.2,10.2,0,0,1-.7-2.43,5.25,5.25,0,0,1-.06-.66.52.52,0,0,1,0-.23,13.1,13.1,0,0,0,1,3.22,10.32,10.32,0,0,0,2.22,3.13,8.84,8.84,0,0,0,2,1.32,5.3,5.3,0,0,0,2.5.59h0a8,8,0,0,0,6.65-3c1.61-2.23,2.23-5.3,2.43-8.36.21-4-.45-7.94-2.7-10.6a8.74,8.74,0,0,0-3.85-2.82,6.19,6.19,0,0,0-2.15-.31,7.53,7.53,0,0,0-2,.29,7.34,7.34,0,0,0-3,1.69A8.87,8.87,0,0,0,521,327.5,20,20,0,0,0,520,329.56Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "528.265px 336.031px 0px",
        }}
        id="el4znrxau4xfx"
        className="animable"
      />
      <path
        d="M517.3,340.51a10.84,10.84,0,0,0,2.26,5.6c1.16,1.51,3.51,3,5.4,2.87a8.24,8.24,0,0,0,7.27-3.65c2.3-3.67,2.87-11.29,1-15.8-1.27-3.11-4.12-6-7.47-6.07a8.27,8.27,0,0,0-8.1,6.29"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "525.824px 336.225px 0px",
        }}
        id="el2vlbrtjnxqb"
        className="animable"
      />
      <path
        d="M517.68,329.75a6.73,6.73,0,0,1,.82-2.15,8.42,8.42,0,0,1,1.65-2.17,7.68,7.68,0,0,1,5.2-2.16,6.29,6.29,0,0,1,2.29.3,8.94,8.94,0,0,1,4.09,2.93,11.89,11.89,0,0,1,2.4,5,21.16,21.16,0,0,1,.44,5.93c-.2,3.13-.81,6.24-2.55,8.63a7.68,7.68,0,0,1-3.32,2.5,8.9,8.9,0,0,1-3.75.63h0a5.68,5.68,0,0,1-2.7-.67,8.44,8.44,0,0,1-2-1.41,9.94,9.94,0,0,1-2.21-3.29,9.58,9.58,0,0,1-.7-2.42,5.25,5.25,0,0,1-.06-.66.52.52,0,0,1,0-.23,13.11,13.11,0,0,0,1,3.21,10.07,10.07,0,0,0,2.22,3.14,8.84,8.84,0,0,0,2,1.32,5.42,5.42,0,0,0,2.5.59h0a8,8,0,0,0,6.65-3c1.61-2.24,2.23-5.3,2.42-8.36.22-4-.44-7.94-2.7-10.6a8.58,8.58,0,0,0-3.84-2.82,6.19,6.19,0,0,0-2.15-.31,7.67,7.67,0,0,0-2,.29,7.37,7.37,0,0,0-3,1.69,8.87,8.87,0,0,0-1.68,2.05A22,22,0,0,0,517.68,329.75Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "525.942px 336.231px 0px",
        }}
        id="eli3e35axlwc"
        className="animable"
      />
      <path
        d="M521.54,328.63a5.14,5.14,0,0,0-5.37,3.09,8.92,8.92,0,0,0,0,6.53,8.26,8.26,0,0,0,1.67,2.94,4.44,4.44,0,0,0,3,1.45A5.2,5.2,0,0,0,525,340.2a9.34,9.34,0,0,0,1.41-7.67c-.46-1.74-1.73-3.51-3.52-3.56"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "521.124px 335.615px 0px",
        }}
        id="elocj9djnjc5e"
        className="animable"
      />
      <path
        d="M522.85,329s.13,0,.39,0a2.72,2.72,0,0,1,1.09.37,5.3,5.3,0,0,1,2.29,3.44,9.5,9.5,0,0,1-.62,6.12,8,8,0,0,1-2.24,2.94,4.35,4.35,0,0,1-3.86.93,5.46,5.46,0,0,1-3.09-2.52,11.29,11.29,0,0,1-1.28-3.46,7.69,7.69,0,0,1,1-6,5.37,5.37,0,0,1,3.49-2.22,3.87,3.87,0,0,1,1.14,0c.25.05.38.09.38.11a7.89,7.89,0,0,0-1.49.09,5.33,5.33,0,0,0-3.22,2.23,7.41,7.41,0,0,0-.83,5.73,11,11,0,0,0,1.25,3.29,4.94,4.94,0,0,0,2.79,2.28,3.81,3.81,0,0,0,3.4-.82,7.52,7.52,0,0,0,2.12-2.74,9.24,9.24,0,0,0,.7-5.84,5.29,5.29,0,0,0-2-3.35A4.22,4.22,0,0,0,522.85,329Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "521.087px 335.74px 0px",
        }}
        id="el49ssxrrev7x"
        className="animable"
      />
      <path
        d="M526.58,345.07c-.07,0-.18-.32-.53-.72a3.68,3.68,0,0,0-1.7-1.09,9.26,9.26,0,0,0-2.1-.33c-.56,0-.91-.09-.92-.16s.34-.15.91-.21a5.86,5.86,0,0,1,2.26.2,3.27,3.27,0,0,1,1.85,1.38C526.67,344.66,526.64,345.06,526.58,345.07Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "523.974px 343.798px 0px",
        }}
        id="el3qirkyoqi5g"
        className="animable"
      />
      <path
        d="M527.37,327.27s-.15.35-.62.72a4.81,4.81,0,0,1-2.11.92,4.92,4.92,0,0,1-2.29-.12c-.57-.19-.87-.41-.84-.47a11.83,11.83,0,0,0,3,.08A12.49,12.49,0,0,0,527.37,327.27Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "524.439px 328.131px 0px",
        }}
        id="elnosv70k8tq"
        className="animable"
      />
    </g>
    <g
      id="freepik--character-1--inject-19"
      style={{
        transformOrigin: "189.773px 286.672px 0px",
      }}
      className="animable"
    >
      <path
        d="M251.84,152.36c.12-4.36-3.9-7.72-8-9.32s-8.56-2.2-12.18-4.63c-3.2-2.16-5.35-5.52-8.06-8.26a26.83,26.83,0,0,0-45.12,12.31c-1.55,6.2-.49,13.53,4.4,17.66,5.68,4.78,14.09,3.72,21.36,2.23s15.51-3,21.58,1.31c3.66,2.58,5.76,6.77,8,10.65s5,7.83,9.28,9.29,10-1.06,10.15-5.53"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "215.534px 153.09px 0px",
        }}
        id="elqxe1i0g3ygq"
        className="animable"
      />
      <path
        d="M251.55,154.75c-.48,3.65,2.16,6.92,4.79,9.5s5.6,5.31,6.07,9c.54,4.27-2.58,8.47-1.67,12.68.57,2.67,2.65,4.72,3.91,7.15a15.24,15.24,0,0,1,.77,11.19c-1.08,3.68-2.81,11.89-5.37,14.75-7.07,7.9-18.32,14.72-28.65,17.09s-21.07,2.39-31.65,1.74c-8.66-.53-18.63-.13-25.85-12.05-3.74-6.18-5.59-14.38-7.57-21.32-1.72-6-3.2-12.37-1.42-18.33,1-3.46,3.13-6.55,4.15-10,1.28-4.34.81-9,1.32-13.47a30.91,30.91,0,0,1,44-24.41"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "215.121px 186.741px 0px",
        }}
        id="elfawcxd4qjh"
        className="animable"
      />
      <path
        d="M174.53,169.72c.81-12.43,9.08-27.67,21.47-26.33l38.66,9.86a7.31,7.31,0,0,1,6.51,7.41l-4.35,93.28.27.05s-8.81,26.31-34.38,18.28c-5.07-1.6-7.39-7.14-8.39-12.85l-.26,0c0-.68,0-1.31,0-2a61.23,61.23,0,0,1-.15-12.81h0c-.07-7.53-.06-10.61-.07-10.54s-15.88-2.68-19.12-23.41C173.07,200.43,173.63,183.53,174.53,169.72Z"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "207.418px 208.551px 0px",
        }}
        id="el3iktxshs9hk"
        className="animable"
      />
      <path
        d="M180.76,182.21a2.65,2.65,0,0,0,2.55,2.69,2.55,2.55,0,0,0,2.75-2.39,2.66,2.66,0,0,0-2.56-2.69A2.54,2.54,0,0,0,180.76,182.21Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "183.41px 182.36px 0px",
        }}
        id="elzf230q1wgek"
        className="animable"
      />
      <path
        d="M178.17,178.52c.33.35,2.35-1.14,5.21-1.13s5,1.45,5.27,1.1c.15-.15-.19-.78-1.1-1.44a7.21,7.21,0,0,0-4.21-1.31,7,7,0,0,0-4.15,1.32C178.31,177.74,178,178.36,178.17,178.52Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "183.405px 177.157px 0px",
        }}
        id="el7odgbm6ihui"
        className="animable"
      />
      <path
        d="M208.87,182.21a2.66,2.66,0,0,0,2.56,2.69,2.54,2.54,0,0,0,2.74-2.39,2.65,2.65,0,0,0-2.55-2.69A2.54,2.54,0,0,0,208.87,182.21Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "211.52px 182.36px 0px",
        }}
        id="elg4j93hqhmmi"
        className="animable"
      />
      <path
        d="M206.29,179.11c.33.35,2.35-1.13,5.21-1.12s4.95,1.45,5.26,1.1c.15-.16-.18-.78-1.1-1.45a7.33,7.33,0,0,0-4.21-1.31,7,7,0,0,0-4.14,1.33C206.43,178.33,206.13,179,206.29,179.11Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "211.522px 177.747px 0px",
        }}
        id="elj0vram546ff"
        className="animable"
      />
      <path
        d="M195.86,200.3c0-.16-1.76-.47-4.64-.85-.73-.07-1.42-.22-1.55-.72a3.7,3.7,0,0,1,.5-2.16l2.17-5.56c3-7.91,5.2-14.42,4.88-14.53s-3,6.19-6,14.11q-1.1,2.92-2.08,5.59a4.2,4.2,0,0,0-.39,2.86,1.84,1.84,0,0,0,1.2,1.07,5.07,5.07,0,0,0,1.24.18C194.05,200.45,195.85,200.47,195.86,200.3Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "192.952px 188.449px 0px",
        }}
        id="elizr7f1ecpa"
        className="animable"
      />
      <path
        d="M193.81,234.15a52.68,52.68,0,0,0,27.77-7.35s-7,14.33-27.75,12.35Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "207.695px 233.069px 0px",
        }}
        id="el5zdp4xcro0i"
        className="animable"
      />
      <path
        d="M197.06,207.2a5.13,5.13,0,0,1,4.62-1.9,4.68,4.68,0,0,1,3.25,1.75,3,3,0,0,1,.21,3.4,3.42,3.42,0,0,1-3.75.94,10.87,10.87,0,0,1-3.65-2.19,2.94,2.94,0,0,1-.81-.84,1,1,0,0,1,0-1.07"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "201.172px 208.434px 0px",
        }}
        id="elflocqyvaej"
        className="animable"
      />
      <path
        d="M204.27,202.3c-.46,0-.47,3.08-3.14,5.27s-6,1.85-6,2.29c0,.19.74.6,2.13.64a7.71,7.71,0,0,0,5-1.73,6.73,6.73,0,0,0,2.43-4.42C204.83,203.05,204.49,202.28,204.27,202.3Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "199.926px 206.4px 0px",
        }}
        id="elp2p5uuy1xzr"
        className="animable"
      />
      <path
        d="M205,174.1c.28.78,3.14.42,6.49.83s6.07,1.33,6.52.64c.2-.33-.27-1.07-1.37-1.82a10.68,10.68,0,0,0-4.82-1.7,10.86,10.86,0,0,0-5.08.58C205.51,173.13,204.89,173.73,205,174.1Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "211.521px 173.893px 0px",
        }}
        id="elqbpfqq61ky"
        className="animable"
      />
      <path
        d="M179.24,170.24c.51.65,2.48,0,4.85,0s4.37.44,4.84-.23c.2-.33-.12-1-1-1.6a7,7,0,0,0-7.8.22C179.3,169.26,179,169.93,179.24,170.24Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "184.074px 168.929px 0px",
        }}
        id="elrvd0iy4t4rl"
        className="animable"
      />
      <path
        d="M249.13,221.44a18.4,18.4,0,0,1-21.89-20.35c.64-5.09,3.39-10.27,1.62-15.09-2.33-6.37-11.26-8.33-13.63-14.69-1.38-3.69-.14-8-1.7-11.63-1.47-3.41-5.05-5.37-8.54-6.63s-7.22-2.13-10.15-4.4-4.75-6.57-2.86-9.76a196,196,0,0,1,45.28,11c3.39,1.26,6.94,2.78,8.94,5.79,2.12,3.18,2,7.29,1.84,11.11l-2.61,55.82"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "220.169px 180.75px 0px",
        }}
        id="el9ygz42kiug"
        className="animable"
      />
      <path
        d="M247.83,228.56s.13-.06.34-.21l.92-.7c.8-.63,2-1.56,3.43-2.85a35.42,35.42,0,0,0,4.87-5.12,19.12,19.12,0,0,0,3.88-8.54,13,13,0,0,0-2.67-10.9,41.64,41.64,0,0,0-4.83-4.12A11.21,11.21,0,0,1,250,191c-1.53-4.22-.85-9.11-1.44-14.11a28.06,28.06,0,0,0-5.88-14.28,19.29,19.29,0,0,0-6.14-5,37.51,37.51,0,0,0-7.25-2.44,16.91,16.91,0,0,1-6.37-3.14,25.26,25.26,0,0,1-4.62-5.09c-2.6-3.7-4.53-7.64-7-10.93a23.43,23.43,0,0,0-4.08-4.33,15.65,15.65,0,0,0-4.84-2.64,10.34,10.34,0,0,0-9.21.92,8.49,8.49,0,0,0-3.27,6.2,6.22,6.22,0,0,0,1.25,4.27,4,4,0,0,0,.84.81,1.44,1.44,0,0,0,.34.22,2.27,2.27,0,0,0-.28-.29,4.59,4.59,0,0,1-.75-.85,6.1,6.1,0,0,1-1-4.13,8.13,8.13,0,0,1,3.21-5.82,9.8,9.8,0,0,1,8.7-.72,15,15,0,0,1,4.61,2.57,23.85,23.85,0,0,1,3.92,4.24c2.4,3.23,4.29,7.17,6.93,11a25.72,25.72,0,0,0,4.76,5.29A17.55,17.55,0,0,0,229,156a36.76,36.76,0,0,1,7.09,2.4,18.49,18.49,0,0,1,5.86,4.8A27.19,27.19,0,0,1,247.67,177c.61,4.83-.09,9.8,1.54,14.29a12.13,12.13,0,0,0,4,5.47,42.31,42.31,0,0,1,4.77,4,12.33,12.33,0,0,1,2.6,10.3,18.73,18.73,0,0,1-3.64,8.33,38.32,38.32,0,0,1-4.71,5.17c-1.41,1.32-2.55,2.29-3.31,3A12.09,12.09,0,0,0,247.83,228.56Z"
        style={{
          fill: "rgb(69, 90, 100)",
          transformOrigin: "225.712px 178.46px 0px",
        }}
        id="elmr1fudiu14"
        className="animable"
      />
      <path
        d="M251.62,231.14c4.26-4.12,11.53-.54,15.11,4.18s6.11,10.82,11.54,13.2,12.48.33,17,4.11c6.28,5.22,2.75,17.31,9.55,21.85,2.69,1.8,6.25,1.74,9.13,3.22,6.79,3.5,6.71,13.08,5.66,20.65C318.18,309,316.56,320,310.7,329s-17.27,15.29-27.41,11.83c-9.75-3.33-14.76-13.85-18.73-23.36Q256.34,297.69,248.1,278c-3.17-7.61-6.38-15.34-7.41-23.52s.35-17,5.56-23.42"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "280.254px 285.564px 0px",
        }}
        id="elzgcdg2ncxh"
        className="animable"
      />
      <path
        d="M254.35,222.06c3.09-.37,5.46,3.42,4.64,6.43s-3.7,5-6.65,6a9.92,9.92,0,0,1-5.13.59,5,5,0,0,1-3.89-3.13,2.94,2.94,0,0,1,1.42-3.89c2-1.27,3.23-2.27,4.84-3.16,2.46-1.35,2.31-1.53,4.77-2.89"
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "251.102px 228.6px 0px",
        }}
        id="el6c7qyfh8xge"
        className="animable"
      />
      <polygon
        points="183.12 255.41 195.14 246.3 240.45 243.4 242.27 249.48 227.53 341.44 239.16 451.16 175.95 451.16 183.12 255.41"
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "209.11px 347.28px 0px",
        }}
        id="el53m30eyfl2e"
        className="animable"
      />
      <g id="elba3rb6oojmc">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "205.772px 428.76px 0px",
          }}
          className="animable"
        >
          <path
            d="M231.63,412.24c-1.45-1.55-3.82-3.67-5.93-3.72-15.14-.33-30.59-1.84-45.73-2.18l-1.21,44.84c3.62-6.38,7.05-13,8-20.3.42-3.18,1-7,3.85-8.34,1.64-.77,3.57-.47,5.34-.11,5.47,1.08,10.9,2.49,16.46,2.84s11.39-.47,16.06-3.51a9.85,9.85,0,0,0,3.84-4.15A5.09,5.09,0,0,0,231.63,412.24Z"
            id="elbjlbgfhc7is"
            style={{
              transformOrigin: "205.772px 428.76px 0px",
            }}
            className="animable"
          />
        </g>
      </g>
      <path
        d="M192.92,257.27l-1.41-13.15a4.18,4.18,0,0,1,3.78-4.6l41.7-3.77a2,2,0,0,1,2.15,1.9l.47,15.65-12.08,20.49-12.41,3.95H199.94l-8.73-9.32Z"
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "215.41px 256.742px 0px",
        }}
        id="elhsz3j9q3pcp"
        className="animable"
      />
      <path
        d="M233.45,248.28a14.19,14.19,0,0,1-1.67-.17,30,30,0,0,0-4.54-.08,44,44,0,0,0-6.68,1c-2.49.52-5.2,1.25-8.07,1.95a52.52,52.52,0,0,1-8.21,1.46,21.23,21.23,0,0,1-6.81-.46,14.13,14.13,0,0,1-4.24-1.76,10,10,0,0,1-1-.74c-.22-.18-.33-.29-.31-.3s.49.33,1.43.86a15.51,15.51,0,0,0,4.23,1.58,21.55,21.55,0,0,0,6.68.34,55.19,55.19,0,0,0,8.13-1.49c2.86-.69,5.58-1.41,8.09-1.91a40.28,40.28,0,0,1,6.76-.86,24.22,24.22,0,0,1,4.59.24q.81.14,1.23.24C233.31,248.22,233.46,248.26,233.45,248.28Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "212.684px 250.106px 0px",
        }}
        id="elamsd5yd0jzl"
        className="animable"
      />
      <g id="eltdq54cdkutg">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "211.191px 254.771px 0px",
          }}
          className="animable"
        >
          <path
            d="M193.91,250.35c-1.12,3.07-1.15,6.87,1.12,9.22,1.84,1.92,4.81,2.4,7.42,1.88a23.41,23.41,0,0,0,7.25-3.26c5.74-3.41,11.58-6.95,18.1-8.4a2.31,2.31,0,0,0,1.17-.49.77.77,0,0,0,0-1.13,1.09,1.09,0,0,0-.54-.15c-5.77-.58-11.5,1-17.15,2.32s-11.7,2.17-17.15.2"
            id="eldapdkzpfmgl"
            style={{
              transformOrigin: "211.191px 254.771px 0px",
            }}
            className="animable"
          />
        </g>
      </g>
      <path
        d="M201.88,239.1a3.83,3.83,0,0,0-1.43,1.36c-.66,1.06-.62,2.86-.57,4.8.11,3.89.75,7,.6,7a6.8,6.8,0,0,1-.57-2,31.51,31.51,0,0,1-.55-5,23.11,23.11,0,0,1,.06-2.79,4.82,4.82,0,0,1,.72-2.21,2.89,2.89,0,0,1,1.17-1.05C201.66,239.07,201.87,239.07,201.88,239.1Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "200.611px 245.672px 0px",
        }}
        id="elqqz51qsrc1b"
        className="animable"
      />
      <path
        d="M212.9,250.53a60.44,60.44,0,0,0-.3-6.36c-.48-3.49-1.64-6.14-1.48-6.2a5.48,5.48,0,0,1,.85,1.69,20.67,20.67,0,0,1,1.15,4.43,20.28,20.28,0,0,1,.13,4.58A5.6,5.6,0,0,1,212.9,250.53Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "212.214px 244.25px 0px",
        }}
        id="elm0ti6y4ycu"
        className="animable"
      />
      <path
        d="M226.33,236.86a4.11,4.11,0,0,1,.79,1.55,10.94,10.94,0,0,1,.55,4.17,10.77,10.77,0,0,1-1.1,4.07,4,4,0,0,1-1,1.43,13.8,13.8,0,0,0,.75-11.22Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "226.631px 242.47px 0px",
        }}
        id="elfegcshkrqvw"
        className="animable"
      />
      <path
        d="M167.85,261.33s-14.22,9.52-18,26.72c-2.94,13.52-11,84.94-11,84.94l-14.09,27.42,9.2,21.88h0a33.64,33.64,0,0,0,22.1-6.31l6.19-4.5,11.35-16.83Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "149.18px 341.852px 0px",
        }}
        id="elamdr8ig4r4b"
        className="animable"
      />
      <polygon
        points="191.95 248.71 167.85 261.33 162.16 280.02 157.09 334.21 166.63 397.74 149.89 450.97 178.76 451.18 179.45 419.98 182.33 318.98 185.16 254.64 191.95 248.71"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "170.92px 349.945px 0px",
        }}
        id="elglvjpekq4vp"
        className="animable"
      />
      <path
        d="M180.28,332.91a5.52,5.52,0,0,1-.45-.76l-1.2-2.21c-.5-1-1-2.22-1.61-3.64-.29-.72-.54-1.51-.83-2.34s-.61-1.71-.86-2.65c-2.27-7.49-3.77-18.3-3.45-30.22,0-.92.07-1.83.13-2.74v-.16l.15-.06,4.22-1.78-.06.44-4.22-3.39-.09-.07v-.1a36.47,36.47,0,0,1,1.92-12.05c1.06-3.53,2.22-6.57,3.24-9.07s1.91-4.45,2.54-5.77c.32-.67.57-1.18.74-1.51a3.44,3.44,0,0,1,.29-.51,2.43,2.43,0,0,1-.21.54l-.67,1.55c-.58,1.34-1.42,3.31-2.4,5.82s-2.1,5.55-3.12,9.07a36.59,36.59,0,0,0-1.83,11.89l-.1-.17,4.23,3.37.33.27-.39.17-4.22,1.78.16-.22c-.06.89-.1,1.8-.13,2.72-.31,11.87,1.12,22.63,3.29,30.09.23.95.54,1.82.81,2.65s.51,1.63.8,2.35c.59,1.42,1,2.67,1.52,3.65l1.1,2.25A6.6,6.6,0,0,1,180.28,332.91Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "176.289px 293.615px 0px",
        }}
        id="elnsz8cxx3chl"
        className="animable"
      />
      <path
        d="M161.36,370.85a7.45,7.45,0,0,1-.23-.78q-.21-.85-.57-2.28c-.25-1-.53-2.2-.81-3.61s-.65-3-.93-4.83-.66-3.76-.93-5.88-.54-4.4-.79-6.79c-.44-4.8-.73-10.09-.73-15.65s.33-10.85.78-15.64c.27-2.39.5-4.67.83-6.79s.62-4.09.94-5.88.66-3.4,1-4.82.58-2.63.83-3.61.43-1.71.58-2.27a5.73,5.73,0,0,1,.23-.79,4.61,4.61,0,0,1-.13.81l-.48,2.29c-.22,1-.49,2.21-.74,3.63s-.61,3-.88,4.83-.62,3.75-.87,5.87-.53,4.39-.78,6.77c-.42,4.79-.72,10.06-.74,15.61s.28,10.81.68,15.6c.25,2.38.45,4.66.75,6.77s.57,4.09.86,5.88.6,3.41.85,4.83.51,2.64.73,3.63.36,1.72.47,2.29A3.82,3.82,0,0,1,161.36,370.85Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "158.965px 331.04px 0px",
        }}
        id="el2p2ivxqhh2g"
        className="animable"
      />
      <path
        d="M167.88,419.63a10.3,10.3,0,0,1-1.17,2.43,39.31,39.31,0,0,1-3.69,5.35,38.72,38.72,0,0,1-4.52,4.67,10.84,10.84,0,0,1-2.15,1.63,19.24,19.24,0,0,1,1.9-1.9,58.29,58.29,0,0,0,4.37-4.73,57.55,57.55,0,0,0,3.78-5.21A19.3,19.3,0,0,1,167.88,419.63Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "162.115px 426.67px 0px",
        }}
        id="el226bjntdy5k"
        className="animable"
      />
      <path
        d="M278.66,259.78A33.17,33.17,0,0,1,299,283.38c7.89,34.76,21.38,81,21.6,95.93.26,19-8,32-18.89,34.4-8.58,1.9-86.32-2.36-86.32-2.36l-3.64-29,63-9.27L266.13,325l8.53-66.72Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "266.178px 336.239px 0px",
        }}
        id="elzv8bgsizdgm"
        className="animable"
      />
      <path
        d="M274.66,258.23l-17.41-7.11L238.9,240.36l-.11,3.15h0A18.46,18.46,0,0,1,241,256.27L225.7,335.93l3.46,58.21,7.11,57h55.21l-25.35-59.65,8.95-80.39Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "258.59px 345.75px 0px",
        }}
        id="elbhp50fu3ret"
        className="animable"
      />
      <path
        d="M290.79,414.2l-.38.05-1.11.11c-1,.1-2.43.09-4.31.14-3.74,0-9.18-.08-15.95-.3-13.53-.45-32.36-1.36-53.63-2.59h-.21l0-.22c-.43-3.45-.89-7.09-1.35-10.78-.79-6.25-1.55-12.35-2.28-18.18l0-.25.24,0,48.08-7,14.12-2,3.79-.51,1-.13.34,0-.33.07-1,.17-3.78.6-14.09,2.15-48,7.17.21-.29c.74,5.84,1.51,11.93,2.29,18.18.47,3.7.92,7.34,1.35,10.79l-.24-.22c21.26,1.24,40.1,2.21,53.62,2.76,6.76.26,12.19.41,15.93.46,1.87,0,3.33,0,4.3-.06l1.11-.06Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "251.18px 393.52px 0px",
        }}
        id="elikylqdtfchi"
        className="animable"
      />
      <path
        d="M265.62,320.08a2.06,2.06,0,0,1,.14.54c.08.41.18.91.3,1.54.26,1.41.61,3.34,1,5.69.85,4.88,2,11.49,3.28,18.8s2.32,13.94,3.14,18.83c.37,2.36.67,4.29.89,5.71.09.63.16,1.15.22,1.56a2.28,2.28,0,0,1,0,.55,1.91,1.91,0,0,1-.15-.53c-.08-.41-.18-.92-.31-1.54-.26-1.42-.62-3.34-1.06-5.69-.87-4.81-2-11.45-3.28-18.8s-2.36-14-3.13-18.83c-.36-2.36-.66-4.3-.88-5.72q-.12-.94-.21-1.56A2.53,2.53,0,0,1,265.62,320.08Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "270.088px 346.69px 0px",
        }}
        id="elqj20n5rfsr"
        className="animable"
      />
      <path
        d="M246.91,246s0,.07.07.2l.16.58c.13.52.35,1.27.56,2.27a72.7,72.7,0,0,1,1.32,8.57,95.88,95.88,0,0,1,.33,13.08c-.17,5-.69,10.47-1.44,16.3l0,.18-.18,0L242,288.24l.19-.35c.33.88.66,1.82.92,2.79a35.45,35.45,0,0,1-.65,19.31,60.46,60.46,0,0,1-6.68,14.4c-2.38,3.86-4.52,6.82-6,8.87l-1.73,2.34c-.18.25-.34.44-.46.6a.81.81,0,0,1-.17.2s0-.08.13-.23l.42-.63c.4-.58,1-1.38,1.64-2.4,1.43-2.08,3.53-5.07,5.85-8.94A61.72,61.72,0,0,0,242,309.86c1.65-5.67,2.43-12.41.61-19a28.38,28.38,0,0,0-.9-2.74l-.11-.29.31-.06,5.69-1.05-.21.22c.76-5.81,1.31-11.29,1.5-16.25a100.51,100.51,0,0,0-.21-13,79.75,79.75,0,0,0-1.17-8.57c-.18-1-.38-1.76-.47-2.28-.05-.24-.09-.43-.12-.59A.66.66,0,0,1,246.91,246Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "238.429px 291.2px 0px",
        }}
        id="elov9h05wawtj"
        className="animable"
      />
      <path
        d="M246.91,327.69V315s.16-3.13-2.48-2.47-2.15,5.61-2.15,5.61-.49,19.31.66,19.15,1.6-1.65,1.6-1.65v-7.43Z"
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "244.544px 324.865px 0px",
        }}
        id="el12vrhd3mpzhl"
        className="animable"
      />
      <path
        d="M257.89,328.48a43.86,43.86,0,0,1-6.81.26,43.86,43.86,0,0,1-6.81-.26,45.4,45.4,0,0,1,6.81-.26A45.4,45.4,0,0,1,257.89,328.48Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "251.08px 328.482px 0px",
        }}
        id="elgem0l90m5kf"
        className="animable"
      />
      <path
        d="M283,267.32a9.4,9.4,0,0,1-1.42.53,32.07,32.07,0,0,0-3.78,1.63,29.39,29.39,0,0,0-10.22,8.71,21.82,21.82,0,0,0-2,3.27,21.2,21.2,0,0,0-1.2,3.36,33.11,33.11,0,0,0-1,6c-.14,1.73-.17,3.15-.18,4.12a12.92,12.92,0,0,1,0,1.52A6.11,6.11,0,0,1,263,295a36.48,36.48,0,0,1,0-4.15,30.9,30.9,0,0,1,.91-6.1,21,21,0,0,1,1.21-3.46,22.22,22.22,0,0,1,2.05-3.36,28.24,28.24,0,0,1,10.48-8.73,26.08,26.08,0,0,1,3.87-1.5A7.49,7.49,0,0,1,283,267.32Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "272.97px 281.89px 0px",
        }}
        id="el63o0cabpala"
        className="animable"
      />
      <polygon
        points="58.94 372.99 149.89 406.67 226.56 418.39 135.4 373.95 58.94 372.99"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "142.75px 395.69px 0px",
        }}
        id="eluhtw3c6eut"
        className="animable"
      />
      <path
        d="M97.86,409.31A38.71,38.71,0,0,0,93,395.53a10,10,0,0,1-1.24-2.59,2.94,2.94,0,0,1,.51-2.71c1.17-1.27,3.3-.71,4.74.25a16.66,16.66,0,0,1,6.12,8.58,49.8,49.8,0,0,1,2,10.51"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "98.3784px 399.531px 0px",
        }}
        id="eldch6dl6cvmp"
        className="animable"
      />
      <path
        d="M133.31,400.65h-4.52a8.86,8.86,0,0,0-4-2.28,3.14,3.14,0,0,0-3,1.17c2-3.33,4.2-7,4.62-8,.81-1.95-1-3.41-3.91-3.41s-15.79,18.23-15.79,18.23,8-14.67,8.77-16.91c1.94-5.6-4.19-6-6.41-3.31-2.76,3.35-13.91,20.22-13.91,20.22s-2.12,3.42-.66,6.67,5.7,2,5.7,2a5.46,5.46,0,0,0,3,4.52c3.26,1.82,7.56-.29,7.56-.29L127.43,423l6-.6a18.48,18.48,0,0,0,16.44-15.68h0Z"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "121.928px 403.764px 0px",
        }}
        id="elphaxjpct3y"
        className="animable"
      />
      <path
        d="M122.1,399.28c.19.12-2,3.6-4.9,7.78-1.44,2.09-2.77,4-3.76,5.3a13.26,13.26,0,0,1-1.68,2.1,13.57,13.57,0,0,1,1.38-2.31c.91-1.39,2.19-3.3,3.63-5.39s2.76-4,3.72-5.32l1.16-1.59A4.07,4.07,0,0,1,122.1,399.28Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "116.936px 406.87px 0px",
        }}
        id="elkzewyfoypw"
        className="animable"
      />
      <path
        d="M121.1,417.84a16.46,16.46,0,0,1,1.48-2.72c1-1.64,2.38-3.87,3.92-6.35.77-1.23,1.51-2.41,2.11-3.5a4.05,4.05,0,0,0,.37-3.09,7.68,7.68,0,0,0-1.42-2.65s.27.14.64.54a5.18,5.18,0,0,1,1.13,2,4.33,4.33,0,0,1-.29,3.41c-.6,1.13-1.33,2.32-2.09,3.56-1.55,2.48-3,4.68-4.06,6.27A21.86,21.86,0,0,1,121.1,417.84Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "125.326px 408.685px 0px",
        }}
        id="elbw5dqrr94i5"
        className="animable"
      />
      <path
        d="M129.85,419.5a9.32,9.32,0,0,1,.89-2.59l1.19-2.72a22.25,22.25,0,0,0,1.22-3.35,3.94,3.94,0,0,0-.53-3.25,3.07,3.07,0,0,0-1.19-.85,3.29,3.29,0,0,0-1.24-.35,5,5,0,0,0-1.79.63,2.13,2.13,0,0,1-.68.28s.19-.19.58-.46a8.19,8.19,0,0,1,.75-.45,2.94,2.94,0,0,1,1.14-.37,3.69,3.69,0,0,1,1.42.32,3.38,3.38,0,0,1,1.38.94,4.29,4.29,0,0,1,.66,3.67,19.14,19.14,0,0,1-1.29,3.44c-.47,1-.92,1.91-1.29,2.67A25.75,25.75,0,0,1,129.85,419.5Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "130.764px 412.76px 0px",
        }}
        id="elgro4vqldpjr"
        className="animable"
      />
      <path
        d="M132.92,403.76a1.78,1.78,0,0,1,.7.21,8.17,8.17,0,0,1,1.74,1,9.48,9.48,0,0,1,2.69,12.06,8.15,8.15,0,0,1-1.13,1.67c-.32.35-.53.51-.55.49s.66-.82,1.35-2.32a9.64,9.64,0,0,0-2.59-11.61A24.06,24.06,0,0,0,132.92,403.76Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "136.044px 411.476px 0px",
        }}
        id="elihsj1ilnynh"
        className="animable"
      />
      <path
        d="M103.62,394.19a48.84,48.84,0,0,1-3.77,6A48,48,0,0,1,95.66,406a48,48,0,0,1,3.76-6A48.68,48.68,0,0,1,103.62,394.19Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "99.64px 400.095px 0px",
        }}
        id="elg068qysyuw7"
        className="animable"
      />
      <path
        d="M211.52,383.47l-10.4-2.35-10-9.41a7.15,7.15,0,0,0-4.46-1.92l-18.17-1a7.06,7.06,0,0,0-5,1.66l-13,11L207.2,409l7.78.69Z"
        style={{
          fill: "rgb(255, 190, 157)",
          transformOrigin: "182.735px 389.233px 0px",
        }}
        id="elqc4t7uwyi5"
        className="animable"
      />
      <path
        d="M180.71,378.62c0,.08-1.34-.16-3.5-.44s-5.24-.53-8.23.86a21.24,21.24,0,0,0-3.92,2.45c-1.15.86-2.18,1.66-3.09,2.27a16.44,16.44,0,0,1-2.25,1.31,3.24,3.24,0,0,1-.89.33c0-.08,1.19-.63,2.93-1.94.87-.64,1.87-1.47,3-2.36a20.63,20.63,0,0,1,4-2.54,11.77,11.77,0,0,1,4.64-1,20.68,20.68,0,0,1,3.85.3c1.09.17,2,.37,2.54.52A2.79,2.79,0,0,1,180.71,378.62Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "169.77px 381.479px 0px",
        }}
        id="el1wdxdmtajpk"
        className="animable"
      />
      <path
        d="M184.44,388.74c0,.08-.74-.25-2-.37a10.35,10.35,0,0,0-4.67.65,24,24,0,0,0-4.28,2.19,8.94,8.94,0,0,1-1.77,1,7.29,7.29,0,0,1,1.56-1.3,18.46,18.46,0,0,1,4.31-2.37,9.56,9.56,0,0,1,4.9-.53A3.71,3.71,0,0,1,184.44,388.74Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "178.08px 390.054px 0px",
        }}
        id="elsmeqa1zhjpk"
        className="animable"
      />
      <path
        d="M207.83,390.42c-.13.08-1.59-2.1-3.52-4.68s-3.59-4.61-3.48-4.71a23.35,23.35,0,0,1,3.89,4.4A22.94,22.94,0,0,1,207.83,390.42Z"
        style={{
          fill: "rgb(235, 153, 110)",
          transformOrigin: "204.327px 385.726px 0px",
        }}
        id="elskfjwuo9hni"
        className="animable"
      />
      <path
        d="M179.21,369.82l23-21.45a2.2,2.2,0,0,0-.16-3.36h0a2.2,2.2,0,0,0-2.81.11l-27,24.22"
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "187.577px 357.188px 0px",
        }}
        id="eli3vx69jcw2"
        className="animable"
      />
      <g id="ell602g698qn">
        <g
          style={{
            opacity: 0.3,
            transformOrigin: "187.577px 357.188px 0px",
          }}
          className="animable"
        >
          <path
            d="M179.21,369.82l23-21.45a2.2,2.2,0,0,0-.16-3.36h0a2.2,2.2,0,0,0-2.81.11l-27,24.22"
            id="elk42hk3ezgm"
            style={{
              transformOrigin: "187.577px 357.188px 0px",
            }}
            className="animable"
          />
        </g>
      </g>
    </g>
    <g
      id="freepik--character-2--inject-19"
      style={{
        transformOrigin: "391.734px 281.896px 0px",
      }}
      className="animable"
    >
      <path
        d="M367.44,163.08c-.36-.14-14.31-3.8-13.47,10.31s14.83,10.35,14.84,9.94S367.44,163.08,367.44,163.08Z"
        style={{
          fill: "rgb(183, 136, 118)",
          transformOrigin: "361.372px 173.319px 0px",
        }}
        id="el1qvegp8tqs5"
        className="animable"
      />
      <path
        d="M364.18,178.25c-.07-.05-.24.18-.64.4a2.44,2.44,0,0,1-1.84.14c-1.52-.45-2.92-2.74-3.14-5.28A8.24,8.24,0,0,1,359,170a2.84,2.84,0,0,1,1.74-2,1.25,1.25,0,0,1,1.49.59c.21.38.14.67.21.69s.29-.26.14-.83a1.52,1.52,0,0,0-.61-.85,1.82,1.82,0,0,0-1.38-.28,3.45,3.45,0,0,0-2.43,2.34,8.22,8.22,0,0,0-.62,4c.26,2.84,1.88,5.41,3.95,5.88a2.64,2.64,0,0,0,2.23-.51C364.17,178.59,364.23,178.27,364.18,178.25Z"
        style={{
          fill: "rgb(170, 101, 80)",
          transformOrigin: "360.849px 173.445px 0px",
        }}
        id="elt4jkdqpad5"
        className="animable"
      />
      <path
        d="M402.66,112.69c7.17,0,14.47,0,23.44,4.69,5.66,3,9.18,7.63,12.13,13.29a30.6,30.6,0,0,1,3.34,14.89c-.26,4.88-.28,8.29-2.23,12.77"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "422.123px 135.51px 0px",
        }}
        id="elkh2kark8hto"
        className="animable"
      />
      <path
        d="M399.79,113.8a38,38,0,0,1,40.65,35.94c.92,17.67,1.73,36.78,1.54,46.4-.42,20.66-21.11,23.71-21.11,23.71s.46,6.12,1,13.72a27.22,27.22,0,0,1-26.12,29h0a27.2,27.2,0,0,1-28.22-26.18l-3.07-83.26a38,38,0,0,1,35.36-39.35Z"
        style={{
          fill: "rgb(183, 136, 118)",
          transformOrigin: "403.22px 188.147px 0px",
        }}
        id="el942sloqxwtm"
        className="animable"
      />
      <path
        d="M420.87,219.85s-12.7.66-26.5-8.17c0,0,6.25,15,26.87,13.34Z"
        style={{
          fill: "rgb(170, 101, 80)",
          transformOrigin: "407.805px 218.414px 0px",
        }}
        id="el5yc88ryey6j"
        className="animable"
      />
      <path
        d="M432.52,161.23a2.94,2.94,0,0,1-2.86,3,2.81,2.81,0,0,1-3-2.68,3,3,0,0,1,2.86-3A2.82,2.82,0,0,1,432.52,161.23Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "429.59px 161.39px 0px",
        }}
        id="el4cwp00o032c"
        className="animable"
      />
      <path
        d="M435.39,157.05c-.37.38-2.59-1.29-5.77-1.31s-5.51,1.56-5.85,1.17c-.17-.18.21-.87,1.23-1.6a8,8,0,0,1,4.69-1.4,7.68,7.68,0,0,1,4.58,1.52C435.24,156.18,435.57,156.88,435.39,157.05Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "429.584px 155.507px 0px",
        }}
        id="elru9cbyncbpc"
        className="animable"
      />
      <path
        d="M401.39,161.23a2.94,2.94,0,0,1-2.87,3,2.82,2.82,0,0,1-3-2.68,3,3,0,0,1,2.87-3A2.83,2.83,0,0,1,401.39,161.23Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "398.455px 161.39px 0px",
        }}
        id="elvja29si0c2p"
        className="animable"
      />
      <path
        d="M404.18,157.4c-.37.38-2.6-1.29-5.77-1.31s-5.51,1.55-5.86,1.16c-.16-.17.21-.87,1.24-1.6a8.09,8.09,0,0,1,4.69-1.4,7.82,7.82,0,0,1,4.58,1.52C404,156.53,404.36,157.22,404.18,157.4Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "398.37px 155.853px 0px",
        }}
        id="elkx1w81nxn4b"
        className="animable"
      />
      <path
        d="M415.51,181c0-.18,2-.5,5.16-.89.81-.08,1.58-.23,1.72-.78a4.06,4.06,0,0,0-.52-2.41c-.74-2-1.53-4-2.35-6.19-3.26-8.82-5.61-16.06-5.25-16.19s3.29,6.91,6.54,15.73c.79,2.17,1.54,4.24,2.25,6.23a4.69,4.69,0,0,1,.4,3.18,2.09,2.09,0,0,1-1.35,1.18,6.15,6.15,0,0,1-1.37.18C417.52,181.21,415.52,181.21,415.51,181Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "418.905px 167.851px 0px",
        }}
        id="els501452e0mc"
        className="animable"
      />
      <path
        d="M406.15,183.15c.52,0,.49,3.42,3.42,5.89s6.61,2.12,6.63,2.6c0,.22-.83.67-2.38.7a8.6,8.6,0,0,1-5.53-2,7.53,7.53,0,0,1-2.65-4.94C405.52,184,405.91,183.13,406.15,183.15Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "410.909px 187.745px 0px",
        }}
        id="elt2plsvomo1"
        className="animable"
      />
      <path
        d="M405.09,145c-.32.86-3.48.43-7.21.85s-6.75,1.41-7.24.63c-.21-.37.32-1.18,1.54-2a12.63,12.63,0,0,1,11-1.12C404.55,143.87,405.23,144.55,405.09,145Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "397.852px 144.679px 0px",
        }}
        id="elz4vz3ty70o8"
        className="animable"
      />
      <path
        d="M434.94,145.75c-.56.72-2.74-.05-5.37-.1s-4.86.44-5.37-.32c-.22-.37.13-1.09,1.11-1.76a7.77,7.77,0,0,1,8.66.34C434.89,144.66,435.19,145.4,434.94,145.75Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "429.581px 144.235px 0px",
        }}
        id="el5n5lrb45ibp"
        className="animable"
      />
      <path
        d="M364.67,159.6s.9,4.78,1.14,6.93c.09.79,0,9.54,2.11,10,1.83.38,5.46-1.29,7.08-4.91s4.7-12.31,4.36-16.26c-.45-5.38-.7-10.86.38-16.16s3.61-10.46,7.86-13.8,10.4-4.49,15.24-2.07c3,1.5,5.21,4.15,8,6a12.41,12.41,0,0,0,8.52,2.21,8,8,0,0,0,6.59-5.4c.88-3.15-.74-6.6-3.27-8.69s-5.78-3.05-9-3.69a48.39,48.39,0,0,0-26.82,1.88c-8.47,3.29-16,9.61-19.6,17.94-4.18,9.63-3.7,15.54-2.59,26"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "395.045px 144.632px 0px",
        }}
        id="elddxco8733js"
        className="animable"
      />
      <path
        d="M411.89,161.36a24.26,24.26,0,0,0-.54-3.4,14,14,0,0,0-5.11-7.61,13.74,13.74,0,0,0-5.83-2.56,13.93,13.93,0,0,0-7.26.61,12.84,12.84,0,0,0-3.48,1.89,12.69,12.69,0,0,0-2.92,2.93,13.76,13.76,0,0,0,0,16.29,13.09,13.09,0,0,0,2.92,2.93,12.8,12.8,0,0,0,3.48,1.88,13.94,13.94,0,0,0,7.26.62,13.7,13.7,0,0,0,5.83-2.57,14,14,0,0,0,5.12-7.6,24.94,24.94,0,0,0,.53-3.41.6.6,0,0,1,0,.23v.67a5.74,5.74,0,0,1-.09,1.09,9.24,9.24,0,0,1-.26,1.46,14,14,0,0,1-5.09,7.86,14.17,14.17,0,0,1-13.47,2.1,13.2,13.2,0,0,1-3.62-1.93,13.4,13.4,0,0,1-3-3,14.23,14.23,0,0,1,0-16.9,14.42,14.42,0,0,1,6.65-5,14.38,14.38,0,0,1,7.49-.59,14.12,14.12,0,0,1,6,2.7,14,14,0,0,1,5.09,7.85,9.34,9.34,0,0,1,.26,1.47,5.64,5.64,0,0,1,.09,1.08v.67C411.91,161.29,411.9,161.36,411.89,161.36Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "397.76px 161.359px 0px",
        }}
        id="el7bxtqc8y75y"
        className="animable"
      />
      <path
        d="M448.26,161.36a26.34,26.34,0,0,0-.54-3.4,14,14,0,0,0-5.12-7.61,13.69,13.69,0,0,0-5.83-2.56,13.89,13.89,0,0,0-7.25.61,13.89,13.89,0,0,0-6.4,4.82,13.72,13.72,0,0,0,0,16.29,13.05,13.05,0,0,0,2.91,2.93,12.71,12.71,0,0,0,3.49,1.88,13.9,13.9,0,0,0,7.25.62,13.7,13.7,0,0,0,5.83-2.57,14,14,0,0,0,5.12-7.6,26.31,26.31,0,0,0,.54-3.41,2.07,2.07,0,0,1,0,.23c0,.18,0,.4,0,.67a6.56,6.56,0,0,1-.08,1.09,9.36,9.36,0,0,1-.27,1.46,13.9,13.9,0,0,1-5.09,7.86,14.07,14.07,0,0,1-6,2.69,14.21,14.21,0,0,1-7.5-.59,14.15,14.15,0,0,1-6.64-5,14.19,14.19,0,0,1,0-16.9,13.31,13.31,0,0,1,3-3,13.51,13.51,0,0,1,3.62-1.94,14.41,14.41,0,0,1,7.5-.59,14.08,14.08,0,0,1,6,2.7,13.91,13.91,0,0,1,5.09,7.85,9.46,9.46,0,0,1,.27,1.47,6.45,6.45,0,0,1,.08,1.08c0,.27,0,.49,0,.67A1.85,1.85,0,0,1,448.26,161.36Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "434.076px 161.353px 0px",
        }}
        id="ely983mgkacj"
        className="animable"
      />
      <path
        d="M420.51,159.86c0,.08-.51-.18-1.3-.44A8.38,8.38,0,0,0,416,159a8.74,8.74,0,0,0-3.1.82c-.75.37-1.17.69-1.22.62s.3-.47,1-.94a7.22,7.22,0,0,1,6.6-.44C420.15,159.44,420.55,159.82,420.51,159.86Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "416.094px 159.457px 0px",
        }}
        id="eldd35uq0mnem"
        className="animable"
      />
      <path
        d="M426.14,125c.21-1.07-.15-1.67.46-2.11s2,.82,2.46,1.34c.16.17.64-.49.83-.61a3.65,3.65,0,0,0,.36-1.37c-.09-.75-1.26-1-1.81-1.46a5.56,5.56,0,0,0-1.95-1.11,1.88,1.88,0,0,0-1.86.11,1.83,1.83,0,0,0-.42,1.4,3.83,3.83,0,0,0,.28,1.46,2,2,0,0,0,1,1.06"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "427.223px 122.243px 0px",
        }}
        id="elgjtv9i10olm"
        className="animable"
      />
      <path
        d="M363.9,148.62s10.13,3.71,15.4,5.95l-.3-5.18-14.73-5.83S363.89,147.41,363.9,148.62Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "371.6px 149.065px 0px",
        }}
        id="elvwuir4tffc"
        className="animable"
      />
      <path
        d="M364.85,140.57a58.3,58.3,0,0,1,7.17,2.75,56.51,56.51,0,0,1,7,3.23,58.3,58.3,0,0,1-7.17-2.75A56.51,56.51,0,0,1,364.85,140.57Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "371.935px 143.56px 0px",
        }}
        id="el1twps4ot39e"
        className="animable"
      />
      <path
        d="M367.9,249.69l-.78-24.51s4.87,22.43,30.38,22.14c24.37-.28,23.93-19.76,23.93-19.76l.87,9.88v39.91l-15.9,65.11L386.09,345l-22.44-89Z"
        style={{
          fill: "rgb(43, 178, 161)",
          transformOrigin: "392.975px 285.09px 0px",
        }}
        id="elx4yhgtn4iog"
        className="animable"
      />
      <path
        d="M419.16,241.85a2.5,2.5,0,0,1-.29.52,14.74,14.74,0,0,1-1,1.42,21,21,0,0,1-4.54,4.4,26.12,26.12,0,0,1-8.48,4,29.88,29.88,0,0,1-11.45.79,37.1,37.1,0,0,1-11-3.31,47.88,47.88,0,0,1-8-4.84,56.57,56.57,0,0,1-4.86-4c-.55-.5-.95-.92-1.23-1.2a2.7,2.7,0,0,1-.4-.44,2.76,2.76,0,0,1,.47.37l1.3,1.12c1.12,1,2.78,2.33,4.94,3.88a49.87,49.87,0,0,0,8,4.7,37.31,37.31,0,0,0,10.85,3.22,29.9,29.9,0,0,0,11.25-.73,26.84,26.84,0,0,0,8.4-3.82,22.52,22.52,0,0,0,4.59-4.22C418.67,242.51,419.12,241.82,419.16,241.85Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "393.535px 246.2px 0px",
        }}
        id="el2aoc0debwlk"
        className="animable"
      />
      <path
        d="M401.46,451.11l-.37-115.7L368.23,251l-1.1-25.41-20,15.14S318,341,317.51,337.54s0,113.57,0,113.57Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "359.376px 338.35px 0px",
        }}
        id="el2ose5ukf7ft"
        className="animable"
      />
      <path
        d="M400.67,450.79c-.14,0-.26-26.07-.26-58.23s.12-58.23.26-58.23.26,26.07.26,58.23S400.81,450.79,400.67,450.79Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "400.67px 392.56px 0px",
        }}
        id="elb0qeromqdyf"
        className="animable"
      />
      <polygon
        points="347.4 257.21 351.46 260.29 348.61 279.01 396.57 329.25 353.01 272.04 356.72 257.21 347.4 257.21"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "371.985px 293.23px 0px",
        }}
        id="elt9uwadlcdj"
        className="animable"
      />
      <path
        d="M403.26,391.84l-73.61-21.58-2.44-55.91,20.19-73.66S299.28,264.7,296,280s-11.17,52.42-12,79.27c-.75,24-.86,40,4.62,47.71,15.7,21.94,119.14,25.86,160.46,0"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "366.355px 332.813px 0px",
        }}
        id="elqied2lwbmgo"
        className="animable"
      />
      <path
        d="M397.5,330.46s-.09-.08-.24-.27-.39-.48-.66-.82l-2.5-3.2c-2.18-2.82-5.29-6.86-9.12-11.84l-29.93-39.17-2.25-3-.08-.1,0-.12c1.28-5.12,2.53-10.09,3.72-14.83l.24.32-9.32,0h-.24v-.24c0-6,0-11.6,0-16.52v-.1l.08-.06,14.47-11,4-3,1-.77a1.9,1.9,0,0,1,.37-.24l-.33.29-1,.81-3.91,3.06-14.36,11.14.08-.16c0,4.92,0,10.47,0,16.52l-.24-.23,9.32,0H357l-.08.32-3.7,14.83-.05-.22,2.26,2.95,29.8,39.27,9,11.93c1,1.4,1.85,2.49,2.42,3.26.26.36.46.65.62.86Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "372.31px 277.98px 0px",
        }}
        id="ela092jw38dcv"
        className="animable"
      />
      <path
        d="M430.31,236.33c-5.87-10.26-8.92-9.2-8.92-9.2l.31,3.66v7.69l-2.79,33-17.83,71.69.37,107.94h67.41l8.39-131.76-.76-56.77Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "439.165px 339.104px 0px",
        }}
        id="eljpkun7jhqxf"
        className="animable"
      />
      <path
        d="M476.5,262.58s17.45,12.88,19,33.61c1.23,16.83,4,62.92,4.55,72.95.8,15.62-11.37,52.21-43.05,41.85-19-6.22-89.18-30.07-89.18-30.07l4.67-29.81,83.3,5,1.66-69.48Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "433.953px 337.694px 0px",
        }}
        id="elfhbgk965u66"
        className="animable"
      />
      <path
        d="M371.5,354.54s-23.58-2.55-29-1.23c-5.25,1.27-12.72,7.69-12.72,7.69l.11,9.31,37.93,10.61Z"
        style={{
          fill: "rgb(183, 136, 118)",
          transformOrigin: "350.64px 366.925px 0px",
        }}
        id="elup9kefxi9om"
        className="animable"
      />
      <path
        d="M401.09,332.39a1.69,1.69,0,0,1,.11-.32c.08-.23.2-.54.35-.92.33-.84.81-2,1.41-3.52l5.28-12.92,7.88-19.14c.75-1.79,1.49-3.66,2.42-5.49s1.94-3.66,3-5.49a122.5,122.5,0,0,1,7.48-10.8c1.36-1.75,2.73-3.46,4.15-5.1l0,.27-2.94-7.47-.1-.25.25-.09L442.65,257l-.13.34c-5.7-9.52-10.51-17.56-13.93-23.26-1.68-2.82-3.12-5.08-4.65-6a6.31,6.31,0,0,0-1.86-.75,6.37,6.37,0,0,1-.69-.17,5.79,5.79,0,0,1,.7.09,5.93,5.93,0,0,1,1.93.71,8.53,8.53,0,0,1,2.36,2.29,40.44,40.44,0,0,1,2.45,3.68l14.1,23.18.15.25-.28.09-12.27,4.21.15-.33,2.95,7.47.06.14-.11.12c-1.4,1.62-2.77,3.33-4.12,5.08A123.23,123.23,0,0,0,422,284.86c-1.07,1.82-2.12,3.61-3,5.44s-1.66,3.65-2.41,5.45c-3,7.18-5.72,13.65-8,19.1s-4.14,9.79-5.43,12.85c-.64,1.49-1.15,2.66-1.5,3.49-.17.38-.3.67-.41.9A1.26,1.26,0,0,1,401.09,332.39Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "422.085px 279.775px 0px",
        }}
        id="elvalmakus23"
        className="animable"
      />
      <path
        d="M486.07,405.19a1.92,1.92,0,0,1-.29.31l-.9.87a25.89,25.89,0,0,1-3.83,2.9,25.56,25.56,0,0,1-7,2.94,28.17,28.17,0,0,1-10,.64,31.62,31.62,0,0,1-5.75-1.22c-2-.62-3.92-1.39-6-2.11-4.11-1.5-8.53-3-13.17-4.68-9.3-3.26-19.55-6.74-30.35-10.29-21.6-7.09-41.3-13.07-55.58-17.34l-16.92-5-4.6-1.37-1.19-.36a2.32,2.32,0,0,1-.4-.15l.42.09,1.2.32,4.63,1.27c4,1.12,9.81,2.77,17,4.85,14.3,4.17,34,10.1,55.63,17.2,10.81,3.54,21.06,7,30.35,10.32,4.64,1.65,9.05,3.22,13.16,4.73,2.06.74,4,1.52,6,2.14a31,31,0,0,0,5.67,1.23,28.15,28.15,0,0,0,9.92-.55,26.31,26.31,0,0,0,7-2.83,28.36,28.36,0,0,0,3.86-2.81l.93-.83A2.28,2.28,0,0,1,486.07,405.19Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "408.08px 391.683px 0px",
        }}
        id="elzmon868hcm"
        className="animable"
      />
      <path
        d="M329.85,370.48c0,.11-1.33-.18-3.44-.09a15.12,15.12,0,0,0-3.64.6,16.56,16.56,0,0,0-4.1,1.87,19.16,19.16,0,0,0-5.71,5.86c-1.11,1.8-1.6,3-1.69,3a3.52,3.52,0,0,1,.28-.89,15.41,15.41,0,0,1,1.1-2.3,17.86,17.86,0,0,1,5.74-6.11,16.13,16.13,0,0,1,4.25-1.89,14.7,14.7,0,0,1,3.76-.51,12.27,12.27,0,0,1,2.54.23A3.42,3.42,0,0,1,329.85,370.48Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "320.56px 375.869px 0px",
        }}
        id="elkr3hr2x4gle"
        className="animable"
      />
      <path
        d="M306.66,363.18s0-.1.09-.27a2.64,2.64,0,0,1,.46-.65,4.73,4.73,0,0,1,2.62-1.3,13.29,13.29,0,0,1,4.32,0,24.5,24.5,0,0,1,5.13,1.25,22.52,22.52,0,0,1,8.13,5,17,17,0,0,1,1.89,2.21,4.38,4.38,0,0,1,.55.91c-.09.07-.91-1.21-2.69-2.85a23.87,23.87,0,0,0-8.06-4.75,27.09,27.09,0,0,0-5-1.27,14.07,14.07,0,0,0-4.2-.12,4.82,4.82,0,0,0-2.53,1.08C306.87,362.85,306.7,363.2,306.66,363.18Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "318.255px 365.558px 0px",
        }}
        id="el1haxrtt974g"
        className="animable"
      />
      <path
        d="M334.14,299a7.85,7.85,0,0,1-.34-1.51c-.15-.48-.36-1.05-.59-1.71s-.63-1.38-1.09-2.14a30.61,30.61,0,0,0-3.75-5,40.79,40.79,0,0,0-5.75-5.06,34.66,34.66,0,0,0-12.57-5.77,26.24,26.24,0,0,0-4.19-.54,9.39,9.39,0,0,1-1.55-.12,8.71,8.71,0,0,1,1.56-.08,21.3,21.3,0,0,1,4.25.38,33,33,0,0,1,12.8,5.71,37.86,37.86,0,0,1,5.8,5.15,28.75,28.75,0,0,1,3.72,5.14c.45.79.74,1.56,1,2.21s.4,1.28.52,1.77A10.55,10.55,0,0,1,334.14,299Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "319.225px 288.032px 0px",
        }}
        id="elyra2ukogfy"
        className="animable"
      />
      <path
        d="M473.89,384.46a1.77,1.77,0,0,1,0-.36l0-1.05c0-.92-.07-2.27-.2-4a56.1,56.1,0,0,0-3-14.41,20.39,20.39,0,0,0-2-4.4,8.63,8.63,0,0,0-3.9-3.31c-3.35-1.44-7.38-1.25-11.48-1.48l-26.84-1.13c-22.18-1-41.91-1.8-54.31-2.46l.17-.14c-.75,4.56-1.35,8.17-1.76,10.68-.21,1.21-.37,2.15-.49,2.82-.05.3-.1.54-.13.73l-.06.24a1.17,1.17,0,0,1,0-.25l.1-.73c.09-.67.23-1.62.4-2.84.38-2.51.92-6.13,1.61-10.7l0-.14h.15l54.31,2.26L453.3,355c2.06.1,4.07.15,6,.31a17.39,17.39,0,0,1,5.6,1.26A9.07,9.07,0,0,1,469,360a20.36,20.36,0,0,1,2,4.5A54,54,0,0,1,473.84,379c.11,1.74.11,3.1.1,4q0,.66,0,1.05C473.9,384.33,473.89,384.46,473.89,384.46Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "421.914px 367.995px 0px",
        }}
        id="elpu5p9qk9tlf"
        className="animable"
      />
      <path
        d="M458,319.24c.14,0,.26,8.07.26,18s-.12,18-.26,18-.26-8.07-.26-18S457.86,319.24,458,319.24Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "458px 337.24px 0px",
        }}
        id="elq6db6zwfv7t"
        className="animable"
      />
      <path
        d="M296.58,278.17s-.06.23-.2.66-.39,1.08-.6,2c-.49,1.73-1.11,4.31-1.8,7.68-1.39,6.74-3.1,16.6-5,28.94-.95,6.18-2,13-3,20.31-.51,3.67-.83,7.49-1.09,11.45s-.47,8-.63,12.24c-.3,8.41-.48,17.29-.24,26.54.08,2.31.16,4.65.35,7,.06,1.18.26,2.34.4,3.51a29.59,29.59,0,0,0,.85,3.46,19.71,19.71,0,0,0,3.32,6.33,14.2,14.2,0,0,0,5.68,4.38h0a86.94,86.94,0,0,0,14.83,5.66c5.17,1.55,10.51,2.8,15.93,3.91a219.06,219.06,0,0,0,35.8,4.36,166.27,166.27,0,0,0,17.09-.44c5.57-.39,11-1,16.25-1.75a170.93,170.93,0,0,0,29.16-6.78,148.6,148.6,0,0,0,23.58-10.12l0,.33L415,396.37l-8.71-3.11-2.25-.82c-.51-.19-.76-.3-.76-.3l.77.25,2.27.77,8.74,3,32.4,11.3.39.13-.36.2A148.91,148.91,0,0,1,423.86,418a170.73,170.73,0,0,1-29.23,6.85c-5.26.79-10.7,1.38-16.28,1.77a167.18,167.18,0,0,1-17.14.46,218.76,218.76,0,0,1-35.89-4.36c-5.44-1.12-10.79-2.37-16-3.92a88.49,88.49,0,0,1-14.92-5.71h0a14.72,14.72,0,0,1-5.92-4.54,20.22,20.22,0,0,1-3.39-6.5,29.16,29.16,0,0,1-.87-3.52c-.15-1.2-.34-2.38-.4-3.56-.19-2.36-.27-4.71-.34-7-.24-9.28,0-18.16.28-26.58q.24-6.3.65-12.25c.27-4,.6-7.8,1.12-11.47,1-7.35,2.06-14.14,3-20.31,2-12.34,3.74-22.19,5.19-28.92.72-3.37,1.36-5.95,1.88-7.68.22-.87.5-1.51.64-1.94S296.58,278.17,296.58,278.17Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "365.616px 352.65px 0px",
        }}
        id="eld0tipdabwm5"
        className="animable"
      />
      <path
        d="M329.74,363.57c12.31-13.63,11.77-37.86,4.7-55.05-.65,18.62-2.85,35.14-4.53,53.24"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "334.591px 336.045px 0px",
        }}
        id="el3aylz4lxu1"
        className="animable"
      />
      <path
        d="M334.47,312a2.77,2.77,0,0,1,0,.6c0,.45-.06,1-.11,1.7-.12,1.55-.28,3.67-.49,6.25-.44,5.34-1,12.59-1.69,20.61l-1.62,20.62c-.19,2.58-.35,4.7-.47,6.25l-.15,1.69a4,4,0,0,1-.08.59,2,2,0,0,1,0-.59c0-.45,0-1,.05-1.71.05-1.48.16-3.62.3-6.26.31-5.28.81-12.58,1.47-20.63s1.32-15.33,1.85-20.6c.27-2.58.49-4.69.65-6.24.09-.69.16-1.25.21-1.69A2.2,2.2,0,0,1,334.47,312Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "332.162px 341.155px 0px",
        }}
        id="elpdydv0rl6q"
        className="animable"
      />
      <path
        d="M364.81,287.38a105.27,105.27,0,0,1,.26,10.57,105.44,105.44,0,0,1-.26,10.57c-.14,0-.26-4.74-.26-10.57S364.67,287.38,364.81,287.38Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "364.827px 297.95px 0px",
        }}
        id="elb24ad610fj6"
        className="animable"
      />
      <path
        d="M356.79,322.49a10.82,10.82,0,0,0,2.27,5.6c1.16,1.51,3.51,3,5.4,2.87a8.24,8.24,0,0,0,7.27-3.65c2.3-3.66,2.87-11.29,1-15.8-1.27-3.1-4.12-6-7.47-6.07-4.4-.1-7.16,3.08-8.1,6.3"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "365.319px 318.204px 0px",
        }}
        id="el03sjrq9o4pug"
        className="animable"
      />
      <path
        d="M357.18,311.74a6.82,6.82,0,0,1,.81-2.16,8.9,8.9,0,0,1,1.65-2.17,7.72,7.72,0,0,1,3.12-1.82,7.81,7.81,0,0,1,2.09-.34,6.29,6.29,0,0,1,2.29.3,9,9,0,0,1,4.08,2.93,11.79,11.79,0,0,1,2.41,5,21.94,21.94,0,0,1,.44,5.93c-.2,3.13-.82,6.25-2.55,8.63a7.8,7.8,0,0,1-3.32,2.5,8.92,8.92,0,0,1-3.76.63h0a5.61,5.61,0,0,1-2.69-.66,9.05,9.05,0,0,1-2-1.42,10.13,10.13,0,0,1-2.2-3.28,9.33,9.33,0,0,1-.7-2.43,5.25,5.25,0,0,1-.06-.66.68.68,0,0,1,0-.23,14.12,14.12,0,0,0,1,3.22,10.15,10.15,0,0,0,2.22,3.13,8.84,8.84,0,0,0,2,1.32,5.3,5.3,0,0,0,2.5.59h0a8,8,0,0,0,6.65-3c1.61-2.23,2.23-5.3,2.42-8.36.22-4-.45-7.94-2.7-10.6A8.66,8.66,0,0,0,367,306a6.19,6.19,0,0,0-2.15-.31,7.61,7.61,0,0,0-2,.29,7.48,7.48,0,0,0-3,1.69,9.07,9.07,0,0,0-1.67,2.05A20,20,0,0,0,357.18,311.74Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "365.446px 318.211px 0px",
        }}
        id="ellahvcvoqcis"
        className="animable"
      />
      <path
        d="M354.45,322.69a10.85,10.85,0,0,0,2.27,5.6c1.15,1.51,3.51,3,5.4,2.87a8.25,8.25,0,0,0,7.27-3.65c2.3-3.67,2.87-11.29,1-15.8-1.27-3.11-4.12-6-7.47-6.07a8.27,8.27,0,0,0-8.1,6.29"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "362.979px 318.405px 0px",
        }}
        id="elpxzd5ie8ii"
        className="animable"
      />
      <path
        d="M354.84,311.93a6.82,6.82,0,0,1,.81-2.15,8.9,8.9,0,0,1,1.65-2.17,7.72,7.72,0,0,1,5.21-2.16,6.32,6.32,0,0,1,2.29.3,9,9,0,0,1,4.08,2.93,11.66,11.66,0,0,1,2.4,5,21.54,21.54,0,0,1,.45,5.93c-.2,3.13-.82,6.24-2.55,8.63a7.8,7.8,0,0,1-3.32,2.5,8.92,8.92,0,0,1-3.76.63h0a5.61,5.61,0,0,1-2.69-.67,8.71,8.71,0,0,1-2-1.41,9.9,9.9,0,0,1-2.2-3.29,9.28,9.28,0,0,1-.7-2.42c0-.29-.06-.51-.07-.66a.82.82,0,0,1,0-.23,14.14,14.14,0,0,0,1,3.21,9.9,9.9,0,0,0,2.22,3.14,8.84,8.84,0,0,0,2,1.32,5.42,5.42,0,0,0,2.5.59h0a8,8,0,0,0,6.64-3c1.62-2.24,2.24-5.3,2.43-8.36.22-4-.45-7.94-2.7-10.6a8.56,8.56,0,0,0-3.85-2.82,6.14,6.14,0,0,0-2.14-.31,7.54,7.54,0,0,0-5,2,9.07,9.07,0,0,0-1.67,2.05A18.06,18.06,0,0,0,354.84,311.93Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "363.102px 318.411px 0px",
        }}
        id="eltsnhyrm62jt"
        className="animable"
      />
      <path
        d="M358.7,310.81a5.13,5.13,0,0,0-5.37,3.09,8.87,8.87,0,0,0,0,6.53,8.11,8.11,0,0,0,1.67,2.94,4.4,4.4,0,0,0,3,1.45,5.22,5.22,0,0,0,4.13-2.44,9.34,9.34,0,0,0,1.41-7.67c-.46-1.74-1.74-3.51-3.53-3.56"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "358.268px 317.795px 0px",
        }}
        id="elq07b861gkw"
        className="animable"
      />
      <path
        d="M360,311.15s.14,0,.4,0a2.72,2.72,0,0,1,1.09.37,5.33,5.33,0,0,1,2.28,3.44,9.37,9.37,0,0,1-.62,6.12,7.76,7.76,0,0,1-2.24,2.94,4.34,4.34,0,0,1-3.86.93,5.4,5.4,0,0,1-3.08-2.52,11.29,11.29,0,0,1-1.28-3.46,7.69,7.69,0,0,1,1-6,5.38,5.38,0,0,1,3.48-2.22,3.93,3.93,0,0,1,1.15,0c.25.05.38.09.38.11a7.87,7.87,0,0,0-1.49.09,5.31,5.31,0,0,0-3.22,2.23,7.37,7.37,0,0,0-.83,5.73,10.77,10.77,0,0,0,1.25,3.29,4.89,4.89,0,0,0,2.78,2.28,3.83,3.83,0,0,0,3.41-.82,7.63,7.63,0,0,0,2.12-2.74,9.16,9.16,0,0,0,.69-5.84,5.24,5.24,0,0,0-2-3.35A4.14,4.14,0,0,0,360,311.15Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "358.246px 317.89px 0px",
        }}
        id="el7pfevi5g2dv"
        className="animable"
      />
      <path
        d="M363.74,327.25c-.08,0-.19-.32-.53-.72a3.72,3.72,0,0,0-1.71-1.09,8.59,8.59,0,0,0-2.1-.33c-.55,0-.91-.09-.91-.16s.34-.15.91-.21a5.87,5.87,0,0,1,2.26.2,3.27,3.27,0,0,1,1.85,1.38C363.82,326.84,363.8,327.24,363.74,327.25Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "361.133px 325.978px 0px",
        }}
        id="el6w8f32v0eoc"
        className="animable"
      />
      <path
        d="M364.53,309.45s-.16.35-.62.72a4.81,4.81,0,0,1-2.11.92,5,5,0,0,1-2.3-.12c-.56-.19-.86-.41-.83-.47a11.75,11.75,0,0,0,3,.08A12.49,12.49,0,0,0,364.53,309.45Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "361.599px 310.311px 0px",
        }}
        id="elqaa4jy0mmj8"
        className="animable"
      />
      <path
        d="M433.24,237.82s.12,0,.33.12a8.72,8.72,0,0,1,.89.46,17.6,17.6,0,0,1,3,2.14,21.45,21.45,0,0,1,6.44,10.18,65.11,65.11,0,0,1,2.45,11.86c.18,1.54.27,2.79.33,3.65a6.06,6.06,0,0,1,0,1.35,7.62,7.62,0,0,1-.22-1.33c-.13-.95-.3-2.17-.49-3.62a76.59,76.59,0,0,0-2.59-11.76,22.16,22.16,0,0,0-6.18-10.06A33.53,33.53,0,0,0,433.24,237.82Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "439.979px 252.7px 0px",
        }}
        id="elwqcopqcp6ps"
        className="animable"
      />
      <path
        d="M420.78,347.89c2.9-.11,5.22-3,8.12-2.84a4,4,0,1,1-.68,8c-3.05-.35-4.83-3.92-7.81-4.6"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "426.492px 349.054px 0px",
        }}
        id="elvo3rzmoqwh"
        className="animable"
      />
      <path
        d="M445.36,350.36a17.15,17.15,0,0,1-2.71-.22l.16-1c3.58.57,6.59,0,8.94-1.75,3-2.17,4.87-6.06,5.67-11.56l0-.21c.33-2.18.78-44.95.78-53.07,0-12.69-11.13-14.42-11.61-14.49s-10.57-1.77-15.94,12.08c-5.62,14.51-14.61,42.47-15.2,45.75-.08.4-.19.9-.31,1.47-.94,4.2-2.88,12.93,2.94,17.95,6.49,5.61,11.61,3.44,11.82,3.35l.41.91c-.24.1-5.88,2.55-12.88-3.5-6.28-5.42-4.25-14.54-3.27-18.93.13-.56.23-1,.3-1.43.6-3.3,9.62-31.38,15.26-45.93s16.92-12.73,17-12.7,12.44,1.9,12.44,15.47c0,6.69-.44,50.9-.79,53.22l0,.2c-.84,5.78-2.88,9.89-6.07,12.23A11.51,11.51,0,0,1,445.36,350.36Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "436.106px 308.677px 0px",
        }}
        id="eljwccn9ucrec"
        className="animable"
      />
      <path
        d="M458.73,290.74a18.42,18.42,0,0,0-2.08-5,17.21,17.21,0,0,0-3.95-4.28,17,17,0,0,0-6.43-3,18.79,18.79,0,0,0-12.65,1.65,22.62,22.62,0,0,0-4.41,3.15,1.44,1.44,0,0,1,.23-.3,6.94,6.94,0,0,1,.75-.8,15,15,0,0,1,3.25-2.37,18.58,18.58,0,0,1,12.95-1.84,17.18,17.18,0,0,1,6.6,3.16,16.6,16.6,0,0,1,4,4.47,13,13,0,0,1,1.54,3.72,8.18,8.18,0,0,1,.21,1.08C458.74,290.6,458.75,290.74,458.73,290.74Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "443.976px 284.124px 0px",
        }}
        id="elcheekstq1k"
        className="animable"
      />
      <path
        d="M484.2,370.68a35.2,35.2,0,0,0-1.38-4.26,18.56,18.56,0,0,0-6.78-8.14,14.73,14.73,0,0,0-5.5-2,47.32,47.32,0,0,0-4.85-.5l-3.29-.23a5.76,5.76,0,0,1-1.2-.16,5,5,0,0,1,1.21,0l3.3.06a41.48,41.48,0,0,1,4.9.4,14.71,14.71,0,0,1,5.71,2,18.09,18.09,0,0,1,6.84,8.44,16,16,0,0,1,.92,3.19A4.57,4.57,0,0,1,484.2,370.68Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "472.701px 363.017px 0px",
        }}
        id="elisuzhxpygp"
        className="animable"
      />
      <path
        d="M433.75,354s9.84-14.89,12.35-16.84,24-11.65,26.49-10.09,1,4.94,1,4.94,9.08-3.18,10.05-1.93a4.49,4.49,0,0,1,0,5.12s5-.77,5,.78a6.37,6.37,0,0,1-2.9,5.22c-2.32,1.74-17.41,11.78-18.62,14.56l-4.89-.3Z"
        style={{
          fill: "rgb(183, 136, 118)",
          transformOrigin: "461.195px 341.331px 0px",
        }}
        id="elsbm8tlvzcfr"
        className="animable"
      />
      <path
        d="M462.05,349.73a4.26,4.26,0,0,1,.45-.94,18.2,18.2,0,0,1,1.62-2.32,27,27,0,0,1,7.13-6,53.93,53.93,0,0,1,8.44-4c1.11-.43,2-.75,2.65-1a4.36,4.36,0,0,1,1-.28,3.8,3.8,0,0,1-.93.47l-2.59,1.11a68.53,68.53,0,0,0-8.3,4.07,29.81,29.81,0,0,0-7.12,5.79C462.84,348.48,462.14,349.78,462.05,349.73Z"
        style={{
          fill: "rgb(170, 101, 80)",
          transformOrigin: "472.695px 342.461px 0px",
        }}
        id="eltbzske2auzm"
        className="animable"
      />
      <path
        d="M449.58,349.49a5.07,5.07,0,0,1,.48-1.09c.34-.69.83-1.68,1.51-2.88a27.53,27.53,0,0,1,2.71-4,12.49,12.49,0,0,1,2.06-2.05c.83-.56,1.7-1.08,2.61-1.62a71.09,71.09,0,0,1,9.6-4.78,25.88,25.88,0,0,1,3.08-1,5.73,5.73,0,0,1,1.18-.21c0,.09-1.63.47-4.12,1.59a90.38,90.38,0,0,0-9.48,4.89c-.9.53-1.77,1.06-2.58,1.59a12.49,12.49,0,0,0-2,1.93,29.19,29.19,0,0,0-2.76,3.9l-1.66,2.78A5.32,5.32,0,0,1,449.58,349.49Z"
        style={{
          fill: "rgb(170, 101, 80)",
          transformOrigin: "461.195px 340.675px 0px",
        }}
        id="elzod5790o03i"
        className="animable"
      />
    </g>
    <g
      id="freepik--Desk--inject-19"
      style={{
        transformOrigin: "375.03px 451.38px 0px",
      }}
      className="animable"
    >
      <path
        d="M712,451.38c0,.14-150.87.26-336.94.26s-337-.12-337-.26,150.84-.26,337-.26S712,451.24,712,451.38Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "375.03px 451.38px 0px",
        }}
        id="el8ay4xyyh0ln"
        className="animable"
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
)
export default DoctorsAnimated
