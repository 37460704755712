import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import {TDataList} from "../../../../types/general";
import {getCellValue} from "../../../../utils/utils";
import {MtoJDateFormat} from "../../../../utils/date";
import {general} from "../../../../store/action";
import {connect, useSelector} from "react-redux";
import {Dispatch} from "redux";
import Chart from "../../../../assets/images/svgComponents/icon/chart";
import Tooltip from "../../general/tooltip";
import Icon from "../../general/icon";
import {useGetPendingDevices} from "../../../../hook/request/pendingDevices";
import RejectPendingDeviceModal from "../../pendingDevices/rejectPendingDeviceModal";
import ApprovePendingDeviceModal from "../../pendingDevices/approvePendingDeviceModal";
import PendingDeviceInfoModal from "../../pendingDevices/pendingDeviceInfoModal";
import EmptyBoxes from "../../../../assets/images/svgComponents/EmptyBoxes";
import {useFindAccessInAccessList} from "../../../../constants/constant/accessProcess";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  status?: string
}

type PendingDevicesProps = {
  handleShowModal: any;
}

const PendingDevices = ({
  handleShowModal
}: PendingDevicesProps) => {

  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [pendingDevicesData, setPendingDevicesData] = useState<TDataList>({
    data: [],
    count: 0
  });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "5",
    search: "",
  });

  // services
  const getPendingDevicesRequest = useGetPendingDevices(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "5",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  // useEffect(() => {
  //   getPendingDevicesRequest.refetch();
  // }, [filterValues])


  useEffect(() => {
    getPendingDevicesRequest?.data?.data?.result &&
    setPendingDevicesData(getPendingDevicesRequest?.data?.data?.result)
  }, [getPendingDevicesRequest.data])

  return (
    <div className="dashboard-card-box h-100">
      <div className="flex-wrap align-items-center m-2">
        <span className="d-flex align-items-center text-white font-25">
          <span className="d-flex align-items-center ms-2">
           <Chart/>
          </span>
          <span className="font-15 fw-bold text-black">
            لیست دستگاه های در صف تایید
          </span>
        </span>
      </div>
      <div className="h-90 m-2">
        {pendingDevicesData?.data && pendingDevicesData?.data.length > 0 ? pendingDevicesData?.data.map((item: any) => {
          return (
            <div className='d-flex align-items-center border-bottom visit-card-animation py-3'>
              <div className='w-100 mx-3'>
                <div className='d-flex justify-content-between font-12'>
                  <div>
                    <span>
                       {getCellValue(item?.device)} &nbsp;
                    </span>
                    <span>
                      :IMEI
                    </span>
                  </div>
                  <div>
                    <span>
                      تاریخ درخواست:
                    </span>
                    <span>
                      &nbsp; {getCellValue(MtoJDateFormat(item?.updateDate))}
                    </span>
                  </div>
                </div>
                <div className='d-flex justify-content-between font-9 text-gray mt-2'>
                  <div>
                    <span>
                      کد دستگاه:
                    </span>
                    <span className='me-2'>
                      {getCellValue(item?.serial)}
                    </span>
                  </div>
                  <div className='d-flex'>
                    <span>
                      وضعیت:
                    </span>
                    <span className='me-2'>
                      {item?.pending ?
                        <div>
                          <span className='bg-gray4 px-3 py-1 rounded text-nowrap'>
                            در انتظار بررسی
                          </span>
                        </div>
                        :
                        <div>
                          <span className='px-3 py-1 text-green2 rounded text-nowrap' style={{backgroundColor: '#E6FAF2'}}>
                            تایید شده
                          </span>
                        </div>
                      }
                    </span>
                  </div>
                </div>
              </div>
              { findAccessInAccessList('DEVICE_CONFIRM') &&
                <div className="d-flex me-auto">
                  <Tooltip alt={'رد کردن'} className="tooltip-pre-line">
                    <span
                      onClick={() => item?.pending && handleShowModal('rejectPendingDeviceModal', item?.id)}
                      className={`d-flex mx-2 font-20 cur-pointer ${item?.pending ? 'text-red-hover' : 'disabled text-gray3'}`}
                    >
                      <Icon name='close-square'/>
                    </span>
                  </Tooltip>
                  <Tooltip alt={'تایید کردن'} className="tooltip-pre-line">
                    <span
                      onClick={() => item?.pending && handleShowModal('approvePendingDeviceModal', item?.id)}
                      className={`d-flex text-green2-hover r mx-2 font-20 cur-pointer ${item?.pending ? 'text-green2-hover' : 'disabled text-gray3'}`} aria-disabled
                    >
                      <Icon name='check-square'/>
                    </span>
                  </Tooltip>
                  <Tooltip alt={'جزئیات'} className="tooltip-pre-line">
                    <span
                      onClick={() => handleShowModal('pendingDeviceInfoModal', item?.id)}
                      className="d-flex text-hover mx-2 font-20 cur-pointer"
                    >
                      <Icon name='eye'/>
                    </span>
                  </Tooltip>
                </div>
              }
            </div>
          )
        }) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <EmptyBoxes/>
            <div className='font-12 mt-2'>
              لیستی برای نمایش وجود ندارد.
            </div>
          </div>
        )}
      </div>
      { findAccessInAccessList('DEVICE_CONFIRM') && <RejectPendingDeviceModal/>}
      { findAccessInAccessList('DEVICE_CONFIRM') && <ApprovePendingDeviceModal />}
      { findAccessInAccessList('DEVICE_CONFIRM') && <PendingDeviceInfoModal />}
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PendingDevices);