import { useMutation, useQuery } from "@tanstack/react-query";
import { PendingDeviceService } from "../../services"
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";

const useGetPendingDevices = (body: any) => {
  return useQuery({
    queryKey: ["getPendingDevices", body],
    queryFn: ({ queryKey }: any) => {
      const [_, body] = queryKey;
      return PendingDeviceService.getPendingDevicesList(body);
    },
    // enabled: false,
    // onSuccess: (data: any) => {
    //   handleResponse({ data })
    // },
  });
}

const useGetPendingDevice = () => {
  return useMutation({
    mutationFn: PendingDeviceService.getPendingDevice,
  })
}

const useRejectPendingDevice = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PendingDeviceService.rejectPendingDevice,
    onSuccess: (data) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPendingDevices"] })
          onSuccess();
        }
      })
    },
  })
}

const useApprovePendingDevice = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: PendingDeviceService.approvePendingDevice,
    onSuccess: (data) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getPendingDevices"] })
          onSuccess();
        }
      })
    },
  })
}

export {
  useGetPendingDevices,
  useGetPendingDevice,
  useRejectPendingDevice,
  useApprovePendingDevice
}
