import { axiosRequest } from "../../api";
import { API_PATH } from "../../../constants";

export const PatientSurgeryList = {
  getPatientSurgeryList(params: any) {
    return axiosRequest("GET", API_PATH.MAXA.lIST_SURGERY(params));
  },
  AddPatientSurgery(data: any) {
    return axiosRequest("POST", API_PATH.SURGERY.ADD, data);
  },
  getPatientSurgeryListAll(params:any){
    return axiosRequest('GET',API_PATH.SURGERY.GET_LIST_All(params))
  }
};
