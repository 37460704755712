import React from 'react';
import emptyChartBg from "../../../assets/images/svg/emptyChart.svg";

const EmptyBox = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
      <img src={emptyChartBg} alt="No data" style={{minWidth: '300px', minHeight: 'auto'}}/>
      <div className="fw-bold text-center font-16 font-vazir mt-2">داده ای یافت نشد</div>
    </div>
  )
}

export default EmptyBox;