import {getCellValue} from "../../../../../../utils/utils";
import {monthDay, MtoJHHMMFormat, yearTitle} from "../../../../../../utils/date";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect, useSelector} from "react-redux";
import React from "react";
import Tooltip from "../../../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type PatientVitalTableRowItemProps = {
  item: any;
  handleShowModal: any;
  readonly?: boolean;
}

const PatientVitalTableRowItem = ({
  item,
  handleShowModal,
  readonly
}: PatientVitalTableRowItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return (
    <tr className={`${readonly && 'border-bottom'}`}>
      <td>
        {
          getCellValue(monthDay(item?.observationDateTime)) + ' ' +
          getCellValue(yearTitle(item?.observationDateTime)) + ' - ' +
          getCellValue(MtoJHHMMFormat(item?.observationDateTime))
        }
      </td>
      <td>{getCellValue(item?.temperature?.magnitude)}</td>
      <td>{getCellValue(item?.bpSystolic?.magnitude) + '/' + getCellValue(item?.bpDiastolic?.magnitude)}</td>
      <td>{getCellValue(item?.pulseRate?.magnitude)}</td>
      <td>{getCellValue(item?.respiratoryRate?.magnitude)}</td>
      <td>{getCellValue(item?.spo2?.magnitude)}</td>
      {
        ((!readonly && isProvider) || findAccessInAccessList('EHR_VITAL_DELETE')) &&
        <td>
          <div className="d-flex justify-content-center align-items-center">
            <Tooltip alt={'حذف'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('deletePatientVitalModal', item?.id)}
              className="d-flex text-red-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='trash'/>
            </span>
            </Tooltip>
          </div>
        </td>
      }

    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientVitalTableRowItem);
