
export const localStorageUpdate = (storageName, value) => {
  let _savedStorage = JSON.parse(localStorage.getItem(storageName)),
    _newStorage = {
      ..._savedStorage,
      ...value
    },
    _siteSetting = JSON.stringify(_newStorage);
  localStorage.setItem(storageName, _siteSetting);
}

export const localStorageSet = (storageName, value) => {
  let _value = typeof value === 'object' ? JSON.stringify(value) : value;
  localStorage.setItem(storageName, _value);
}

export const localStorageGet = (storageName) => {
  let _value = localStorage.getItem(storageName);
  try { return JSON.parse(_value) }
  catch(e) { return _value }
}

export const localStorageRemove = (storageName) => {
  localStorage.removeItem(storageName);
}

export const localStorageClear = () => {
  localStorage.clear();
}