import React, { Component } from 'react';
import Icon from "../../../general/icon";
import {Button, ButtonGroup, Dropdown} from "react-bootstrap";
import EmtyUser from "../../../../../assets/images/svgComponents/videoPageSvgs/EmtyUser";

const handleOverlayClick = (e) => {
  e.stopPropagation();
};

export default class OpenViduVideoComponent extends Component {

    constructor(props) {
      super(props);
      this.videoRef = React.createRef();
    }
    //
    // componentDidUpdate(props) {
    //     if (props && !!this.videoRef) {
    //         this.props.streamManager.addVideoElement(this.videoRef.current);
    //     }
    // }
    //
    // componentDidMount() {
    //     if (this.props && !!this.videoRef) {
    //         this.props.streamManager.addVideoElement(this.videoRef.current);
    //     }
    // }

  componentDidMount() {
    if (this.props && this.props.user.streamManager && !!this.videoRef) {
      console.log('PROPS: ', this.props);
      this.props.user.getStreamManager().addVideoElement(this.videoRef.current);
    }

    if (this.props && this.props.user.streamManager.session && this.props.user && !!this.videoRef) {
      this.props.user.streamManager.session.on('signal:userChanged', (event) => {
        const data = JSON.parse(event.data);
        if (data.isScreenShareActive !== undefined) {
          this.props.user.getStreamManager().addVideoElement(this.videoRef.current);
        }
      });
    }
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef) {
      this.props.user.getStreamManager().addVideoElement(this.videoRef.current);
    }
  }

  componentWillUnmount() {
      if(this.props.updateFloatingDiv) {
        this.props.updateFloatingDiv({
          PiP: null,
          name: null,
          camStatusChange: this.props.camStatusChange,
          camStatus: null,
          micStatusChange: this.props.micStatusChange,
          micStatus: null,
          leaveSession: this.props.leaveSession,
        }, false);
      }
  }

  handleFullscreenClick = () => {
    this.props.toggleFullscreen(`video_call_container_${this.props.index}`);
  }

  handlePiP =  () => {
    // Store reference to the original container
    const stream = this.videoRef.current.srcObject;
    this.props.updateFloatingDiv({
      PiP: stream,
      name: this.props.user.nickname,
      camStatusChange: this.props.camStatusChange,
      camStatus: this.props.camStatus,
      micStatusChange: this.props.micStatusChange,
      micStatus: this.props.micStatus,
      leaveSession: this.props.leaveSession,
    });

  }

  render() {
      return (
        <div id={`video_call_element_${this.props.index}`} className={`padding-0 video-call-cell ${this.props.micStatus ? "" : "b-2-red"} radius-25 width-full h-100-important bg-black-100 m-auto position-relative ${this.props.subscriberCount === 1 ? 'w-100 h-100' : ''}`}>
            {this.props.camStatus ? ("") : (
                <>
                    <div className="camStatus z-3 ">
                        <EmtyUser/>
                    </div>
                </>
            )}
            {!this.props.micStatus &&
                <div className="micStatus z-3">
                    <Icon name="microphone-mute"/>
                </div>
            }
          {(this.props.updateFloatingDiv && this.props.toggleFullscreen) && (
            <Dropdown drop='end'
                      onClick={handleOverlayClick}
            >
              <Dropdown.Toggle
                className='sidebar-toggle-btn btn bg-light-dark d-flex font-16 rounded-circle zIndex-1 me-md-2 p-2 position-absolute'
                variant="success" id="dropdown-split-basic">
                <Icon name='dots-vertical'/>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={this.handleFullscreenClick}>نمایش تمام صفحه</Dropdown.Item>
                <Dropdown.Item onClick={this.handlePiP}>تصویر شناور</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <video className='radius-25 position-relative width-full h-100-important o-f-c padding-0' autoPlay={true}
                 ref={this.videoRef}
                 muted={!this.props.user.audioActive}
                 disablePictureInPicture
          />
          <div className="overlay-bottom position-absolute"
               onClick={handleOverlayClick}
          >
            {this.props.user.nickname}
          </div>
        </div>
      );
  }

}
