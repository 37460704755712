// all action types
export const ACTION_TYPES = {
  GENERAL: {
    MODAL: {
      SHOW: 'MODAL_SHOW',
      HIDE: 'MODAL_HIDE',
      RESET: 'MODAL_RESET',
    },
  },
  AUTH: {
    LOGIN: {
      REQUESTING: 'AUTH_LOGIN_REQUESTING',
      SUCCESS: 'AUTH_LOGIN_SUCCESS',
      ERROR: 'AUTH_LOGIN_ERROR',
      RESET: 'AUTH_LOGIN_RESET',
    },
    LOGIN_VERIFY_CODE: {
      REQUESTING: 'AUTH_LOGIN_VERIFY_CODE_REQUESTING',
      SUCCESS: 'AUTH_LOGIN_VERIFY_CODE_SUCCESS',
      ERROR: 'AUTH_LOGIN_VERIFY_CODE_ERROR',
      RESET: 'AUTH_LOGIN_VERIFY_CODE_RESET',
    },
    LOGOUT: {
      REQUESTING: 'AUTH_LOGOUT_REQUESTING',
      SUCCESS: 'AUTH_LOGOUT_SUCCESS',
      ERROR: 'AUTH_LOGOUT_ERROR',
      RESET: 'AUTH_LOGOUT_RESET',
    },
  },
  CLINIC: {
    LIST: {
      REQUESTING: 'CLINICS_LIST_REQUESTING',
      SUCCESS: 'CLINICS_LIST_SUCCESS',
      UPDATE: 'CLINICS_LIST_UPDATE',
      ERROR: 'CLINICS_LIST_ERROR',
    },
    ALL_LIST: {
      REQUESTING: 'CLINICS_ALL_LIST_REQUESTING',
      SUCCESS: 'CLINICS_ALL_LIST_SUCCESS',
      ERROR: 'CLINICS_ALL_LIST_ERROR',
    },
    INFO: {
      REQUESTING: 'CLINIC_INFO_REQUESTING',
      SUCCESS: 'CLINIC_INFO_SUCCESS',
      ERROR: 'CLINIC_INFO_ERROR',
      RESET: 'CLINIC_INFO_RESET',
    },
    ADD: {
      REQUESTING: 'CLINIC_ADD_REQUESTING',
      SUCCESS: 'CLINIC_ADD_SUCCESS',
      ERROR: 'CLINIC_ADD_ERROR',
      RESET: 'CLINIC_ADD_RESET',
    },
    EDIT: {
      REQUESTING: 'CLINIC_EDIT_REQUESTING',
      SUCCESS: 'CLINIC_EDIT_SUCCESS',
      ERROR: 'CLINIC_EDIT_ERROR',
      RESET: 'CLINIC_EDIT_RESET',
    },
    DELETE: {
      REQUESTING: 'CLINIC_DELETE_REQUESTING',
      SUCCESS: 'CLINIC_DELETE_SUCCESS',
      ERROR: 'CLINIC_DELETE_ERROR',
      RESET: 'CLINIC_DELETE_RESET',
    },
    DOCTOR_EXPERTISE: {
      REQUESTING: 'CLINIC_DOCTOR_EXPERTISE_REQUESTING',
      SUCCESS: 'CLINIC_DOCTOR_EXPERTISE_SUCCESS',
      ERROR: 'CLINIC_DOCTOR_EXPERTISE_ERROR',
      RESET: 'CLINIC_DOCTOR_EXPERTISE_RESET',
    }
  },
  ERRORS: {
    HAPPENED: {
      SHOW: 'ERROR_HAPPENED_SHOW',
      HIDE: 'ERROR_HAPPENED_HIDE'
    }
  }
};
