import React from "react";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

const MedicationPrescriptionEmptyRow = () => {

  const location = useLocation();
  const mode = location?.state?.mode;

  return (
    <div className="d-flex">
        <div className="card w-100 shadow-none border mb-4">
          <div className='bg-gray rounded-top'>
            <div className='d-flex just mx-4 my-3 align-items-center justify-content-center'>
              <Icon name='clipboard-tick'/>
              <span className="fw-bold text-center text-primary px-4">
                هیچ دارویی برای این بیمار در این نسخه ثبت نشده است.
              </span>
            </div>
          </div>
        </div>
    </div>
  )
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(MedicationPrescriptionEmptyRow);