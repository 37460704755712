import { OpenVidu } from 'openvidu-browser';
import axios from 'axios';
import React, { Component } from 'react';
import UserModel from "../../../../../types/videoCallUserModel";
import {Collapse, Nav, Tab} from "react-bootstrap";
import EmptyBox from "../../../general/emptyBox";
import Icon from "../../../general/icon";
import {
  getTimeDiff, getTimeLeftUntilDate,
  MtoJDateFormat, MtoJFullDateFormat,
  MtoJHHMMFormat,
} from "../../../../../utils/date";
import {API_PATH} from "../../../../../constants";
import {useDispatch} from "react-redux";

var localUser = new UserModel();

class Lobby extends Component {
  constructor(props) {
    super(props);

    this.hasBeenUpdated = false;
    let sessionName = this.props.sessionName ? this.props.sessionName : 'SessionA';
    let userName = this.props.user ? this.props.user : 'OpenVidu_User' + Math.floor(Math.random() * 100);
    this.remotes = [];
    this.localUserAccessAllowed = false;
    this.state = {
      mySessionId: sessionName,
      meeting: undefined,
      participant: undefined,
      myUserName: userName,
      session: undefined,
      mainStreamManager: undefined,
      localUser: undefined,
      waitingList: [],
      showDrawer: false,
      currentVideoDevice: undefined,
      messageList: [],
      privateMessageList: [],
      message: '',
      openTab: 'waitingList',
      privateChatList: true,
      privateChatData: new UserModel(),
      subscribers: [],
    };

    // this.joinSession = this.joinSession.bind(this);
    // this.leaveSession = this.leaveSession.bind(this);
    // this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
    // this.handleChangeUserName = this.handleChangeUserName.bind(this);
    // this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
    // this.onbeforeunload = this.onbeforeunload.bind(this);
    this.connectToMeeting = this.connectToMeeting.bind(this);


    // this.sendMessage = this.sendMessage.bind(this);
    // this.addMessageToChat = this.addMessageToChat.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.handleSend = this.handleSend.bind(this);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.messages !== prevProps.messages) {
  //     if (this.state.messages !== this.props.messages) {
  //       this.setState({ messages: this.props.messages });
  //     }
  //   }
  // }

  // handleChange(event) {
  //   this.setState({ message: event.target.value });
  // }
  //
  // handleSend() {
  //   if (this.state.message.trim() !== '') {
  //     this.sendMessage(this.state.message);
  //     this.setState({ message: '' });
  //   }
  // }
  //
  // addMessageToChat(message) {
  //   this.setState((prevState) => ({
  //     messages: [...prevState.messages, message]
  //   }));
  // }
  //
  // sendMessage(message) {
  //   const mySession = this.state.session;
  //   // console.log(mySession)
  //   mySession.signal({
  //     data: message,
  //     to: [],  // Sends to all participants in the session
  //     type: 'text',  // The signal type
  //   })
  //     .then(() => {
  //       // console.log('Message sent successfully');
  //       this.addMessageToChat({ user: 'Me', text: message });
  //     })
  //     .catch((error) => {
  //       console.error('Error sending message:', error.message);
  //     });
  // }

  componentDidMount() {
    this.state.showDrawer ?
      document.body.classList.remove('lobby-collapsed') :
      document.body.classList.add('lobby-collapsed');
    window.addEventListener('beforeunload', this.onbeforeunload);
    let response = this.getWaitingList().then(data => {
      this.setState({
        waitingList: data?.result
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps?.isVisible !== this.props.isVisible && this.props.isVisible) {
      this.getWaitingList().then(data => {
        this.setState({
          waitingList: data?.result
        });
      });
    }
  }

  // componentWillUnmount() {
  //   window.removeEventListener('beforeunload', this.onbeforeunload);
  // }
  //
  // onbeforeunload(event) {
  //   this.leaveSession();
  // }
  //
  // handleChangeSessionId(e) {
  //   this.setState({
  //     mySessionId: e.target.value,
  //   });
  // }
  //
  // handleChangeUserName(e) {
  //   this.setState({
  //     myUserName: e.target.value,
  //   });
  // }
  //
  // handleMainVideoStream(stream) {
  //   if (this.state.mainStreamManager !== stream) {
  //     this.setState({
  //       mainStreamManager: stream
  //     });
  //   }
  // }
  //
  // deleteSubscriber(stream) {
  //   const remoteUsers = this.state.subscribers;
  //   const userStream = remoteUsers.filter((user) => user.getStreamManager().stream === stream)[0];
  //   let index = remoteUsers.indexOf(userStream, 0);
  //   if (index > -1) {
  //     remoteUsers.splice(index, 1);
  //     this.setState({
  //       subscribers: remoteUsers,
  //     });
  //   }
  // }
  //
  // joinSession() {
  //   this.OV = new OpenVidu();
  //
  //   this.setState(
  //     {
  //       session: this.OV.initSession(),
  //     },
  //     async () => {
  //       this.subscribeToStreamCreated();
  //       await this.connectToSession();
  //     },
  //   );
  // }
  //
  // async connectToSession() {
  //   if (this.props.token !== undefined) {
  //     // console.log('token received: ', this.props.token);
  //     this.connect(this.props.token);
  //   } else {
  //     try {
  //       let response = await this.getLobbyToken();
  //       this.setState({
  //         mySessionId: response.result.sessionId,
  //         participant: response.result.participant,
  //         meeting: response.result.meeting,
  //         myUserName: response.result.participant.name,
  //       }, () => {
  //         // This callback is executed after the state has been updated
  //         let token = response.result.connectionToken;
  //         console.log('parti ghabl az connect', this.state.participant);
  //         this.connect(token);
  //       });
  //       // let token = response.result.connectionToken;
  //       // console.log('parti ghabl az connect', this.state.participant)
  //       // this.connect(token);
  //     } catch (error) {
  //       console.error('There was an error getting the token:', error.code, error.message);
  //       if(this.props.error){
  //         this.props.error({ error: error.error, messgae: error.message, code: error.code, status: error.status });
  //       }
  //       alert('There was an error getting the token:', error.message);
  //     }
  //   }
  // }
  //
  // connect(token) {
  //   this.state.session
  //     .connect(
  //       token,
  //       {
  //         clientData: {
  //           participant: this.state.participant
  //         },
  //       },
  //     )
  //     .then(async (event) => {
  //       let response = await this.getWaitingList();
  //       this.setState({
  //         waitingList: response?.result
  //       });
  //       await this.connectWebCam()
  //     })
  //     .catch((error) => {
  //       if(this.props.error){
  //         this.props.error({ error: error.error, messgae: error.message, code: error.code, status: error.status });
  //       }
  //       alert('There was an error connecting to the session:', error.message);
  //       // console.log('There was an error connecting to the session:', error.code, error.message);
  //     });
  // }
  //
  // async connectWebCam() {
  //   // Only connect to the session, do not initialize a publisher
  //   if (this.state.session.capabilities.subscribe) {
  //     localUser.setNickname(this.state.myUserName);
  //     localUser.setConnectionId(this.state.session.connection.connectionId);
  //     localUser.setScreenShareActive(false);
  //     localUser.setConnection(this.state.session.connection);
  //
  //     // Update your subscribers
  //     this.updateSubscribers();
  //
  //     // Subscribe to user and stream events
  //     this.subscribeToUserChanged();
  //     this.subscribeToStreamDestroyed();
  //
  //     // Send signal for user status changes
  //     this.sendSignalUserChanged({ isScreenShareActive: localUser.isScreenShareActive() });
  //
  //     // Update state with local user (who is just a subscriber)
  //     this.setState({ localUser: localUser });
  //   }
  //
  //   if (this.props.joinSession) {
  //     this.props.joinSession();
  //   }
  // }
  //
  //
  // updateSubscribers() {
  //   // Retrieve the list of remote subscribers (i.e., all stream managers that are not the local user)
  //   const subscribers = this.state.session.streamManagers.filter(
  //     (streamManager) => streamManager !== this.state.localUser.getStreamManager()
  //   );
  //
  //   console.log("updateSubscribers: ", subscribers);
  //
  //   this.setState(
  //     {
  //       subscribers: subscribers, // Update the state with the list of subscribers
  //     },
  //     () => {
  //       if (this.state.localUser) {
  //         this.sendSignalUserChanged({
  //           isAudioActive: false,
  //           isVideoActive: false,
  //           nickname: this.state.localUser.getNickname(),
  //           isScreenShareActive: false,
  //         });
  //       }
  //       // If you have a layout update method, uncomment the following line
  //       // this.updateLayout();
  //     }
  //   );
  // }
  //
  //
  // leaveSession() {
  //   const mySession = this.state.session;
  //
  //   if (mySession) {
  //     mySession.disconnect();
  //   }
  //
  //   // Empty all properties...
  //   this.OV = null;
  //   this.remotes = [];
  //   this.setState({
  //     session: undefined,
  //     subscribers: [],
  //     mySessionId: 'SessionA',
  //     myUserName: 'OpenVidu_User' + Math.floor(Math.random() * 100),
  //     localUser: undefined,
  //   });
  //   if (this.props.leaveSession) {
  //     this.props.leaveSession();
  //   }
  // }
  //
  // subscribeToStreamCreated() {
  //   this.state.session.on('streamCreated', (event) => {
  //     const subscriber = this.state.session.subscribe(event.stream, undefined);
  //     // var subscribers = this.state.subscribers;
  //     subscriber.on('streamPlaying', (e) => {
  //       subscriber.videos[0].video.parentElement.classList.remove('custom-class');
  //     });
  //     const newUser = new UserModel();
  //     newUser.setStreamManager(subscriber);
  //     newUser.setConnectionId(event.stream.connection.connectionId);
  //     newUser.setType('remote');
  //     console.log('event connection: ', event.stream.connection, event.stream.connection.connectionId)
  //     newUser.setConnection(event.stream.connection)
  //     // Extracting and parsing clientData
  //     const connectionData = event.stream.connection.data;
  //     const parsedData = JSON.parse(connectionData);
  //     const clientData = parsedData.clientData;
  //
  //     console.log('event connection: ', event.stream.connection, event.stream.connection.connectionId);
  //     console.log('parsed clientData: ', connectionData);
  //
  //     newUser.setParticipant(clientData.participant);  // Setting participant with clientData
  //     this.remotes.push(newUser);
  //     if(this.localUserAccessAllowed) {
  //       this.updateSubscribers();
  //     }
  //   });
  // }
  //
  // subscribeToStreamDestroyed() {
  //   // On every Stream destroyed...
  //   this.state.session.on('streamDestroyed', (event) => {
  //     // Remove the stream from 'subscribers' array
  //     this.deleteSubscriber(event.stream);
  //     event.preventDefault();
  //     this.updateLayout();
  //   });
  // }
  //
  // subscribeToUserChanged() {
  //   this.state.session.on('signal:userChanged', (event) => {
  //     let remoteUsers = this.state.subscribers;
  //     remoteUsers.forEach((user) => {
  //       if (user.getConnectionId() === event.from.connectionId) {
  //         const data = JSON.parse(event.data);
  //         // console.log('EVENTO REMOTE: ', event.data);
  //         if (data.isAudioActive !== undefined) {
  //           user.setAudioActive(data.isAudioActive);
  //         }
  //         if (data.isVideoActive !== undefined) {
  //           user.setVideoActive(data.isVideoActive);
  //         }
  //         if (data.nickname !== undefined) {
  //           user.setNickname(data.nickname);
  //         }
  //         if (data.isScreenShareActive !== undefined) {
  //           user.setScreenShareActive(data.isScreenShareActive);
  //         }
  //       }
  //     });
  //     this.setState(
  //       {
  //         subscribers: remoteUsers,
  //       });
  //   });
  // }
  //
  // sendSignalUserChanged(data) {
  //   const signalOptions = {
  //     data: JSON.stringify(data),
  //     type: 'userChanged',
  //   };
  //   this.state.session.signal(signalOptions);
  // }

  handleShowDrawer = () => {
    this.setState(
      {showDrawer: !this.state.showDrawer,},
      () => {
        this.state.showDrawer ?
          document.body.classList.remove('lobby-collapsed') :
          document.body.classList.add('lobby-collapsed');
      },
    );

  };

  connectToMeeting (visitId) {
    if(this.props.session !== undefined){
      this.props.leaveSession();
    }
    this.props.handleCloseLobbyDrawer()
    this.props.connectPatientToMeeting(visitId, false);
    this.setState({
      openTab: 'onlineList'
    })
  }

  render() {
    return (
      <aside className=' radius-18 lobby w-25-per lobby-xs h-100-important bg-yellow=500 radius-18'>
        <div className='lobby-content pos-rel h-100  position-relative radius-18'>
          <Tab.Container
            id="video_call_lobby_tabs"
            activeKey={this.state.openTab}
            onSelect={(eventKey) => this.setState({openTab: eventKey || ''})}
          >
            <div className='rounded h-100 p-3 position-relative'>
              <div className='h-100 px-1 overflow-y-auto overflow-x-hidden position-relative scrollbar-custom-lobby'>
                <div className='rounded-top sticky-top'>
                  <div className=''>
                    <Nav variant={'pills'} justify>
                      <div
                        className='d-flex align-items-center bg-lighter-dark w-100  rounded shadow text-size-11 ps-2'>
                        <Nav.Item>
                          <Nav.Link eventKey='waitingList' className='rounded-3 inline-block text-white margin-5'>
                            لیست ویزیت‌های در انتظار
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey='onlineList' className='rounded-3 inline-block text-white margin-5'>
                            لیست افراد آنلاین
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    </Nav>
                  </div>
                </div>
                <Tab.Content>
                  <Tab.Pane className='h-100  margin-t-16' eventKey="waitingList">
                    <div className='lobby-list d-flex flex-column overflow-hidden flex-grow-1 h-100'>
                      <div className="overflow-auto " ref={this.lobbyScroll}>
                        <div>
                          {
                            this.state.waitingList?.length > 0 ?
                              this.state.waitingList?.map((visit, index) => {
                                return (
                                  <div key={index} className={`margin-b-8 bg-gray-custom p-2 rounded-3 ${this.props.meeting?.visitId === visit?.id ? 'border border-light-blue border-3' : 'border border-gray-700'}`}
                                    // onClick={() => {
                                    //   this.setState({
                                    //     privateChatList: false,
                                    //     privateChatData: visit
                                    //   })
                                    // }}
                                  >
                                    <div className="d-flex align-items-center ">
                                      <div className='w-100'>
                                        <div className='d-flex align-items-center justify-content-between '>
                                          <div
                                            className='text-white text-size-15 align-content-center '>
                                            {visit?.patient?.name}
                                          </div>
                                          <div
                                            className={`text-primary font-20 flex items-center`}
                                            onClick={() => this.connectToMeeting(visit?.id)}
                                          >
                                            <Icon name='add-square'/>
                                          </div>
                                        </div>
                                        <div className='d-flex mt-2'>
                                          <div
                                            className='d-flex align-items-center justify-content-between  w-100 text-truncate text-white text-size-10 align-content-center '>
                                            <div className="">
                                              <span>تاریخ نوبت: </span>
                                              <span>{MtoJDateFormat(visit?.scheduledDateTime)}</span>
                                            </div>
                                            <div className="">
                                              <span className='me-3'>ساعت: </span>
                                              <span>{MtoJHHMMFormat(visit?.scheduledDateTime)}</span>
                                            </div>
                                            <div className="">
                                              <span className='me-3'>زمان انتظار: </span>
                                              <span>{getTimeLeftUntilDate(visit?.scheduledDateTime)}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }) :
                              <EmptyBox/>
                          }
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane className='h-100  margin-t-16' eventKey="onlineList">
                    <div className='lobby-list d-flex flex-column overflow-hidden flex-grow-1 h-100'>
                      <div className="overflow-auto" ref={this.lobbyScroll}>
                        <div>
                          {
                            this.props.subscribers.length > 0 ?
                              this.props.subscribers.map((sub, index) => {
                                return (
                                  <div key={index}
                                       className={`margin-b-8 bg-gray-custom p-2 rounded-3 ${this.props.meeting?.id === sub?.id ? 'border border-light-blue border-3' : 'border border-white'}`}
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className='w-100'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                          <div
                                            className='text-white text-size-15 align-content-center '>
                                            {sub?.nickname}
                                          </div>
                                        </div>
                                        <div className='d-flex mt-2'>
                                          <div
                                            className='d-flex align-items-center justify-content-between  w-100 text-truncate text-white text-size-10 align-content-center '>
                                            <div className="">
                                              <span>کد ملی: </span>
                                              <span>{MtoJDateFormat(sub?.participant?.patient?.nationalCode)}</span>
                                            </div>
                                            <div className="">
                                              <span className='me-3'>پزشک معالج: </span>
                                              <span>{MtoJHHMMFormat(sub?.participant?.provider?.name)}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }) :
                              <EmptyBox/>
                          }
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </aside>
    );
  }


  /**
   * --------------------------------------------
   * GETTING A TOKEN FROM YOUR APPLICATION SERVER
   * --------------------------------------------
   * The methods below request the creation of a Session and a Token to
   * your application server. This keeps your OpenVidu deployment secure.
   *
   * In this sample code, there is no user control at all. Anybody could
   * access your application server endpoints! In a real production
   * environment, your application server must identify the user to allow
   * access to the endpoints.
   *
   * Visit https://docs.openvidu.io/en/stable/application-server to learn
   * more about the integration of OpenVidu in your application server.
   */

  async getLobbyToken() {

    const response = await axios.post(API_PATH.VIDEO_CALL.LOBBY,  {}, {
      headers: {'Content-Type': 'application/json',},
    });
    return response.data; // The token
  }

  async getWaitingList() {
    try {
      const response = await axios.post( API_PATH.VIDEO_CALL.LOBBY_LIST, {}, {
        headers: {'Content-Type': 'application/json'},
      });
      const data = response.data;
      console.log(data);
      return data; // Return the data or process it further
    } catch (error) {
      console.error('There was an error fetching the waiting list!', error);
      // Handle the error as needed
    }
  }
}

export default Lobby;
