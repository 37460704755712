import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants";
import Icon from "../../components/general/icon";
import SearchBox from "../../components/general/searchBox";
import DeleteUsersModal from "../../components/users/deleteUserModal";
import { useWindowSize } from "../../../hook/useWindowSize";
import UsersTable from "../../components/users/dataList/userTable";
import UsersCardsBox from "../../components/users/dataList/userCardsBox";
import { useGetUsers } from "../../../hook/request/users";
import { useMySearchParams } from "../../../hook/useMySearchParams";
import Pagination from "../../components/general/table/pagination";
import ChangeAccountPasswordModal from "../../components/accounts/changeAccountPasswordModal";
import BlockingAccountModal from "../../components/accounts/blockingAccountModal";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

const dataListTitles = [
  "نام کاربر",
  "کد ملی",
  "شماره همراه",
  "تاریخ ایجاد",
  "وضعیت",
  "سطح دسترسی",
  "",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const UsersList = () => {

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [usersData, setUsersData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getUsersRequest = useGetUsers(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getUsersRequest?.data?.data?.result &&
      setUsersData(getUsersRequest?.data?.data.result)
  }, [getUsersRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی کاربر"
            />
          </div>
          {
            findAccessInAccessList('OPERATOR_CREATE') &&
            <div
              className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <Link to={ROUTES.USER_ADD.PATH}
                    className="btn btn-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                افزودن کاربر
              </Link>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 1400 ?
              <UsersCardsBox
                dataList={usersData}
                dataListTitles={dataListTitles}
                isRequesting={getUsersRequest.isPending}
              /> :
              <UsersTable
                dataList={usersData}
                dataListTitles={dataListTitles}
                isRequesting={getUsersRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...usersData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <BlockingAccountModal/>
      <ChangeAccountPasswordModal/>
      <DeleteUsersModal />
    </>
  );
};

export default UsersList;