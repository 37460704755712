import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import Header from '../components/main/header';
import Sidebar from '../components/main/sidebar';
import {connect, useDispatch, useSelector} from "react-redux";
import { errorDataSelector } from "../../store/selector/general.js";
import { useWindowSize } from "../../hook/useWindowSize";
import VideoCallOverlay from "../components/visits/visitDetail/videoCall/videoCallOverlay";
import VideoCallPiP from "../components/visits/visitDetail/videoCall/videoCallPiP";
import NotificationService from "../components/notification/notificationService";
import {setFullName, setIsProvider} from "../../store/slice/userData";
import {localStorageGet} from "../../utils/localStorage";
import {setAccessProcessList} from "../../store/slice/accessProcess";

type PiPProps = {
  PiP: HTMLVideoElement | null,
  name: string,
  leaveSession: any,
}
type Status = {
  micStatus: any,
  micStatusChange: any,
  camStatus: any,
  camStatusChange: any,
}

const InternalLayout = ({
  errorData,
}: any) => {

  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(true);
  const [width] = useWindowSize();
  const handleShowDrawer = () => setShowDrawer(!showDrawer);
  const [floatingContent, setFloatingContent] = useState<PiPProps>({
    PiP: null,
    name: '',
    leaveSession: null,
  });
  const [statusContent, setStatusContent] = useState<Status>({
    micStatus: null,
    micStatusChange: null,
    camStatus: null,
    camStatusChange: null,
  });
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider); // Access the ID from the Redux store


  useEffect(() => {
    if(errorData?.data) showErrorMessage(errorData)
  }, [errorData]);

  const showErrorMessage = (error: any) => {
    let errorsData = error?.data,
      errorsMessages = errorsData?.errors,
      errorsMessagesText = '';
    if(errorsMessages){
      for(let err in errorsMessages) errorsMessagesText += `${errorsMessages[err]} \n`;
    }
    const allErrorsMessagesText = errorsMessages ? errorsMessagesText : errorsData?.title || errorsData?.Title;
    // Noti('error', allErrorsMessagesText || t('general.please_try_again_later'));
  }

  useEffect(() => {
    const userData = localStorageGet("USER_DATA")
    if(userData?.providerId !== 0) {
      dispatch(setIsProvider(true))
    } else if(userData?.operatorId !== 0) {
      dispatch(setIsProvider(false))
    }
    dispatch(setFullName(userData?.account?.firstName + ' ' + userData?.account?.lastName))
  }, []);

  useEffect(() => {
    const accessProcess = localStorageGet("ACCESS_PROCESS")
    if(accessProcess) {
      dispatch(setAccessProcessList(accessProcess))
    }
  }, []);

  // const history = useHistory()
  // useEffect(() => {
  //   return history.listen(() => {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //     !!width && width < 992 && setShowDrawer(false);
  //   })
  // },[history, width])

  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);


  useEffect(() => {
    showDrawer ?
      document.body.classList.remove('sidebar-collapsed') :
      document.body.classList.add('sidebar-collapsed');
  }, [showDrawer]);

  const updateFloatingDiv = (floatingDiv: any, close: boolean = true) => {
    if (floatingDiv) {
      setFloatingContent({
        PiP: floatingDiv.PiP,
        name: floatingDiv.name,
        leaveSession: floatingDiv.leaveSession,
      });
      setStatusContent({
        micStatus: floatingDiv.micStatus,
        micStatusChange: floatingDiv.micStatusChange,
        camStatus: floatingDiv.camStatus,
        camStatusChange: floatingDiv.camStatusChange,
      })
      if(close) {
        closeModal();
      }
    }
  }

  return (
    <div className="main-container">
      <Header handleShowDrawer={handleShowDrawer} updateFloatingDiv={updateFloatingDiv} openModal={openModal}/>
      <Sidebar handleShowDrawer={handleShowDrawer} setShowDrawer={setShowDrawer} showDrawer={showDrawer}/>
      <VideoCallPiP floatingContent={floatingContent} statusContent={statusContent} openModal={openModal}/>
      {/*<EmergencyNotification showDrawer={showDrawer}/>*/}
      <NotificationService showDrawer={showDrawer}/>
      <main className="main-content overflow-hidden">
        <Outlet/>
        {/* Other routing components */}
      </main>
      {isProvider &&
        <VideoCallOverlay isVisible={isModalVisible} onClose={closeModal} updateFloatingDiv={updateFloatingDiv}/>
      }
    </div>
  )
}

// const RouteComponent = ({
//   item,
//   renderItem
// }) => {
//   return (
//     <>
//       {
//         isLoggedIn() ?
//           hasRoutePermission(item) ?
//             <>
//               <Helmet>
//                 <title>{item.title} - {process.env.REACT_APP_WEBSITE_TITLE}</title>
//               </Helmet>
//               <item.component {...renderItem} />
//             </> :
//             <Navigate to={ROUTES.NOT_FOUND.PATH} replace /> :
//           <Navigate to={ROUTES.LOGIN.PATH} replace />
//       }
//     </>
//   )
// }

const mapStateToProps = (state: any) => ({
  errorData: errorDataSelector(state),
});

export default connect(mapStateToProps, null)(InternalLayout);
