import React, {useEffect, useState} from "react";
import SelectField from "../../../../general/form/selectField";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect} from "react-redux";
import {useGetMedicationBrand} from "../../../../../../hook/request/medication";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import Tooltip from "../../../../general/tooltip";
import {Collapse} from "react-bootstrap";
import {TIhioDataAndPrescriptionMedicationItemFields} from "../../../../../../types/prescription";
import DebounceField from "../../../../general/form/debounceField";
import {
  ISSUER_TYPE,
  ISSUER_TYPE_ENUM,
  MEDICATION_SHAPE_IHIO,
  VISIT_MODE
} from "../../../../../../constants/constant/enum";
import TextAreaField from "../../../../general/form/textAreaField";
import {useLocation} from "react-router-dom";
import {getCellValue} from "../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../utils/date";

/*
    کامپوننت مربوط به انتخاب اطلاعات تجویز هر خدمت دارو برای نسخه
    Parent: <PrescriptionMedicationItem/>
    Children: -
    در این کامپوننت هر یک از خدمت های دارو تجویز شده رندر میشود،
    سپس اطلاعات نسخه مانند تعداد خدمت ثبت میشود و اطلاعات آن برای کامپوننت پرنت فرستاده میشود.
*/

type MedicationPrescriptionRowItemProps = {
  item: any;
  index: number;
  insurance?: number;
  frequency: any;
  dosage: any;
  control: any;
  errors: any;
  handleShowModal:any;
  onFieldsChange: any;
  addMedicationItemToFavorites: any;
  handleMedicationModalSubmit: any;
  deleteMedicationItemFromList: any;
  addIhioActiveDates: any;
  addTaminActiveDates: any;
  register: any;
  watch?: any
  update?: any;
  IhioCheck?: any;
}

type TBrandFilterValues = {
  molecule?: string;
  dose?: string;
  doseForm?: string;
}

const MedicationPrescriptionRowItem = ({
 item,
 index,
 insurance,
 frequency,
 dosage,
 handleShowModal,
 control,
 errors,
 onFieldsChange,
 addMedicationItemToFavorites,
 deleteMedicationItemFromList,
 handleMedicationModalSubmit,
 addIhioActiveDates,
 addTaminActiveDates,
 register,
 watch,
 update,
 IhioCheck
}: MedicationPrescriptionRowItemProps) => {

  //<editor-fold desc="States">
  const location = useLocation();
  const  mode  = location?.state?.mode || VISIT_MODE.SHOW.value;
  const { getQueryParams } = useMySearchParams();
  const [showDescription, setShowDescription] = useState(false)
  const [fieldValue, setFieldValue] = useState<TIhioDataAndPrescriptionMedicationItemFields>()
  const brandFilterValuesInitialState = {
    molecule: "",
    dose: "",
    doseForm: "",
  }
  const [brandFilterValues, setBrandFilterValues] = useState<TBrandFilterValues>(brandFilterValuesInitialState);
  const [brandList, setBrandList] = useState<any>([])
  const [showBrand, setShowBrand] = useState(mode === VISIT_MODE.SHOW.value)
  const [lastInputTime, setLastInputTime] = useState<number | null>(null);
  //</editor-fold>

  //<editor-fold desc="Services">
  const getMedicationBrandRequest = useGetMedicationBrand(getQueryParams(brandFilterValues), showBrand);
  //</editor-fold>

  //<editor-fold desc="UseEffects">
  useEffect(() => {
    setFieldValue(item)
  }, [item]);

  /*
    دریافت برند های یک دارو برای سلکت و حذف برند هایی که توسط بیمه بیمار ارائه نمی‌شوند.
    @Dependency: getMedicationBrandRequest
  */
  useEffect(() => {
    if (getMedicationBrandRequest?.data?.data?.result) {
      const data  = getMedicationBrandRequest.data.data.result.filter((item: any) => {
        if (insurance === ISSUER_TYPE.IHIO.value) {
          return item.ihioActive !== false; // Filter out if ihioActive is false
        } else if (insurance === ISSUER_TYPE.TAMIN.value) {
          return item.taminActive !== false; // Filter out if taminActive is false
        }
        return true; // If no specific insurance type, include all items
      })
      const _data = data.map((item: any) => (
        { label: item?.brandName ?
            <Tooltip alt={`${item?.brandName}`} placement='right-start' show={300} hide={200} className="tooltip-pre-line"><span className={`${determineBrandSupportAndActive(item)}`}>{item?.brand ? item?.brand : "No Brand"}</span></Tooltip>
            : <span className={`${determineBrandSupportAndActive(item)}`}>{item?.brand ? item?.brand : "No Brand"}</span>,
          value: item?.id,
          name: item?.brand ? item?.brand : "No Brand"
        }
      ))
      setBrandList(_data);
    }
  }, [getMedicationBrandRequest.data])

  /*
    فاکتور های پیدا کردن برند دارو بر اساس اطلاعات دارو تغییر میکنند.
    @Dependency: item
  */
  useEffect(() => {
    setBrandFilterValues({
      molecule: item?.item?.molecule,
      dose: item?.item?.dose,
      doseForm: item?.item?.doseForm,
    })
  }, [item])

  useEffect(() => {
    if (lastInputTime === null) return;
    const timeout = setTimeout(() => {
      const now = Date.now();
      if (now - lastInputTime >= 5000) {
        IhioCheck(index)
      }
    }, 5000);

    // Cleanup timeout on input change or component unmount
    return () => clearTimeout(timeout);
  }, [lastInputTime]);

  //</editor-fold desc="UseEffects">

  /*
 تغییر رنگ تکست بر اساس ساپورت بودن و نبودن بیمه بیمار.
 @param: item
*/
  const determineBrandSupportAndActive = (item: any)=> {
    console.log(item, insurance)
    if(insurance === ISSUER_TYPE.IHIO.value){
      if(!item?.ihioSupport){
        return 'text-red'
      }
    } else if(insurance === ISSUER_TYPE.TAMIN.value){
      if(!item?.taminSupport){
        return 'text-red'
      }
    }
  };

  const handleTaminActiveDates = (dates: any[]) => {
    addTaminActiveDates(dates, index);
  }

  const handleIhioActiveDates = (dates: any[]) => {
    const formattedDates = dates?.map((item: any) => {
      const newDate = new Date(item);
      return newDate.toISOString();
    })
    addIhioActiveDates(formattedDates, index);
  }

  const removeFutureDates = () => {
    onFieldsChange('futureDates_', [], index, item)
  }

  /*
    تغییر اطلاعات استیت در کامپوننت پرنت بر اساس اطلاعات تغییر یافته در اینپوت ها.
    @param: e
  */
  const handleFieldChange = (e?: any) => {
    const { name, value } = e?.target;
    const fieldName = name.split('_')[0];
    const optionsMap: { [key: string]: any[] | undefined } = {
      dosage,
      frequency,
      brand: brandList,
    };
    const labelKey = fieldName === 'brand' ? 'name' : 'label';
    const label = optionsMap[fieldName]?.find((item: any) => item?.value === value)?.[labelKey];
    // onFieldsChange(name, value, label, index, item);
    let fieldValue = {}
    if(fieldName !== 'brand') {
      if(fieldName === 'dosage' || fieldName === 'frequency'){
        fieldValue = {...item, [fieldName]: value, [fieldName + 'Name']: label}
      } else {
        fieldValue = {...item, [fieldName]: value}
      }
    } else {
      console.log(value, label)
      fieldValue = {...item,
        item: {
          ...item.item,
          id: value,
          // erx: brand?.erx,
          // support: insurance === ISSUER_TYPE.TAMIN.value ? brand?.taminSupport : brand?.ihioSupport,
        },
        brand: value,
        brandName: label
      }
    }
    if(insurance === ISSUER_TYPE.IHIO.value) {
      setLastInputTime(Date.now());
    }
    update(index, { ...fieldValue})
  };


  return (
    <div className={`bg-primary bg-opacity-10 rounded my-4 px-4`}>
      <div className='d-flex font-14 pt-4'>
        { mode === VISIT_MODE.EDIT.value && (
          <>
            <Tooltip alt={'حذف دارو'} className="tooltip-pre-line">
              <div className='p-1 btn btn-gray rounded rounded-3'
                 onClick={() => deleteMedicationItemFromList(index)}
              >
                <span
                  className='mx-2 font-20 d-flex  align-items-center h-100'>
                  <Icon name='trash'/>
                </span>
              </div>
            </Tooltip>
            <Tooltip alt={'افزودن تاریخ تکرار دارو'} className="tooltip-pre-line">
              <div className='p-1 btn btn-gray rounded rounded-3 mx-2'
                 onClick={() => handleShowModal('prescriptionActiveDateModal',
                   {
                     insurance: insurance,
                     index: index,
                     item: item,
                     handleIhioActiveDates: handleIhioActiveDates,
                     handleTaminActiveDates: handleTaminActiveDates
                   })
                 }
              >
                <span className='mx-2 font-20 d-flex  align-items-center h-100'>
                  <Icon name='calendar'/>
                </span>
              </div>
            </Tooltip>
            <Tooltip alt={'افزودن دارو به علاقه‌مندی‌ها'} className="tooltip-pre-line">
              <div className='p-1 btn btn-gray rounded rounded-3 mx-2'
                   onClick={() => addMedicationItemToFavorites(index)}
              >
                <span
                  className='mx-2 font-20 d-flex  align-items-center h-100'>
                  <Icon name={fieldValue?.medId ? 'star-fill' : 'star'}/>
                </span>
              </div>
            </Tooltip>
          </>
        )}
        <div
          onClick={() => setShowDescription(!showDescription)}
          className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
            <span
              className="btn btn-gray rounded d-flex align-items-center px-4 mx-2">
              <span className="d-inline-flex font-25 ms-2 ps-2">
                <Icon name={`${showDescription ? 'chat-close' : 'chat'}`}/>
              </span>
              توضیحات دارو
            </span>
        </div>
        <div className="align-content-center me-auto">
          <bdi className='d-flex fw-bold text-primary en-f me-2'>
            {
              fieldValue?.message?.warning?.map((item: string) => {
                return (
                  <Tooltip alt={item} className='p-1 '>
                    <span className="d-flex align-content-center text-yellow font-20 me-2"><Icon name={'info-circle'}/></span>
                  </Tooltip>
                );
              })
            }
            {
              fieldValue?.message?.info?.map((item: string) => {
                return (
                  <Tooltip alt={item} className='p-1 '>
                    <span className="d-flex align-content-center text-primary font-20 me-2"><Icon name={'info-circle'}/></span>
                  </Tooltip>
                );
              })
            }
            <span className='d-flex align-items-center font-en'>
              {(index + 1) + '. ' + MEDICATION_SHAPE_IHIO.find((item: any) => item?.value === fieldValue?.item?.shape?.id)?.enName + ' - ' + fieldValue?.item?.molecule} - &nbsp;
              {fieldValue?.item?.dose} - &nbsp;
              {fieldValue?.itemCount} - &nbsp;
              {frequency && frequency?.find((item: any) => item?.value === fieldValue?.frequency)?.maxaValue} - &nbsp;
              {/*{fieldValue?.frequencyName} - &nbsp;*/}
              {/*{dosage && dosage?.find((item: any) => item?.value === fieldValue?.dosage)?.label} &nbsp;*/}
              {fieldValue?.dosageName} &nbsp;
              {/*{brandList && brandList?.find((item: any) => item?.value === fieldValue?.item?.id)?.label}*/}
              {fieldValue?.brandName}
            </span>
          </bdi>
        </div>
      </div>
      <div className="d-flex row">
        {!showBrand ?
          <div className="col-lg-2 col-md-1 col-sm-1 mt-4 ps-2"
               onClick={() => setShowBrand(true)}
          >
            <div className='btn btn-primary d-flex align-items-center rounded border'>
              <span
                className=' font-27 d-flex  align-items-center'>
                <Icon name='color-swatch'/>
              </span>
              <span className='mx-3 font-14'>
                برند
              </span>
            </div>
          </div> :
          <div className=" col-lg-2 col-sm-2 ps-2 mt-4 mb-2">
            <div className="form-group">
              <SelectField
                width={'50px'}
                options={brandList}
                name={`brand_${index}`}
                placeholder='برند'
                control={control}
                error={errors}
                onChange={handleFieldChange}
                isDisabled={mode === VISIT_MODE.SHOW.value}
                register={register(`root.${index}.brand`)}
                watch={watch(`root.${index}.brand`)}
              />
            </div>
          </div>
        }
        <div className=" col-lg-2 col-sm-5 px-2 mt-4">
          <div className="form-group">
            <SelectField
              width={'10px'}
              options={dosage}
              name={`dosage_${index}`}
              placeholder='دستور مصرف'
              control={control}
              error={errors}
              onChange={handleFieldChange}
              isDisabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.dosage`)}
              watch={watch(`root.${index}.dosage`)}
            />
          </div>
        </div>
        <div className=" col-lg-2 col-sm-5 px-2 mt-4">
          <div className="form-group">
            <SelectField
              width={'10px'}
              options={frequency}
              name={`frequency_${index}`}
              placeholder='تواتر مصرف'
              control={control}
              error={errors}
              onChange={handleFieldChange}
              isDisabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.frequency`)}
              watch={watch(`root.${index}.frequency`)}
            />
          </div>
        </div>
        <div className=" col-1 px-2 mt-4">
          <div className="form-group">
            <DebounceField
              onChange={handleFieldChange}
              name={`itemCount_${index}`}
              placeholder="تعداد"
              control={control}
              error={errors}
              disabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.itemCount`)}
              watch={watch(`root.${index}.itemCount`)}
            />
          </div>
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 pe-2 mt-4">
          <div className="form-group">
            <div className={`d-flex justify-content-end border form-control mb-3 pl-5 text-truncate ${mode === VISIT_MODE.SHOW.value ? 'bg-gray-disabled' : 'bg-white cur-pointer'}`}
                 onClick={() => mode === VISIT_MODE.EDIT.value && handleShowModal('addPrescriptionMedicationItemModal', {
                   index: index,
                   item: item,
                   handleMedicationModalSubmit: handleMedicationModalSubmit,
                   insurance: insurance
                 })}>
              <span className='d-flex align-items-center ms-2 font-en'> {item?.item?.molecule} </span>
              <span className='ms-1'>
                <span
                  className={`rounded-3 bg-primary text-white border border-2 border-primary font-12 text-primary text-nowrap px-2 py-1`}>
                  {item?.item?.category?.name}
                 </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      {
        insurance === ISSUER_TYPE.TAMIN.value && item?.futureDates && item?.futureDates?.length > 0 &&
        <div className='d-flex align-items-center flex-row-reverse pb-3 ms-2'>
          <span className='font-12 fw-semibold'>
            تاریخ تکرار دارو
          </span>
          <span className='rounded rounded-3 bg-white ms-3 px-2 py-1'>
            <div className='d-flex'>
              {
                item?.futureDates?.map((date: any, index: number) => {
                  return (
                    <span className='mx-1'>
                      {MtoJDateFormat(date)} {(index + 1) !== item?.futureDates?.length && ' و '}
                    </span>
                  )
                })
              }
              <span className='d-flex text-red-hover align-items-center font-20 me-2' onClick={() => removeFutureDates()}>
                <Icon name='close-square'/>
              </span>
            </div>
          </span>
        </div>
      }
      {
        insurance === ISSUER_TYPE.IHIO.value && item?.activeDate &&
        <div className='d-flex align-items-center flex-row-reverse pb-3 ms-2'>
          <span className='font-12 fw-semibold'>
            تاریخ ارائه دارو
          </span>
          <span className='rounded rounded-3 bg-white ms-3 px-2 py-1'>
            {getCellValue(MtoJDateFormat(item?.activeDate))}
          </span>
        </div>
      }
      <Collapse in={showDescription}>
        <div className={`col-12`}>
          <div className="form-group pb-3">
            <TextAreaField
              rows={1}
              onChange={handleFieldChange}
              name={`drugDescription_${index}`}
              placeholder="توضیحات دارو"
              control={control}
              error={errors}
              disabled={mode === VISIT_MODE.SHOW.value}
              register={register(`root.${index}.drugDescription`)}
              watch={watch(`root.${index}.drugDescription`)}
            />
          </div>
        </div>
      </Collapse>
      {
        fieldValue?.message?.error?.map((item: string) => {
          return (
            // <div className="pb-2">
            //   <span className='d-flex text-danger mb-1 me-4'>
            //     <span className='d-flex align-items-center font-20 ms-2'>
            //
            //     </span>
            //     <span className='text-danger bg-danger-subtle py-1 px-2'>{item}</span>
            //   </span>
            // </div>
            <div className="pb-2 w-100">
            <span className='d-flex w-100 text-red text-nowrap pb-1'>
              <span className='d-flex w-100 rounded bg-danger-subtle border-2 border-red px-3 py-2'>
                <span className='d-flex align-items-center text-red font-20 ms-2'>
                  <Icon name={'info-circle'}/>
                </span>
                {item}
              </span>
            </span>
            </div>
          );
        })
      }
    </div>
  )
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(MedicationPrescriptionRowItem);