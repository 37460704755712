import React from "react";
import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../../utils/date";

type PatientAppointmentTableRowItemProps = {
  item: any;
};

const PatientAppointmentTableRowItem = ({
  item,
}: PatientAppointmentTableRowItemProps) => {

  return (
    <tr>
      <td>
        <div>{getCellValue(item?.substanceMisuse?.name)}</div>
      </td>

      <td>
        <div className="word-spacing-10">
          {MtoJDateFormat(item?.startDate)}
        </div>
      </td>
      <td>
        <div>{MtoJDateFormat(item?.endDate)}</div>
      </td>
       <td>
        <div>{getCellValue(item?.description)}</div>
      </td>
     
    </tr>
  );
};

export default PatientAppointmentTableRowItem;
