import React, {useEffect, useRef, useState} from 'react';
import { io, Socket, ManagerOptions, SocketOptions } from 'socket.io-client';
import { useGetNotificationToken } from "../../../hook/request/notification";
import {localStorageGet, localStorageSet} from "../../../utils/localStorage";
import {NOTIFICATION_TYPE_ENUM, VISIT_MODE} from "../../../constants/constant/enum";
import EmergencyNotification from "./emergencyNotification";
import {TVisitRequest} from "../../../types/notification";
import {usePatientPresenceToast} from "./notificationUtil";
import {generatePath, useNavigate} from "react-router-dom";
import {ROUTES} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {setVideoCallOpenPiP, videoCall} from "../../../store/slice/videoCall";

const NotificationService = ({showDrawer}: any) => {

  const dispatch = useDispatch();
  const isInVideoCall = useSelector((state: any) => state.general.videoCallReducer.isInVideoCall); // Access the ID from the Redux store
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider); // Access the ID from the Redux store
  const navigate = useNavigate()
  const [isProviderInVideoCall, setIsProviderInVideoCall] = useState(false)
  const [notificationToken, setNotificationToken] = useState<string | null>(null);
  const [valid, setValid] = useState<boolean>(true);
  const [visitRequestQueue, setVisitRequestQueue] = useState<TVisitRequest[]>([]);
  const [requestIsApproved, setRequestIsApproved] = useState({
    isApproved: false,
    id: 0,
    uvrId: 0
  });
  const [approveAcceptId, setApproveAcceptId] = useState<number>(0);
  const isProviderInVideoCallRef = useRef(isProviderInVideoCall);

  useEffect(() => {
    isProviderInVideoCallRef.current = isProviderInVideoCall;
  }, [isProviderInVideoCall]);

  const { triggerPatientPresenceToast } = usePatientPresenceToast();

  // Services
  const getNotificationTokenRequest = useGetNotificationToken({}, !valid);

  useEffect(() => {
    const _notificationToken = localStorageGet("NOTIFICATION_TOKEN")
    setNotificationToken(_notificationToken || 'invalid')
  }, []);

  useEffect(() => {
    if(isInVideoCall) {
      setIsProviderInVideoCall(true);
    } else {
      setIsProviderInVideoCall(false)
    }
  }, [isInVideoCall]);

  useEffect(() => {
    if (getNotificationTokenRequest?.data?.data?.result) {
      setNotificationToken(getNotificationTokenRequest?.data?.data?.result);
      localStorageSet("NOTIFICATION_TOKEN", getNotificationTokenRequest?.data?.data?.result)
      setValid(true)
    }
  }, [getNotificationTokenRequest.data]);

  useEffect(() => {
    if (notificationToken) {
      const uri = 'https://hdpush.hamrahdoctor.com';

      // Define the options for the socket connection
      const options: Partial<ManagerOptions & SocketOptions> = {
        auth: { token: notificationToken },
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        randomizationFactor: 0.5,
        timeout: 20000,
        transports: ['websocket', 'polling'] // Specify the transports to be used
      };

      // Initialize the socket
      const socketInstance: Socket = io(uri, options);

      // Listen for socket events
      socketInstance.on('connect', () => {
        console.log('Socket connected');
      });

      socketInstance.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      socketInstance.on('connect_error', (err) => {
        console.error('Socket connection error:', err);
        if(err.message === 'Authentication error: Invalid token') {
          setValid(false)
          getNotificationTokenRequest.refetch();
        }
      });

      socketInstance.on('connect_timeout', () => {
        console.warn('Socket connection timeout');
      });

      socketInstance.on('reconnect', (attemptNumber) => {
        console.log(`Socket reconnected on attempt ${attemptNumber}`);
      });

      socketInstance.on('reconnect_attempt', (attemptNumber) => {
        console.log(`Socket reconnect attempt ${attemptNumber}`);
      });

      socketInstance.on('reconnect_error', (err) => {
        console.error('Socket reconnect error:', err);
      });

      socketInstance.on('reconnect_failed', () => {
        console.error('Socket reconnect failed');
      });

      // Listen for the "VISIT" event
      if(isProvider) {
        socketInstance.on('VISIT', (objects: any, callback: any) => {
          console.log('Notification received', objects);
          console.log('Function received', callback);
          callback(true)
          if(objects?.type === NOTIFICATION_TYPE_ENUM.URGENT_VISIT_REQUEST.type) {
            enqueueVisitRequest(objects?.data, objects?.type)
            // setUrgentVisitRequest()
          }
          if(objects?.type === NOTIFICATION_TYPE_ENUM.VISIT_REQUEST.type) {
            enqueueVisitRequest(objects?.data, objects?.type)
            // setVisitRequest(objects?.data)
          }
          if(objects?.type === NOTIFICATION_TYPE_ENUM.ANNOUNCE_PATIENT_PRESENCE.type) {
            triggerPatientPresenceToast(objects?.data)
          }
          if(objects?.type === NOTIFICATION_TYPE_ENUM.URGENT_VISIT_APPROVED.type) {
            setRequestIsApproved({
              isApproved: true,
              id: objects?.data?.id,
              uvrId: objects?.data?.uvrId
            })
          }
          if(objects?.type === NOTIFICATION_TYPE_ENUM.VISIT_APPROVED.type) {
            setRequestIsApproved({
              isApproved: true,
              id: objects?.data?.id,
              uvrId: objects?.data?.uvrId
            })
          }
          // Send acknowledgment if needed
          const ack = objects[objects.length - 1];
          if (typeof ack === 'function') {
            ack(true);
          }
        });
      }
      // Clean up the socket connection on component unmount
      return () => {
        socketInstance.disconnect();
      };
    }
  }, [notificationToken]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (visitRequestQueue.length > 0) {
        checkVisitRequests();
      }
    }, 1000); // Execute every 1 second (1000 ms)

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [visitRequestQueue.length]);

  // Enqueue (add an element to the back of the queue)
  const enqueueVisitRequest = (item: any, type: string) => {
    if (!isProviderInVideoCallRef.current) {
      const newItem = {
        id: item?.id,
        patient: item?.patient,
        type: type,
        requestTime: new Date(),
      }
      setVisitRequestQueue((prevQueue) => [...prevQueue, newItem]);
    }
  };

  const checkVisitRequests = () => {
    const oneMinuteInMillis = 60 * 1000; // 1 minute in milliseconds (currently set to 10 seconds for testing)
    const now = Date.now(); // Current time in milliseconds

    // Exclude the first item (index 0) by slicing the array starting from index 1
    const recentRequests = visitRequestQueue.slice(1).filter((request) => {
      if (!request.requestTime) return false; // Ignore if there's no requestTime
      const requestTimeMillis = new Date(request.requestTime).getTime(); // Convert to milliseconds
      return now - requestTimeMillis <= oneMinuteInMillis; // Keep if it's less than or equal to 1 minute old
    });

    // Update the state with only the recent requests (excluding index 0)
    setVisitRequestQueue([visitRequestQueue[0], ...recentRequests]);
  };

  // Dequeue (remove an element from the front of the queue)
  const handleDequeueVisitRequest = () => {
    setVisitRequestQueue((prevQueue) => prevQueue.slice(1));
    setRequestIsApproved({
      isApproved: false,
      id: 0,
      uvrId: 0
    })
  };

  useEffect(() => {
    if(requestIsApproved?.isApproved === true && requestIsApproved?.id !== 0 && visitRequestQueue[0]?.id === approveAcceptId) {
      navigate(generatePath(ROUTES.VISIT_DETAIL.PATH, {id: requestIsApproved?.id}), {state: {mode: VISIT_MODE.EDIT.value}})
      dispatch(videoCall(requestIsApproved?.id));
      dispatch(setVideoCallOpenPiP(true));
    }
  }, [requestIsApproved]);

  const handleEmptyVisitRequestQueue = () => {
    setVisitRequestQueue([]);
    setRequestIsApproved({
      isApproved: false,
      id: 0,
      uvrId: 0
    })
  };

  return (
    visitRequestQueue[0] ?
      <EmergencyNotification
        showDrawer={showDrawer}
        visitRequest={visitRequestQueue[0]}
        visitRequestType={visitRequestQueue[0]?.type}
        handleDequeueVisitRequest={handleDequeueVisitRequest}
        handleEmptyVisitRequestQueue={handleEmptyVisitRequestQueue}
        requestIsApproved={requestIsApproved?.isApproved}
        setApproveAcceptId={setApproveAcceptId}
      />
      : null
  );
};

export default NotificationService;
