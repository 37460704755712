import {general} from "../../../../../../../store/action";
import {connect} from "react-redux";
import React, {useState} from "react";
import { Accordion } from "react-bootstrap";
import AccordionButtonIcon from "../../../../../../../assets/images/svgComponents/accordionButtonIcon";


type VisitMedicationPrescriptionCardsItemProps = {
  item: any;
  handleShowModal: any;
  index: number
}

const VisitMedicationPrescriptionCardsItem = ({
 item,
 index
}: VisitMedicationPrescriptionCardsItemProps) => {
  const [arrowColor, setArrowColor] = useState('black')

  return (
    <Accordion.Item eventKey={`medicationItem_${index}`} className={` rounded my-1`}>
      <Accordion.Header className='rounded font-14'
                        onClick={() => setArrowColor(arrowColor === 'blue' ? 'black' : 'blue')}
      >
        <div className="accordion-title d-flex justify-content-between align-items-center w-100">
          <span className="accordion-icon">
            <AccordionButtonIcon fill={arrowColor}/>
          </span>
          <div className='w-100 d-flex justify-content-end align-items-center'>
            <span className='me-2'>{item?.item?.molecule}</span>
            <div
              className={`bg-primary bg-opacity-10 rounded rounded-3 border border-primary font-12 fw-bold text-primary text-nowrap px-1 py-2 me-2`}>
              {item?.item?.shape?.name[0]}
            </div>
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="">
          <span className="text-gray fw-semibold ms-1">نام کامل:</span>
          <div className='text-start'>
            <bdi>
            {item?.item?.name} - {item?.item?.dose}
            <span className="text-green2 fw-semibold mx-1"> {item?.itemCount} </span>
            <span className="text-primary fw-semibold mx-1"> {item?.frequencyName}</span>
            <span className="text-orange fw-semibold mx-1"> {item?.dosageName} </span>
            </bdi>
          </div>
        </div>
        <div className="">
          <span className="text-gray fw-semibold mx-1">مقدار مصرف:</span>
          <span className="text-primary fw-semibold mx-1"> {item?.frequencyName}</span>
        </div>
        <div className="">
          <span className="text-gray fw-semibold mx-1">تعداد:</span>
          <span className="text-green2 fw-semibold mx-1"> {item?.itemCount} </span>
        </div>
        <div className="">
          <span className="text-gray fw-semibold mx-1">واحد:</span>
          <span className="text-green2 fw-semibold mx-1"> {item?.dosageName} </span>
        </div>
        <div className="">
          <span className="text-gray fw-semibold mx-1">برند:</span>
          <span className="text-green2 fw-semibold mx-1"> {item?.brandName} </span>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VisitMedicationPrescriptionCardsItem);
