import React, {useEffect, useState} from "react";
import EmptyAvatar from "../../../assets/images/svg/profile-empty-avatar.svg"
import {Collapse} from "react-bootstrap";
import {TPatientFields} from "../../../types/patient";
import {TCheckInsuranceFields} from "../../../types/prescription";
import {getAge, getCellValue} from "../../../utils/utils";
import Image from "../general/image";
import {TGIdNameData} from "../../../types/general";
import {MtoJFullDateFormat} from "../../../utils/date";
import {TAddDeviceField} from "../../../types/device";
import {useGetDevice} from "../../../hook/request/devices";


type ExaminationInfoResponsiveProps = {
  device?: TGIdNameData;
  patient?: TPatientFields;
  examiner?: TGIdNameData;
  provider?: {
    id: string,
    name: string
  },
  healthUnit?: {
    id: string,
    name: string
  },
  chiefComplaint?: string;
  leadCount?: number;
  startDate?: any;
}

const ExaminationInfoResponsive = ({
 device,
 patient,
 examiner,
 provider,
 healthUnit,
  chiefComplaint,
 leadCount,
 startDate
}: ExaminationInfoResponsiveProps) => {

  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [deviceInfo, setDeviceInfo] = useState<TAddDeviceField>()

  const getDeviceRequest = useGetDevice()

  useEffect(() => {
    device?.id && getDeviceRequest.mutate({id: device.id})
  }, [device]);

  useEffect(() => {
    if(getDeviceRequest?.data?.data?.result) {
      const {imei, deviceCode, serialCode, deviceType, manufactureDate, updateDate} = getDeviceRequest?.data?.data?.result
      setDeviceInfo({imei, deviceCode, serialCode, deviceType, manufactureDate, updateDate})
    }
  }, [getDeviceRequest?.data?.data?.result]);

  return (
    <div className="align-items-center px-4 mb-4">
      <div className=' rounded mx-3'>
        <div className="d-flex justify-content-center">
          <div className='align-content-center align-items-center text-center mx-2 w-25'>
            <Image
              className='rounded mb-4'
              src={EmptyAvatar}
            />
          </div>
        </div>
        <div className='d-flex justify-content-center font-12 fw-semibold mb-2'>
          {getCellValue(patient?.firstName)} {getCellValue(patient?.lastName)}
        </div>
        <div className='d-flex justify-content-center font-10 fw-semibold mb-2'>
          <span>کد ملی:</span>
          <span className='me-1'>{getCellValue(patient?.nationalCode)}</span>
        </div>
        <Collapse in={showDetails}>
          <div className='mt-2'>
            <div className='row row-cols-1 d-flex align-items-center'>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>زمان گرفتن نمونه:</span>
                <span className='me-1'>{getCellValue(MtoJFullDateFormat(startDate))}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>نوع نمونه گیری:</span>
                <span className='me-1'>{leadCount ? leadCount + ' لید' : '-'}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>علت مراجعه:</span>
                <span className='me-1'>{getCellValue(chiefComplaint)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>کد دستگاه:</span>
                <span className='me-1'>{getCellValue(deviceInfo?.deviceCode)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>دستگاه IMEI:</span>
                <span className='me-1'>{getCellValue(deviceInfo?.imei)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>نام مرکز:</span>
                <span className='me-1'>{getCellValue(healthUnit?.name)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>پزشک معالج:</span>
                <span className='me-1'>{getCellValue(provider?.name)}</span>
              </div>
              <div className='col fw-semibold mb-2'>
                <span className='text-gray'>اپراتور مربوطه:</span>
                <span className='me-1'>{getCellValue(examiner?.name)}</span>
              </div>
            </div>
          </div>
        </Collapse>
        <div className="d-flex justify-content-center">
          <div
            className="btn btn-light-blue rounded d-flex align-items-center fw-bold mt-3 px-2 p-2"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'کمتر' : 'بیشتر'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExaminationInfoResponsive;