import React from "react";
import BoxLoader from "../../../general/boxLoader";
import EmptyBox from "../../../general/emptyBox";
import FavoriteMedicationCardsBoxItem from "./favoriteMedicationCardsBox_Item";

type FavoriteMedicationCardsBoxProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const FavoriteMedicationCardsBox = ({
  dataList,
  dataListTitles,
  isRequesting
}: FavoriteMedicationCardsBoxProps) => {
  const _dataList = dataList && dataList?.length > 0 && dataList?.map((item: any, i: number) => {
    return <FavoriteMedicationCardsBoxItem item={item} dataListTitles={dataListTitles} key={i}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList ? _dataList : <EmptyBox content={''} className={''} children={''}/>}
    </div>
  )
}

export default FavoriteMedicationCardsBox;