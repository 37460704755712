import {getCellValue} from "../../../../../../utils/utils";
import {MtoJDateFormat, MtoJFullDateFormat, MtoJHHMMFormat} from "../../../../../../utils/date";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect, useSelector} from "react-redux";
import React from "react";
import Tooltip from "../../../../general/tooltip";
import moment from 'moment';
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";


type PatientConditionTableRowItemProps = {
  item: any;
  handleShowModal: any;
  readonly: boolean;
}

const PatientConditionTableRowItem = ({
  item,
  handleShowModal,
  readonly
}: PatientConditionTableRowItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const getRangeStatus = () => {
    const today = moment().startOf('day');

    const start = item?.startDateTime ? moment(item?.startDateTime).startOf('day') : null;
    const end = item?.endDateTime ? moment(item?.endDateTime).startOf('day') : null;

    if (start && end) {
      if (end.isBefore(today)) {
        return 'پایان یافته';
      } else if (start.isAfter(today)) {
        return 'آینده';
      } else if (start.isSameOrBefore(today) && end.isSameOrAfter(today)) {
        return 'جاری';
      }
    } else if (start) {
      if (start.isAfter(today)) {
        return 'آینده';
      } else if (start.isSameOrBefore(today)) {
        return 'جاری';
      }
    } else if (end) {
      if (end.isBefore(today)) {
        return 'پایان یافته';
      } else if (end.isSameOrAfter(today)) {
        return 'جاری';
      }
    } else {
      return 'جاری';
    }

    return 'invalid range'; // In case something goes wrong
  }

  return (
    <tr>
      <td>{getCellValue(item?.condition?.name)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.startDateTime))}</bdi>
      </td>
      <td>{item?.endDateTime ? getCellValue(MtoJHHMMFormat(item?.endDateTime)) + ' - ' + getCellValue(MtoJDateFormat(item?.endDateTime)) : '-'}</td>
      <td>{getRangeStatus()}</td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {((!readonly && isProvider) || findAccessInAccessList('EHR_MEDICAL_CONDITIONS_DELETE')) &&

            <Tooltip alt={'حذف'} className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('deletePatientConditionModal', item?.id)}
                className="d-flex text-red-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='trash'/>
              </span>
            </Tooltip>
          }
        </div>
      </td>
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientConditionTableRowItem);
