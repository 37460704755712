import {useMutation, useQuery} from "@tanstack/react-query";
import { DoctorService } from "../../services"
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import Noti from "../../view/components/general/noti";
import {handleResponse, queryClient} from "./setup";
import {localStorageSet} from "../../utils/localStorage";

const useGetDoctors = (params: any, isOperator?: boolean) => {
  return useQuery({
    queryKey: ["getDoctors", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return DoctorService.getDoctorsList(params);
    },
    enabled: isOperator ?? true
  });
}

const useGetDoctor = () => {
  return useMutation({
    mutationFn: DoctorService.getDoctor,
    onSuccess: (data, variables, context) => {
      handleResponse({
        data,
      })
    },
  })
}

const useGetMyProviderInfo = () => {
  return useMutation({
    mutationFn: DoctorService.getMyProviderInfo,
    onSuccess: (data, variables, context) => {
      handleResponse({
        data,
        onSuccess: () => {
          localStorageSet('PROVIDER_DATA', data.data.result);
        }
      })
    },
  })
}

const useGetDoctorStatistics = () => {
  return useMutation({
    mutationFn: DoctorService.getDoctorStatistics,
  })
}

// get specialities list
const useGetSpecialitiesList = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getSpecialitiesList", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return DoctorService.getSpecialitiesList(params);
    },
    enabled: modalName ?? true
  });
}

const useAddDoctor = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: DoctorService.addDoctor,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.DOCTOR.PATH);
        }
      })
    },
  })
}

const useUpdateDoctorProfile = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: DoctorService.updateDoctorProfile,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.DASHBOARD.PATH);
        }
      })
    },
  })
}

const useDeleteDoctor = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: DoctorService.deleteDoctor,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getDoctors"] })
          onSuccess();
        }
      })
    },
  })
}

const useDoctorVisitStatusChange = () => {
  return useMutation({
    mutationFn: DoctorService.doctorVisitStatusChange,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
        }
      })
    },
  })
}

const useGetDoctorVisitStatus = () => {
  return useMutation({
    mutationFn: DoctorService.getDoctorVisitStatus,
  })
}

export {
  useGetDoctors,
  useGetDoctor,
  useGetMyProviderInfo,
  useGetDoctorStatistics,
  useGetSpecialitiesList,
  useAddDoctor,
  useUpdateDoctorProfile,
  useDeleteDoctor,
  useDoctorVisitStatusChange,
  useGetDoctorVisitStatus
}
