export const SET_IS_PROVIDER = 'SET_IS_PROVIDER';
export const SET_FULL_NAME = 'SET_FULL_NAME';

// Action creators
export const setIsProvider = (value: boolean | null) => ({
  type: SET_IS_PROVIDER,
  payload: value,
});

export const setFullName = (value: string) => ({
  type: SET_FULL_NAME,
  payload: value,
});