import React, { useEffect, useRef } from 'react';

interface ObserverProps {
  onIntersect: (inView: boolean, entry: IntersectionObserverEntry) => void;
  threshold?: number | number[];
  root?: Element | null;
  rootMargin?: string;
  children: React.ReactNode;
}

const Observer: React.FC<ObserverProps> = ({
 onIntersect,
 threshold = 0.5,
 root = null,
 rootMargin = '0px',
 children,
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (onIntersect) {
          onIntersect(entry.isIntersecting, entry);
        }
      },
      {
        root,
        rootMargin,
        threshold,
      }
    );

    const currentElement = elementRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [onIntersect, root, rootMargin, threshold]);

  return <div ref={elementRef}>{children}</div>;
};

export default Observer;
