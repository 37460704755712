import Highcharts from "highcharts";
import emptyChartBg from "../../../assets/images/svg/emptyChart.svg"

type ChartType = 'column' | 'line' | 'bar' | 'pie' | 'area' | 'scatter' | 'spline' | 'areaspline'; // Add all other valid chart types
export type HighChartProps = {
  chartType: ChartType,
  data: Highcharts.XrangePointOptionsObject[][],
  dataNames: string[],
  labels: string[],
  chartTitle: string,
  displayXLabels?: boolean,
  displayYLabels?: boolean,
  displayDataLabels?: boolean,
  showLegend?: boolean,
  showExportButton?: boolean,
  min?: number,
  max?: number,
}

export const langDefaultOptions = {
  noData:
    `
      <div class="w-100 h-100">
        <img src="${emptyChartBg}" alt="No data" style="min-width: 300px; min-height: auto;">
        <div class="fw-bold text-center font-16 font-vazir mt-2">داده ای یافت نشد</div><br/>
      </div>
    `,
  // contextButtonTitle: 'تصدير الرسم البياني',
  viewFullscreen: `<div class="font-vazir font-12">نمایش تمام صفحه</div>`,
  printChart: `<div class="font-vazir font-12">پرینت گرفتن</div>`,
  downloadJPEG: `<div class="font-vazir font-12">دانلود با فرمت JPEG</div>`,
  downloadPDF: `<div class="font-vazir font-12">دانلود با فرمت PDF</div>`,
  downloadPNG: `<div class="font-vazir font-12">دانلود با فرمت PNG</div>`,
  downloadSVG: `<div class="font-vazir font-12">دانلود با فرمت SVG</div>`,
  resetZoom: `<div class="font-vazir font-12">حالت پیش‌فرض</div>`,
  resetZoomTitle: `<div class="font-vazir font-12">حالت پیش‌فرض</div>`,
  months: [
    'فروردین', 'اردیبهشت', 'خرداد', 'تیر',
    'مرداد', 'شهریور', 'مهر', 'آبان',
    'آذر', 'دی', 'بهمن', 'اسفند'
  ],
  weekdays: [
    'شنبه', 'یک‌شنبه', 'دو‌شنبه', 'سه‌شنبه',
    'چهار‌شنبه', 'پنج‌شنبه', 'جمعه'
  ]
}

const chartOptions = ({
  chartType,
  data,
  dataNames,
  labels,
  chartTitle,
  displayXLabels = true,
  displayYLabels = true,
  displayDataLabels = false,
  showLegend = true,
  showExportButton = true,
  min,
  max,
}: HighChartProps) : Highcharts.Options => {
  Highcharts.setOptions({
    lang: {
      resetZoom: `<div class="font-vazir fw-medium font-12">حالت پیش‌فرض</div>`,
      resetZoomTitle: `<div class="font-vazir font-12">تنظیم مجدد بزرگنمایی</div>`
    }
  });
  return {
    title: {
      text: '' // Set an empty string to hide the title
    },
    chart: {
      backgroundColor: 'transparent',
      type: chartType,
      zooming: {
        type: 'x'
      },
      panning: {
        enabled: true,
        type: 'x'
      },
      panKey: 'shift',
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        turboThreshold: 75000,
        dataLabels: {
          enabled: displayDataLabels,
        }
      },
    },
    series: data.map((seriesData, index) => ({
      type: chartType,
      name: dataNames[index],
      data: seriesData,
    })),
    xAxis: {
      // type: 'datetime'
      categories: labels,
      className: 'font-vazir',
      labels: {
        rotation: -45,
        enabled: displayXLabels,
      }
    },
    yAxis: {
      labels: {
        enabled: displayYLabels
      },
      className: 'font-vazir',
      title: {
        text: ''
      },
      min: min && min,
      max: max && max
    },
    tooltip: {
      valueDecimals: 2,
      format:
        '<div style="font-family: \'Vazir\', Arial, sans-serif;"><span style="font-size: 0.8em; ' +
        'font-weight: 600; ' +
        '">{key}</span><br/>' +
        '{#each points}' +
        '&ensp;<div><span>{series.name}: <b>{y}</b></span>' +
        '<span style="color:{color}">\u25CF</span></div>' +
        '{/each}</div>',
      shared: true,
    },
    lang: langDefaultOptions,
    legend: {
      enabled: showLegend,
      className: 'font-vazir'
    },
    noData: {
      position: {
        // Adjust the position of the noData text
        align: 'center',
        verticalAlign: 'top'
      },
      useHTML: true,  // Enable use of HTML for rendering the image
      style: {
        fontSize: '16px',
      },
      attr: {
        zIndex: 1
      },
    },
    exporting: {
      filename: chartTitle + ' - ' + new Date(),
      chartOptions: {
        title: {
          text: chartTitle
        },
        exporting: {
          enabled: false
        },
        chart: {
          style: {
            fontFamily: 'Vazir, sans-serif'
          }
        }
      },
      buttons: {
        contextButton: {
          enabled: false
        },
        exportButton: {
          enabled: showExportButton,
          className: 'rounded-circle font-vazir font-en',
          menuClassName:'rtl text-end font-vazir font-en',
          symbol: "menu",
          symbolSize: 12,
          // Use only the download related menu items from the default
          // context button
          menuItems: [
            "viewFullscreen",
            'printChart',
            "separator",
            'downloadPDF',
            'downloadPNG',
            'downloadJPEG',
            'downloadSVG',
          ]
        },
      }
    },
    credits: {
      enabled: false
    }
  }
};

const BloodPressureScatterChart = ({
 data
}: any) => {
  Highcharts.setOptions({
    lang: {
      resetZoom: `<div class="font-vazir fw-medium font-12">حالت پیش‌فرض</div>`,
      resetZoomTitle: `<div class="font-vazir font-12">تنظیم مجدد بزرگنمایی</div>`
    }
  });
  return {
    chart: {
      type: 'scatter',
      backgroundColor: 'transparent',
      className: 'w-100',
    },
    title: {
      text: '',
    },
    xAxis: {
      min: 40,
      max: 120,
      title: {
        text: 'Diastolic',
        align: 'high',
        fontFamily: 'Gilroy, sans-serif'
      },
      tickWidth: 0,
      tickInterval: 10,  // Set tick intervals to 10
      lineWidth: 0,
      gridLineWidth: 1,
      plotBands: [
        {
          from: 40,
          to: 60,
          color: 'rgba(88, 145, 255, 1)',
          zIndex: -20
        },
        {
          from: 60,
          to: 80,
          color: 'rgba(102, 241, 80, 1)',
          zIndex: -19
        },
        {
          from: 80,
          to: 100,
          color: 'rgba(255, 149, 89, 1)',
          zIndex: -18
        },
        {
          from: 100,
          to: 120,
          color: 'rgba(255, 85, 85, 1)',
          zIndex: -17
        },
      ],
    },
    yAxis: {
      min: 70,
      max: 160,
      title: {
        text: 'Systolic',
        align: 'high',
        fontFamily: 'Gilroy, sans-serif'
      },
      tickInterval: 10,  // Set tick intervals to 10
      lineWidth: 0,
      gridLineWidth: 1,
      plotBands: [
        {
          from: 70,
          to: 90,
          color: 'rgba(88, 145, 255, 1)', // Light blue
          zIndex: -20
        },
        {
          from: 90,
          to: 120,
          color: 'rgba(102, 241, 80, 1)',
          zIndex: -19
        },
        {
          from: 120,
          to: 140,
          color: 'rgba(255, 149, 89, 1)',
          zIndex: -18
        },
        {
          from: 140,
          to: 160,
          color: 'rgba(255, 85, 85, 1)',
          zIndex: -17
        },
      ],
    },
    series: [{
      name: 'فشار خون',
      color: 'rgba(0, 0, 0, 1)',
      data: data,
      marker: {
        radius: 7, // Increase the size of the points
        symbol: 'circle' // You can also change the shape if needed
      },
    }],
    lang: langDefaultOptions,
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  };
}


export {
  chartOptions,
  BloodPressureScatterChart
}