import React, { useEffect, useMemo, useState } from "react";
import { ROUTES } from "../../../constants";
import { handleChange } from "../../../utils/utils";
import BoxLoader from "../../components/general/boxLoader";
import SelectField from "../../components/general/form/selectField";
import TextField from "../../components/general/form/textField";
import TextAreaField from "../../components/general/form/textAreaField";
import { useNavigate, useParams } from "react-router-dom";
import { DEPARTMENT_TYPE } from "../../../constants/constant/enum";
import ImageUploaderBox from "../../components/utility/imageUploader/imageUploaderBox";
import { useAddOrganization, useGetOrganization } from "../../../hook/request/organizations";
import { TAddOrganizationFields } from "../../../types/organization";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addOrganizationSchema } from "../../../schema/organizationSchema";
import NumberField from "../../components/general/form/numberField";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

const AddOrganization = () => {

  const navigate = useNavigate();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const { id } = useParams();
  const [fieldValue, setFieldValue] = useState<TAddOrganizationFields>({
    name: '',
    telNo: '',
    departmentType: '',
    postalCode: '',
    address: '',
    latitude: '',
    longitude: ''
  });
  const [avatarData, setAvatarData] = useState({
    avatar: {
      id: 0,
      imageUrl: "",
      imageData: {
        name: ""
      }
    },
    backgroundImage: {
      id: 0,
      imageUrl: "",
      imageData: {
        name: ""
      }
    }
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddOrganizationFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(addOrganizationSchema),
  });
  
  // services
  const getOrganizationRequest = useGetOrganization();
  const addOrganizationRequest = useAddOrganization();

  useEffect(() => {
    id && getOrganizationRequest.mutate({id});
  }, [id])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getOrganizationRequest?.data?.data?.result) {
      const { avatar, backgroundImage, name, telNo, departmentType, postalCode, address, latitude, longitude } = getOrganizationRequest.data.data.result,
        _fieldValue = { 
          name, telNo, postalCode, address, latitude, longitude,
          departmentType: departmentType?.id,
        };
      setFieldValue(_fieldValue);
      setAvatarData({
        avatar: {
          id: avatar?.id,
          imageUrl: avatar?.name,
          imageData: {
            name: ""
          }
        },
        backgroundImage: {
          id: backgroundImage?.id,
          imageUrl: backgroundImage?.name,
          imageData: {
            name: ""
          }
        }
      })
    }
  }, [getOrganizationRequest.data])

  const addOrganizationSubmit: SubmitHandler<TAddOrganizationFields> = (data) => {
    const { name, departmentType, telNo, postalCode, address } = data,
      { latitude, longitude } = fieldValue,
      { avatar, backgroundImage } = avatarData,
      body = {
        ...(id ? {id} : {}),
        name, telNo, postalCode, address, latitude, longitude,
        departmentType: {
          id: departmentType,
          name: ""
        },
        avatar: avatar?.id ? {
            id: avatar?.id,
            name: avatar?.imageData?.name
          } : null,
        backgroundImage: backgroundImage?.id ? {
            id: backgroundImage?.id,
            name: backgroundImage?.imageData?.name
          } : null
      };
    addOrganizationRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getOrganizationRequest?.isPending ||
          addOrganizationRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(addOrganizationSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">افزودن تصویر نمایه</h4>
            </div>
            <div className="col-12 px-4">
              <div className="d-flex justify-content-center rounded pos-rel mb-6">
                <ImageUploaderBox
                  setFieldValue={setAvatarData}
                  fieldValue={avatarData}
                  name='backgroundImage'
                  type="full"
                />
                <div className="position-absolute top-50 end-5 zIndex-2">
                  <ImageUploaderBox
                    setFieldValue={setAvatarData}
                    fieldValue={avatarData}
                    name='avatar'
                  />
                </div>
              </div>
            </div>
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5 mt-4">اطلاعات مرکز درمانی</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="name">نام مرکز درمانی</label>
                <TextField
                  name="name"
                  placeholder="نام مرکز درمانی"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="telNo">شماره تلفن مرکز</label>
                <TextField
                  name="telNo"
                  placeholder="شماره تلفن مرکز"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            {/* <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="firstName">نام مدیریت</label>
                <TextField
                  onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, setFieldValue, fieldValue)
                  }
                  value={fieldValue?.firstName}
                  name="firstName"
                  placeholder="نام مدیریت"
                  control={control}
                  error={errors}
                />
              </div>
            </div> */}
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="departmentType">نوع سازمان</label>
                <SelectField
                  options={DEPARTMENT_TYPE}
                  name="departmentType"
                  placeholder='انتخاب کنید'
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="postalCode">‌کد پستی مرکز درمانی</label>
                <NumberField
                  name="postalCode"
                  placeholder="کد پستی مرکز درمانی"
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={10}
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="address">‌آدرس مرکز</label>
                <TextAreaField
                  value={fieldValue?.address}
                  name="address"
                  placeholder="افزودن آدرس"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            {/* <div className="col-12 px-4">
              <div className="border rounded p-5 mb-5">
                نقشه
              </div>
            </div> */}
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.ORGANIZATION?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                {
                  ((findAccessInAccessList('HEALTH_ORGANIZATION_EDIT') && id) || (findAccessInAccessList('HEALTH_ORGANIZATION_CREATE') && !id)) &&
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    { id ? "ذخیره تغییرات" : "افزودن مرکز درمانی" }
                  </button>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrganization;