import LoginHistorySection from "../../components/account/loginHistory/loginHistorySection";

const LoginHistory = () => {
  return (
    <div className="card-box">
      <LoginHistorySection backButton={true} />
    </div>
  )
}

export default LoginHistory;