import React, {useEffect, useState} from "react";

import TwoPerson from "../../../../assets/images/svgComponents/icon/twoPerson";
import {useGetVisits} from "../../../../hook/request/visit/visits";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import {TDataList, TDataListInitialValue} from "../../../../types/general";
import {getCellValue} from "../../../../utils/utils";
import {MtoJDateFormat, MtoJHHMMFormat} from "../../../../utils/date";
import {VISIT_STATUS_ENUM, VISIT_TYPE_ENUM} from "../../../../constants/constant/enum";
import {general} from "../../../../store/action";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import EmptyBoxes from "../../../../assets/images/svgComponents/EmptyBoxes";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  status?: string
}

const RecentVisits = () => {

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [visitsData, setVisitsData] = useState<TDataList>(TDataListInitialValue);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "5",
    search: "",
    status: `${VISIT_STATUS_ENUM.RESOLVED.value}`
  });

  // services
  const getVisitsRequest = useGetVisits(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "5",
      search: searchParams.get("search") || "",
      status: searchParams.get("status") || `${VISIT_STATUS_ENUM.RESOLVED.value}`
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getVisitsRequest?.data?.data?.result &&
    setVisitsData(getVisitsRequest?.data?.data.result)
  }, [getVisitsRequest.data])

  return (
    <div className="dashboard-card-box h-100">
      <div className="flex-wrap align-items-center m-2">
        <span className="d-flex align-items-center text-white font-25">
          <span className="d-flex align-items-center ms-2">
           <TwoPerson/>
          </span>
          <span className="font-15 fw-bold text-black">
            لیست آخرین ویزیت‌های انجام شده
          </span>
        </span>
      </div>
      <div className="h-90 m-2">
        {visitsData?.data && visitsData?.data.length > 0 ? visitsData?.data.map((item: any, key: number) => {
          const firstCharacter = item?.patient?.name ? item?.patient?.name.charAt(0) : '';
          return (
            <div key={key} className='d-flex align-items-center border-bottom visit-card-animation py-3'>
              <div className="rounded-circle avatar-placeholder">
                {firstCharacter}
              </div>
              <div className='w-100 me-3'>
                <div className='font-15'>
                  {item?.patient?.name}
                </div>
                <div className='d-flex justify-content-between font-9 text-gray mt-2'>
                  <div>
                    <span>
                      تاریخ شروع ویزیت:
                    </span>
                    <span className='me-2'>
                      {item?.startDateTime ? getCellValue(MtoJDateFormat(item?.startDateTime)) : '-'}
                    </span>
                  </div>
                  <div>
                    <span>
                      ساعت:
                    </span>
                    <span className='me-2'>
                      {item?.startDateTime ? getCellValue(MtoJHHMMFormat(item?.startDateTime)) : '-'}
                    </span>
                  </div>
                  <div>
                    <span>
                      نوع ویزیت:
                    </span>
                    <span className='mx-2'>
                      {item?.visitType?.id === VISIT_TYPE_ENUM.ONLINE.value ? 'آنلاین' : 'حضوری'}
                    </span>
                  </div>
                </div>
              </div>
              {/*<div className="me-auto">*/}
              {/*  <button*/}
              {/*    type='submit'*/}
              {/*    className="btn btn-green rounded-3 d-flex text-nowrap align-items-center me-2 px-2 py-1"*/}
              {/*    onClick={() => handleShowModal('changeStatusAppointmentModal', {id: item?.id})}*/}
              {/*  >*/}
              {/*    شروع ویزیت*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>
          )
        }) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <EmptyBoxes/>
            <div className='font-12 mt-2'>
              لیستی برای نمایش وجود ندارد.
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(RecentVisits);