import React from "react";
import BoxLoader from "../../../../general/boxLoader";
import DiagnosisCardsBoxItemResponsive from "./diagnosisCardsBox_ItemResponsive";

type VisitCardsBoxProps = {
  dataList?: any;
  isRequesting: boolean
  readOnly?: boolean;
}

const DiagnosisCardsBoxResponsive = ({
 dataList,
 isRequesting,
 readOnly = false
}: VisitCardsBoxProps) => {
  const _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
    return <DiagnosisCardsBoxItemResponsive item={item} key={i} readOnly={readOnly}/>
  })
  return (
    <div>
      {isRequesting ? <BoxLoader/> : _dataList && _dataList}
    </div>
  )
}

export default DiagnosisCardsBoxResponsive;