import { useEffect, useMemo, useState } from "react";
import { ROUTES } from "../../../constants";
import BoxLoader from "../../components/general/boxLoader";
import NumberField from "../../components/general/form/numberField";
import TextField from "../../components/general/form/textField";
import { useNavigate } from "react-router-dom";
import ImageUploaderBox from "../../components/utility/imageUploader/imageUploaderBox";
import { useGetUser, useUpdateUserProfile } from "../../../hook/request/users";
import { TAddUserFields } from "../../../types/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editUserSchema } from "../../../schema/userSchema";
import { localStorageGet } from "../../../utils/localStorage";

const UserProfile = () => {

  const navigate = useNavigate();
  const [fieldValue, setFieldValue] = useState<TAddUserFields>({
    firstName: '',
    lastName: '',
    nationalCode: '',
    mobile: '',
    email: '',
    password: ''
  });
  const [avatarData, setAvatarData] = useState({
    avatar: {
      id: 0,
      imageUrl: "",
      imageData: {
        name: ""
      }
    }
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TAddUserFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(editUserSchema),
  });

  // services
  const getUserRequest = useGetUser();
  const updateUserProfileRequest = useUpdateUserProfile();

  useEffect(() => {
    const _userData = localStorageGet('USER_DATA'),
      id = _userData?.providerId;
    getUserRequest.mutate({id});
  }, [])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (getUserRequest?.data?.data?.result) {
      const { avatar, firstName, lastName, nationalCode, email, mobile } = getUserRequest.data.data.result,
        _fieldValue = { firstName, lastName, nationalCode, mobile, email };
      setFieldValue(_fieldValue);
      setAvatarData({
        avatar: {
          id: avatar?.id,
          imageUrl: avatar?.name,
          imageData: {
            name: ""
          }
        }
      })
    }
  }, [getUserRequest.data])

  const updateUserProfileSubmit: SubmitHandler<TAddUserFields> = (data) => {
    const { firstName, lastName, nationalCode, mobile, email, password } = data,
      { avatar } = avatarData,
      body = {
        firstName, lastName, nationalCode, mobile, email, password,
        avatar: avatar?.id ? {
            id: avatar?.id,
            name: avatar?.imageData?.name
          } : null
      };
    updateUserProfileRequest.mutate(body);
  }

  return (
    <div className="card-box mb-3">
      {
        (
          getUserRequest?.isPending ||
          updateUserProfileRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="form-box w-800 mw-100 mx-auto py-3">
        <form onSubmit={handleSubmit(updateUserProfileSubmit)}>
          <div className="row">
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">افزودن تصویر نمایه</h4>
            </div>
            <div className="col-12 px-2 px-lg-4">
              <div className="form-group mb-5">
                <ImageUploaderBox
                  setFieldValue={setAvatarData}
                  fieldValue={avatarData}
                  name='avatar'
                />
              </div>
            </div>
            <div className="col-12 px-4">
              <h4 className="title-dot font-16 font-weight-bold mb-5">اطلاعات کاربر</h4>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="firstName">نام</label>
                <TextField
                  name="firstName"
                  placeholder="نام"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="lastName">نام خانوادگی</label>
                <TextField
                  name="lastName"
                  placeholder="نام خانوادگی"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="nationalCode">‌کد ملی</label>
                <NumberField
                  name="nationalCode"
                  format='##########'
                  className="ltr plc-right"
                  placeholder='کدملی'
                  disabled={true}
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={10}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-4">
                <label className="inp-lbl me-2 mb-2" htmlFor="mobile">‌شماره همراه</label>
                <NumberField
                  name="mobile"
                  format='##########'
                  className="ltr plc-right"
                  placeholder="شماره همراه"
                  disabled={true}
                  control={control}
                  error={errors}
                  allowLeadingZeroes
                  isNumericString
                  maxLength={11}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-5">
                <label className="inp-lbl me-2 mb-2" htmlFor="email">‌ایمیل</label>
                <TextField
                  name="email"
                  placeholder="ایمیل"
                  className="ltr plc-right"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  onClick={() => navigate(ROUTES?.ACCOUNT?.PATH)}
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                >
                  بازگشت
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  ویرایش
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;