import * as React from "react"
import { SVGProps } from "react"
const DashboardSquareDiagonal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={28}
    height={29}
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.3}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.79094 13.1096C2.21552 13.7056 2.21552 14.6719 2.79094 15.2678L5.6569 18.2362C6.23232 18.8321 7.16526 18.8321 7.74068 18.2362L10.6066 15.2678C11.1821 14.6719 11.1821 13.7056 10.6066 13.1096L7.74068 10.1413C7.16526 9.54534 6.23232 9.54534 5.6569 10.1413L2.79094 13.1096ZM16.7909 13.1096C16.2155 13.7056 16.2155 14.6719 16.7909 15.2678L19.6569 18.2362C20.2323 18.8321 21.1653 18.8321 21.7407 18.2362L24.6066 15.2678C25.1821 14.6719 25.1821 13.7056 24.6066 13.1096L21.7407 10.1413C21.1653 9.54534 20.2323 9.54534 19.6569 10.1413L16.7909 13.1096Z"
      fill="#607DF8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.79094 5.85964C9.21552 6.45561 9.21552 7.42187 9.79094 8.01784L12.6569 10.9862C13.2323 11.5821 14.1653 11.5821 14.7407 10.9862L17.6066 8.01784C18.1821 7.42187 18.1821 6.45561 17.6066 5.85964L14.7407 2.89132C14.1653 2.29534 13.2323 2.29534 12.6569 2.89131L9.79094 5.85964ZM9.79094 20.3596C9.21552 20.9556 9.21552 21.9219 9.79094 22.5178L12.6569 25.4862C13.2323 26.0821 14.1653 26.0821 14.7407 25.4862L17.6066 22.5178C18.1821 21.9219 18.1821 20.9556 17.6066 20.3596L14.7407 17.3913C14.1653 16.7953 13.2323 16.7953 12.6569 17.3913L9.79094 20.3596Z"
      fill="#3F8DFD"
    />
  </svg>
)
export default DashboardSquareDiagonal
