import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../../hook/useMySearchParams";
import {TDataList, TDataListInitialValue} from "../../../../types/general";
import {getCellValue} from "../../../../utils/utils";
import {MtoJDateFormat} from "../../../../utils/date";
import {general} from "../../../../store/action";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import ChangeStatusAppointmentModal from "../../appointments/changeStatusAppointmentModal";
import DashboardDiamond from "../../../../assets/images/svgComponents/icon/dashboardDiamond";
import {useGetVacations} from "../../../../hook/request/vacations";
import EmptyBoxes from "../../../../assets/images/svgComponents/EmptyBoxes";
import Icon from "../../general/icon";
import Tooltip from "../../general/tooltip";

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  today?: boolean;
}

const DoctorsOnVacation = () => {

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [vacationsData, setVacationsData] = useState<TDataList>(TDataListInitialValue);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "5",
    search: "",
    today: true
  });

  // services
  const getVacationsRequest = useGetVacations(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "5",
      search: searchParams.get("search") || "",
      today: true
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getVacationsRequest?.data?.data?.result &&
    setVacationsData(getVacationsRequest?.data?.data.result)
  }, [getVacationsRequest.data])

  return (
    <div className="dashboard-card-box h-100">
      <div className="flex-wrap align-items-center m-2">
        <span className="d-flex align-items-center text-white font-25">
          <span className="d-flex align-items-center ms-2">
           <DashboardDiamond/>
          </span>
          <span className="font-15 fw-bold text-black">
            لیست پزشکان در مرخصی
          </span>
        </span>
      </div>
      <div className="h-90 m-2">
        {vacationsData?.data && vacationsData?.data.length > 0 ? vacationsData?.data.map((item: any) => {
          const firstCharacter = item?.provider?.name ? item?.provider?.name.charAt(0) : '';
          return (
            <div className='d-flex align-items-center border-bottom visit-card-animation py-3'>
              <div className="rounded-circle avatar-placeholder">
                {firstCharacter}
              </div>
              <div className='w-100 me-3'>
                <div className='font-15'>
                  {item?.provider?.name}
                </div>
                <div className='d-flex justify-content-between font-9 text-gray mt-2'>
                  <div>
                    <span>
                      از تاریخ:
                    </span>
                    <span className='me-2'>
                      {item?.fromDateTime ? getCellValue(MtoJDateFormat(item?.fromDateTime)) : '-'}
                    </span>
                    <span className='me-2'>
                      الی
                    </span>
                    <span className='me-2'>
                      {item?.toDateTime ? getCellValue(MtoJDateFormat(item?.toDateTime)) : '-'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="me-auto">
                <Tooltip alt={`ثبت شده در تاریخ ${getCellValue(MtoJDateFormat(item?.insertDateTime))}`} className="tooltip-pre-line">
                  <span className='d-flex align-items-center text-primary font-25'>
                    <Icon name='calendar'/>
                  </span>
                </Tooltip>
              </div>
            </div>
          )
        }) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <EmptyBoxes/>
            <div className='font-12 mt-2'>
              لیستی برای نمایش وجود ندارد.
            </div>
          </div>
        )}
      </div>
      <ChangeStatusAppointmentModal/>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(DoctorsOnVacation);