import {axiosRequest} from "../api";
import {API_PATH} from "../../constants";

export const MedicationItemService = {
    getMedicationItemsList(params){ return axiosRequest('GET', API_PATH.MEDICATION.LIST(params)) },
    getMedicationFrequencyList(params){ return axiosRequest('GET', API_PATH.MEDICATION.FREQUENCY_LIST(params)) },
    getMedicationDosageList(params){ return axiosRequest('GET', API_PATH.MEDICATION.DOSAGE_LIST(params)) },
    getMedicationBrandList(params){ return axiosRequest('GET', API_PATH.MEDICATION.BRAND(params)) },
    getMedicationItem(data){ return axiosRequest('POST', API_PATH.MEDICATION.INFO, data) },
    addMedicationItem(data){ return axiosRequest('POST', API_PATH.MEDICATION.ADD, data) },
    deleteMedicationItem(data){ return axiosRequest('POST', API_PATH.MEDICATION.DELETE, data) },
}