import {useWindowSize} from "../../../hook/useWindowSize";
import {useSearchParams} from "react-router-dom";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import React, {useEffect, useMemo, useState} from "react";
import SearchBox from "../../components/general/searchBox";
import Pagination from "../../components/general/table/pagination";
import {useGetVisits} from "../../../hook/request/visit/visits";
import Icon from "../../components/general/icon";
import VisitsTable from "../../components/visits/dataList/visitsTable";
import VisitsCardsBox from "../../components/visits/dataList/visitCardsBox";
import SelectField from "../../components/general/form/selectField";
import {useForm} from "react-hook-form";
import {general} from "../../../store/action";
import {connect, useSelector} from "react-redux";
import AddVisitModal from "../../components/visits/addVisitModal";
import {VISIT_STATUS} from "../../../constants/constant/enum";
import ChangeStatusAppointmentModal from "../../components/appointments/changeStatusAppointmentModal";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";


const dataListTitles = [
  "نام و نام خانوادگی بیمار",
  "نام پزشک",
  "نام مرکز",
  "تاریخ و ساعت ویزیت",
  "نوع ویزیت",
  "وضعیت",
  "شماره پیگیری",
  "وضعیت نسخه",
  "جزئیات",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  status?: string
}


type VisitsListProps = {
  handleShowModal: any;
}

const VisitsList = ({
  handleShowModal
}: VisitsListProps) => {

  const [width] = useWindowSize();
  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [visitsData, setVisitsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
    status: ""
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TFilterValues>({
    defaultValues: useMemo(() => filterValues, [filterValues]),
  });

  // services
  const getVisitsRequest = useGetVisits(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
      status: searchParams.get("status") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getVisitsRequest?.data?.data?.result &&
    setVisitsData(getVisitsRequest?.data?.data.result)
  }, [getVisitsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  const openFilterDropDown = (param: any) => {
    if(param?.target?.value != null) {
      const {value} = param?.target?.value
      setFilterValues({
        ...filterValues,
        status: value
      })
    } else {
      setFilterValues({
        ...filterValues,
        status: ''
      })
    }
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی ویزیت"
            />
          </div>
          <div className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
            {
              (isProvider || findAccessInAccessList('VISIT_VIEW')) &&
              <button
                onClick={() => handleShowModal('addVisitModal')}
                className="btn btn-primary rounded d-flex align-items-center fw-bold px-4 ms-2"
              >
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                پـذیـرش بیمار
              </button>
            }
            <SelectField
              options={VISIT_STATUS}
              name="status"
              placeholder='وضعیت راانتخاب کنید'
              control={control}
              isClearable={true}
              error={errors}
              onChange={(e) => openFilterDropDown(e)}
              returnObjectValue={true}
            />
          </div>
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <VisitsCardsBox
                dataList={visitsData}
                dataListTitles={dataListTitles}
                isRequesting={getVisitsRequest.isPending}
              /> :
              <VisitsTable
                dataList={visitsData}
                dataListTitles={dataListTitles}
                isRequesting={getVisitsRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...visitsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <AddVisitModal/>
      <ChangeStatusAppointmentModal/>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VisitsList);