import * as React from "react";
import { SVGProps } from "react";

interface AccordionButtonIconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
}

const AccordionButtonIcon: React.FC<AccordionButtonIconProps> = ({ fill = "#454855", ...props }) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11ZM7.46967 8.96967C7.76256 8.67678 8.23744 8.67678 8.53033 8.96967L11 11.4393L13.4697 8.96967C13.7626 8.67678 14.2374 8.67678 14.5303 8.96967C14.8232 9.26256 14.8232 9.73744 14.5303 10.0303L11.5303 13.0303C11.2374 13.3232 10.7626 13.3232 10.4697 13.0303L7.46967 10.0303C7.17678 9.73744 7.17678 9.26256 7.46967 8.96967Z"
      fill={fill}
    />
  </svg>
);

export default AccordionButtonIcon;
