import {getCellValue} from "../../../../../../utils/utils";
import {monthDay, MtoJHHMMFormat, yearTitle} from "../../../../../../utils/date";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect, useSelector} from "react-redux";
import React from "react";
import Tooltip from "../../../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type PatientBiometricTableRowItemProps = {
  item: any;
  handleShowModal: any;
  readonly: boolean;
}

const PatientBiometricTableRowItem = ({
  item,
  handleShowModal,
  readonly
}: PatientBiometricTableRowItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  return (
    <tr className={`${readonly && 'border-bottom'}`}>
      <td>
        {
          getCellValue(monthDay(item?.observationDateTime)) + ' ' +
          getCellValue(yearTitle(item?.observationDateTime)) + ' - ' +
          getCellValue(MtoJHHMMFormat(item?.observationDateTime))
        }
      </td>
      <td>{getCellValue(item?.weight?.magnitude)}</td>
      <td>{getCellValue(item?.height?.magnitude)}</td>
      <td>{item?.bmi?.magnitude ? (Math.round(getCellValue(item?.bmi?.magnitude) * 100) / 100) : '-'}</td>
      <td>{getCellValue(item?.muac?.magnitude)}</td>
      {
        ((!readonly && isProvider) || findAccessInAccessList('EHR_BIOMETRIC_DELETE')) &&
        <td>
          <div className="d-flex justify-content-center align-items-center">
            <Tooltip alt={'حذف'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('deletePatientBiometricModal', item?.id)}
              className="d-flex text-red-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='trash'/>
            </span>
            </Tooltip>
          </div>
        </td>
      }

    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientBiometricTableRowItem);
