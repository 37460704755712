import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../../utils/date";
import {general} from "../../../../../../../store/action";
import {connect} from "react-redux";
import React, {useState} from "react";
import { Accordion } from "react-bootstrap";
import AccordionButtonIcon from "../../../../../../../assets/images/svgComponents/accordionButtonIcon";


type VisitParaclinicalPrescriptionCardsItemProps = {
  item: any;
  handleShowModal: any;
  index: number
}

const VisitParaclinicalPrescriptionCardsItem = ({
  item,
  index
}: VisitParaclinicalPrescriptionCardsItemProps) => {
  const [arrowColor, setArrowColor] = useState('black')

  return (
    <Accordion.Item eventKey={`medicationItem_${index}`} className={` rounded my-1`}>
      <Accordion.Header className='rounded font-14'
                        onClick={() => setArrowColor(arrowColor === 'blue' ? 'black' : 'blue')}
      >
        <div className="accordion-title d-flex justify-content-between align-items-center w-100">
          <span className="accordion-icon">
            <AccordionButtonIcon fill={arrowColor}/>
          </span>
          <div className='w-100 d-flex justify-content-end align-items-center text-truncate'>
            <span className='me-2'>{item?.service?.enName ? getCellValue(item?.service?.enName) : getCellValue(item?.service?.name)}</span>
            <div
              className={`bg-primary bg-opacity-10 rounded rounded-3 border border-primary font-12 fw-bold text-primary text-nowrap px-1 py-2 me-2 text-truncate`}>
              {item?.service?.type?.name[0]}
            </div>
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="">
          <span className="text-gray fw-semibold ms-1">نام کامل:</span>
          <div className='text-start'>
            {item?.service?.enName ? getCellValue(item?.service?.enName) : getCellValue(item?.service?.name)}
          </div>
        </div>
        <div className="">
          <span className="text-gray fw-semibold mx-1">نوع خدمت:</span>
          <span className="text-orange fw-semibold mx-1"> {getCellValue(item?.service?.type?.name)}</span>
        </div>
        <div className="">
          <span className="text-gray fw-semibold mx-1">تاریخ:</span>
          <span className="text-primary fw-semibold mx-1"> {getCellValue(MtoJDateFormat(item?.activeDate))}</span>
        </div>
        <div className="">
          <span className="text-gray fw-semibold mx-1">تعداد:</span>
          <span className="text-green2 fw-semibold mx-1"> {getCellValue(item?.itemCount)} </span>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(VisitParaclinicalPrescriptionCardsItem);
