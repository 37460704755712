import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../constants";
import Icon from "../../components/general/icon";
import SearchBox from "../../components/general/searchBox";
import DeleteDoctorModal from "../../components/doctors/deleteDoctorModal";
import { useWindowSize } from "../../../hook/useWindowSize";
import DoctorTable from "../../components/doctors/dataList/doctorTable";
import DoctorCardsBox from "../../components/doctors/dataList/doctorCardsBox";
import { useGetDoctors } from "../../../hook/request/doctors";
import { useMySearchParams } from "../../../hook/useMySearchParams";
import Pagination from "../../components/general/table/pagination";
import ChangeAccountPasswordModal from "../../components/accounts/changeAccountPasswordModal";
import BlockingAccountModal from "../../components/accounts/blockingAccountModal";
import {useFindAccessInAccessList} from "../../../constants/constant/accessProcess";

const dataListTitles = [
  "نام پزشک",
  "تخصص",
  "کد ملی",
  "شماره همراه",
  "نظام پزشکی",
  "تاریخ ایجاد",
  "وضعیت",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const DoctorsList = () => {

  const [width] = useWindowSize();
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [doctorsData, setDoctorsData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getDoctorsRequest = useGetDoctors(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getDoctorsRequest?.data?.data?.result &&
      setDoctorsData(getDoctorsRequest?.data?.data.result)
  }, [getDoctorsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }
  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="form-group ms-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی پزشک"
            />
          </div>
          {
            findAccessInAccessList('PROVIDER_CREATE') &&
            <div className="d-flex align-items-center align-items-stretch justify-content-center justify-content-md-start mb-3 m-md-0">
              <Link to={ROUTES.DOCTOR_ADD.PATH} className="btn btn-primary rounded d-flex align-items-center fw-bold px-4">
                <span className="d-inline-flex font-25 ms-2 ps-2"><Icon name="add-square"/></span>
                افزودن پزشک
              </Link>
            </div>
          }
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <DoctorCardsBox
                dataList={doctorsData}
                dataListTitles={dataListTitles}
                isRequesting={getDoctorsRequest.isPending}
              /> :
              <DoctorTable
                dataList={doctorsData}
                dataListTitles={dataListTitles}
                isRequesting={getDoctorsRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...doctorsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>
      <BlockingAccountModal/>
      <ChangeAccountPasswordModal/>
      <DeleteDoctorModal />
    </>
  );
};

export default DoctorsList;