import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {handleResponse, queryClient} from "./setup";
import Noti from "../../view/components/general/noti";
import {ROUTES} from "../../constants";
import {AnnouncementsService} from "../../services/requests/announcements";

const useGetAnnouncements = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getAnnouncements", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return AnnouncementsService.getAnnouncementsList(params);
    },
    enabled: modalName ?? true
  });
}

const useGetLatestAnnouncements = (params: any, modalName?: boolean) => {
  return useQuery({
    queryKey: ["getLatestAnnouncements", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return AnnouncementsService.getLatestAnnouncementsList(params);
    },
    enabled: modalName ?? true
  });
}


const useGetAnnouncement = () => {
  return useMutation({
    mutationFn: AnnouncementsService.getAnnouncement,
  })
}

const useAddAnnouncement = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: AnnouncementsService.addAnnouncement,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          Noti("success", data?.data?.msg)
          navigate(ROUTES.VACCINE.PATH);
        }
      })
    },
  })
}

const useDeleteAnnouncement = ({
  onSuccess
}: {
  onSuccess: () => void
}) => {
  return useMutation({
    mutationFn: AnnouncementsService.deleteAnnouncement,
    onSuccess: (data: any) => {
      handleResponse({
        data,
        onSuccess: () => {
          console.log(data)
          Noti("success", data?.data?.msg)
          queryClient.invalidateQueries({ queryKey: ["getAnnouncements"] })
          onSuccess();
        }
      })
    },
    onError: (error) => {
      Noti("error", error?.message)
    }
  })
}

export {
  useGetAnnouncements,
  useGetLatestAnnouncements,
  useGetAnnouncement,
  useAddAnnouncement,
  useDeleteAnnouncement
}
