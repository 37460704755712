// src/redux/reducers/idReducer.js

import {SET_ACCESS_PROCESS_LIST} from "../../slice/accessProcess";
import {TGIdNameData} from "../../../types/general";

type TAccessProcess = {
  accessProcessList: TGIdNameData[]
}

const initialState: TAccessProcess = {
  accessProcessList: [],
};

const accessProcessReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ACCESS_PROCESS_LIST:
      return {
        ...state,
        accessProcessList: action.payload,
      };
    default:
      return state;
  }
};

export default accessProcessReducer;
