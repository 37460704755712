import { useEffect, useMemo, useState } from "react";
import Tooltip from "../general/tooltip";
import { DAY_OF_WEEK } from "../../../constants/constant/enum";
import { general } from "../../../store/action";
import { connect } from "react-redux";
import { convertToHourFormat, convertToShortTime, diffTwoTime, getHour } from "../../../utils/utils";

type ScheduleTimelineProps = {
  scheduleItemsList: any;
  draftStatus: any;
  handleShowModal: any;
}

const ScheduleTimeline = ({
  scheduleItemsList,
  draftStatus,
  handleShowModal
}: ScheduleTimelineProps) => {

  const [timelineRange, setTimelineRange] = useState([7, 22]);

  useEffect(() => {
    if(scheduleItemsList){
      let startTime = getHour(scheduleItemsList[0]?.fromTime);
      let endTime = getHour(scheduleItemsList[0]?.endTime);
      for(const itm of scheduleItemsList) {
        startTime = getHour(itm?.fromTime) < startTime ? getHour(itm?.fromTime) : startTime;
        endTime = getHour(itm?.toTime) > endTime ? getHour(itm?.toTime) : endTime;
      }
      startTime === endTime ?
        setTimelineRange([8, 21]) :
        setTimelineRange([startTime, endTime + 1])
    }
  }, [scheduleItemsList])

  const generatedHeader = useMemo(() => {
    let tblHeader: any = [];
    if(timelineRange.length > 0){
      for(let rng = timelineRange[0]; rng < timelineRange[1]; rng++){
        tblHeader.push(
          <div className="tmln-cell" key={rng}>
            <span className="tmln-time">{convertToHourFormat(rng)}</span>
          </div>
        )
      }
    }
    return tblHeader;
  }, [timelineRange])

  const generateDayRow = (dayNum: number) => {
    const _dayItems = scheduleItemsList.filter((item: any) => item?.dayOfWeek?.id === dayNum),
      rowCellWidth = document.getElementsByClassName("tmln-cube-cell")[0]?.clientWidth,
      allRowUnit = diffTwoTime(`${convertToHourFormat(timelineRange[0])}:00`, `${convertToHourFormat(timelineRange[1])}:00`),
      unitWidth = rowCellWidth / allRowUnit;

    if(_dayItems.length > 0) {
      const allDayItems = _dayItems.map((dayItem: any) => {
        const _fromTime = dayItem?.fromTime,
          _toTime = dayItem?.toTime,
          _generatedRow = [],
          cubeCellUnit = diffTwoTime(_fromTime, _toTime) * unitWidth,
          rigthPosition = diffTwoTime(`${convertToHourFormat(timelineRange[0])}:00`, _fromTime) * unitWidth;

        _generatedRow.push(
          <Tooltip alt={dayItem?.visitType?.name + ' - ' +  dayItem?.healthUnit?.name} placement='top' key={dayItem?.id}>
            <div
              className={`tmln-cube timeline-filled-cell font-10 cur-pointer visit-type-${dayItem?.visitType?.id}`}
              style={{
                width: `${cubeCellUnit}px`,
                right: `${rigthPosition}px`
              }}
              onClick={() => draftStatus && handleShowModal('deleteScheduleItemModal', dayItem?.id)}
            >
              <div>
                <div className="font-serif mb-2">
                  {convertToShortTime(dayItem?.fromTime)}
                  <span className="font-vazir mx-1">تا</span>
                  {convertToShortTime(dayItem?.toTime)}
                </div>
                ظرفیت <span>{dayItem?.capacity}</span> نفر
              </div>
            </div>
          </Tooltip>
        )
        return _generatedRow;
      })
      return allDayItems;
    }
  }

  const _daysRow = DAY_OF_WEEK.map((item) => {
    return (
      <div className="tmln-row" key={item?.value}>
        <div className="tmln-cell">{item?.label}</div>
        <div className="tmln-cell tmln-cube-cell flex-grow-1 position-relative">
          {generateDayRow(item?.value)}
        </div>
      </div>
    )
  })

  return (
    <>
    <div className="timeline-container bg-gray mx-1 mb-1">
      <div className="tmln-thead">
        <div className="tmln-row">
          <div className="tmln-cell"></div>
          {generatedHeader}
        </div>
      </div>
      <div className="tmln-tbody">
        {_daysRow}
      </div>
    </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(ScheduleTimeline);
