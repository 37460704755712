import React, {useEffect, useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Carousel} from "react-bootstrap";
import {
  DonutChart,
  radialChartOptions,
  VariableRadiusPie
} from "../../general/dashboardChartOptions";
import Analytics from "../../../../assets/images/svgComponents/icon/analytics";
import {
  useGetVisitAgeChart,
  useGetVisitGenderChart, useGetVisitStatusChart
} from "../../../../hook/request/systemReport";
import EmptyChart from "../../general/emptyChart";

const VisitChartsCarousel = () => {

  const [genderSum, setGenderSum] = useState<number>();
  const [genderData, setGenderData] = useState([]);
  const [genderLabels, setGenderLabels] = useState([]);

  const [ageData, setAgeData] = useState([]);
  const [ageLabels, setAgeLabels] = useState([]);

  const [statusSum, setStatusSum] = useState();
  const [statusData, setStatusData] = useState([]);
  const [statusLabels, setStatusLabels] = useState([]);

  //services
  const getVisitGenderChart = useGetVisitGenderChart();
  const getVisitAgeChart = useGetVisitAgeChart();
  const getVisitStatusChart = useGetVisitStatusChart();

  useEffect(() => {
    getVisitGenderChart.mutate({})
  }, []);

  useEffect(() => {
    if(!getVisitGenderChart.isPending && getVisitGenderChart?.data?.data){
      const { result } = getVisitGenderChart?.data?.data;
      let _genderData = [],
        _genderLabel = [];
      if(result){
        _genderData = result
          .map((item:any) => (item?.values[0]));
        _genderLabel = result
          .map((item:any) => (`${item?.label}`));
      }
      const totalSum = _genderData.reduce((acc:any , curr: any) => acc + curr, 0);
      setGenderSum(totalSum);
      setGenderData(_genderData)
      setGenderLabels(_genderLabel)
    }
  }, [getVisitGenderChart.data])

  useEffect(() => {
    getVisitAgeChart.mutate({})
  }, []);

  useEffect(() => {
    if(!getVisitAgeChart.isPending && getVisitAgeChart?.data?.data){
      const { result } = getVisitAgeChart?.data?.data;
      let _ageData = [],
        _ageLabel = [];
      if(result){
        _ageData = result
          .map((item:any) => (item?.values[0]));
        _ageLabel = result
          .map((item:any) => (`${item?.label}`));
      }
      setAgeData(_ageData)
      setAgeLabels(_ageLabel)
    }
  }, [getVisitAgeChart.data])

  useEffect(() => {
    getVisitStatusChart.mutate({})
  }, []);

  useEffect(() => {
    if(!getVisitStatusChart.isPending && getVisitStatusChart?.data?.data){
      const { result } = getVisitStatusChart?.data?.data;
      let _statusData = [],
        _statusLabel = [];
      if(result){
        _statusData = result
          .map((item:any) => (item?.values[0]));
        _statusLabel = result
          .map((item:any) => (`${item?.label}`));
      }
      const totalSum = _statusData.reduce((acc:any , curr: any) => acc + curr, 0);
      setStatusSum(totalSum);
      setStatusData(_statusData)
      setStatusLabels(_statusLabel)
    }
  }, [getVisitStatusChart.data])

  return (
    <div className="dashboard-card-box h-100">
      <Carousel className='dashboard-carousel-indicators carousel-container remove-carousel-arrows h-100'>
        <Carousel.Item>
          <div className="flex-wrap align-items-center m-2">
            <span className="d-flex align-items-center text-white font-25">
              <span className="d-flex align-items-center ms-2">
               <Analytics/>
              </span>
              <span className="font-15 fw-bold text-black">
                نمودار‌ آماری ویزیت‌ها
              </span>
              <span className="font-12 me-auto border border-primary bg-primary-10 rounded-3 text-primary py-1 px-3">
                بازه سنی
              </span>
            </span>
            {
              ageData.every(item => item === 0) ?
                <EmptyChart/> :
                <HighchartsReact
                  highcharts={Highcharts}
                  options={VariableRadiusPie({
                    data: ageData,
                    labels: ageLabels
                  })}
                />
            }
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="flex-wrap align-items-center m-2">
            <span className="d-flex align-items-center text-white font-25">
              <span className="d-flex align-items-center ms-2">
               <Analytics/>
              </span>
              <span className="font-15 fw-bold text-black">
                نمودار‌ آماری ویزیت‌ها
              </span>
              <span className="font-12 me-auto border border-primary bg-primary-10 rounded-3 text-primary py-1 px-3">
                جنسیت
              </span>
            </span>
            {
              genderData?.every(item => item === 0) ?
                <EmptyChart/> :
              <HighchartsReact
                highcharts={Highcharts}
                options={radialChartOptions({
                  data: genderData,
                  labels: genderLabels,
                  max: genderSum
                })}
              />
            }
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="flex-wrap align-items-center m-2">
            <span className="d-flex align-items-center text-white font-25">
              <span className="d-flex align-items-center ms-2">
               <Analytics/>
              </span>
              <span className="font-15 fw-bold text-black">
                نمودار‌ آماری ویزیت‌ها
              </span>
              <span className="font-12 me-auto border border-primary bg-primary-10 rounded-3 text-primary py-1 px-3">
                وضعیت
              </span>
            </span>
            {
              statusData?.every(item => item === 0) ?
                <EmptyChart/> :
              <HighchartsReact
                highcharts={Highcharts}
                options={DonutChart({
                  data: statusData,
                  labels: statusLabels,
                  totalCount: statusSum,
                })}
              />
            }
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default VisitChartsCarousel;