import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {useNavigate} from "react-router-dom";

import {Dispatch} from "redux";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import BoxLoader from "../general/boxLoader";
import Modal from "../general/modal";
import Icon from "../general/icon";
import {useActiveSchedule} from "../../../hook/request/schedule";
import {ROUTES} from "../../../constants";

type ActiveScheduleModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

const ActiveScheduleModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: ActiveScheduleModalProps) => {
  const navigate = useNavigate();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  useEffect(() => {
    if(
      isShowModalData &&
      modalName === 'activeScheduleModal'
    ){
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  // services
  const activeScheduleRequest = useActiveSchedule({
    onSuccess: () => {
      handleSetShowModal();
      navigate(ROUTES.SCHEDULE.PATH)
    }
  });

  const activeScheduleSubmit = () => {
    const body = {id: modalInfo};
    activeScheduleRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-400 mw-100"
      bodyClassName="py-5 px-3 px-lg-5 pos-rel"
    >
      { activeScheduleRequest?.isPending && <BoxLoader type="cover" /> }
      <div className="text-center">
        <div className="d-flex justify-content-center mb-4">
          <span className="info-item-bg d-flex justify-content-center align-items-center text-red font-25 pt-4">
            <Icon name='check-square' />
          </span>
        </div>
        <h4 className="font-14 mb-2">کاربر گرامی</h4>
        <p className="text-black">
          مطمئن شوید که برنامه زمان‌بندی شما کامل و صحیح است. با تشکر از توجه شما.
        </p>
      </div>

      <div className="d-flex justify-content-center mt-4 pt-2">
        <button
          onClick={() => handleSetShowModal()}
          className="btn btn-outline-gray flex-grow-1 py-2 mx-1"
        >
          انصراف
        </button>
        <button
          onClick={activeScheduleSubmit}
          className="btn btn-primary d-flex align-items-center py-2 mx-1"
        >
          تایید و انتشار
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveScheduleModal);
