import { useEffect, useState } from "react";
import { useGetDoctorStatistics } from "../../../hook/request/doctors";
import { TStatisticsDoctorFields } from "../../../types/doctor";
import BoxLoader from "../general/boxLoader";
import { ROUTES } from "../../../constants";
import { Link } from "react-router-dom";

type StatisticsSectionProps = {
  isProvider: boolean;
}

const StatisticsSection = ({
  isProvider
}: StatisticsSectionProps) => {

  const [fieldValue, setFieldValue] = useState<TStatisticsDoctorFields>({
    totalDraftPrescription: 0,
    visitNumber: [],
    totalAssignedDevice: 0
  });

  // services
  const getDoctorStatisticsRequest = useGetDoctorStatistics();

  useEffect(() => {
    getDoctorStatisticsRequest.mutate({});
  }, [])

  useEffect(() => {
    if (getDoctorStatisticsRequest?.data?.data?.result) {
      const { totalDraftPrescription, visitNumber, totalAssignedDevice } = getDoctorStatisticsRequest.data.data.result,
        _visitNumber = visitNumber.filter((item: any) => item.label === 400),
        _fieldValue = {
          totalDraftPrescription, totalAssignedDevice,
          visitNumber: _visitNumber[0]
        };
      setFieldValue(_fieldValue);
    }
  }, [getDoctorStatisticsRequest.data])

  return (
    <div className="mb-5">
      {
        (
          getDoctorStatisticsRequest?.isPending
        ) && <BoxLoader type="cover" />
      }
      <div className="d-flex align-items-center title-dot tile-dot-primary font-15 font-weight-bold text-gray me-3">
        اطلاعات آماری
      </div>
      <div className="row mt-4 text-center">
        <div className="acc-box col col-md-3">
          <div className="rounded bg-gray4 p-4">
            <h5 className="font-14">تاریخچه ورود به سیستم</h5>
            <div className="d-flex align-items-center mt-3">
              <Link to={ROUTES?.LOGIN_HISTORY?.PATH} className="btn btn-primary px-3 py-1">مشاهده</Link>
              <div className="acc-inner-box"></div>
            </div>
          </div>
        </div>
        {
          isProvider &&
          <>
            <div className="acc-box col col-md-3">
              <div className="rounded bg-gray4 p-4">
                <h5 className="font-14">نسخه‌های پیش‌نویس</h5>
                <div className="d-flex mt-3">
                  <bdi className="d-flex align-items-center font-weight-900 font-25 text-primary">
                    {fieldValue?.totalDraftPrescription}
                  </bdi>
                  <div className="acc-inner-box"></div>
                </div>
              </div>
            </div>
            <div className="acc-box col col-md-3">
              <div className="rounded bg-gray4 p-4">
                <h5 className="font-14">مجموع ویزیت‌های انجام شده</h5>
                <div className="d-flex mt-3">
                  <bdi className="d-flex align-items-center font-weight-900 font-25 text-primary">
                    {fieldValue?.visitNumber.values}
                  </bdi>
                  <div className="acc-inner-box"></div>
                </div>
              </div>
            </div>
            <div className="acc-box col col-md-3">
              <div className="rounded bg-gray4 p-4">
                <h5 className="font-14">دستگاه‌ها و افراد متصل</h5>
                <div className="d-flex mt-3">
                  <bdi className="d-flex align-items-center font-weight-900 font-25 text-primary">
                    {fieldValue?.totalAssignedDevice}
                  </bdi>
                  <div className="acc-inner-box"></div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default StatisticsSection;