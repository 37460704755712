import {getCellValue} from "../../../../../../utils/utils";
import {MtoJFullDateFormat} from "../../../../../../utils/date";
import Icon from "../../../../general/icon";
import {general} from "../../../../../../store/action";
import {connect, useSelector} from "react-redux";
import React from "react";
import Tooltip from "../../../../general/tooltip";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type PatientAppointmentTableRowItemProps = {
  item: any;
  handleShowModal: any;
}

const PatientAppointmentTableRowItem = ({
  item,
  handleShowModal
}: PatientAppointmentTableRowItemProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const determineBgColor = () => {
    switch (item?.status?.id) {
      case 100:
        return 'bg-gray4'
      case 200:
        return 'bg-gray3'
      case 400:
        return 'bg-yellow1 text-yellow'
      default:
        return 'bg-light-red text-red'
    }
  }

  return (
    <tr>
      <td>{getCellValue(item?.healthUnit?.name)}</td>
      <td>{getCellValue(item?.provider?.firstName)} {getCellValue(item?.provider?.lastName)}</td>
      <td>
        <bdi className="word-spacing-10">{getCellValue(MtoJFullDateFormat(item?.dateTime))}</bdi>
      </td>
      <td>{getCellValue(item?.visitType?.name)}</td>
      <td>
        <div>
          <span className={`px-3 py-1 rounded text-nowrap ${determineBgColor()} `}>
            {getCellValue(item?.status?.name)}
          </span>
        </div>
      </td>
      {(isProvider || findAccessInAccessList('APPOINTMENT_VIEW')) &&
        <td>
          <div className="d-flex justify-content-center align-items-center">
            <Tooltip alt={'جزئیات'} className="tooltip-pre-line">
              <span
                onClick={() => handleShowModal('changeStatusAppointmentModal', {id: item?.id, ehr:true})}
                className="d-flex text-hover mx-2 font-20 cur-pointer"
              >
                <Icon name='eye'/>
              </span>
            </Tooltip>
          </div>
        </td>
      }
    </tr>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientAppointmentTableRowItem);
