import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import Modal from "../general/modal";
import Icon from "../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import Highcharts from "highcharts";
import {chartOptions} from "../general/chartOptions";
import HighchartsReact from "highcharts-react-official";
import {
  useGetInsuranceResponseTimePercentage,
} from "../../../hook/request/systemReport";
import {ISSUER_TYPE} from "../../../constants/constant/enum";

type InsuranceStabilityModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  issuerType: number
}


const InsuranceStabilityModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: InsuranceStabilityModalProps) => {
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>();
  const [responseTimePercentage, setResponseTimePercentage] = useState([]);
  const [responseTimePercentageLabels, setResponseTimePercentageLabels] = useState<string[]>([]);


  // services
  const getInsuranceResponseTimePercentageRequest = useGetInsuranceResponseTimePercentage()

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'insuranceStabilityModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])


  useEffect(() => {
    modalInfo?.issuerType && getInsuranceResponseTimePercentageRequest.mutate({id: modalInfo?.issuerType});
  }, [modalInfo?.issuerType]);

  useEffect(() => {
    if(!getInsuranceResponseTimePercentageRequest.isPending && getInsuranceResponseTimePercentageRequest?.data?.data){
      const { result } = getInsuranceResponseTimePercentageRequest?.data?.data;
      let _responseTimeData = [],
        _responseTimeLabel: string[] = [];
      console.log(result)
      if(result){
        _responseTimeData = result
          .map((item: any) => (item?.values[0]));
        _responseTimeLabel = result
          .map((item: any) => (`${item?.label}`));
      }
      setResponseTimePercentage(_responseTimeData)
      setResponseTimePercentageLabels(_responseTimeLabel)
    }
  }, [getInsuranceResponseTimePercentageRequest.data]);


  const handleSetShowModal = () => {
    setShowState(false);
    // setFieldValue(fieldValueInitialState)
    handleHideModal();
  }

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              پایداری سرویس بیمه {modalInfo?.issuerType === ISSUER_TYPE.IHIO.value ? "تامین اجتماعی" : "سلامت"}
            </h4>
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions({
            chartType: 'areaspline',
            data: [responseTimePercentage],
            dataNames: ['درصد'],
            labels: responseTimePercentageLabels,
            chartTitle: 'نمودار پایداری',
            max: 100
          })}
          ref={chartComponentRef}
        />
        {/*<HighchartsReact*/}
        {/*  highcharts={Highcharts}*/}
        {/*  options={PictorialOptions()}*/}
        {/*  ref={chartComponentRef}*/}
        {/*/>*/}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceStabilityModal);