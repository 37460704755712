import React from "react";
import EmptyRow from "../../../general/table/emptyRow";
import TableLoader from "../../../general/table/tableLoader";
import FavoriteMedicationTableRowItem from "./favoriteMedicationTable_rowItem";

type FavoriteMedicationsTableProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const FavoriteMedicationsTable = ({
  dataList,
  dataListTitles,
  isRequesting
}: FavoriteMedicationsTableProps) => {
  const _dataListTitles = dataListTitles.map((item, i) => <td key={i}>{item}</td>),
    _dataList = dataList && dataList?.length > 0 && dataList?.map((item: any, i: number) => {
      return <FavoriteMedicationTableRowItem item={item} key={i}/>
    });

  return (
    <table>
      <thead>
      <tr>
        {_dataListTitles}
      </tr>
      </thead>
      <tbody>
      {isRequesting ?
        <TableLoader colSpan={dataListTitles.length}/> :
        _dataList ? _dataList : <EmptyRow colSpan={dataListTitles.length} content={''}/>
      }
      </tbody>
    </table>
  )
}

export default FavoriteMedicationsTable;
