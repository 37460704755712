import React, {useEffect, useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ComputerAnalytics from "../../../../assets/images/svgComponents/icon/computerAnalytics";
import {MtoJDateFormat} from "../../../../utils/date";
import {useGetVisitIssuerDailyChart} from "../../../../hook/request/systemReport";
import {MultipleDonutBarLineChart} from "../../general/dashboardChartOptions";
import Analytics from "../../../../assets/images/svgComponents/icon/analytics";
import emptyChartBg from "../../../../assets/images/svg/emptyChart.svg"
import EmptyChart from "../../general/emptyChart";


const DailyVisitIssuerChart = () => {

  const [ihioData, setIhioData] = useState<number[]>([]);
  const [taminData, setTaminData] = useState<number[]>([]);
  const [mData, setMData] = useState<number[]>([]);
  const [bData, setBData] = useState<number[]>([]);
  const [averageData, setAverageData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const getVisitIssuerDailyChart = useGetVisitIssuerDailyChart();

  useEffect(() => {
    getVisitIssuerDailyChart.mutate({})
  }, []);

  useEffect(() => {
    if(!getVisitIssuerDailyChart.isPending && getVisitIssuerDailyChart?.data?.data){
      const { result } = getVisitIssuerDailyChart?.data?.data;
      let _ihioData: number[] = [];
      let _taminData: number[] = [];
      let _mData: number[] = [];
      let _bData: number[] = [];
      let _totalVisitCountLabel: string[] = [];
      let _average: number[] = []
      if(result){
        result.map((item:any) => {
          const values: any = item.values;
          // Push each value into the corresponding index array
          _ihioData.push(values[0]);
          _taminData.push(values[1]);
          _mData.push(values[2]);
          _bData.push(values[3]);
          const sum = values.reduce((acc: number, curr: number) => acc + curr, 0);
          const nonZeroCount = values.filter((value: number) => value !== 0).length;
          const average = nonZeroCount > 0 ? sum / nonZeroCount : 0;
          _average.push(average)
        });
        _totalVisitCountLabel = result
          .map((item:any) => (`${MtoJDateFormat(item?.label)}`));
      }

      setIhioData(_ihioData)
      setTaminData(_taminData)
      setMData(_mData)
      setBData(_bData)
      setAverageData(_average)
      setLabels(_totalVisitCountLabel)
    }
  }, [getVisitIssuerDailyChart.data])

  return (
    <div className="dashboard-card-box h-100">
      <div className="flex-wrap align-items-center m-2">
          <div className="d-flex align-items-center text-white font-25 mb-3">
            <span className="d-flex align-items-center ms-2">
             <Analytics/>
            </span>
            <span className="font-15 fw-bold text-black">
              نمودار آماری بیماران
            </span>
              <span
                className="font-12 me-auto border border-primary bg-primary-10 rounded-3 text-primary py-1 px-3">
              نوع بیمه
            </span>
          </div>
        {
          (ihioData?.length === 0 &&
          taminData?.length === 0 &&
          mData?.length === 0 &&
          bData?.length === 0 &&
          averageData?.length === 0) ? (
                <EmptyChart/>
            ) :
            <HighchartsReact
              highcharts={Highcharts}
              options={MultipleDonutBarLineChart({
                ihioData: ihioData,
                taminData: taminData,
                mData: mData,
                bData: bData,
              averageData: averageData,
              labels: labels
            })}
          />
        }

      </div>
    </div>
  )
}

export default DailyVisitIssuerChart;