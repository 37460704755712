import * as React from "react"
import { SVGProps } from "react"
const LobbyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 3.25C5.04086 3.25 3.25 5.04086 3.25 7.25V7.91667C3.25 10.1258 5.04086 11.9167 7.25 11.9167H18.75C20.9591 11.9167 22.75 10.1258 22.75 7.91667V7.25C22.75 5.04086 20.9591 3.25 18.75 3.25H7.25ZM9.75 7.58333C9.75 8.77995 8.77995 9.75 7.58334 9.75C6.38672 9.75 5.41667 8.77995 5.41667 7.58333C5.41667 6.38671 6.38672 5.41666 7.58334 5.41666C8.77995 5.41666 9.75 6.38671 9.75 7.58333Z"
      fill="#454855"
    />
    <path
      opacity={0.3}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 14.083C5.04086 14.083 3.25 15.8739 3.25 18.083V18.7497C3.25 20.9588 5.04086 22.7497 7.25 22.7497H18.75C20.9591 22.7497 22.75 20.9588 22.75 18.7497V18.083C22.75 15.8739 20.9591 14.083 18.75 14.083H7.25ZM20.5833 18.4163C20.5833 19.613 19.6133 20.583 18.4167 20.583C17.22 20.583 16.25 19.613 16.25 18.4163C16.25 17.2197 17.22 16.2497 18.4167 16.2497C19.6133 16.2497 20.5833 17.2197 20.5833 18.4163Z"
      fill="#7E8299"
    />
  </svg>
)
export default LobbyIcon
