import React, { useEffect, useState } from "react";
import BoxLoader from "../../components/general/boxLoader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetOrganization, useGetRelatedOrganizations } from "../../../hook/request/organizations";
import SearchBox from "../../components/general/searchBox";
import { useWindowSize } from "../../../hook/useWindowSize";
import AddRelationTable from "../../components/organization/addRelation/dataList/addRelationTable";
import AddRelationCardsBox from "../../components/organization/addRelation/dataList/addRelationCardsBox";
import Icon from "../../components/general/icon";
import { useMySearchParams } from "../../../hook/useMySearchParams";
import Pagination from "../../components/general/table/pagination";

const dataListTitles = [
  "نام مرکز",
  "شماره تلفن",
  "آدرس",
  "وضعیت اتصال",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
  originUnit?: string
}

const AddRelation = () => {

  const { id } = useParams();
  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [relatedOrganizationsData, setRelatedOrganizationsData] = useState([]);
  const navigate = useNavigate();
  const [fieldValue, setFieldValue] = useState({
    name: ""
  });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });
  const [relatedFilterValues, setRelatedFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
    originUnit: id,
  });

  // services
  const getOrganizationRequest = useGetOrganization();
  const getRelatedOrganizationsRequest = useGetRelatedOrganizations(getQueryParams(relatedFilterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    let _relatedFilterValues = {};
    _relatedFilterValues = {
      ...relatedFilterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
      originUnit: id
    }
    setRelatedFilterValues(_relatedFilterValues)
  }, [searchParams]);

  useEffect(() => {
    id && getOrganizationRequest.mutate({id});
  }, [id])


  useEffect(() => {
    if (getOrganizationRequest?.data?.data?.result) {
      const { name } = getOrganizationRequest.data.data.result,
        _fieldValue = { name };
      setFieldValue(_fieldValue);
    }
  }, [getOrganizationRequest.data])

  useEffect(() => {
    getRelatedOrganizationsRequest?.data?.data?.result &&
      setRelatedOrganizationsData(getRelatedOrganizationsRequest?.data?.data.result)
  }, [getRelatedOrganizationsRequest.data])

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateSearchParams({
      pageNumber: "0",
      perPage: filterValues?.perPage,
      [name]: value,
    })
  }

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="card-box">
        <section className="filter-box d-flex flex-column flex-md-row mb-4">
          <div className="d-flex align-items-center">
            <button
              onClick={() => navigate(-1)}
              className="btn-arrow-right btn btn-dark px-1 d-flex align-items-center justify-content-center size-47 font-25 shadow"
            >
              <Icon name='arrow-right' />
            </button>
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold me-3">
              اتصال مراکز درمانی به
              {
                getOrganizationRequest?.isPending ? 
                  <BoxLoader type="text-loader" className="text-loader mx-2" /> :
                  <span className="d-inline-flex fw-semibold text-primary mx-1">{fieldValue?.name}</span>
              }
            </h4>
          </div>
          <div className="form-group me-md-auto">
            <SearchBox
              name="search"
              value={filterValues?.search}
              onChange={handleFilterChange}
              placeholder="جستجوی مرکز درمانی"
            />
          </div>
        </section>

        <section className="table-cont table-responsive">
          {
            width < 900 ?
              <AddRelationCardsBox
                dataList={relatedOrganizationsData}
                dataListTitles={dataListTitles}
                isRequesting={getRelatedOrganizationsRequest.isPending}
              /> :
              <AddRelationTable
                dataList={relatedOrganizationsData}
                dataListTitles={dataListTitles}
                isRequesting={getRelatedOrganizationsRequest.isPending}
              />
          }
          <Pagination
            paginationData={{...filterValues, ...relatedOrganizationsData}}
            doChangePage={doChangePage}
          />
        </section>
      </div>

      {/* <DeleteOrganizationModal /> */}
    </>
  );
};

export default AddRelation;