import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {general} from "../../../../../../store/action";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../../store/selector/general";
import Modal from "../../../../general/modal";
import Icon from "../../../../general/icon";
import SelectField from "../../../../general/form/selectField";
import {
  ISSUER_TYPE,
  TAMIN_REPEAT_COUNT, TAMIN_REPEAT_PERIOD_ENUM,
} from "../../../../../../constants/constant/enum";
import {weekDays} from "../../../../general/form/datePickerField";
import {SubmitHandler, useForm} from "react-hook-form";
import DatePicker, {DateObject} from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import {getStartOfTomorrowAsDate} from "../../../../../../utils/date";

/*
    مودال نمایش و انتخاب تاریخ برای اقلام دارویی جهت تجویز کردن برای آینده.
    Parent: <PrescriptionMedicationItem/>
    در این کامپوننت دکتر میتواند برای اقلام دارویی تاریخی در آینده را مشخص کند،
    و با انتخاب هر تاریخ، آن تاریخ به اطلاعات داروی مورد نظر اضافه می‌شود.
*/


type AddPrescriptionMedicationItemModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  insurance?: number;
  index?: number;
  item?: any;
  handleIhioActiveDates?: (dates: any) => void
  handleTaminActiveDates?: (dates: any) => void
}

type TTaminDates = {
  repeatPeriod?: number;
  repeatCount?: number;
}

const PrescriptionActiveDateModal = ({
  modalData,
  modalName,
  isShowModalData,
  handleHideModal,
  handleResetModal,
}: AddPrescriptionMedicationItemModalProps) => {

  //<editor-fold desc="States">
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    insurance: 0,
    item: null,
    index: -1,
  });
  const [fieldValue, setFieldValue] = useState<TTaminDates>({});
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TTaminDates>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });

  const [dates, setDates] = useState<DateObject[]>([]);

  //</editor-fold>

  //<editor-fold desc="UseEffects">

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'prescriptionActiveDateModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  //</editor-fold>

  const isValidDate = (date: DateObject): boolean => {
    // Check if date is a valid date
    return date instanceof DateObject && !isNaN(date.toDate().getTime());
  };


  const handleChange = (selectedDates: DateObject[]) => {
    if(selectedDates !== null) {
      const validDates = selectedDates.filter(date => isValidDate(date));
      setDates(validDates);
    } else {
      setDates([])
    }
  };

  const _calculateDiffDays = (date: DateObject) => {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0); // Set to start of today
    const diff = Math.floor((date.toDate().getTime() - startOfToday.getTime()) / (1000 * 60 * 60 * 24))
    return `${diff} روز آینده `
  }
  const filterSelectedDay = (date: DateObject) => {
    const filteredDates = dates.filter(item => item !== date);
    setDates(filteredDates);
  }

  const calculateRepeatDates = ({ repeatPeriod, repeatCount }: TTaminDates): Date[] => {
    // Find the corresponding days for the selected range
    const selectedRange = TAMIN_REPEAT_PERIOD_ENUM.find(range => range.value === repeatPeriod);
    const selectedCount = TAMIN_REPEAT_COUNT.find(count => count.value === repeatCount);

    if (!selectedRange || !selectedCount) {
      throw new Error("Invalid repeat range or count");
    }

    const dates: Date[] = [];
    const daysBetween = selectedRange.days;
    const count = selectedCount.count;

    // Generate the dates
    for (let i = 1; i <= count; i++) {
      const nextDate = new Date();
      nextDate.setDate(nextDate.getDate() + i * daysBetween);
      dates.push(nextDate);
    }

    return dates;
  };


  const handleTaminActiveDatesSubmit: SubmitHandler<TTaminDates> = (data) => {
    const {repeatPeriod, repeatCount} = data
    const dates = calculateRepeatDates({ repeatPeriod, repeatCount });
    modalInfo?.handleTaminActiveDates && modalInfo?.handleTaminActiveDates(dates);
    handleSetShowModal()
  }

  const handleIhioActiveDatesSubmit = () => {
    modalInfo?.handleIhioActiveDates && modalInfo?.handleIhioActiveDates(dates);
    handleSetShowModal()
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setDates([]);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              فرم نسخه برای آینده
            </h4>
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
            <Icon name='close-square'/>
          </span>
        </div>
        {
          modalInfo?.insurance === ISSUER_TYPE.TAMIN.value ?
            <form className='row' onSubmit={handleSubmit(handleTaminActiveDatesSubmit)}>
              <div className="col-6 col-md-4 px-4">
                <div className="form-group">
                  <SelectField
                    className='font-en'
                    name="repeatPeriod"
                    placeholder="بازه تکرار دارو"
                    options={TAMIN_REPEAT_PERIOD_ENUM}
                    control={control}
                    error={errors}
                  />
                </div>
              </div>
              <div className="col-6 col-md-4 px-4">
                <div className="form-group">
                  <SelectField
                    className='font-en'
                    name="repeatCount"
                    placeholder="دفعات تکرار دارو"
                    options={TAMIN_REPEAT_COUNT}
                    control={control}
                    error={errors}
                  />
                </div>
              </div>
              <div className=" col-md-4 px-4">
                <div className="d-flex form-group mr-auto justify-content-end h-100">
                  <button
                    type='submit'
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    افزودن تاریخ تکرار
                  </button>
                </div>
              </div>
            </form> :
            <div className="row">
              <div className="d-flex px-4">
                <div className="form-group flex-grow-1">
                  <DatePicker
                    calendar={persian}
                    locale={persian_fa}
                    containerClassName="w-100"
                    inputClass={"form-control placeholder-field-color"}
                    multiple
                    dateSeparator=' و '
                    value={dates}
                    onChange={handleChange}
                    minDate={getStartOfTomorrowAsDate()}
                    mapDays={({date}) => {
                      let props: any = {},
                        isWeekend = [6].includes(date.weekDay.index)
                      if (isWeekend) props.className = "highlight highlight-red";
                      return props
                    }}
                    placeholder='تاریخ‌های آینده'
                    weekDays={weekDays}
                    plugins={[
                      <DatePanel header={'تاریخ‌های آینده'} position={'left'}/>
                    ]}
                  />
                </div>
                <div className="d-flex form-group mr-auto">
                  <button
                    type='button'
                    onClick={handleIhioActiveDatesSubmit}
                    className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                  >
                    افزودن تاریخ تکرار
                  </button>
                </div>
              </div>
            </div>
        }
        {Array.isArray(dates) && dates.length > 0 &&
          <div className='px-4 mt-3'>
            <div>
              تاریخ‌های انتخابی:
            </div>
            <div className="row">
              {
                dates?.map((date: any) => {
                  return (
                    <div className='col-2 mt-3'>
                    <div className='rounded border-hover-red p-1'
                         onClick={() => filterSelectedDay(date)}
                    >
                        <div className='d-flex align-items-center me-1'>
                          <span className='d-flex align-items-center text-red-hover font-16'>
                            <Icon name='close-square'/>
                          </span>
                          <span className='me-1'>
                            {_calculateDiffDays(date)}
                          </span>
                        </div>
                        <div className='text-center'>
                          {date.format("YYYY/MM/DD")}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionActiveDateModal);