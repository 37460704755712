import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Modal from "../../../general/modal";
import Icon from "../../../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {general} from "../../../../../store/action";
import {TPrescriptionFields} from "../../../../../types/prescription";
import VisitParaclinicalPrescriptionTable
  from "../../../visits/visitDetail/endVisit/dataList/paraclinical/visitParaclinicalPrescriptionTable";
import VisitMedicationPrescriptionTable
  from "../../../visits/visitDetail/endVisit/dataList/medication/visitMedicationPrescriptionTable";

const medicationDataListTitles = [
  "برند",
  "واحد",
  "مقدار مصرف",
  "عدد",
  "دوز",
  "نام دارو",
  "شکل دارو",
]

const paraclinicalDataListTitles = [
  "تعداد",
  "تاریخ",
  "نوع خدمت",
  "عنوان",
]


type ShowPatientPrescriptionModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
  handleShowModal: any;
}

type ModalInfoProps = {
  id: string;
  visitId: string;
  patientData?: any;
  prescriptionFieldValue?: TPrescriptionFields;
  medicalHistoryFieldValue?: any;
  draftDescription?: string;
  handlePrescriptionModalSubmit: any;
}

const ShowPatientPrescriptionModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: ShowPatientPrescriptionModalProps) => {
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    id: '',
    visitId: '',
    patientData: '',
    handlePrescriptionModalSubmit: null
  });

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'showPatientPrescriptionModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      // setPrescriptionData(modalData?.prescriptionFieldValue)
      handleResetModal();
    }
  }, [modalData, modalName])

  const handleSetShowModal = () => {
    setShowState(false);
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-2 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              اطلاعات نسخه
            </h4>
            {modalInfo?.patientData &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.patientData?.name}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
            <Icon name='close-square'/>
          </span>
        </div>
        <h4 className='d-flex font-14 mb-2 pb-2'>
          <div className="d-flex align-items-center align-content-center border-bottom ms-auto ps-5">
            <span className="d-flex font-22"><Icon name='pills'/></span>
            <span className='me-2'>تجویز دارویی</span>
          </div>
        </h4>
        <section className="table-cont table-responsive border rounded mb-3">
          <VisitMedicationPrescriptionTable
            dataList={modalInfo?.prescriptionFieldValue?.medicationItems}
            dataListTitles={medicationDataListTitles}
            isRequesting={false}
          />
        </section>
        <h4 className='d-flex font-14 mb-2 pb-2'>
          <div className="d-flex align-items-center align-content-center border-bottom ms-auto ps-5">
            <span className="d-flex font-22"><Icon name='test'/></span>
            <span className='me-2'>تجویز پاراکلینیک</span>
          </div>
        </h4>
        <section className="table-cont table-responsive border rounded mb-4">
          <VisitParaclinicalPrescriptionTable
            dataList={modalInfo?.prescriptionFieldValue?.paraclinicalItems}
            dataListTitles={paraclinicalDataListTitles}
            isRequesting={false}
          />
        </section>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowPatientPrescriptionModal);