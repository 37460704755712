import Highcharts from "highcharts";
import {HighChartProps, langDefaultOptions} from "./chartOptions";
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import variablePie from "highcharts/modules/variable-pie";
import Item from "highcharts/modules/item-series";

const pieChartOptions = ({
 chartType,
 data,
 dataNames,
 labels,
 chartTitle,
 displayXLabels = true,
 displayYLabels = true,
 displayDataLabels = false,
 showLegend = true,
 showExportButton = true
}: HighChartProps) : Highcharts.Options => {
  return {
    title: {
      text: '' // Set an empty string to hide the title
    },
    chart: {
      backgroundColor: 'transparent',
      type: chartType,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        turboThreshold: 75000,
        dataLabels: {
          enabled: displayDataLabels,
        }
      },
      pie: {
        dataLabels: {
          enabled: true,
          distance: 10,
          style: {
            fontWeight: 'bold',
            fontFamily: 'Vazir, sans-serif',
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '75%'],
        size: '100%'
      }
    },
    series: [{
      type: 'pie',
      name: 'جنسیت',
      innerSize: '50%',
      data: data.map((seriesData: any, index: number) => [
        labels[index],
        seriesData,
      ]),
    }],
    tooltip: {
      pointFormat: '<span>{series.name}: {point.y}</span>',
      style: {
        fontWeight: 'bold',
        fontFamily: 'Vazir, sans-serif',
      },
      followPointer: false
    },
    lang: langDefaultOptions,
    legend: {
      enabled: showLegend,
      className: 'font-vazir'
    },
    noData: {
      position: {
        // Adjust the position of the noData text
        align: 'center',
        verticalAlign: 'top'
      },
      useHTML: true,  // Enable use of HTML for rendering the image
      style: {
        fontSize: '16px',
      },
      attr: {
        zIndex: 1
      },
    },
    exporting: {
      filename: chartTitle + ' - ' + new Date(),
      chartOptions: {
        title: {
          text: chartTitle
        },
        exporting: {
          enabled: false
        },
        chart: {
          style: {
            fontFamily: 'Vazir, sans-serif'
          }
        }
      },
      buttons: {
        contextButton: {
          enabled: false
        },
        exportButton: {
          enabled: showExportButton,
          className: 'rounded-circle font-vazir font-en',
          menuClassName:'rtl text-end font-vazir font-en',
          symbol: "menu",
          symbolSize: 12,
          // Use only the download related menu items from the default
          // context button
          menuItems: [
            "viewFullscreen",
            'printChart',
            "separator",
            'downloadPDF',
            'downloadPNG',
            'downloadJPEG',
            'downloadSVG',
          ]
        },
      }
    },
    credits: {
      enabled: false
    }
  }
};

type radialChartProps = {
  data: any,
  labels: string[]
}
highchartsMore(Highcharts);
const colors = Highcharts.getOptions()?.colors ?? ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c'];
const trackColors = colors.map(color =>
  new Highcharts.Color(color).setOpacity(0.3).get()
);
const radialColors = ['#2CAFFE', '#544FC5', '#00E272']
const radialChartOptions = ({
  data,
  labels,
  max
}: any)  => {
  return {
    chart: {
      type: 'column',
      inverted: true,
      polar: true
    },
    title: {
      text: ''
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> ' +
        '{point.name}</b><br/>' +
        'تعداد: <b>{point.y}</b><br/>',
      style: {
        fontWeight: 'bold',
        fontFamily: 'Vazir, sans-serif',
      },
      followPointer: false
    },
    pane: {
      size: '85%',
      innerSize: '20%',
      endAngle: 270
    },
    legend: {
      enabled: false
    },
    xAxis: {
      tickInterval: 1,
      labels: {
        align: 'right',
        useHTML: true,
        allowOverlap: true,
        step: 1,
        y: 3,
        style: {
          fontSize: '13px'
        }
      },
      lineWidth: 0,
      categories: labels
    },
    yAxis: {
      lineWidth: 0,
      tickInterval: 25,
      reversedStacks: true,
      endOnTick: false,
      showLastLabel: true,
      max: max
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0.15,
        borderRadius: '25%' // Adding border radius to the columns
      }
    },
    series: [{
      data: data.map((seriesData: any, index: number) => ({
        name: labels[index],
        y: seriesData,
        color: radialColors[index],
      })),
    }],
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  }
};

type solidGaugeProps = {
  data: any,
  labels: string[],
  max?: number
}

solidGauge(Highcharts);
const radius = ['112%', '87%', '62%']
const innerRadius = ['88%', '63%', '38%']
const MultipleGauge = ({
  data,
  labels,
  max = 100
}: solidGaugeProps)  => {
  return {
    chart: {
      type: 'solidgauge',
      height: '80%',
    },
    title: {
      text: ''
    },
    tooltip: {
      borderWidth: 0,
      backgroundColor: 'none',
      shadow: false,
      style: {
        fontSize: '16px'
      },
      pointFormat: '' +
        '<span style="font-size: 1.8vh; color: {point.color}; ' +
        'font-family: \'Vazir\', Arial, sans-serif; ' +
        'font-weight: bold; text-align: center; display: flex; justify-content: center;">' + // Center-align text
        '{series.name}: {point.y}</span>',
      positioner: function (this: Highcharts.Tooltip, labelWidth: number, labelHeight: number): Highcharts.PositionObject {
        const chart = this.chart;
        const chartWidth = chart.chartWidth;
        const plotHeight = chart.plotHeight;
        return {
          x: (chartWidth - labelWidth) / 2,
          y: (plotHeight / 2) - 10
        };
      }
    },

    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [{ // Track for Conversion
        outerRadius: '112%',
        innerRadius: '88%',
        backgroundColor: trackColors[0],
        borderWidth: 0
      }, { // Track for Engagement
        outerRadius: '87%',
        innerRadius: '63%',
        backgroundColor: trackColors[1],
        borderWidth: 0
      }, { // Track for Feedback
        outerRadius: '62%',
        innerRadius: '38%',
        backgroundColor: trackColors[2],
        borderWidth: 0
      }]
    },

    yAxis: {
      min: 0,
      max: max,
      lineWidth: 0,
      tickAmount: 5,
      tickPosition: []
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true
      }
    },
    series: data.map((seriesData: any, index: number) => ({
      name: labels[index],
      data: [{
        color: colors[index],
        radius: radius[index],
        innerRadius: innerRadius[index],
        y: seriesData
      }],
      custom: {
        icon: 'filter',
        iconColor: '#303030'
      }
    })),
    lang: langDefaultOptions,
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  }
};

type VariableRadiusPieProps = {
  data: any,
  labels: string[]
}
const z = [92, 119, 121, 136, 200, 213, 235]

variablePie(Highcharts);
const VariableRadiusPie = ({
  data,
  labels
}: VariableRadiusPieProps)  => {
  return {
    chart: {
      type: 'variablepie'
    },
    title: {
      text: '',
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> ' +
        '{point.name}</b><br/>' +
        'تعداد: <b>{point.y}</b><br/>',
      style: {
        fontWeight: 'bold',
        fontFamily: 'Vazir, sans-serif',
      },
      followPointer: false
    },
    series: [{
      minPointSize: 10,
      innerSize: '20%',
      zMin: 0,
      name: 'تعداد',
      borderRadius: 5,
      data: data.map((seriesData: any, index: number) => ({
        name: labels[index],
        y: seriesData,
        z: seriesData,
      })),
      colors: [
        '#4caefe',
        '#3dc3e8',
        '#2dd9db',
        '#1feeaf',
        '#0ff3a0',
        '#00e887',
        '#23e274',
        '#544FC5'
      ]
    }],
    lang: langDefaultOptions,
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  }
};

type ItemChartProps = {
  data: any;
  labels: string[];
}
const ItemColors = ['#CC0099', '#EE0011', '#448833', '#FFCC00', '#000000', '#3366CC', '#3399FF', '#000099']
Item(Highcharts)
const ItemChart = ({
  data,
  labels
}: ItemChartProps)  => {
  return {
    chart: {
      type: 'item',
      height: '80%'
    },
    title: {
      text: ''
    },
    legend: {
      labelFormat: '{name} <span style="opacity: 0.4">{y}</span>',
      enabled: false
    },
    tooltip: {
      style: {
        fontWeight: 'bold',
        fontFamily: 'Vazir, sans-serif',
      },
      followPointer: false
    },
    series: [{
      name: 'تعداد',
      keys: ['name', 'y', 'color', 'label'],
      data:  data.map((seriesData: any, index: number) => [
        labels[index],
        seriesData,
        ItemColors[index],
        labels[index]
      ]),
      dataLabels: {
        enabled: true,
        format: '{point.label}',
        style: {
          textOutline: '3px contrast'
        }
      },

      // Circular options
      center: ['50%', '50%'],
      size: '100%',
      startAngle: -100,
      endAngle: 100
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 600
        },
        chartOptions: {
          series: [{
            dataLabels: {
              distance: -30
            }
          }]
        }
      }]
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  }
};

interface CustomChartOptions extends Highcharts.ChartOptions {
  custom?: {
    label?: Highcharts.SVGElement;
  };
}

type DonutChartProps = {
  data: any;
  labels: string[];
  totalCount?: number;
}

const DonutChart = ({
  data,
  labels,
  totalCount
}: DonutChartProps) => {
  return {
    chart: {
      type: 'pie',
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    title: {
      text: ''
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> ' +
        '{point.name}</b><br/>' +
        'تعداد: <b>{point.y}</b><br/>',
      style: {
        fontWeight: 'bold',
        fontFamily: 'Vazir, sans-serif',
      },
      followPointer: false
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      pie: {
        innerSize: '75%',
        allowPointSelect: true,
        cursor: 'pointer',
        borderRadius: 8,
        dataLabels: {
          enabled: true,
          distance: -70,
          format: '{point.name}',
          style: {
            fontFamily: 'Vazir, Arial, sans-serif', // Custom font family
            fontSize: '0.5rem', // Adjust font size as needed
            color: '#000' // Custom font color
          }
        },
        showInLegend: true
      }
    },
    yAxis: {
      enabled: false,
      labels: {
        enabled: false
      }
    },
    xAxis: {
      enabled: false,
      labels: {
        enabled: false
      }
    },
    series: [{
      colorByPoint: true,
      innerSize: '75%',
      data: data.map((seriesData: any, index: number) => ({
        name: labels[index],
        y: seriesData,
      })),
    }],
    lang: langDefaultOptions,
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  };
};

type MultipleDonutBarLineChartProps = {
  ihioData?: any;
  taminData?: any;
  mData?: any;
  bData?: any;
  averageData?: any;
  labels?: string[]
}

const MultipleDonutBarLineChart = ({
  ihioData,
  taminData,
  mData,
  bData,
  averageData,
  labels
}: MultipleDonutBarLineChartProps) => {
  const ihioSum = ihioData.reduce((acc:any , curr: any) => acc + curr, 0);
  const taminSum = taminData.reduce((acc:any , curr: any) => acc + curr, 0);
  const mSum = mData.reduce((acc:any , curr: any) => acc + curr, 0);
  const bSum = bData.reduce((acc:any , curr: any) => acc + curr, 0);

  return {
    title: {
      text: '',
    },
    xAxis: {
      categories: labels,
      fontFamily: 'Vazir, Arial, sans-serif',
    },
    yAxis: {
      title: {
        text: 'نفر',
        style: {
          fontFamily: 'Vazir, Arial, sans-serif',
          fontSize: '16px'
        }
      },
      softMax: 5,
      gridLineDashStyle: 'Dash',
    },
    tooltip: {
      followPointer: false,
      valueSuffix: ' نفر',
      style: {
        fontFamily: 'Vazir, Arial, sans-serif',
      }
    },
    plotOptions: {
      series: {
        borderRadius: '25%'
      }
    },
    series:
      [{
      type: 'column',
      name: 'سلامت',
      data: ihioData,
    }, {
      type: 'column',
      name: 'تامین ‌اجتماعی',
      data: taminData
    }, {
      type: 'column',
      name: 'نامشخص',
      data: mData
    }, {
      type: 'column',
      name: 'آزاد',
      data: bData
    }, {
      type: 'line',
      step: 'center',
      name: 'میانگین',
      data: averageData,
      marker: {
        lineWidth: 2,
        lineColor: colors[3],
        fillColor: 'white'
      }
    }, {
      type: 'pie',
      name: 'مجموع',
      data: [{
        name: 'سلامت',
        y: ihioSum,
        color: colors[0], // 2020 color
        dataLabels: {
          enabled: true,
          distance: -50,
          format: '{point.total}',
          style: {
            fontSize: '15px'
          }
        },
      }, {
        name: 'تامین اجتماعی',
        y: taminSum,
        color: colors[1] // 2021 color
      }, {
        name: 'نامشخص',
        y: mSum,
        color: colors[2] // 2022 color
      }, {
        name: 'آزاد',
        y: bSum,
        color: colors[3] // 2022 color
      }],
      center: [75, 65],
      size: 100,
      innerSize: '70%',
      showInLegend: false,
      dataLabels: {
        enabled: false
      }
    }],
    lang: langDefaultOptions,
    legend: {
      className: 'font-vazir',
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 100,
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  };
};

export {
  pieChartOptions,
  radialChartOptions,
  MultipleGauge,
  VariableRadiusPie,
  ItemChart,
  DonutChart,
  MultipleDonutBarLineChart
}