import {useQuery,useMutation} from '@tanstack/react-query'
import { PatientCigaretteHistory } from '../../../services/requests/ehr/patientCigarette';
import { PatientFamilyHistory } from '../../../services/requests/ehr/patientFamilyHistory';
import { PatientSurgeryList } from '../../../services/requests/ehr/surgery';

import Noti from "../../../view/components/general/noti";
import { handleResponse } from '../setup';


const useGetListCigarette= (params:any, modalName: boolean)=>{
  return useQuery({
      queryKey:["getListCigarette",params],
      queryFn:({queryKey})=>{
       const [_,params] = queryKey;
       return PatientCigaretteHistory.getCigaretteList(params)
      },
      enabled: modalName ?? true
  })
}



const useGetListCigaretteAll = (params:any, modalName?: boolean)=>{
    return useQuery({
        queryKey:["getListCigaretteList",params],
        queryFn:({queryKey})=>{
         const [_,params] = queryKey;
         return PatientCigaretteHistory.getCigaretteListAll(params);
        },
      enabled: modalName ?? true
    })
  }
  
const useAddCigarette = ({ onSuccess }: { onSuccess: () => void }) => {
    return useMutation({
        mutationFn:PatientCigaretteHistory.addCigarettePatient,
        onSuccess: (data: any) => {
          handleResponse({
            data,
            onSuccess: () => {
              console.log(data);
              Noti("success", data?.data?.msg);
              onSuccess();
            },
          });
      },
    });
  }



  export {
      useAddCigarette,
      useGetListCigarette,
      useGetListCigaretteAll
  }