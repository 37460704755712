import React from "react";
import { Outlet } from "react-router-dom";

const ExternalLayout = () => {
  return(
    <>
      <Outlet />
    </>
    // <Suspense fallback={<PageLoader />}>
    //   <Routes>
    //     {
    //       mainRoutes.map((item, i) => {
    //         if (item.redirect) return <Navigate from={item.path} to={item.to} replace key={i} />;
    //         return  <Route exact={item.exact} path={item.path} key={i} render={(innerItem) => (
    //                   <>
    //                     <Helmet>
    //                       <title>{item.title} - {process.env.REACT_APP_WEBSITE_TITLE}</title>
    //                     </Helmet>
    //                     <item.component {...innerItem} subRoutes={item?.subRoutes} />
    //                   </>
    //                 )} />
    //       })
    //     }
    //   </Routes>
    // </Suspense>
  )
}

export default ExternalLayout;
