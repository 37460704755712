const DEPARTMENT_TYPE = [
  { value: 10, label: "دولتی" }, // GOVERNMENTAL
  { value: 20, label: "غیردولتی" }, // NON_GOVERNMENTAL
  { value: 30, label: "خصوصی" }, // PRIVATE
  { value: 40, label: "خیریه" } // CHARITABLE
]

const DEVICE_TYPE_OPTIONS = [
  { value: 10, label: "SADRA A15" },
]

const MULTIMEDIA_TYPE = [
  { value: 100, label: "تصویر" }, // IMAGE
  { value: 200, label: "ویدئو" }, // VVALUEEO
  { value: 300, label: "صوتی" }, // AUDIO
  { value: 400, label: "فایل پی دی اف" }, // PDF
  { value: 500, label: "فایل زیپ" }, // ZIP
  { value: 600, label: "داکیومنت" }, // DOCUMENT
  { value: 2000, label: "پیوست" } // OTHER
]

const DAY_OF_WEEK = [
  { value: 6, label: "شنبه" }, // SATURDAY
  { value: 7, label: "یک‌شنبه" }, // SUNDAY
  { value: 1, label: "دوشنبه" }, // MONDAY
  { value: 2, label: "سه‌شنبه" }, // TUESDAY
  { value: 3, label: "چهارشنبه" }, // WEDNESDAY
  { value: 4, label: "پنج‌شنبه" }, // THURSDAY
  { value: 5, label: "جمعه" }, // FRIDAY
]

const VISIT_TYPE = [
  { value: 100, label: "حضوری" }, // ONSITE
  { value: 300, label: "آنلاین - تصویری" } //ONLINE_VIDEO
]

const VISIT_TYPE_ENUM = {
  ONSITE: { value: 100, label: "حضوری"} , // ONSITE
  ONLINE: { value: 300, label: "آنلاین - تصویری"} //ONLINE_VIDEO
}

const VISIT_STATUS = [
  {value: 100, label: "درحال تکمیل اطلاعات بیمار"},
  {value: 200, label: "منتظر انجام ویزیت"},
  {value: 300, label: "درحال انجام ویزیت"},
  {value: 400, label: "انجام شده"},
  {value: 500, label: "لغو شده"},
  {value: 600, label: "عدم حضور بیمار"},
  {value: 700, label: "عدم حضور ارایه دهنده خدمت(پزشک)"},
]

const VISIT_STATUS_ENUM = {
  INITIALIZED: {value: 100, label: "درحال تکمیل اطلاعات بیمار"},
  RESERVED: {value: 200, label: "منتظر انجام ویزیت"},
  IN_PROGRESS:{value: 300, label: "درحال انجام ویزیت"},
  RESOLVED: {value: 400, label: "انجام شده"},
  CANCEL: {value: 500, label: "لغو شده"},
  PATIENT_IS_NOT_PRESENT: {value: 600, label: "عدم حضور بیمار"},
  PROVIDER_IS_NOT_PRESENT: {value: 700, label: "عدم حضور ارایه دهنده خدمت(پزشک)"},
}

const VISIT_MODE = {
  SHOW: {value: 'show'},
  EDIT: {value: 'edit'},
}

const TIME_OF_DAY = [
  // { value: "00:00", label: "00:00" },
  // { value: "00:30", label: "00:30" },
  // { value: "01:00", label: "01:00" },
  // { value: "01:30", label: "01:30" },
  // { value: "02:00", label: "02:00" },
  // { value: "02:30", label: "02:30" },
  // { value: "03:00", label: "03:00" },
  // { value: "03:30", label: "03:30" },
  // { value: "04:00", label: "04:00" },
  // { value: "04:30", label: "04:30" },
  // { value: "05:00", label: "05:00" },
  // { value: "05:30", label: "05:30" },
  { value: "06:00", label: "06:00" },
  { value: "06:30", label: "06:30" },
  { value: "07:00", label: "07:00" },
  { value: "07:30", label: "07:30" },
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },
  { value: "22:00", label: "22:00" },
  { value: "22:30", label: "22:30" },
  { value: "23:00", label: "23:00" },
  { value: "23:30", label: "23:30" },
  { value: "24:00", label: "24:00" },
]

const ALLERGY_TYPE = [
  { value: 10, label: "حساسیت حیوانات" }, // ANIMAL_ALLERGY
  { value: 20, label: "حساسیت دارویی" }, // DRUG_ALLERGY
  { value: 30, label: "حساسیت محیطی" }, // ENVIRONMENTAL_ALLERGY
  { value: 40, label: "حساسیت غذایی" }, // FOOD_ALLERGY
  { value: 50, label: "حساسیت فصلی" }, // POLLEN_ALLERGY
  { value: 60, label: "حساسیت گیاهان" }, // PLANT_ALLERGY
  { value: 70, label: "حساسیت متفرقه" }, // MISCELLANEOUS_ALLERGY
  { value: 80, label: "تداخل متفرقه" } // MISCELLANEOUS_CONTRADICTION
]

const ALLERGY_SEVERITY = [
  { value: 10, label: "ملایم" }, // MILD
  { value: 20, label: "متوسط" }, // MODERATE
  { value: 30, label: "شدید" }, // SEVERE
  { value: 40, label: "نامشخص" } // UNKNOWN
]

const VITAL_TYPE = {
  SPO2: { value: 100, label: "اکسیژن خون" },
  PULSE_RATE: { value: 200, label: "تعداد نبض در واحد زمان" },
  RESPIRATORY_RATE: { value: 300, label: "تعداد نفس در واحد زمان" },
  TEMPERATURE: { value: 400, label: "دمای بدن" },
  BLOOD_PRESSURE: { value: 500, label: "فشتارخون" }
}

const BIOMETRIC_TYPE = {
  HEIGHT: {value: 100, label: "قد"},
  WEIGHT: {value: 200, label: "وزن"},
  BMI: {value: 300, label:"شاخص توده بدنی"},
  MUAC: {value: 400, label:"محیط بازوی میانی-بالا"}
}

const DIAGNOSIS_SEVERITY = [
  {value: 10, label: "ملایم"}, // MILD
  {value: 20, label: "متوسط"}, // MODERATE
  {value: 30, label:"شدید"} // SEVERE
]

const DIAGNOSIS_STATUS = [
  {value: 10, label: "تشخیص اولیه"}, // PRIMARY
  {value: 20, label: "تشخیص حین درمان"}, // SECONDARY
  {value: 30, label:"تشخیص نهایی"} // FINAL
]

const ISSUER_TYPE = {
  IHIO: {value: 10, label: "بیمه سلامت"}, // I
  TAMIN: {value: 20, label: "تامین اجتماعی"}, // T
  ESATA: {value: 30, label:"نیرو های مسلح"}, // N
  B: {value: 40, label:"آزاد"}, // B
  M: {value: 50, label:"نامشخص"} // M
}

const ISSUER_TYPE_ENUM = [
  {value: 10, label: "بیمه سلامت"}, // I
  {value: 20, label: "تامین اجتماعی"}, // T
  {value: 30, label:"نیرو های مسلح"}, // N
  {value: 40, label:"آزاد"}, // B
  {value: 50, label:"نامشخص"} // M
]


const PARACLINICAL_PRESCRIPTION_SERVICE_TYPE = {
  // MEDICATION : {value: 10, label: "دارو"},
  PHYSIOTHERAPY : {value: '2', label:"فیزیوتراپی"},
  TEST : {value: '3', label: "آزمایش"},
  IMAGING : {value: '4', label:"تصویر برداری"},
  REFERENCE: {value: '5', label: "ارجاع"},
  SERVICE : {value: '6', label:"خدمات"}
}

const MEDICATION_SHAPE_IHIO = [
  {value: 1, label: 'آمپول', enName: 'INJ'},
  {value: 2, label: 'انسولین', enName: 'INJ'},
  {value: 3, label: 'قرص', enName: 'Tab'},
  {value: 4, label: 'کپسول', enName: 'Cap'},
  {value: 5, label: 'اسپری', enName: 'INHL'},
  {value: 6, label: 'ژل', enName: 'Cream'},
  {value: 7, label: 'قطره', enName: 'Drop'},
  {value: 8, label: 'پودر', enName: 'Powder'},
  {value: 9, label: 'شربت', enName: 'Syrp'},
  {value: 10, label: 'سایر', enName: 'Other'},
]

const CIGARETTE_CONSUMPTION = [
  {value: 10, label: 'عدم مصرف', enName: 'Non-Smoker', shortEnName: 'N'},
  {value: 20, label: 'کمتر از ۵ نخ در روز', enName: 'Light Smoker', shortEnName: 'L'},
  {value: 30, label: '۵ تا ۱۰ نخ در روز', enName: 'Moderate Smoker', shortEnName: 'M'},
  {value: 40, label: '۱۰ تا ۱۵ نخ در روز', enName: 'Heavy Smoker', shortEnName: 'H'},
  {value: 50, label: 'بیشتر از ۱۵ نخ در روز', enName: 'Very Heavy Smoker', shortEnName: 'V'},
]

const PhysiotherapyIllnessConstant = [
  {value: 1, label: 'فلج مغزي', enName: 'CEREBRAL_PALSY'},
  {value: 2, label: 'ام اس', enName: 'MULTIPLE_SCLEROSIS'},
  {value: 3, label: 'مياستني گراو', enName: 'MYASTHENIA_GRAVIS'},
  {value: 4, label: 'گلين باره', enName: 'GUILLAIN_BARRE'},
  {value: 5, label: 'دوشن', enName: 'DUCHENNE'},
  {value: 6, label: 'لنف ادما', enName: 'LYMPHEDEMA'},
  {value: 7, label: 'ساير', enName: 'OTHER'},
]

const PhysiotherapyTreatmentPlanDetails = [
  {value: 1, label: 'EXS', code: "1901645", description: 'تمرین'},
  {value: 2, label: 'H.P', code: "2901645", description: 'گرما درمانی'},
  {value: 3, label: 'ICE', code: "3901645", description: 'یخ'},
  {value: 4, label: 'IR', code: "4901645", description: 'مادون قرمز'},
  {value: 5, label: 'TENS', code: "5901645", description: 'تحریک الکتریکی'},
  {value: 6, label: 'U.S', code: "6901645", description: 'اولتراسوند'},
  {value: 7, label: 'APS', code: "7901645", description: 'تحریک الکترومغناطیسی'},
  {value: 8, label: 'BYO FEEDBACK', code: "8901645", description: 'بیوفیدبک'},
  {value: 9, label: 'DIADYNAMIC', code: "9901645", description: 'دیا داینامیک'},
  {value: 10, label: 'FARADIC', code: "10901645", description: 'فارادیک'},
  {value: 11, label: 'GAIT TRANNING', code: "11901645", description: 'تمرین راه رفتن'},
  {value: 12, label: 'GALVANIC', code: "12901645", description: 'گالوانیک'},
  {value: 13, label: 'HI.VOLTAGE', code: "13901645", description: 'ولتاژ بالا'},
  {value: 14, label: 'IDC', code: "14901645", description: 'جریان تداخلی'},
  {value: 15, label: 'IF', code: "15901645", description: 'تحریک تداخلی'},
  {value: 16, label: 'IONTOPHORESIS', code: "16901645", description: 'یونتوفورز'},
  {value: 17, label: 'MAN.STRATCHING', code: "17901645", description: 'کشش دستی'},
  {value: 18, label: 'MAN.TRACTION', code: "18901645", description: 'کشیدن دستی'},
  {value: 19, label: 'MANIPULATION', code: "19901645", description: 'دستکاری'},
  {value: 20, label: 'MASSAGE', code: "20901645", description: 'ماساژ'},
  {value: 21, label: 'MOBILIATION', code: "21901645", description: 'متحرک سازی'},
  {value: 22, label: 'MWD', code: "22901645", description: 'میکروویو درمانی'},
  {value: 23, label: 'PARAFFIN BATH', code: "23901645", description: 'حمام پارافین'},
  {value: 24, label: 'PARAFIN', code: "24901645", description: 'پارافین'},
  {value: 25, label: 'PNF', code: "25901645", description: 'تسهیل عصبی-عضلانی'},
  {value: 26, label: 'R.O.M', code: "26901645", description: 'دامنه حرکتی'},
  {value: 27, label: 'RUSS', code: "27901645", description: 'جریان روس'},
  {value: 28, label: 'STIMULATION', code: "28901645", description: 'تحریک'},
  {value: 29, label: 'TRACTION', code: "29901645", description: 'کشیدن'},
  {value: 30, label: 'U.V', code: "30901645", description: 'ماوراء بنفش'},
  {value: 31, label: 'VASOTRAIN', code: "31901645", description: 'وازوتراین'}
];

const MARITAL_STATUS = [
  {value: 10, label: 'طلاق گرفته', enName: 'DIVORCED'},
  {value: 20, label: 'متأهل', enName: 'MARRIED'},
  {value: 30, label: 'مجرد', enName: 'SINGLE'},
  {value: 40, label: 'همسر فوت شده', enName: 'WIDOWED'},
  {value: 50, label: 'نامشخص', enName: 'UNSPECIFIED'}
]

const GENDER_ENUM = [
  {value: 10, label: 'مرد', enName: 'MALE'},
  {value: 20, label: 'زن', enName: 'FEMALE'},
  {value: 30, label: 'دوجنسی/ نامشخص', enName: 'INTERSEX_UNSPECIFIED'},
  {value: 40, label: 'تعیین نشده', enName: 'NOT_SPECIFIED'},
]

const TAMIN_REPEAT_PERIOD_ENUM = [
  { value: 10, label: 'هر روز', days: 1 },
  { value: 20, label: 'هر هفته', days: 7 },
  { value: 30, label: 'هر دو هفته', days: 14 },
  { value: 40, label: 'هر ماه', days: 30 },
  { value: 50, label: 'هر سه ماه', days: 90 },
  { value: 60, label: 'هر شش ماه', days: 180 },
]

const TAMIN_REPEAT_COUNT = [
  { value: 10, label: '۱', count: 1 },
  { value: 20, label: '۲', count: 2 },
  { value: 30, label: '۳', count: 3 },
]

const NOTIFICATION_TYPE_ENUM = {
  NOTIFICATION: { type: "NOTIFICATION" },
  VISIT_REQUEST: { type: "VISIT_REQUEST" },
  VISIT_RESPONSE: { type: "VISIT_RESPONSE" },
  VISIT_APPROVED: { type: "VISIT_APPROVED" },
  ANNOUNCE_PATIENT_PRESENCE: { type: "ANNOUNCE_PATIENT_PRESENCE" },
  URGENT_VISIT_REQUEST: { type: "URGENT_VISIT_REQUEST" },
  URGENT_VISIT_RESPONSE: { type: "URGENT_VISIT_RESPONSE" },
  URGENT_VISIT_APPROVED: { type: "URGENT_VISIT_APPROVED" },
  URGENT_VISIT_REQUEST_INFO: { type: "URGENT_VISIT_REQUEST_INFO" },
}

const PRESCRIPTION_STATUS_ENUM = {
  ADD_OK: { value: 10, label: "ارسال شده" }, //با موفقیت ثبت شد
  ADD_ERROR: { value: 20, label: "خطا در ارسال" }, // خطا در ثبت
  EDIT_OK: { value: 30, label: "ارسال شده" }, // با موفقیت ویرایش شد
  EDIT_ERROR: { value: 40, label: "خطا در ارسال" }, // خطا در ویرایش
}

const PROVIDER_VISIT_STATUS_ENUM = {
  OFFLINE: { value: 100, label: "آفلاین" }, //با موفقیت ثبت شد
  ONLINE: { value: 200, label: "آنلاین" }, // خطا در ثبت
  ONLINE_FOR_MY_VISIT: { value: 300, label: "آنلاین برای ویزیت‌های خودم" }, // با موفقیت ویرایش شد
}

const ANNOUNCEMENT_TYPE_ENUM = {
  INFORMATION: { value: 10, label: "اطلاعاتی" },
  WARNING: { value: 20, label: "هشدار" },
  ALERT: { value: 30, label: "اعلان" },
  UPDATE: { value: 40, label: "به‌روزرسانی" },
  EVENT: { value: 50, label: "رویداد" },
}

const ARRHYTHMIA_TYPE_ENUM = {
  TYPE_N: { value: 110, label: 'N', name: 'ضربان عادی', enName: 'Normal beat', backgroundColor: '#C1FFE4' },
  TYPE_L: { value: 120, label: 'L', name: 'ضربان بلوک شاخه چپ', enName: 'Left bundle branch block beat', backgroundColor: '#D5DDFF' },
  TYPE_R: { value: 130, label: 'R', name: 'ضربان بلوک شاخه راست', enName: 'Right bundle branch block beat', backgroundColor: '#D5DDFF' },
  TYPE_j: { value: 140, label: 'j', name: 'ضربان فرار گره‌ای (جوانک)', enName: 'Nodal (junctional) escape beat', backgroundColor: '#D5DDFF' },
  TYPE_A: { value: 150, label: 'A', name: 'ضربان پیش از موعد دهلیزی', enName: 'Atrial premature beat', backgroundColor: '#FFF3D3' },
  TYPE_a: { value: 160, label: 'a', name: 'ضربان پیش از موعد دهلیزی غیرعادی', enName: 'Aberrated atrial premature beat', backgroundColor: '#D5DDFF' },
  TYPE_J: { value: 170, label: 'J', name: 'ضربان پیش از موعد گره‌ای (جوانک)', enName: 'Nodal (junctional) premature beat', backgroundColor: '#FFF3D3' },
  TYPE_V: { value: 180, label: 'V', name: 'انقباض زودهنگام بطنی', enName: 'Premature ventricular contraction', backgroundColor: '#FCDCE2' },
  TYPE_E: { value: 190, label: 'E', name: 'ضربان فرار بطنی', enName: 'Ventricular escape beat', backgroundColor: '#FCDCE2' },
  TYPE_F: { value: 200, label: 'F', name: 'ادغام ضربان بطنی و عادی', enName: 'Fusion of ventricular and normal beat', backgroundColor: '#FFF3D3' },
  TYPE_P: { value: 210, label: '/', name: 'ضربان تنظیم‌شده', enName: 'Paced beat', backgroundColor: '#FFF3D3' },
  TYPE_f: { value: 220, label: 'f', name: 'ادغام ضربان تنظیم‌شده و عادی', enName: 'Fusion of paced and normal beat', backgroundColor: '#D5DDFF' },
  TYPE_U: { value: 230, label: 'U', name: 'ضربان غیرقابل شناسایی', enName: 'Unclassifiable beat', backgroundColor: '#C1FFE4'},
}

function getValueById(id: number, constant: any, targetValue: any) {
  const foundValue = constant.find((item: any) => item.value === id);
  return foundValue ? foundValue[targetValue] : null;
}

export {
  DEPARTMENT_TYPE,
  DEVICE_TYPE_OPTIONS,
  MULTIMEDIA_TYPE,
  DAY_OF_WEEK,
  VISIT_TYPE,
  VISIT_TYPE_ENUM,
  VISIT_STATUS,
  VISIT_STATUS_ENUM,
  VISIT_MODE,
  TIME_OF_DAY,
  ALLERGY_TYPE,
  ALLERGY_SEVERITY,
  VITAL_TYPE,
  BIOMETRIC_TYPE,
  DIAGNOSIS_SEVERITY,
  DIAGNOSIS_STATUS,
  ISSUER_TYPE,
  ISSUER_TYPE_ENUM,
  PARACLINICAL_PRESCRIPTION_SERVICE_TYPE,
  MEDICATION_SHAPE_IHIO,
  CIGARETTE_CONSUMPTION,
  PhysiotherapyIllnessConstant,
  PhysiotherapyTreatmentPlanDetails,
  MARITAL_STATUS,
  GENDER_ENUM,
  TAMIN_REPEAT_PERIOD_ENUM,
  TAMIN_REPEAT_COUNT,
  NOTIFICATION_TYPE_ENUM,
  PRESCRIPTION_STATUS_ENUM,
  PROVIDER_VISIT_STATUS_ENUM,
  ANNOUNCEMENT_TYPE_ENUM,
  ARRHYTHMIA_TYPE_ENUM,
  getValueById
}