import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import Modal from "../general/modal";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import Icon from "../general/icon";
import {
  useGetTaminReferralByTrackingCode,
} from "../../../hook/request/providerTamin";
import BoxLoader from "../general/boxLoader";
import {useMySearchParams} from "../../../hook/useMySearchParams";
import {TaminReferralType} from "../../../types/visit";
import {SubmitHandler, useForm} from "react-hook-form";
import NumberField from "../general/form/numberField";
import {ISSUER_TYPE} from "../../../constants/constant/enum";
import {localStorageGet, localStorageRemove} from "../../../utils/localStorage";
import {toast} from "react-hot-toast";


type TaminReferralTrackingCodeType = {
  trackingCode: number
}

type TaminReferralTrackingCodeModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
  handleShowModal: any
}

type ModalInfoProps = {
  patientId: number,
  setTaminReferralData?: React.Dispatch<React.SetStateAction<TaminReferralType | null>>,
}

// بعد از گرفتن تعداد ارجاع های بیمار به تخصص پزشک، مودال وارد کردن کد ارجاع رو داریم.
const TaminReferralTrackingCodeModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
 handleShowModal
}: TaminReferralTrackingCodeModalProps) => {

  const { getQueryParams } = useMySearchParams();
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    patientId: 0,
    setTaminReferralData: undefined,
  });
  const [trackingCode, setTrackingCode] = useState<number>(0);
  const [fetch, setFetch] = useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState<TaminReferralTrackingCodeType>({trackingCode: 0});
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });

  const getTaminReferralByTrackingCodeRequest = useGetTaminReferralByTrackingCode(modalInfo.patientId, trackingCode, false)

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'taminReferralTrackingCodeModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
      var data = {
        id: modalData?.id
      }
    }
  }, [modalData, modalName])

  useEffect(() => {
    if(trackingCode) {
      getTaminReferralByTrackingCodeRequest.refetch()
    }
  }, [trackingCode, fetch]);

  useEffect(() => {
    if(getTaminReferralByTrackingCodeRequest?.data?.data?.result) {
      modalInfo?.setTaminReferralData && modalInfo?.setTaminReferralData(getTaminReferralByTrackingCodeRequest?.data?.data?.result);
      handleSetShowModal()
    }
  }, [getTaminReferralByTrackingCodeRequest?.data]);

  const checkForTaminAuthorization = () => {
    const taminAuthorization = localStorageGet('TAMIN_ACCESS_TOKEN');
    if (!taminAuthorization) {
      return false; // No authorization token found
    }

    const expireTime = taminAuthorization.date_time + (taminAuthorization.expires_in * 1000);
    if (expireTime > Date.now()) {
      return true; // Token is valid
    }

    // Token is expired, remove it
    localStorageRemove('TAMIN_ACCESS_TOKEN');
    return false;
  };

  const getReferralData: SubmitHandler<TaminReferralTrackingCodeType> = (data) => {
    if(checkForTaminAuthorization()) {
      const {trackingCode} = data
      setTrackingCode(trackingCode)
      setFetch(!fetch)
    } else {
      toast(
        <div>
          <div className="d-flex align-items-center">
            <span className='d-flex align-items-center font-25 ms-2'>
              <Icon name='tamin-logo' />
            </span>
            <span>
              پزشک گرامی ورود شما به سامانه تأمین اجتماعی برای تجویز نسخه الزامی است.
            </span>
          </div>
          <div
            className="d-flex align-items-center justify-content-center justify-content-md-end m-md-0 px-2"
            onClick={() => {
              handleSetShowModal();
              handleShowModal('taminLoginModal')
            }}
          >
            <span
              className="btn btn-dark rounded rounded-3 font-10 d-flex align-items-center fw-bold px-3"
            >
              ورود به سامانه
            </span>
          </div>
        </div>,
        {
          position: 'top-center',
          duration: 10000,
          style: {
            borderRadius: '10px',
            background: "#f8d7da",  // Semi-transparent background
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
            overflow: "hidden",
            backdropFilter: 'blur(10px)',  // Blur effect behind the component
            WebkitBackdropFilter: 'blur(10px)', // For compatibility with WebKit browsers
            width: '1000px'
          },
        }
      );
    }
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setTrackingCode(0)
    reset();
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      backdropClassName="zIndex-1055"
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          getTaminReferralByTrackingCodeRequest?.isLoading
        ) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              وارد کردن کد ارجاع تامین اجتماعی
            </h4>
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(getReferralData)}>
          <div className="d-flex">
            <div className="w-100 px-4">
              <div className="form-group">
                <NumberField
                  name="trackingCode"
                  placeholder="کد ارجاع را وارد کنید"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className=" px-2 d-flex justify-content-end">
              <div className="d-flex form-group">
                <button
                  type='submit'
                  className="d-flex align-items-center btn btn-primary rounded text-nowrap me-2 px-4 p-0"
                >
                  تایید
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaminReferralTrackingCodeModal);