import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import BoxLoader from "../../../general/boxLoader";
import {useAddDraftPrescription} from "../../../../../hook/request/visit/draftPrescription";
import Icon from "../../../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector, modalDataSelector,
  modalNameSelector
} from "../../../../../store/selector/general";
import {general} from "../../../../../store/action";
import TextField from "../../../general/form/textField";
import Modal from "../../../general/modal";


type FavoritePrescriptionModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  providerId: string,
  prescriptionData: any,
  draftMedication?: boolean,
  navigateToList?: () => void;
}

type DraftPrescription = {
  draftTitle: string
}

const FavoritePrescriptionModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: FavoritePrescriptionModalProps) => {
  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    providerId: '',
    prescriptionData: '',
  });

  const [fieldValue, setFieldValue] = useState<DraftPrescription>({draftTitle: ''});
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
  });

  // services
  const addDraftPrescriptionRequest = useAddDraftPrescription({
    onSuccess: () => {
      handleSetShowModal();
    }
  });

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'favoritePrescriptionModal'
    ) {
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])

  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);

  const addDraftPrescription: SubmitHandler<DraftPrescription> = (data) => {
    const updatedPrescriptionData = modalInfo?.prescriptionData.map
    (({ bodySite, bodySiteDescription, draft, id, patientInstruction, prescription, provider,
      allowed, brand, checkCode, contract, maxCoveredCount, message,
        ...rest}: any) => rest || [])
    // const updatedModalInfo = {
    //   ...modalInfo,
    //   prescriptionData: restPrescriptionData
    // };
    // console.log(restPrescriptionData)
    const { draftTitle } = data,
      body = {
        draftTitle,
        medicationItems: modalInfo?.draftMedication ? updatedPrescriptionData.map((item: any) => ({
          dosage: { id: item.dosage },  // Correct object structure
          frequency: { id: item.frequency },
          item: item.item,
          itemCount: item.itemCount,
        })) : null,
        paraclinicalItems: !modalInfo?.draftMedication ? updatedPrescriptionData.map((item: any) => ({
          service: item.service,
          itemCount: item.itemCount,
          note: item?.note,
          illness: item?.illness,
          plan: item?.plan,
        })) : null,
        draftMedication: modalInfo?.draftMedication,
      };
    addDraftPrescriptionRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue({draftTitle: ''})
    handleHideModal();
    modalInfo?.navigateToList && modalInfo.navigateToList();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      backdropClassName="zIndex-1055"
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      {
        (
          addDraftPrescriptionRequest?.isPending
        ) && <BoxLoader type="cover"/>}
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              افزودن به لیست نسخ پراستفاده
            </h4>
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(addDraftPrescription)}>
          <div className="d-flex">
            <div className="w-100 px-4">
              <div className="form-group">
                <TextField
                  name="draftTitle"
                  placeholder="نام نسخه دارویی"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className=" px-2 d-flex justify-content-end">
              <div className="d-flex form-group">
                <button
                  type='submit'
                  className="d-flex align-items-center btn btn-primary rounded text-nowrap me-2 px-4 p-0"
                >
                  ثبت نسخه
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoritePrescriptionModal);