import React from "react";
import FavoriteParaclinicalItemRowItem from "./favoriteParaclinicalItem_rowItem";


type FavoriteParaclinicalItemRowProps = {
  item?: any
  pushFavoriteParaclinicalItem: any
}


const FavoriteParaclinicalItemRow = ({
 item,
 pushFavoriteParaclinicalItem
}: FavoriteParaclinicalItemRowProps) => {

  // Function to handle changes in body checkboxes
  const handleBodyCheckboxChange = (checked: boolean, item: any) => {
    pushFavoriteParaclinicalItem(item, checked)
  };

  return (
    <div className="form-group">
      <FavoriteParaclinicalItemRowItem
        key={item.id}
        item={item}
        onChange={handleBodyCheckboxChange}
      />
    </div>
  );
};


export default FavoriteParaclinicalItemRow