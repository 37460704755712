import {useMutation, useQuery} from "@tanstack/react-query";
import {PatientEHRService} from "../../../services/requests/ehr/patientSummary";
import {PatientService} from "../../../services";

const useGetPatientSummary = () => {
  return useMutation({
    mutationFn: PatientEHRService.getPatientSummary,
  })
}

const useGetPatientEHRPrescriptions = (params: any, modalName: boolean) => {
  return useQuery({
    queryKey: ["getPatientEHRPrescriptions", params],
    queryFn: ({ queryKey }: any) => {
      const [_, params] = queryKey;
      return PatientEHRService.getPatientEHRPrescriptions(params);
    },
    enabled: modalName ?? true
  });
}

const useGetPatientEHRVisit = () => {
  return useMutation({
    mutationFn: PatientEHRService.getPatientEHRVisit,
  })
}

const useGetPatientEHRAppointments = () => {
  return useMutation({
    mutationFn: PatientEHRService.getPatientEHRAppointments,
  })
}

export {
  useGetPatientSummary,
  useGetPatientEHRPrescriptions,
  useGetPatientEHRVisit,
  useGetPatientEHRAppointments
}
