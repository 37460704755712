import * as React from "react"
import { SVGProps } from "react"
const DashboardDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={37}
    height={37}
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.0914 6.0137V12.3345L18.5372 16.6512V7.8637L23.9331 4.78036C24.8581 4.31786 26.0914 4.93454 26.0914 6.0137ZM3.89141 20.8137L9.28724 23.897L16.8414 19.5804L9.28724 15.2637L3.89141 18.347C2.81224 18.9637 2.81224 20.3512 3.89141 20.8137ZM27.7872 30.372V24.0512L20.2331 19.7345V28.522L25.6289 31.6054C26.5539 32.0679 27.7872 31.4512 27.7872 30.372Z"
      fill="#3F8DFD"
    />
    <path
      opacity={0.3}
      d="M13.1432 4.78036L18.5391 7.8637V16.6512L10.9849 12.3345V6.0137C10.9849 4.93454 12.2182 4.31786 13.1432 4.78036ZM11.4474 31.6054L16.8432 28.522V19.7345L9.28906 24.0512V30.372C9.28906 31.4512 10.5224 32.0679 11.4474 31.6054ZM33.1849 18.347L27.7891 15.2637L20.2349 19.5804L27.7891 23.897L33.1849 20.8137C34.2641 20.3512 34.2641 18.9637 33.1849 18.347Z"
      fill="#97ABFF"
    />
  </svg>
)
export default DashboardDiamond
