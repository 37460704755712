import * as React from "react"
import { SVGProps } from "react"
const TriangleBackground = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={26}
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.76528 14.703C0.496954 13.9219 0.496956 12.0781 1.76528 11.297L18.4512 1.02114C19.7838 0.20052 21.5 1.15917 21.5 2.72411L21.5 23.2759C21.5 24.8408 19.7838 25.7995 18.4512 24.9789L1.76528 14.703Z"
      fill="#3B425E"
    />
  </svg>
)
export default TriangleBackground
