import { API_PATH } from "../../../constants";
import { axiosRequest } from "../../api";

const PatientMisuse = {
  addMisusePatient(data: any) {
    return axiosRequest("POST", API_PATH.MISUSE.ADD, data);
  },
  getMisuseMaxaList(params: any) {
    return axiosRequest("GET",API_PATH.MAXA.GET_SUBSTANCE_MISUSE_LIST)
  },
  getPatientMisuseListAll(params:any){
    return axiosRequest('GET',API_PATH.MISUSE.GET_LIST_MISUSE(params))
  }
};

export { PatientMisuse };
