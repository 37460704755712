import React from 'react';
import Icon from '../icon';
import { Controller } from 'react-hook-form';

type InputWrapperProps = {
  renderInput: (value?: unknown, onChange?: (value: any) => void) => React.ReactElement
}

type TextFieldProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: any) => void;
  name: string;
  id?: string;
  value?: string;
  placeholder: string
  autoComplete?: string;
  className?: string;
  icon?: string;
  iconContent?: any;
  regexp?: any;
  readOnly?: boolean;
  disabled?: boolean;
  children?: string;
  error?: any;
  control?: any;
}

const TextField = ({
  onChange,
  onKeyDown,
  name,
  id,
  value,
  placeholder,
  autoComplete = 'off',
  className = '',
  icon,
  iconContent,
  regexp,
  readOnly,
  disabled,
  children,
  error,
  control
}: TextFieldProps) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, onFieldChange?: any) => {
    if(regexp && !regexp.test(e.target.value)) return false;
    onChange && onChange(e);
    onFieldChange && onFieldChange(e);
  }

  const InputWrapper = ({
    renderInput
  }: InputWrapperProps) => {
    return (
      control ? 
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => renderInput(value, onChange)}
        /> :
        renderInput()
    )
  }

  let showError = error?.[name] && error?.[name]?.message;
  return(
    <>
      <div className={`inp-box ${showError ? 'invalid-field' : ''}`}>
        { iconContent ? iconContent : <span className="inp-icon"><Icon name={icon} /></span> }
        <InputWrapper
          renderInput={(fieldValue?: any, onFieldChange?: any) => (
            <input 
              className={`form-control placeholder-field-color ${icon ? 'pl-5' : ''} ${className}`}
              type="text"
              value={fieldValue || value}
              name={name}
              id={id}
              onChange={(e) => handleChange(e, onFieldChange)}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              readOnly={readOnly}
              disabled={disabled}
              autoComplete={autoComplete}
            />
          )}
        />
        {children}
      </div>
      {showError && <p className="text-danger font-10 mt-1 me-2">{error?.[name]?.message}</p>}
    </>
  )
}

export default TextField;