import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../../../../../hook/useWindowSize";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import {general} from "../../../../../../store/action";
import {connect, useSelector} from "react-redux";
import {useGetPatientPrograms} from "../../../../../../hook/request/ehr/patientProgram";
import AddPatientProgramModal from "../../program/addPatientProgramModal";
import PatientProgramCardsBox from "../../program/dataList/patientProgramCardsBox";
import PatientProgramsTable from "../../program/dataList/patientProgramsTable";
import {TDataList} from "../../../../../../types/general";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";


const dataListTitles = [
  "عنوان برنامه",
  "تاریخ و ساعت شروع برنامه",
  "تاریخ و ساعت پایان برنامه",
  "محل برگزاری",
  "وضعیت برنامه",
]

type TFilterValues = {
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

type patientProgramSummaryProps = {
  patientData?: any;
  openTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const PatientProgramSummary = ({
 patientData,
 openTab,
 handleShowModal,
 visitEHRCheck = false,
}: patientProgramSummaryProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();

  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [programsData, setProgramsData] = useState<TDataList>({
    data: [],
    count: 0
  });
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    patient: patientData ? patientData : '0',
    pageNumber: "0",
    perPage: "3",
    search: "",
  });

  // services
  const getProgramsRequest = useGetPatientPrograms(getQueryParams(filterValues), openTab === 'patientSummary' && filterValues?.patient !== '0');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      patient: patientData,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "3",
      search: searchParams.get("search") || "",
    }
    setFilterValues(_filterValues)
  }, [searchParams, patientData]);

  useEffect(() => {
    getProgramsRequest?.data?.data?.result &&
    setProgramsData(getProgramsRequest?.data?.data.result)
  }, [getProgramsRequest.data])

  return (
    <>
      <div className="rounded">
        <section className=" d-flex flex-column align-items-center flex-md-row border-bottom">
          <div className="form-group ms-md-auto fw-bold mx-3 my-2">
            برنامه ها
          </div>
          {((visitEHRCheck && isProvider) || findAccessInAccessList('EHR_PROGRAM_CREATE')) &&
            <a className='p-1 d-flex align-items-center btn btn-dark rounded rounded-3 mx-3 my-2'
              onClick={() => handleShowModal('addPatientProgramModal', {
                patientId: patientData,
                patientData: patientData
              })}>
              <span className="m-1">افزودن برنامه</span>
            </a>
          }
        </section>
        <section className="mx-3 my-2">
          <section className="table-cont table-responsive">
            {
              width < 900 ?
                <PatientProgramCardsBox
                  dataList={programsData}
                  dataListTitles={dataListTitles}
                  isRequesting={getProgramsRequest.isPending}
                  readonly={true}
                /> :
                <PatientProgramsTable
                  dataList={programsData}
                  dataListTitles={dataListTitles}
                  isRequesting={getProgramsRequest.isPending}
                  readonly={true}
                />
            }
          </section>
        </section>
      </div>
      {openTab === 'patientSummary' && <AddPatientProgramModal/>}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientProgramSummary);
