// Check Validation
export const formValidation = (values, validator) => {
  return Object.keys(values).reduce((acc, key) => {
      const newError = validator[key](values[key]);
      return {
        errors: {
          ...acc.errors,
          ...(newError && { [key]: newError })
        }
      };
    },
      {
        errors: {}
      }
  );
}

// is Validate
export const isValidate = (formValidation) => {
  return !Object.values(formValidation?.errors || {}).length
}

// Validation Rules
export const textValidation = (fieldName, fieldValue) => {
  let _fieldValue = fieldValue + '';
  if (!fieldValue || _fieldValue.trim() === '') {
    return `${fieldName} اجباری است.`
  }
  return null;
};

export const lengthValidation = (fieldName, fieldValue, length) => {
  let _fieldValue = fieldValue + '';
  if (!fieldValue || _fieldValue.trim() === '') {
    return `${fieldName} اجباری است.`
  }
  if(_fieldValue.length === length) return null;
  // return i18n.t('general.validation_min_length', { fieldName: i18n.t(`fields.${fieldName}`), length })
};

export const isRequiedValidation = (fieldName, fieldValue, validationText) => {
  // if((fieldValue?.constructor === Array && fieldValue.length === 0) || !fieldValue) return validationText ? validationText : i18n.t('general.validation_is_required', { fieldName: i18n.t(`fields.${fieldName}`) });
  return null;
};

export const numberValidation = (fieldName, fieldValue) => {
  let _fieldValue = fieldValue + '';
  if (!fieldValue || _fieldValue.trim() === '') {
    return `${fieldName} اجباری است.`
  }
  if (/^[0-9]+$/.test(_fieldValue)) {
    return null;
  }
  return `لطفاً یک ${fieldName} معتبر وارد کنید.`
}

export const decimalNumValidation = (fieldName, fieldValue) => {
  let _fieldValue = fieldValue && String(fieldValue);
  if (!fieldValue || _fieldValue.trim() === '') {
    return `${fieldName} اجباری است.`
  }
  if (/^[0-9]\d*(\.\d+)?$/.test(_fieldValue)) {
    return null;
  }
  // return i18n.t('general.validation_enter_valid_value', { fieldName: i18n.t(`fields.number`) })
}

export const emailValidation = (fieldName, fieldValue) => {
  if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(fieldValue)) {
    return null;
  }
  if (fieldValue.trim() === '') {
    // return i18n.t('general.is_required', { fieldName: i18n.t(`fields.${fieldName}`) })
  }
  // return i18n.t('general.validation_enter_valid_value', { fieldName: i18n.t(`fields.${fieldName}`) })
};

export const passwordValidation = (fieldName, fieldValue) => {
  let _fieldValue = fieldValue + '';
  if (!fieldValue || _fieldValue.trim() === '') {
    return `${fieldName} اجباری است.`
  }
  // if (/[^a-zA-Z -]/.test(fieldValue)) {
  //   return 'Invalid characters';
  // }
  if (fieldValue.trim().length < 3) {
    // return i18n.t('general.validation_min_length3', { fieldName: i18n.t(`fields.${fieldName}`) })
  }
  return null;
};

export const confirmPasswordValidation = (fieldName, fieldValue) => {
  let { mainField: _fieldValue, confirmField: _confirmFieldValue  } = fieldValue;
  if (!_fieldValue || _fieldValue.trim() === '') {
    return `${fieldName} اجباری است.`
  }
  if (_fieldValue.trim().length < 3) {
    // return i18n.t('general.validation_min_length3', { fieldName: i18n.t(`fields.${fieldName}`) })
  }
  if(_fieldValue !== _confirmFieldValue[1]){
    // return i18n.t('general.validation_same_value', { fieldName1: i18n.t(`fields.${fieldName}`), fieldName2: i18n.t(`fields.${_confirmFieldValue[0]}`) })
  }
  return null;
};




export const  truncate = (source, size)=> {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}