import axios from 'axios';
import axiosRetry from 'axios-retry';
import { localStorageGet } from '../utils/localStorage';
import Noti from '../view/components/general/noti';
import {API_PATH} from "../constants";
import {config} from "../config";

axiosRetry(axios, { retries: 1 });
axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay });

axios.interceptors.request.use(
  axiosConfig => {
    const _token = localStorageGet('USER_DATA')?.token;
    const _tamin = localStorageGet('TAMIN_ACCESS_TOKEN')?.access_token;
    axiosConfig.timeout = 30000;
    axiosConfig.headers["HD-Authorization"] = _token || "";
    // ریکوئست هایی که به تامین میرسن نیاز به هدر تامین دارن
    if(axiosConfig.url.endsWith(API_PATH.PRESCRIPTION.ADD) || axiosConfig.url.startsWith(`${config?.apiUrl?.base}/provider/tamin/referral/count/`) || axiosConfig.url.startsWith(`${config?.apiUrl?.base}/provider/tamin/referral/`)) {
      axiosConfig.headers["TAMIN_ACCESS_TOKEN"] = _tamin || "";
    }
    return axiosConfig;
  },
  error => {
    return Promise.reject(error);
  });

axios.interceptors.response.use(
  response => {
    if(response.data.status === 403) {
      window.location = `${window.location.origin}/auth/login?returnUrl=${window.location.pathname}`;
      return Promise.reject(false);
    }
    return response;
  },
  error => {
    if(!error?.response) Noti('error', 'لطفاً چند لحظه بعد دوباره تلاش کنید!');
    if(error?.response?.status === 401) {
      window.location = `${window.location.origin}/auth/login?returnUrl=${window.location.pathname}`;
      return Promise.reject(false);
    }
    return Promise.reject(error?.response);
  });

// let pendingRequests = '';
// const abortPendingRequests = () => {
//   if (!!pendingRequests) {
//     pendingRequests('REQUEST_CANCELLED');
//     pendingRequests = null;
//   }
// };

export const axiosRequest = (reqType, url, data, headers) => {
  // abortPendingRequests();
	let reqData = {
    headers: headers,
		method: reqType, // get, post, put, delete, patch
		url,
    // cancelToken: new axios.CancelToken(function executor(c) {
    //   pendingRequests = c;
    // }),
		...(data ? { data } : {})
	}
	return axios({
			...reqData
		}).then(res => {
			return res;
		})
		.catch(err => {
			throw err;
		})
}
