import { all, call, delay, spawn } from 'redux-saga/effects';
import generalSaga from './general';
import authSaga from './auth';
import clinicSaga from './clinic';

const makeRestartable = (saga) =>
  function* () {
    yield spawn(function* () {
      while (true) {
        try {
          yield call(saga);
        } catch (e) {
          console.error('Saga error, the saga will be restarted', e);
        }
        yield delay(1000);
      }
    });
  };

const rootSagas = [
  generalSaga,
  authSaga,
  clinicSaga,
].map(makeRestartable);

export default function* rootSaga() {
  yield all(rootSagas.map((saga) => call(saga)));
}
