import React, {useState} from "react";
import {Accordion, Form} from "react-bootstrap";
import {useWindowSize} from "../../../../../hook/useWindowSize";
import FavoriteParaclinicalPrescriptionAccordionItem from "./favoriteParaclinicPrescription_accordionItem";
import Icon from "../../../general/icon";
import Tooltip from "../../../general/tooltip";
import {general} from "../../../../../store/action";
import {connect} from "react-redux";

type FavoriteParaclinicalPrescriptionRowAccordionProps = {
  item?: any;
  draftMedication?: boolean;
  handleShowModal: any;
}

const FavoriteParaclinicalPrescriptionRowAccordion = ({
 item,
 draftMedication,
 handleShowModal
}: FavoriteParaclinicalPrescriptionRowAccordionProps) => {

  const [width] = useWindowSize();

  return (
    <Accordion.Item eventKey={item?.id} className='mb-3 rounded border'>
      <Accordion.Header className='d-flex flex-wrap'>
        <div className="d-flex me-auto font-12 ms-2 fw-bold py-2"
             onClick={(e) => e.stopPropagation()}
        >
          <span className="d-flex align-items-center mx-2">
            {item?.draftTitle}
          </span>
          <Tooltip alt={'حذف'} className="tooltip-pre-line">
            <span
              onClick={() => handleShowModal('deleteFavoritePrescriptionModal', item?.id)}
              className="d-flex text-red-hover mx-2 font-20 cur-pointer"
            >
              <Icon name='trash'/>
            </span>
          </Tooltip>
        </div>
        {width > 900 &&
          <span className='font-14 fw-bold me-2 text-black'>
            <span
              className={`bg-primary bg-opacity-10 rounded rounded-3 border border-primary font-12 fw-bold text-primary text-nowrap px-4 py-2 mx-2`}>
              {draftMedication ? ` حاوی ${item?.medicationItems?.length} قلم دارویی ` : ` حاوی ${item?.paraclinicalItems?.length} خدمت پاراکلینیک `}
            </span>
        </span>
        }
      </Accordion.Header>
      <Accordion.Body className='rounded-bottom'>
        <div className=" ">
          <div className="form-group">
            {
              draftMedication ? item?.medicationItems?.map((item:any, index: number) => {
                return (
                  <FavoriteParaclinicalPrescriptionAccordionItem
                    key={item.id}
                    item={item}
                    index={index}
                    draftMedication={draftMedication}
                  />
                )
              }) : item?.paraclinicalItems?.map((item:any, index: number) => {
                return (
                  <FavoriteParaclinicalPrescriptionAccordionItem
                    key={item.id}
                    item={item}
                    index={index}
                    draftMedication={draftMedication}
                  />
                )
              })
            }
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(FavoriteParaclinicalPrescriptionRowAccordion);