import { config } from "../../config";

// all api paths
export const API_PATH = {
  GENERAL: {
    UPLOAD_MULTIMEDIA: `${config?.apiUrl?.base}/multimedia/upload`,
    // USER_INFO: `${config?.apiUrl?.base}/user/me`,
    // USER_STATUS: query => `${config?.apiUrl?.base}/user/status${query}`,
    // SET_ONLINE_STATUS: `${config?.apiUrl?.base}/user/status`,
    // SET_OFFLINE_STATUS: `${config?.apiUrl?.base}/user/status`,
  },
  AUTH: {
    LOGIN: `${config?.apiUrl?.base}/auth/web/login`,
    GET_CAPTCHA: `${config?.apiUrl?.base}/auth/captcha`,
    LOGOUT: `${config?.apiUrl?.base}/auth/logout`
  },
  ORGANIZATION: {
    LIST: (params) => `${config?.apiUrl?.base}/organization/healthUnit/list${params}`,
    INFO: `${config?.apiUrl?.base}/organization/healthUnit/get`,
    ADD: `${config?.apiUrl?.base}/organization/healthUnit/save`,
    DELETE: `${config?.apiUrl?.base}/organization/healthUnit/delete`,
    LIST_RELATED: (params) => `${config?.apiUrl?.base}/organization/healthUnit/list/related${params}`,
    ADD_RELATED: `${config?.apiUrl?.base}/organization/healthUnit/related/save`,
  },
  DOCTOR: {
    LIST: (params) => `${config?.apiUrl?.base}/provider/list${params}`,
    INFO: `${config?.apiUrl?.base}/provider/get`,
    MY_INFO: `${config?.apiUrl?.base}/provider/my-info`,
    ADD: `${config?.apiUrl?.base}/provider/save`,
    UPDATE_PROFILE: `${config?.apiUrl?.base}/provider/save/profile`,
    DELETE: `${config?.apiUrl?.base}/provider/delete`,
    SPECIALITIES_LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/speciality/list${params}`,
    VISIT_STATUS_CHANGE: `${config?.apiUrl?.base}/provider/visit-status/change`,
    VISIT_STATUS_GET: `${config?.apiUrl?.base}/provider/visit-status/get`,
  },
  SCHEDULE: {
    LIST: (params) => `${config?.apiUrl?.base}/provider/schedule/list${params}`,
    CURRENT: (params) => `${config?.apiUrl?.base}/provider/schedule/get/current${params}`,
    INFO: `${config?.apiUrl?.base}/provider/schedule/get`,
    ADD: `${config?.apiUrl?.base}/provider/schedule/save`,
    CLOSE: `${config?.apiUrl?.base}/provider/schedule/expire`,
    LIST_ITEM: `${config?.apiUrl?.base}/provider/schedule/item/list`,
    ADD_ITEM: `${config?.apiUrl?.base}/provider/schedule/item/save`,
    ACTIVE: `${config?.apiUrl?.base}/provider/schedule/active`,
    DELETE_ITEM: `${config?.apiUrl?.base}/provider/schedule/item/delete`,
  },
  VACATION: {
    LIST: (params) => `${config?.apiUrl?.base}/provider/vacation/list${params}`,
    INFO: `${config?.apiUrl?.base}/provider/vacation/get`,
    ADD: `${config?.apiUrl?.base}/provider/vacation/save`,
    DELETE: `${config?.apiUrl?.base}/provider/vacation/delete`,
  },
  DEVICE: {
    LIST: (params) => `${config?.apiUrl?.base}/device/list${params}`,
    INFO: `${config?.apiUrl?.base}/device/get`,
    ADD: `${config?.apiUrl?.base}/device/save`,
    DELETE: `${config?.apiUrl?.base}/device/delete`,
    ASSIGNABLE_ACCOUNTS_LIST: (params) => `${config?.apiUrl?.base}/device/assign/list/account${params}`,
    DEVICE_ASSIGNED_LIST: (params) => `${config?.apiUrl?.base}/device/assign/list${params}`,
    GET_ASSIGN: `${config?.apiUrl?.base}/device/assign/get`,
    ADD_ASSIGN: `${config?.apiUrl?.base}/device/assign/save`,
    DELETE_ASSIGN: `${config?.apiUrl?.base}/device/assign/delete`,
  },

  PENDING_DEVICE: {
    LIST: (params) => `${config?.apiUrl?.base}/device/pending/list${params}`,
    GET: `${config?.apiUrl?.base}/device/pending/get`,
    REJECT: `${config?.apiUrl?.base}/device/pending/reject`,
    APPROVE: `${config?.apiUrl?.base}/device/pending/approve`,
  },
  PATIENT: {
    LIST: (params) => `${config?.apiUrl?.base}/patient/list${params}`,
    INFO: `${config?.apiUrl?.base}/patient/get`,
    SAVE: `${config?.apiUrl?.base}/patient/save`,
    ADMISSION: `${config?.apiUrl?.base}/patient/admission`,
  },
  PATIENT_EHR: {
    PRESCRIPTION: (params) => `${config?.apiUrl?.base}/patient/ehr/prescription${params}`,
    VISIT: `${config?.apiUrl?.base}/patient/ehr/visit`,
    GET_LAST: `${config?.apiUrl?.base}/patient/ehr/getLast`,
    APPOINTMENT: `${config?.apiUrl?.base}/patient/ehr/appointment`,
  },
  PATIENT_VITAL: {
    LIST: (params) => `${config?.apiUrl?.base}/patient/vital/list${params}`,
    INFO: `${config?.apiUrl?.base}/patient/vital/get`,
    ADD: `${config?.apiUrl?.base}/patient/vital/save`,
    DELETE: `${config?.apiUrl?.base}/patient/vital/delete`,
    CHART: (params) => `${config?.apiUrl?.base}/patient/vital/chart${params}`,
  },
  PATIENT_BIOMETRIC: {
    LIST: (params) => `${config?.apiUrl?.base}/patient/biometric/list${params}`,
    INFO: `${config?.apiUrl?.base}/patient/biometric/get`,
    ADD: `${config?.apiUrl?.base}/patient/biometric/save`,
    DELETE: `${config?.apiUrl?.base}/patient/biometric/delete`,
    CHART: (params) => `${config?.apiUrl?.base}/patient/biometric/chart${params}`,
  },
  PATIENT_VACCINE: {
    LIST: (params) => `${config?.apiUrl?.base}/patient/vaccine/list${params}`,
    INFO: `${config?.apiUrl?.base}/patient/vaccine/get`,
    ADD: `${config?.apiUrl?.base}/patient/vaccine/save`,
    DELETE: `${config?.apiUrl?.base}/patient/vaccine/delete`,
  },
  PATIENT_CONDITION: {
    LIST: (params) => `${config?.apiUrl?.base}/patient/condition/list${params}`,
    INFO: `${config?.apiUrl?.base}/patient/condition/get`,
    ADD: `${config?.apiUrl?.base}/patient/condition/save`,
    DELETE: `${config?.apiUrl?.base}/patient/condition/delete`,
  },
  PATIENT_ALLERGY: {
    LIST: (params) => `${config?.apiUrl?.base}/patient/allergy/list${params}`,
    INFO: `${config?.apiUrl?.base}/patient/allergy/get`,
    ADD: `${config?.apiUrl?.base}/patient/allergy/save`,
    DELETE: `${config?.apiUrl?.base}/patient/allergy/delete`,
  },
  PATIENT_ATTACHMENT: {
    LIST: (params) => `${config?.apiUrl?.base}/patient/attachment/list${params}`,
    INFO: `${config?.apiUrl?.base}/patient/attachment/get`,
    ADD: `${config?.apiUrl?.base}/patient/attachment/save`,
    DELETE: `${config?.apiUrl?.base}/patient/attachment/delete`,
  },
  PATIENT_PROGRAM: {
    LIST: (params) => `${config?.apiUrl?.base}/patient/program/list${params}`,
    INFO: `${config?.apiUrl?.base}/patient/program/get`,
    ADD: `${config?.apiUrl?.base}/patient/program/save`,
    DELETE: `${config?.apiUrl?.base}/patient/program/delete`,
  },
  USER: {
    LIST: (params) => `${config?.apiUrl?.base}/operator/list${params}`,
    INFO: `${config?.apiUrl?.base}/operator/get`,
    MY_INFO: `${config?.apiUrl?.base}/operator/my-info`,
    ADD: `${config?.apiUrl?.base}/operator/save`,
    UPDATE_PROFILE: `${config?.apiUrl?.base}/operator/save/profile`,
    DELETE: `${config?.apiUrl?.base}/operator/delete`,
    GET_ACCESS: `${config?.apiUrl?.base}/dashboard/menu/access/group/account`,
    ADD_ACCESS: `${config?.apiUrl?.base}/dashboard/menu/access/account/save`,
    GET_ACCESS_PROCESS: `${config?.apiUrl?.base}/dashboard/menu/access/process/list`,
  },
  APPOINTMENT: {
    LIST: (params) => `${config?.apiUrl?.base}/appointment/list${params}`,
    INFO: `${config?.apiUrl?.base}/appointment/get`,
    ADD: `${config?.apiUrl?.base}/appointment/save`,
    STATUS: `${config?.apiUrl?.base}/appointment/status`,
  },
  VISIT: {
    LIST: (params) => `${config?.apiUrl?.base}/visit/list${params}`,
    INFO: `${config?.apiUrl?.base}/visit/get`,
    ADD: `${config?.apiUrl?.base}/visit/save`,
    STATUS: `${config?.apiUrl?.base}/visit/status`,
    CHECK_INSURANCE: `${config?.apiUrl?.base}/visit/checkInsurance`,
    EHR_CHECK: `${config?.apiUrl?.base}/visit/visitEHRCheck`,
    MEDICAL_HISTORY: `${config?.apiUrl?.base}/visit/medicalHistory`,
    ADMISSION: `${config?.apiUrl?.base}/visit/admission`,
    URGENT_REQUEST: `${config?.apiUrl?.base}/visit/urgent/request`,
    URGENT_APPROVE: `${config?.apiUrl?.base}/visit/urgent/approve`,
    URGENT_ACCEPT: `${config?.apiUrl?.base}/visit/urgent/accept`,
    VISIT_CALL_REQUEST: `${config?.apiUrl?.base}/visit/call/request`,
    VISIT_CALL_APPROVE: `${config?.apiUrl?.base}/visit/call/approve`,
    VISIT_CALL_ACCEPT: `${config?.apiUrl?.base}/visit/call/accept`,
  },
  PRESCRIPTION: {
    LIST: (params) => `${config?.apiUrl?.base}/prescription/list/my${params}`,
    RECENT_LIST: (params) => `${config?.apiUrl?.base}/prescription/list/recent${params}`,
    INFO: `${config?.apiUrl?.base}/prescription/get`,
    ADD: `${config?.apiUrl?.base}/prescription/save`,
    DELETE: `${config?.apiUrl?.base}/prescription/delete`,
    ITEM_IHIO_CHECK: `${config?.apiUrl?.base}/prescription/item/ihio-check`,
    INSURER_SAVE: `${config?.apiUrl?.base}/prescription/insurer-save`,
  },
  EXAMINATION: {
    LIST: (params) => `${config?.apiUrl?.base}/examination/list${params}`,
    INFO: `${config?.apiUrl?.base}/examination/get`,
    LAST: `${config?.apiUrl?.base}/examination/get/last`,
    LEADS: `${config?.apiUrl?.base}/examination/ecg-lead`,
    SEGMENT: `${config?.apiUrl?.base}/examination/ai/v1`,
  },
  PRESCRIPTION_MEDICATION_ITEM: {
    LIST: (params) => `${config?.apiUrl?.base}/prescription/medication/item/list${params}`,
    INFO: `${config?.apiUrl?.base}/prescription/medication/item/get`,
    ADD: `${config?.apiUrl?.base}/prescription/medication/item/save`,
    DELETE: `${config?.apiUrl?.base}/prescription/medication/item/delete`,
  },
  DRAFT_PRESCRIPTION: {
    LIST: (params) => `${config?.apiUrl?.base}/prescription/draft/list${params}`,
    INFO: `${config?.apiUrl?.base}/prescription/draft/get`,
    ADD: `${config?.apiUrl?.base}/prescription/draft/save`,
    DELETE: `${config?.apiUrl?.base}/prescription/draft/delete`,
  },
  DRAFT_PRESCRIPTION_MEDICATION_ITEM: {
    LIST: (params) => `${config?.apiUrl?.base}/prescription/draft/medication/item/list${params}`,
    INFO: `${config?.apiUrl?.base}/prescription/draft/medication/item/get`,
    ADD: `${config?.apiUrl?.base}/prescription/draft/medication/item/save`,
    DELETE: `${config?.apiUrl?.base}/prescription/draft/medication/item/delete`,
  },
  PRESCRIPTION_PARACLINICAL_ITEM: {
    LIST: (params) => `${config?.apiUrl?.base}/prescription/paraclinic/item/list${params}`,
    INFO: `${config?.apiUrl?.base}/prescription/paraclinic/item/get`,
    ADD: `${config?.apiUrl?.base}/prescription/paraclinic/item/save`,
    DELETE: `${config?.apiUrl?.base}/prescription/paraclinic/item/delete`,
  },
  DRAFT_PRESCRIPTION_PARACLINICAL_ITEM: {
    LIST: (params) => `${config?.apiUrl?.base}/prescription/draft/paraclinic/item/list${params}`,
    INFO: `${config?.apiUrl?.base}/prescription/draft/paraclinic/item/get`,
    ADD: `${config?.apiUrl?.base}/prescription/draft/paraclinic/item/save`,
    DELETE: `${config?.apiUrl?.base}/prescription/draft/paraclinic/item/delete`,
  },
  DIAGNOSIS: {
    LIST: (params) => `${config?.apiUrl?.base}/diagnosis/list${params}`,
    INFO: `${config?.apiUrl?.base}/diagnosis/get`,
    ADD: `${config?.apiUrl?.base}/diagnosis/save`,
    DELETE: `${config?.apiUrl?.base}/diagnosis/delete`,
  },
  ICD11: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/icd11/list${params}`,
    INFO: `${config?.apiUrl?.base}/setting/maxa/icd11/get`,
    ADD: `${config?.apiUrl?.base}/setting/maxa/icd11/save`,
    DELETE: `${config?.apiUrl?.base}/setting/maxa/icd11/delete`,
  },
  MAXA: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/list${params}`,
    lIST_SURGERY:(params) => `${config?.apiUrl.base}/setting/maxa/surgery/list${params}`,
    GET_MAXA:`${config?.apiUrl?.base}/setting/maxa/surgery/list`,
    INFO: `${config?.apiUrl?.base}/setting/maxa/get`,
    ADD: `${config?.apiUrl?.base}/setting/maxa/save`,
    DELETE: `${config?.apiUrl?.base}/setting/maxa/delete`,
    GET_SUBSTANCE_MISUSE_LIST:`${config?.apiUrl?.base}/setting/maxa/substanceMisuse/list?pageNumber=0&perPage=10`, //TODO: FIX THIS SHIIIIIIIIIIIIT!!!!!!!!!!!
    TAMIN_DIAGNOSIS_LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/tamin/diagnosis/list${params}`,
    TAMIN_COMPLAINT_LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/tamin/complaint/list${params}`
  },

  MEDICATION: {
    LIST: (params) => `${config?.apiUrl?.base}/prescription/setting/medication/list${params}`,
    FREQUENCY_LIST: (params) => `${config?.apiUrl?.base}/prescription/setting/medication/frequency/list${params}`,
    DOSAGE_LIST: (params) => `${config?.apiUrl?.base}/prescription/setting/medication/dosage/list${params}`,
    BRAND: (params) => `${config?.apiUrl?.base}/prescription/setting/medication/brand${params}`,
    INFO: `${config?.apiUrl?.base}/prescription/setting/medication/get`,
    ADD: `${config?.apiUrl?.base}/prescription/setting/medication/save`,
    DELETE: `${config?.apiUrl?.base}/prescription/setting/medication/delete`,
  },
  MEDICATION_CATEGORY: {
    LIST: (params) =>  `${config?.apiUrl?.base}/prescription/setting/medication/category/list${params}`,
    INFO: `${config?.apiUrl?.base}/prescription/setting/medication/category/get`,
    ADD: `${config?.apiUrl?.base}/prescription/setting/medication/category/save`,
    DELETE: `${config?.apiUrl?.base}/prescription/setting/medication/category/delete`,
  },
  PARACLINICAL_SERVICE: {
    LIST: (params) => `${config?.apiUrl?.base}/prescription/setting/service/list${params}`,
    INFO: `${config?.apiUrl?.base}/prescription/setting/service/get`,
    ADD: `${config?.apiUrl?.base}/prescription/setting/service/save`,
    DELETE: `${config?.apiUrl?.base}/prescription/setting/service/delete`,
  },
  VACCINE: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/vaccine/list${params}`,
    INFO: `${config?.apiUrl?.base}/setting/maxa/vaccine/get`,
    ADD: `${config?.apiUrl?.base}/setting/maxa/vaccine/save`,
    DELETE: `${config?.apiUrl?.base}/setting/maxa/vaccine/delete`,
  },
  CONDITION: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/condition/list${params}`,
    INFO: `${config?.apiUrl?.base}/setting/maxa/condition/get`,
    ADD: `${config?.apiUrl?.base}/setting/maxa/condition/save`,
    DELETE: `${config?.apiUrl?.base}/setting/maxa/condition/delete`,
  },
  PROGRAM: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/program/list${params}`,
    INFO: `${config?.apiUrl?.base}/setting/program/get`,
    ADD: `${config?.apiUrl?.base}/setting/program/save`,
    DELETE: `${config?.apiUrl?.base}/setting/program/delete`,
  },
  MENU: {
    LIST: `${config?.apiUrl?.base}/dashboard/menu/account/tree`,
    ADD: `${config?.apiUrl?.base}/dashboard/menu/save`,
    DELETE: (params) => `${config?.apiUrl?.base}/dashboard/menu/delete${params}`,
    ACCESS_GROUP_LIST: `${config?.apiUrl?.base}/dashboard/menu/access/group/list`,
  },
  IMAGING_SERVICE: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/imagingService/list${params}`,
    INFO: `${config?.apiUrl?.base}/setting/maxa/imagingService/get`,
    ADD: `${config?.apiUrl?.base}/setting/maxa/imagingService/save`,
    DELETE: `${config?.apiUrl?.base}/setting/maxa/imagingService/delete`,
  },
  LABORATORY_SERVICE: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/laboratoryService/list${params}`,
    INFO: `${config?.apiUrl?.base}/setting/maxa/laboratoryService/get`,
    ADD: `${config?.apiUrl?.base}/setting/maxa/laboratoryService/save`,
    DELETE: `${config?.apiUrl?.base}/setting/maxa/laboratoryService/delete`,
  },
  LABORATORY_SERVICE_CATEGORY: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/maxa/laboratoryServiceCategory/list${params}`,
    INFO: `${config?.apiUrl?.base}/setting/maxa/laboratoryServiceCategory/get`,
    ADD: `${config?.apiUrl?.base}/setting/maxa/laboratoryServiceCategory/save`,
    DELETE: `${config?.apiUrl?.base}/setting/maxa/laboratoryServiceCategory/delete`,
  },
  ALLERGY: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/allergy/list${params}`,
    INFO: `${config?.apiUrl?.base}/setting/allergy/get`,
    ADD: `${config?.apiUrl?.base}/setting/allergy/save`,
    DELETE: `${config?.apiUrl?.base}/setting/allergy/delete`,
  },
  ALLERGY_REACTION: {
    LIST: (params) => `${config?.apiUrl?.base}/setting/allergy/reaction/list${params}`,
    INFO: `${config?.apiUrl?.base}/setting/allergy/reaction/get`,
    ADD: `${config?.apiUrl?.base}/setting/allergy/reaction/save`,
    DELETE: `${config?.apiUrl?.base}/setting/allergy/reaction/delete`,
  },
  ACCOUNT: {
    CHANGE_PASSWORD_FORCE: `${config?.apiUrl?.base}/account/password/change/force`,
    CHANGE_PASSWORD: `${config?.apiUrl?.base}/account/password/change`,
    BLOCKING: `${config?.apiUrl?.base}/account/blocking`,
    LOGIN_HISTORY: (params) => `${config?.apiUrl?.base}/account/loginHistory${params}`,
  },
  SETTING:{
  GET_PROVIDER_INSURER:`${config?.apiUrl?.base}/provider/insurer/get`,
    SAVE_PROVIDER_INSURER:`${config?.apiUrl?.base}/provider/insurer/save`,
  },
  SURGERY:{
    GET_LIST_All:(params)=>`${config?.apiUrl?.base}/patient/surgery/list${params}`,
    ADD:`${config?.apiUrl?.base}/patient/surgery/save`,
  },
  MISUSE:{
    ADD:`${config?.apiUrl?.base}/patient/misuse/save`,
    GET_LIST_MISUSE:(params)=>`${config?.apiUrl?.base}/patient/misuse/list${params}`,
  },
  FAMILY_HISTORY:{
    ADD:`${config?.apiUrl?.base}/patient/familyHistory/save`,
    GET_LIST:(params)=> `${config?.apiUrl?.base}/patient/familyHistory/list${params}`,
    GET_FAMILY_RELATION:(params)=>`${config?.apiUrl?.base}/basic/familyRelation${params ? params : ''}`
  },
  CIGARETTE:{
    ADD:`${config?.apiUrl?.base}/patient/misuse/cigarette/save`,
    GET_LIST:(params)=>`${config?.apiUrl?.base}/patient/misuse/cigarette/list${params}`,
    CIGARETTE_LIST_All:(params)=>`${config?.apiUrl?.base}/basic/cigaretteFrequency${params ? params : ''}`
  },
  REPORT: {
    STABILITY_PERCENTAGE:`${config?.apiUrl?.base}/report/insurance-api/stability-percentage`,
    RESPONSE_TIME_PERCENTAGE:`${config?.apiUrl?.base}/report/insurance-api/response-time-percentage`,
    STATISTICS: `${config?.apiUrl?.base}/report/dashboard/statistics`,
    STATISTICS_PROVIDER: `${config?.apiUrl?.base}/report/dashboard/statistics/provider`,
    TOTAL_VISIT_COUNT_CHART: `${config?.apiUrl?.base}/report/visit/total`,
    VISIT_STATUS_CHART: `${config?.apiUrl?.base}/report/visit/chart-status`,
    VISIT_ISSUER_CHART: `${config?.apiUrl?.base}/report/visit/chart-issuer`,
    VISIT_ISSUER_DAILY_CHART: `${config?.apiUrl?.base}/report/visit/chart-issuer-daily`,
    VISIT_GENDER_CHART: `${config?.apiUrl?.base}/report/visit/chart-gender`,
    VISIT_AGE_CHART: `${config?.apiUrl?.base}/report/visit/chart-age`,
    PRESCRIPTION_ISSUER_DAILY_CHART: `${config?.apiUrl?.base}/report/prescription/issuer-daily`,
    VISIT_DAILY_CHART: `${config?.apiUrl?.base}/report/visit/chart-daily`,
    TOTAL_PATIENT_COUNT_CHART: `${config?.apiUrl?.base}/report/patient/total`,
    PATIENT_GENDER_CHART: `${config?.apiUrl?.base}/report/patient/chart-gender`,
    PATIENT_AGE_CHART: `${config?.apiUrl?.base}/report/patient/chart-age`,
  },
  VIDEO_CALL:{
    MEETING:`${config?.apiUrl?.base}/visit/meeting/connect`,
    LOBBY:`${config?.apiUrl?.base}/waiting-room/connect`,
    LOBBY_LIST:`${config?.apiUrl?.base}/waiting-room/visit/list`,
  },
  NOTIFICATION:{
    TOKEN:(params)=>`${config?.apiUrl?.base}/notification/token`,
    PUSH_MESSAGE:`${config?.apiUrl?.base}/notification/push-message`,
  },
  ANNOUNCEMENTS:{
    LIST: (params) => `${config?.apiUrl?.base}/announcement/list${params}`,
    LAST: (params) => `${config?.apiUrl?.base}/announcement/last${params}`,
    INFO: `${config?.apiUrl?.base}/announcement/get`,
    ADD: `${config?.apiUrl?.base}/announcement/save`,
    DELETE: `${config?.apiUrl?.base}/announcement/delete`,
  },
  PROVIDER_TAMIN:{
    AUTH: (params) => `${config?.apiUrl?.base}/provider/tamin/auth`,
    AUTH_VERIFY: (params) => `${config?.apiUrl?.base}/provider/tamin/auth/verify${params}`,
    REFERRAL_COUNT: (params) => `${config?.apiUrl?.base}/provider/tamin/referral/count/${params}`,
    GET_REFERRAL_BY_TRACKING_CODE: (patientId, trackingCode) => `${config?.apiUrl?.base}/provider/tamin/referral/${patientId}/${trackingCode}`,
  }
};