import {getCellValue} from "../../../../../../../utils/utils";
import {MtoJDateFormat} from "../../../../../../../utils/date";

type PatientCigarettesCardsBoxItemProps = {
  item: any,
  dataListTitles: string[],
}

const PatientCigarettesCardsBoxItem = ({
  item,
  dataListTitles,
}: PatientCigarettesCardsBoxItemProps) => {

  return (
    <div className="box-shadow rounded mb-4 px-4 py-3 mt-2 mx-2">
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="healthUnit">{dataListTitles[0]}</label>
        <span className="mr-2">{getCellValue(item?.frequency.name)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="provider">{dataListTitles[1]}</label>
        <span className="mr-2">{getCellValue(MtoJDateFormat(item?.startDate))}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="dateTime">{dataListTitles[2]}</label>
        <span className="mr-2">
          <bdi className="word-spacing-10">{getCellValue(MtoJDateFormat(item?.endDate))}</bdi>
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="visitType">{dataListTitles[3]}</label>
        <span className="mr-2">{getCellValue(MtoJDateFormat(item?.updateDate))}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="text-gray mb-0" htmlFor="visitType">{dataListTitles[4]}</label>
        <span className="mr-2">{getCellValue(item?.description)}</span>
      </div>
    </div>
  )
}

export default PatientCigarettesCardsBoxItem;