import { QueryCache, QueryClient } from "@tanstack/react-query"
import { THandleResponse } from "../../types/request"
import { ROUTES } from "../../constants";
import Noti from "../../view/components/general/noti";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: (attemptIndex) => {
        return Math.min(60000 * 1 ** attemptIndex, 600000);
      },
      // retry: (failureCount) => {
      //   console.log(failureCount)
      //   return failureCount < 2;
      // },
      // refetchInterval: 30000,
    },
  },
  queryCache: new QueryCache({
    onSuccess: (data) => {
      // console.log('----- success', data)
      // toast(`Something went wrong: ${error.message}`),
    },
    onError: (error) => {
      // console.log('----- error', error)
      // toast(`Something went wrong: ${error.message}`),
    }
  }),
})

export const handleResponse = ({ data, onSuccess, onError }: THandleResponse) => {
  const _status = data?.data?.status;
  if (_status === 200) onSuccess && onSuccess();
  else if (_status === 400) {
    onError && onError();
    Noti("error", data?.data?.msg);
  }
  else if (_status === 403) window.location.href = ROUTES.LOGIN.PATH;
  else Noti("error", data?.data?.msg);
}