import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useWindowSize } from "../../../../hook/useWindowSize";
import LoginHistoryTable from "./dataList/loginHistoryTable";
import LoginHistoryCardsBox from "./dataList/loginHistoryCardsBox";
import { useMySearchParams } from "../../../../hook/useMySearchParams";
import Pagination from "../../general/table/pagination";
import { useGetLoginHistory } from "../../../../hook/request/account";
import Icon from "../../general/icon";
import { ROUTES } from "../../../../constants";

const dataListTitles = [
  "نام",
  "شماره همراه",
  "آدرس IP",
  "تاریخ ورود",
  "دستگاه",
]

type TFilterValues = {
  pageNumber?: string;
  perPage?: string;
  search?: string;
}

const LoginHistorySection = ({
  backButton
}: {
  backButton?: boolean
}) => {

  const navigate = useNavigate();
  const [width] = useWindowSize();
  const [searchParams] = useSearchParams();
  const { getQueryParams, updateSearchParams } = useMySearchParams();
  const [loginHistoryData, setLoginHistoryData] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    pageNumber: "0",
    perPage: "10",
    search: "",
  });

  // services
  const getLoginHistoryRequest = useGetLoginHistory(getQueryParams(filterValues));

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || ""
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    getLoginHistoryRequest?.data?.data?.result &&
      setLoginHistoryData(getLoginHistoryRequest?.data?.data.result)
  }, [getLoginHistoryRequest.data])

  const doChangePage = (param: any) => {
    updateSearchParams({
      pageNumber: param?.index,
      perPage: filterValues?.perPage,
    })
  }

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        {
          backButton &&
            <button
              onClick={() => navigate(ROUTES?.ACCOUNT?.PATH)}
              className="btn-arrow-right btn btn-dark px-1 d-flex align-items-center justify-content-center size-47 font-25 shadow"
            >
              <Icon name='arrow-right' />
            </button>
        }
        <div className="d-flex align-items-center title-dot tile-dot-primary font-weight-bold text-black font-16 me-3">
          تاریخچه ورود به سیستم
        </div>
      </div>
      <section className="table-cont table-responsive">
        {
          width < 900 ?
            <LoginHistoryCardsBox
              dataList={loginHistoryData}
              dataListTitles={dataListTitles}
              isRequesting={getLoginHistoryRequest.isPending}
            /> :
            <LoginHistoryTable
              dataList={loginHistoryData}
              dataListTitles={dataListTitles}
              isRequesting={getLoginHistoryRequest.isPending}
            />
        }
        <Pagination
          paginationData={{...filterValues, ...loginHistoryData}}
          doChangePage={doChangePage}
        />
      </section>
    </>
  );
};

export default LoginHistorySection;