import { useSearchParams } from "react-router-dom";

export const useMySearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (params: any) => {
    const newSearchParams = new URLSearchParams(searchParams);
    for (const [key, value] of Object.entries(params)) {
      value || value === 0 ?
        newSearchParams.set(key, value + ""):
        newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
  }

  const getQueryParams = (params: any) => {
    Object.keys(params).forEach((k) => (params[k] == null || params[k] === "") && delete params[k]);
    const _queryParams = new URLSearchParams(params);
    return `?${_queryParams.toString()}`;
  }

  return { getQueryParams, updateSearchParams };

};