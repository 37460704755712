import BoxLoader from "../../../../general/boxLoader";
import React, {useEffect, useRef, useState} from "react";
import {useGetPatientVitalChartsData} from "../../../../../../hook/request/ehr/patientVital";
import {useMySearchParams} from "../../../../../../hook/useMySearchParams";
import {useSearchParams} from "react-router-dom";
import {MtoJDateFormat} from "../../../../../../utils/date";
import {VITAL_TYPE} from "../../../../../../constants/constant/enum";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {chartOptions} from "../../../../general/chartOptions";

type TFilterValues = {
  type?: number;
  patient?: string;
  pageNumber?: string;
  perPage?: string;
  search?: string;
  sort?: string;
}

type TChartProps = {
  patientId?: string;
  displayX?: boolean;
  displayY?: boolean;
  showLegend?: boolean;
  displayDataLabels?: boolean;
  showExportButton?: boolean
  openTab?: string;
}

const PatientPulseRateChart = ({
 patientId,
 displayX = true,
 displayY = true,
 showLegend = true,
 displayDataLabels = false,
 showExportButton = true,
 openTab,
}: TChartProps) => {

  const [searchParams] = useSearchParams();
  const { getQueryParams } = useMySearchParams();
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    type: VITAL_TYPE.PULSE_RATE.value,
    patient: patientId,
    pageNumber: "0",
    perPage: "10",
    search: "",
    sort: "asc",
  });

  //services
  const getVitalChartsRequest = useGetPatientVitalChartsData(getQueryParams(filterValues), openTab === 'pulseRate');

  useEffect(() => {
    let _filterValues = {};
    _filterValues = {
      ...filterValues,
      type: searchParams.get("type") || VITAL_TYPE.PULSE_RATE.value,
      patient: patientId,
      pageNumber: searchParams.get("pageNumber") || "0",
      perPage: searchParams.get("perPage") || "10",
      search: searchParams.get("search") || "",
      sort: searchParams.get("sort") || "asc",
    }
    setFilterValues(_filterValues)
  }, [searchParams]);

  useEffect(() => {
    if(!getVitalChartsRequest.isPending && getVitalChartsRequest?.data?.data?.result){
      const { data } = getVitalChartsRequest?.data?.data?.result;
      let _pulseRateData = [],
        _pulseRateLabel = [];
      if(data){
        _pulseRateData = data
          .map((item:any) => (item?.values[0]));
        _pulseRateLabel = data
          .map((item:any) => (`${MtoJDateFormat(item?.label)}`));
      }

      setData(_pulseRateData)
      setLabels(_pulseRateLabel)

    }
  }, [getVitalChartsRequest.data])

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return (
    <div>
      {getVitalChartsRequest.isPending ? <BoxLoader/> :
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions({
            chartType: 'column',
            data: [data],
            dataNames: ['ضربان قلب'],
            labels: labels,
            chartTitle: 'نمودار ضربان قلب بیمار',
            displayXLabels: displayX,
            displayYLabels: displayY,
            displayDataLabels: displayDataLabels,
            showLegend: showLegend,
            showExportButton: showExportButton
          })} ref={chartComponentRef}
        />
      }
    </div>
  );
};

export default PatientPulseRateChart;