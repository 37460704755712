import React, {useEffect, useMemo, useState} from "react";
import Modal from "../general/modal";
import Icon from "../general/icon";
import {
  isHideModalDataSelector,
  isShowModalDataSelector,
  modalDataSelector,
  modalNameSelector
} from "../../../store/selector/general";
import {general} from "../../../store/action";
import {connect} from "react-redux";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import PasswordField from "../general/form/passwordField";
import {TPasswordFields} from "../../../types/accountPassword";
import {accountPasswordSchema} from "../../../schema/accountPassword-schema";
import {useChangeAccountPassword} from "../../../hook/request/account";
import BoxLoader from "../general/boxLoader";


type ChangeAccountPasswordModalProps = {
  modalData: any;
  modalName: string;
  isShowModalData: boolean;
  handleHideModal: any;
  handleResetModal: any;
}

type ModalInfoProps = {
  firstName: string,
  lastName: string,
  accountId: string
}

const ChangeAccountPasswordModal = ({
 modalData,
 modalName,
 isShowModalData,
 handleHideModal,
 handleResetModal,
}: ChangeAccountPasswordModalProps) => {

  const [showState, setShowState] = useState(false);
  const [modalInfo, setModalInfo] = useState<ModalInfoProps>({
    firstName: '',
    lastName: '',
    accountId: ''
  });
  const fieldValueInitialState = {
    newPassword: '',
    repeatNewPassword: ''
  }
  const [fieldValue, setFieldValue] = useState<TPasswordFields>(fieldValueInitialState);
  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<TPasswordFields>({
    defaultValues: useMemo(() => fieldValue, [fieldValue]),
    resolver: yupResolver(accountPasswordSchema),
  });

  // services
  const changeAccountPasswordRequest = useChangeAccountPassword({
    onSuccess: () => {
      handleSetShowModal();
    }
  });

  useEffect(() => {
    if (
      isShowModalData &&
      modalName === 'changeAccountPasswordModal'
    ) {
      console.log(modalData)
      setShowState(true);
      setModalInfo(modalData);
      handleResetModal();
    }
  }, [modalData, modalName])


  useEffect(() => {
    reset(fieldValue);
  }, [fieldValue]);



  const changeAccountPassword: SubmitHandler<TPasswordFields> = (data) => {
    const { newPassword } = data,
      body = {
        accountId: modalInfo?.accountId,
        newPassword: newPassword,
      };
    changeAccountPasswordRequest.mutate(body);
  }

  const handleSetShowModal = () => {
    setShowState(false);
    setFieldValue(fieldValueInitialState)
    handleHideModal();
  }

  return (
    <Modal
      setShow={handleSetShowModal}
      showState={showState}
      noCloseBtn={true}
      className="w-1000 mw-100"
      bodyClassName="px-3 px-lg-5 pos-rel"
    >
      { changeAccountPasswordRequest?.isPending && <BoxLoader type="cover" /> }
      <div className="form-box w-1000 mw-100 mx-auto py-3">
        <div className='d-flex mb-4 fs-3 px-2'>
          <div className=" px-2 flex-fill align-content-center">
            <h4 className="d-flex align-items-center title-dot font-16 font-weight-bold">
              تغییر کلمه عبور
            </h4>
            {modalInfo?.firstName &&
              <span className="d-inline-flex fw-semibold text-primary mx-4 font-12">
                {modalInfo?.firstName + ' ' + modalInfo?.lastName}
              </span>
            }
          </div>
          <span className='text-red-hover align-content-center mt-2' onClick={() => handleSetShowModal()}>
              <Icon name='close-square'/>
          </span>
        </div>
        <form onSubmit={handleSubmit(changeAccountPassword)}>
          <div className="row">
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="password">‌کلمه عبور جدید</label>
                <PasswordField
                  name="newPassword"
                  placeholder="‌کلمه عبور"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 px-4">
              <div className="form-group mb-3">
                <label className="inp-lbl me-2 mb-2" htmlFor="password">‌تکرار کلمه عبور جدید</label>
                <PasswordField
                  name="repeatNewPassword"
                  placeholder="‌تکرار کلمه عبور"
                  className="ltr plc-right font-en"
                  control={control}
                  error={errors}
                />
              </div>
            </div>
            <div className="col-12 px-4 d-flex justify-content-end">
              <div className="d-flex form-group mb-5 mr-auto mt-auto pb-1">
                <button
                  type='button'
                  className="btn btn-outline-gray rounded d-flex align-items-center px-4 p-2"
                  onClick={() => handleSetShowModal()}
                >
                  انصراف
                </button>
                <button
                  type='submit'
                  className="btn btn-primary rounded d-flex align-items-center me-2 px-4 p-2"
                >
                  ویرایش کلمه عبور
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  modalData: modalDataSelector(state),
  modalName: modalNameSelector(state),
  isShowModalData: isShowModalDataSelector(state),
  isHideModalData: isHideModalDataSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  handleHideModal: () => dispatch(general.handleHideModal()),
  handleResetModal: () => dispatch(general.handleResetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeAccountPasswordModal);