import React, {useState} from "react";
import {general} from "../../../../../../store/action";
import {connect, useSelector} from "react-redux";
import {Nav, Tab} from "react-bootstrap";
import AddPatientBiometricModal from "../../biometric/addPatientBiometricModal";
import Icon from "../../../../general/icon";
import BiometricChartsSummary from "./dataList/biometricChartsSummary";
import BiometricsListSummary from "./dataList/biometricListSummary";
import {useFindAccessInAccessList} from "../../../../../../constants/constant/accessProcess";

type patientBiometricSummaryProps = {
  patientData?: any;
  initialOpenTab?: string;
  handleShowModal: any;
  visitEHRCheck?: boolean;
}

const PatientBiometricSummary = ({
 patientData,
 initialOpenTab,
 handleShowModal,
 visitEHRCheck = false,
}: patientBiometricSummaryProps) => {

  const isProvider = useSelector((state: any) => state.general.userDataReducer.isProvider);
  const {findAccessInAccessList} = useFindAccessInAccessList();
  const [openTab, setOpenTab] = useState('biometricsCharts')

  return (
    <>
      <Tab.Container
        id="biometrics-list-chart-tabs"
        defaultActiveKey="biometricsCharts"
        onSelect={(eventKey) => setOpenTab(eventKey || '')}
      >
        <div className="rounded">
          <section className=" d-flex flex-column align-items-center flex-md-row border-bottom">
            <div className="form-group ms-md-auto fw-bold mx-3 my-2">
              بایومتریک
            </div>
            <Nav variant="pills" justify>
              <div className={`d-flex bg-primary-subtle vital-list-chart-nav my-2 ${!visitEHRCheck ? 'mx-3' : ''}`}>
                <Nav.Item>
                  <Nav.Link eventKey="biometricsList" className='font-20 d-flex'><Icon name='document'/></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="biometricsCharts" className='font-20 d-flex'><Icon name='graph'/></Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
            {((visitEHRCheck && isProvider) || findAccessInAccessList('EHR_BIOMETRIC_CREATE')) &&
              <a className='p-1 d-flex align-items-center btn btn-dark rounded rounded-3 text-nowrap mx-3 my-2'
                onClick={() => handleShowModal('addPatientBiometricModal', {
                  patientId: patientData,
                  patientData: patientData
                })}>
                <span className="m-1">افزودن بایومتریک</span>
              </a>
            }
          </section>
          <section className="mt-2">
            <Tab.Content>
              <Tab.Pane eventKey="biometricsList">
                <BiometricsListSummary patientData={patientData} openTab={openTab}/>
              </Tab.Pane>
              <Tab.Pane eventKey="biometricsCharts">
                {openTab === 'biometricsCharts' && <BiometricChartsSummary patientData={patientData}/>}
              </Tab.Pane>
            </Tab.Content>

          </section>
        </div>
      </Tab.Container>
      {initialOpenTab === 'patientSummary' && <AddPatientBiometricModal/>}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  handleShowModal: (data: any, body: any) => dispatch(general.handleShowModal(data, body)),
});

export default connect(null, mapDispatchToProps)(PatientBiometricSummary);
