import TableLoader from "../../general/table/tableLoader";
import EmptyRow from "../../general/table/emptyRow";
import React from "react";
import ExaminationTableRowItem from "./examinationsTable_rowItem";

type ExaminationTableProps = {
  dataList?: any;
  dataListTitles: string[];
  isRequesting: boolean
}

const ExaminationTable = ({
  dataList,
  dataListTitles,
  isRequesting
}: ExaminationTableProps) => {
  const _dataListTitles = dataListTitles.map((item, i) => <td key={i}>{item}</td>),
    _dataList = dataList?.data && dataList?.data.length > 0 && dataList?.data.map((item: any, i: number) => {
      return <ExaminationTableRowItem item={item} key={i}/>
    });

  return (
    <table>
      <thead>
      <tr>
        {_dataListTitles}
      </tr>
      </thead>
      <tbody>
      {isRequesting ?
        <TableLoader colSpan={dataListTitles.length}/> :
        _dataList ? _dataList : <EmptyRow colSpan={dataListTitles.length} content={''}/>
      }
      </tbody>
    </table>
  )
}

export default ExaminationTable;
